import { CenterProps, Flex } from '@chakra-ui/react'
import React from 'react'

export const InputContainerBase = (props: CenterProps) => (
  <Flex
    width='100%'
    borderRadius='6px'
    align='center'
    pos='relative'
    {...props}
  />
)
