import {
  Button,
  Flex,
  Stack,
  Text,
  Tooltip,
  useDisclosure,
} from '@chakra-ui/react'
import { generatePaymentSchedule, getAssessmentName } from '@hb/shared'
import React, {
  useCallback, useContext, useMemo, useState,
} from 'react'
import { useApp } from '../../../../contexts'
import { PopUpMessageContext } from '../../../../contexts/PopUpMessage/PopUpMessageContext'
import { UserContext } from '../../../../contexts/UserContext'
import { useUpdateDoc } from '../../../../hooks/backend/useUpdateDoc'
import { ActionButton } from '../../../Buttons/ActionButton'
import { NoteForm } from '../../../forms/NoteForm'
import LoadingIcon from '../../../LoadingIcon'
import { DefaultModal } from '../../../Modals/DefaultModal'
import { AssessmentDocuments } from '../Documents/AssessmentDocuments'
import { DraftView } from '../DraftView'
import { SelectAssessmentBox } from '../SelectAssessmentBox'
import { PaymentsDue } from './PaymentsDue'
import { PaymentsContext } from './PaymentsProvider'
import { ReceivedPayments } from './ReceivedPayments/ReceivedPayments'

export const NoPayments = () => {
  const { modal } = useContext(PaymentsContext)
  const { user, selectedAssessment } = useContext(UserContext)
  const { processResponse } = useContext(PopUpMessageContext)
  const [isLoading, setIsLoading] = useState(false)
  const { payments, adminRef } = selectedAssessment || {}
  const { appName } = useApp()
  const update = useUpdateDoc()
  const generate = useCallback(async () => {
    if (payments?.due) {
      return processResponse({ error: 'Patient already has payments' })
    }
    if (!selectedAssessment || !user) return processResponse({ error: 'no assessment' })
    setIsLoading(true)
    const res = await update(
      adminRef,
      'payments',
      generatePaymentSchedule(user, selectedAssessment),
    )
    setIsLoading(false)
    return processResponse(res)
  }, [user, adminRef, payments, processResponse, selectedAssessment, update])
  return (
    <Flex direction="column" bg="white" p={4} shadow="md">
      <Text fontSize="md">No payments scheduled for {user?.name}</Text>
      {appName === 'app' ? (
        <Flex mt={2}>
          <ActionButton isLoading={isLoading} onClick={generate} size="sm">
            Generate Payment Schedule
          </ActionButton>
          <ActionButton size="sm" ml={2} onClick={modal.onOpen}>
            Manual Entry
          </ActionButton>
        </Flex>
      ) : null}
    </Flex>
  )
}

export const AssessmentPayments = ({ small }: {small?: boolean}) => {
  const {
    selectedAssessment, loading, assessmentId, user,
  } = useContext(UserContext)
  const patientId = user?.id
  const { appName } = useApp()
  const {
    midwife, paymentsNote, payments, adminRef, practiceRef, drafts,
  } = selectedAssessment || {}
  const name = useMemo(
    () => (selectedAssessment ? getAssessmentName(selectedAssessment) : ''),
    [selectedAssessment],
  )
  const update = useUpdateDoc('payments')
  if (!midwife && loading) return <LoadingIcon />
  return (
    <SelectAssessmentBox overlay>
      {midwife ? (
        <Stack direction="column" spacing={3}>
          <NoteForm
            note={paymentsNote}
            onSubmit={(v) => update(appName === 'providers-app' ? practiceRef : adminRef, 'paymentsNote', v)}
          />
          {payments && Object.keys(payments).length ? (
            <>
              <PaymentsDue />
              <ReceivedPayments small={small} />
            </>
          ) : (
            <NoPayments />
          )}
          {appName === 'app' ? (
            <DraftView
              name="Receipts/Invoices Template"
              draft={drafts?.invoicesReceiptsTemplates}
              templateKey="invoiceAndReceipt"
            />
          ) : null}
          {assessmentId && patientId ? (
            <AssessmentDocuments
              adminView
              patientId={patientId}
              category="invoiceAndReceipt"
              assessmentId={assessmentId}
            />
          ) : null}
        </Stack>
      ) : (
        <Text p={4}>
          <i>No midwife assigned to {name}</i>
        </Text>
      )}
    </SelectAssessmentBox>
  )
}

export const PaymentsPopUp = () => {
  const { isOpen, onClose, onOpen } = useDisclosure()
  return (
    <>
      <Tooltip placement="top" label="Patient Payments">
        <Button
          onClick={onOpen}
          bg="green.600"
          color="white"
          position="absolute"
          left={2}
          top={2}
          size="xs"
        >
          $
        </Button>
      </Tooltip>
      <DefaultModal
        size="2xl"
        overlayHeader
        isOpen={isOpen}
        bodyProps={{ p: 2 }}
        onClose={onClose}
        render={() => <AssessmentPayments />}
      />
    </>
  )
}
