import { EmailIcon } from '@chakra-ui/icons'
import {
  Button,
  HStack,
  IconButton,
  Popover,
  PopoverContent,
  PopoverTrigger,
  Text,
  Tooltip,
  VStack,
} from '@chakra-ui/react'
import {
  colors,
  getInvoiceSent,
  objectToArray,
  PracticeAccess,
  PracticeInvoice,
  PRACTICE_ACCESS,
  WithId,
} from '@hb/shared'
import React, {
  useCallback, useContext, useMemo, useState,
} from 'react'
import { sendInvoiceNotificationEmail } from '../../../backend'
import { PopUpMessageContext } from '../../../contexts'
import { useDocument } from '../../../hooks/backend/useDocument'
import { ActionButton } from '../../Buttons'
import { PracticeUserBadge } from '../../Practices/PracticeAccess/PracticeAccessView'
import { SendInvoiceButton } from './SendInvoice'

const SendInvoiceBody = ({
  invoice,
  onSend,
  onClose,
}: {
  invoice: PracticeInvoice
  onSend: (sendTo: string) => Promise<void>
  onClose: () => void
}) => {
  const { data: practiceAccess } = useDocument<PracticeAccess>(
    PRACTICE_ACCESS,
    invoice.practiceId,
  )
  const [sendTo, setSendTo] = useState(practiceAccess?.sendInvoicesTo || null)

  const usersArr = useMemo(
    () => objectToArray(practiceAccess?.users || {}),
    [practiceAccess],
  )
  return (
    <VStack bg="gray.50" px={3} py={2} spacing={1}>
      <Text fontFamily="Hero-new">Send Notification Email To:</Text>
      {usersArr.length ? (
        usersArr.map((user, i) => (
          <PracticeUserBadge
            index={i}
            selected={sendTo === user.id}
            select={() => setSendTo(user.id)}
            key={user.id}
            user={user}
          />
        ))
      ) : (
        <Text color="gray.600" fontStyle="italic" p={2}>
          No users added to practice access
        </Text>
      )}
      <HStack ml="auto" spacing={3}>
        <Button size="sm" variant="link" onClick={onClose}>
          Cancel
        </Button>
        <ActionButton
          size="sm"
          isDisabled={!sendTo}
          onClick={() => onSend(sendTo || '')}
        >
          Send
        </ActionButton>
      </HStack>
    </VStack>
  )
}

const SendNotificationEmailButton = ({
  invoice,
}: {
  invoice: WithId<PracticeInvoice>
}) => {
  const [sending, setSending] = useState(false)
  const [isOpen, setIsOpen] = useState(false)
  const { processResponse } = useContext(PopUpMessageContext)
  const sendNotificationEmail = useCallback(
    async (sendTo: string) => {
      setSending(true)
      setIsOpen(false)
      try {
        await sendInvoiceNotificationEmail({ invoiceId: invoice.id, sendTo })
        processResponse({ success: 'Notification email sent successfully' })
      } catch (err: any) {
        processResponse({
          error: err?.message || 'Error sending notification email',
        })
      }
      setSending(false)
    },
    [invoice, processResponse],
  )
  return (
    <Popover
      isOpen={isOpen}
      onClose={() => setIsOpen(false)}
      onOpen={() => setIsOpen(true)}
      isLazy
    >
      <PopoverTrigger>
        <HStack>
          <Tooltip label="Send notification email">
            <IconButton
              variant="outline"
              size="sm"
              border={`1px solid ${colors.green.hex}`}
              color={colors.green.hex}
              isLoading={sending}
              aria-label="Send Notification Email"
              icon={<EmailIcon />}
            />
          </Tooltip>
        </HStack>
      </PopoverTrigger>
      <PopoverContent>
        <SendInvoiceBody
          onClose={() => setIsOpen(false)}
          invoice={invoice}
          onSend={sendNotificationEmail}
        />
      </PopoverContent>
    </Popover>
  )
}

export const InvoiceActions = ({
  invoice,
}: {
  invoice: WithId<PracticeInvoice>
}) => {
  const invoiceSent = useMemo(() => getInvoiceSent(invoice), [invoice])
  return (
  <HStack>
    {
      invoiceSent ? null : (
        <SendInvoiceButton invoice={invoice} />
      )
    }
    {invoice.status === 'sent' ? (
      <SendNotificationEmailButton invoice={invoice} />
    ) : null}
  </HStack>
  )
}
