import { ArrowForwardIcon } from '@chakra-ui/icons'
import {
  Box,
  Flex,
  IconButton,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  Tooltip,
  useDisclosure,
  VStack,
} from '@chakra-ui/react'
import {
  AssessmentInvoiceSnippet,
  colors,
  DropdownField,
  FieldTypes,
  WithId,
} from '@hb/shared'
import { INVOICES } from '@hb/shared/collections'
import { PracticeInvoice, UNSENT_INVOICE_STATUSES } from '@hb/shared/invoicing'
import {
  capitalizeFirstLetterOfEachWord,
  getDateString,
  objectToArray,
} from '@hb/shared/utils'
import {
  collection, Query, query, where,
} from 'firebase/firestore'
import React, {
  useCallback, useContext, useMemo, useState,
} from 'react'
import { db, manuallyMovePaymentToInvoice } from '../../backend'
import { PopUpMessageContext } from '../../contexts'
import { useQuery } from '../../hooks/backend/useQuery'
import { ActionButton } from '../Buttons'
import { StandaloneInput } from '../forms'

const getUnsentPracticeInvoicesQuery = (practiceId: string) => query(
  collection(db, INVOICES),
  where('practiceId', '==', practiceId),
  where('status', 'in', UNSENT_INVOICE_STATUSES),
) as Query<PracticeInvoice>

export const MovePaymentPopover = ({
  invoice,
  assessmentId,
  paymentId,
}: {
  invoice: WithId<PracticeInvoice | AssessmentInvoiceSnippet> | null
  assessmentId: string | undefined
  paymentId: string
}) => {
  const { practiceId } = invoice || {}

  const unsentInvoicesQuery = useMemo(
    () => (practiceId ? getUnsentPracticeInvoicesQuery(practiceId) : null),
    [practiceId],
  )

  const { data: unsentInvoices } = useQuery<PracticeInvoice>(unsentInvoicesQuery)

  const unsentInvoicesExceptCurrent = useMemo(
    () => objectToArray(unsentInvoices || {}).filter(
      (unsentInvoice) => unsentInvoice.id !== invoice?.id,
    ) || [],
    [unsentInvoices, invoice],
  )

  const { isOpen, onOpen, onClose } = useDisclosure()
  const { processResponse } = useContext(PopUpMessageContext)

  const [moveToId, setMoveToId] = useState<string | null>(null)

  const field = useMemo<DropdownField>(
    () => ({
      type: FieldTypes.DROPDOWN,
      placeholder: 'Select invoice',
      options: unsentInvoicesExceptCurrent.map((unsentInvoice) => ({
        id: unsentInvoice.id,
        text: `${capitalizeFirstLetterOfEachWord(
          unsentInvoice.status.split('-').join(' '),
        )} - ${unsentInvoice.scheduledFor
          ? `Scheduled for ${getDateString(unsentInvoice.scheduledFor, 'short', true)}`
          : `Due on ${getDateString(unsentInvoice.dueDate, 'short', true)}`}`,
      })),
    }),
    [unsentInvoicesExceptCurrent],
  )
  const handleClose = useCallback(() => {
    onClose()
    setMoveToId(null)
  }, [onClose])
  const [moveLoading, setMoveLoading] = useState(false)
  const onMove = useCallback(async () => {
    if (!moveToId) return
    const invoiceId = invoice?.id

    if (!invoiceId) return
    if (!assessmentId) {
      processResponse({ error: 'Assessment ID not found' })
      return
    }
    setMoveLoading(true)
    try {
      await manuallyMovePaymentToInvoice({
        assessmentId,
        fromInvoiceId: invoiceId,
        toInvoiceId: moveToId,
        paymentId,
      })
      processResponse({ success: 'Moved payment' })
      handleClose()
    } catch (err: any) {
      console.error(err)
      processResponse({ error: err?.message || 'Error moving payment' })
    }
    setMoveLoading(false)
  }, [moveToId, invoice, assessmentId, paymentId, processResponse, handleClose])

  return (
    <Popover
      closeOnBlur={false}
      isOpen={isOpen}
      onClose={handleClose}
      onOpen={onOpen}
      strategy="fixed"
    >
      <PopoverTrigger>
        <Flex>
          <Tooltip
            placement="top"
            hasArrow
            label={`Move ${paymentId === 'deposits' ? 'deposits' : 'payment'} to another invoice`}
            aria-label="Move payment"
          >
            <IconButton
              aria-label="Move payment"
              borderLeftRadius={0}
              size="xs"
              color={colors.green.hex}
              variant="ghost"
              colorScheme="gray"
              icon={<ArrowForwardIcon width={5} height={5} />}
            />
          </Tooltip>
        </Flex>
      </PopoverTrigger>
      <PopoverContent>
        <PopoverArrow />
        <PopoverCloseButton />
        <PopoverHeader>Move payment</PopoverHeader>
        <PopoverBody>
          <VStack position="relative" align="start" spacing={4}>
            {unsentInvoicesExceptCurrent.length === 0 ? (
              <Box>No other unsent invoices</Box>
            ) : (
              <StandaloneInput
                field={field}
                theme="detailed"
                value={moveToId}
                onChange={setMoveToId}
              />
            )}
            <ActionButton
              isLoading={moveLoading}
              onClick={onMove}
              size="sm"
              variant="outline"
            >
              Move payment
            </ActionButton>
          </VStack>
        </PopoverBody>
      </PopoverContent>
    </Popover>
  )
}
