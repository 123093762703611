import { useState } from 'react'

export const useImagesLoaded = (numImages: number) => {
  const [numLoaded, setNumLoaded] = useState(0)
  return {
    loaded: numLoaded >= numImages,
    onLoad: () => {
      setTimeout(() => {
        setNumLoaded((i) => i + 1)
      }, 250)
    },
  }
}
