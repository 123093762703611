import { CloseIcon, ExternalLinkIcon } from '@chakra-ui/icons'
import {
  Box,
  Button,
  Center,
  CircularProgress,
  Collapse,
  Flex,
  HStack,
  IconButton,
  Text,
  Tooltip,
  VStack,
} from '@chakra-ui/react'
import { colors } from '@hb/shared/constants'
import React, { useContext, useState } from 'react'
import { Link } from 'react-router-dom'
import { useApp } from '../../../contexts'
import { useArrayFromRecord } from '../../../hooks'
import { CollapseError } from '../../CollapseError'
import { PresetFilters } from '../../DataList/PresetFilters'
import { SimpleForm } from '../../forms'
import { BugReportPreview } from './BugReportView'
import { bugReportField, bugReportPresetFilters } from './constants'
import { BugReportsContext } from './context'
import { submitNewBugReport } from './utils'

const BugReportFilters = () => {
  const {
    filters, toggleFilter,
  } = useContext(BugReportsContext)
  return (
    <Flex justifyContent="flex-end" flex={1}>
      <PresetFilters
        presetFilters={bugReportPresetFilters}
        currentFilters={filters}
        toggleFilter={toggleFilter}
      />
    </Flex>
  )
}

export const BugReportsList = ({
  onClose,
  fullHeight,
}: {
  onClose?: () => void
  fullHeight?: boolean
}) => {
  const {
    bugReports: { data, loading, error },
    selectedBugReportId,
  } = useContext(BugReportsContext)
  const bugReportsArr = useArrayFromRecord(data || {})
  const [creatingNew, setCreatingNew] = useState(false)
  const { appName } = useApp()

  return (
    <VStack
      bg="#efefef"
      height={fullHeight ? '100%' : 'auto'}
      spacing={0}
      w="100%"
    >
      <Flex
        bg="gray.50"
        borderTopRadius={4}
        borderTopRightRadius={fullHeight ? 0 : 4}
        flexDir="column"
        w="100%"
      >
        <HStack
          borderTopRadius={4}
          borderTopRightRadius={fullHeight ? 0 : 4}
          px={2}
          py={2}
          bg="red.600"
          color="white"
          w="100%"
        >
          <Text fontFamily="Open Sans" color="white" fontWeight={600}>
            Bug Reports
          </Text>
          {onClose ? (
            <Link to="/admin/bug-reports">
              <Tooltip bg='gray.100' color='red.600' hasArrow placement='top' label="Go to Page">
                <IconButton
                  size="xs"
                  bg="transparent"
                  color="white"
                  _hover={{
                    bg: 'blackAlpha.300',
                  }}
                  aria-label="Go to Bug Reports Page"
                  icon={<ExternalLinkIcon width="14px" height="auto" />}
                />
              </Tooltip>
            </Link>
          ) : null}
          <HStack ml="auto">
            {creatingNew ? null : (
              <Button
                onClick={() => setCreatingNew(true)}
                borderRadius="full"
                size="xs"
                bg="blackAlpha.300"
                color="white"
              >
                + CREATE BUG REPORT
              </Button>
            )}
            {onClose ? (
              <IconButton
                onClick={onClose}
                aria-label="Close"
                bg="blackAlpha.400"
                borderRadius="full"
                icon={<CloseIcon />}
                size="xs"
                color="white"
                _hover={{ bg: 'red.700' }}
              />
            ) : null}
          </HStack>
        </HStack>
      </Flex>
      {creatingNew ? (
        <Box flex={fullHeight ? 1 : 'unset'} overflowY="auto" w="100%" p={2}>
          <SimpleForm
            field={bugReportField}
            theme="detailed"
            onCancel={() => setCreatingNew(false)}
            onSubmit={(submitted, onUploadProgress, form) => submitNewBugReport(appName, submitted, onUploadProgress).then(
              (res) => {
                if (typeof res === 'string') {
                  form.restart()
                  setCreatingNew(false)
                  return undefined
                }
                return res
              },
            )
            }
          />
        </Box>
      ) : (
        <VStack
          overflowY="hidden"
          flex={fullHeight ? 1 : 'unset'}
          w="100%"
          spacing={0}
        >
          <HStack
            bg="gray.50"
            borderBottom="1px solid #cdcdcd"
            py={1}
            zIndex={2}
            px={2}
            w="100%"
          >
            <BugReportFilters />
          </HStack>
          <Collapse style={{ width: '100%' }} in={!data && loading}>
            <Center w="100%" py={1}>
              <HStack spacing={1}>
                <CircularProgress
                  isIndeterminate
                  color={colors.green.hex}
                  size={5}
                />
                <Text color="gray.500" fontSize="sm">
                  Loading...
                </Text>
              </HStack>
            </Center>
          </Collapse>
          <Flex
            flex={fullHeight ? 1 : 'unset'}
            overflowY="auto"
            py={2}
            align="center"
            px={2}
            w="100%"
            gap={2}
            flexDir="column"
          >
            {bugReportsArr.length ? (
              bugReportsArr.map((bugReport) => (
                <BugReportPreview
                  selected={selectedBugReportId === bugReport.id}
                  key={bugReport.id}
                  bugReport={bugReport}
                />
              ))
            ) : (
              <Text py={1} color="gray.500" fontSize="sm">
                No bug reports yet
              </Text>
            )}
          </Flex>
          <CollapseError error={error} />
        </VStack>
      )}
    </VStack>
  )
}
