/* eslint-disable max-len */
import { InfoIcon } from '@chakra-ui/icons'
import { Stack, Text, Tooltip } from '@chakra-ui/react'
import {
  BigGreenButton, Info,
  InfoBox,
  InfoHeader,
  InfoStack,
  InfoText,
  MyAssessmentShortcut,
  PageContainer,
  PageHeader,
  ViewHeaderImage,
} from '@hb/shared-frontend'
import React from 'react'
import { Link } from 'react-router-dom'
import { useImagesLoaded } from '../../hooks/useImagesLoaded'
import patientsHeader from './patients-header.png'
import patientsImage3 from './patients-image-1.png'
import patientsImage2 from './patients-image-2.png'
import patientsImage1 from './patients-image-3.png'

const PatientsView = () => {
  const { loaded, onLoad } = useImagesLoaded(4)

  return (
    <PageContainer transition="opacity 300ms" opacity={loaded ? 1 : 0}>
      <PageHeader >Patients</PageHeader>
      <ViewHeaderImage onLoad={onLoad} src={patientsHeader} />
      <InfoBox spacing={2}>
        <InfoHeader>We’re here to help navigate coverage.</InfoHeader>
        <Text mb={2}>
          Dealing with insurance companies is nobody’s favorite pastime, but we’re here to shed some
          light on how it all works. For years we’ve been helping patients obtain and understand
          coverage from their insurance plans.
        </Text>
        <Text>
          See below for steps on receiving an assessment from our team on your specific plan’s
          coverage.
        </Text>
      </InfoBox>
      <Stack spacing={24} my={24}>
        <InfoStack direction={['column-reverse', 'column-reverse', 'row']}>
          <Info>
            <InfoHeader>1. Tell us a little about yourself</InfoHeader>
            <InfoText>
              To get started, please answer a few questions about yourself for a free assessment
              from our team!
            </InfoText>
          </Info>
          <ViewHeaderImage width={[200, 250, 300]} onLoad={onLoad} src={patientsImage1} />
        </InfoStack>
        <InfoStack>
          <ViewHeaderImage width={[200, 250, 300]} onLoad={onLoad} src={patientsImage2} />
          <Tooltip
            placement="bottom-end"
            py={2}
            px={4}
            borderRadius={4}
            bg="pink.300"
            label="Insurers will only divulge critical information to you as the member, which is partly why we ask that you call with specific questions. It’s also important down the road if you sign on with one of our providers!">
            <Info>
              <InfoHeader whiteSpace="nowrap">
                2. Call your insurance company
                <InfoIcon ml={2} color="blackAlpha.800" w={4} h={4} />
              </InfoHeader>
              <InfoText>
                Next, you’ll need to call your insurer with a list of questions so you can get the
                answers we need to assess your coverage.
              </InfoText>
            </Info>
          </Tooltip>
        </InfoStack>

        <InfoStack direction={['column-reverse', 'column-reverse', 'row']}>
          <Info>
            <InfoHeader>3. Receive an assessment</InfoHeader>
            <InfoText>
              After submitting all required information, our team does additional research so we can
              give you the most accurate assessment possible.
            </InfoText>
          </Info>
          <ViewHeaderImage width={[200, 250, 300]} onLoad={onLoad} src={patientsImage3} />
        </InfoStack>
      </Stack>
      <Link style={{ opacity: 1 }} to="/assessment">
        <BigGreenButton>Get started!</BigGreenButton>
      </Link>
      <MyAssessmentShortcut />
    </PageContainer>
  )
}

export default PatientsView
