import {
  Box, Collapse, ListItem, ListItemProps, Stack, Text, UnorderedList,
} from '@chakra-ui/react'
import {
  ActionButton, Bold, FormsViewContext, Italic, SolidActionButton,
} from '@hb/shared-frontend'
import React, { useContext } from 'react'
import { Disclaimer } from '../../Assessment/Disclaimer'

const DisclaimerListItem = (props: ListItemProps) => (
  <ListItem {...props} fontSize='md' fontWeight={500} color='gray.600' fontFamily='Open Sans' />
)

export const AssessmentDisclaimers: React.FC<{
  onSubmit: () => void
  viewedOn?: number
}> = ({ onSubmit, viewedOn }) => {
  const { selectStep, assessment } = useContext(FormsViewContext)
  const { disclaimers } = assessment || {}
  const { disclaimer1Accepted, disclaimer2Accepted } = disclaimers || { 1: false, 2: false }
  // const handleAcceptDisclaimer1 = async () => update(assessment.admin)
  // const setDisclaimer1Accepted = async () => {
  //   setLoadingDisclaimer1(true)
  //   await update(assessment?.ref, 'disclaimers.1', true)
  //   setLoadingDisclaimer1(false)
  // }
  // const setDisclaimer2Accepted = async () => {
  //   setLoadingDisclaimer2(true)
  //   // await update({ disclaimers: { 2: true } })
  //   setLoadingDisclaimer2(false)
  // }
  return (
    <Box mt={2}>
      <Box pt={4} mb={4} borderTop='1px solid #cdcdcd'>
        <Italic>
          Please read the following terms before viewing your assessment
        </Italic>
      </Box>
      <Disclaimer
        index={1}
        title='Disclaimer 1'
        accepted={!!disclaimer1Accepted}
      >
        <Text fontWeight={500} color='gray.600' fontFamily='Open Sans'>
        Please note that the following assessment is contingent upon the
        information you have gathered from your insurer. If you are at all
        uncertain about your answers, please schedule a call with us after
        receiving the assessment so we can assist.
        </Text>
      </Disclaimer>
      <Disclaimer
        index={2}
        title='Disclaimer 2'
        accepted={!!disclaimer2Accepted}
      >
        <span
          style={{
            textDecoration: 'underline',
            fontWeight: 500,
          }}
        >
          I accept and understand the following:
        </span>
        <UnorderedList mt={3} spacing={3}>
          <DisclaimerListItem >
            Hamilton Billing is providing me with an assessment{' '}
            <Bold>only</Bold> and not a guarantee of coverage from my plan
          </DisclaimerListItem>
          <DisclaimerListItem>
            The assessment I am receiving is based on projections for full
            maternity care (prenatal care, delivery and post partum care
            combined).
          </DisclaimerListItem>
          <DisclaimerListItem>
            The provider I have contacted is non-participating with any insurer,
            therefore ultimately, I agree to be responsible for any balance my
            insurer may not cover towards care should I sign up for services.
          </DisclaimerListItem>
          <DisclaimerListItem>
            <Bold color='gray.700' fontWeight={600}>
              Hamilton Billing is not responsible for outcomes on coverage or
              reimbursement from my plan that differ from any assessment
              provided.
            </Bold>
          </DisclaimerListItem>
          <DisclaimerListItem>
            Hamilton Billing does not work for any insurance company, nor can
            they predict future changes in my plan coverage nor decisions made
            by my insurer in the processing of claims.
          </DisclaimerListItem>
        </UnorderedList>
      </Disclaimer>
      <Stack w='100%' direction='column'>
        <ActionButton
          w='100%'
          variant={viewedOn ? 'outline' : 'solid'}
          isDisabled={!(disclaimer1Accepted && disclaimer2Accepted)}
          // size='sm'
          onClick={onSubmit}
        >
          View Assessment
        </ActionButton>
        <Collapse in={!!viewedOn} style={{ width: '100%' }}>
          <SolidActionButton
            w='100%'
            onClick={() => selectStep('signOn')}
            isDisabled={!viewedOn || !(disclaimer1Accepted && disclaimer2Accepted)}
            opacity={viewedOn ? 1 : 0}
            pointerEvents={viewedOn ? 'auto' : 'none'}
          >
            Proceed to Sign on
          </SolidActionButton>
        </Collapse>
      </Stack>
    </Box>
  )
}
