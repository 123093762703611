import { Text, VStack } from '@chakra-ui/react'
import {
  CheckboxField, DropdownField, FieldTypes, Provider,
} from '@hb/shared'
import React, { useContext, useMemo } from 'react'
import { UserContext } from '../../../../../../contexts/UserContext'
import { FormElement } from '../../../../../forms/Input'

const shouldUseCustomRenderingProviderField: CheckboxField = {
  placeholder: 'Should use custom rendering provider',
  type: FieldTypes.CHECKBOX,
  yesText: 'Assign custom rendering provider',
  noText: 'Assign billing provider as rendering provider',
  inputType: 'switch',
}

export const RenderingProvider = () => {
  const { selectedAssessment } = useContext(UserContext)
  const { midwife } = selectedAssessment || {}
  const { renderingProviders } = midwife || {}
  const renderingMidwifeField = useMemo<DropdownField<Provider>>(
    () => ({
      type: FieldTypes.DROPDOWN,
      options: (renderingProviders || []).filter((p) => !!p.npi).map((provider) => ({
        id: provider,
        text: `${provider.fname} ${provider.lname} | NPI: ${provider.npi}`,
      })),
      format: (v) => (v ? `${v.fname} ${v.lname} | NPI: ${v.npi}` : ''),
      getKey: (v) => v.npi || '',
      condition: (v) => !!v?.shouldUseCustomRenderingProvider,
      placeholder: 'Rendering provider',
      optional: true,
    }),
    [renderingProviders],
  )
  return (
    <VStack align='flex-start' w='100%' spacing={0} pt={2}>
      <Text fontWeight={600} color='gray.500' fontSize='sm'>
        Rendering provider
      </Text>
      {renderingProviders?.length ? (
        <>
          <FormElement
            name='shouldUseCustomRenderingProvider'
            field={shouldUseCustomRenderingProviderField}
          />
          <FormElement field={renderingMidwifeField} name='renderingProvider' />
        </>
      ) : (
        <Text color='gray.500' fontSize='sm'>
          {midwife?.name} has no rendering providers
        </Text>
      )}
    </VStack>
  )
}
