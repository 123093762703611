import { Flex, FlexProps } from '@chakra-ui/react'
import { colors } from '@hb/shared'
import React from 'react'

export const BoxHeader = (props: FlexProps) => (
    <Flex
      fontFamily='Comfortaa'
      fontSize='lg'
      color={colors.green.hex}
      px={0}
      py={1}
      fontWeight={600}
      {...props}
    />
)
