import {
  AdminUserData,
  authAppealsTemplatesCollection,
  authInstructionsTemplatesCollection,
  Claim,
  claimTemplatesCollection,
  clinicalsTemplatesCollection,
  consentFormTemplatesCollection,
  getInsuranceProviderId,
  getMidwifeId, invoiceTemplatesCollection, midwivesCollection, PopulatedAssessment, providersCollection, TemplateKey,
  User,
  UserContextData,
  USERS,
  USER_INVITES,
  ViewedTemplate,
} from '@hb/shared'
import {
  useCallback, useContext, useEffect, useMemo, useState,
} from 'react'

import { PopUpMessageContext } from '../../../contexts/PopUpMessage/PopUpMessageContext'
import { useAuth } from '../../../store/auth'
import { useCollectionItem } from '../useCollectionItem'
import { useDocument } from '../useDocument'
import { useAssessmentClaims } from './usePatientClaims'
import { useAssessmentFromId } from './usePopulatedAssessment'
import { usePopulatedUser } from './usePopulatedUser'
import { getEmailVerified } from './userUtils'
import { useUserAssessments } from './useUserAssessments'

export const useAssessmentMidwife = (
  assessment?: PopulatedAssessment,
) => useCollectionItem(midwivesCollection, getMidwifeId(assessment))
  .item

export const useAssessmentInsurer = (assessment?: PopulatedAssessment) => useCollectionItem(
  providersCollection,
  getInsuranceProviderId(assessment),
).item

export const useAdminItem = (id?: string) => useDocument<AdminUserData>('users-admin', id).data
export const useUserItem = (
  id?: string,
  isInvite?: boolean,
) => useDocument<User>(isInvite ? USER_INVITES : USERS, id).data

const useUserEmailVerified = (id?: string) => {
  const [verified, setVerified] = useState(false)
  const [loading, setLoading] = useState(false)
  const { processResponse } = useContext(PopUpMessageContext)

  const refetch = useCallback(() => {
    if (!id) {
      setVerified(false)
      return
    }
    setLoading(true)
    getEmailVerified(id)
      .then((updated) => {
        setVerified(updated)
        setLoading(false)
      })
      .catch((err: any) => {
        processResponse({
          error: err?.message || 'Error getting email verified',
        })
        setVerified(false)
        setLoading(false)
      })
  }, [id, processResponse])
  useEffect(refetch, [refetch])

  return { verified, loading, refetch }
}

export const useUserFromId = (
  admin: boolean,
  selectAssessment: (id: string | null) => void,
  _userId?: string,
  _assessmentId?: string,
  claimId?: string,
): UserContextData => {
  const { data: claim } = useDocument<Claim>('claims', claimId)
  const assessmentId = useMemo(
    () => (claimId ? claim?.assessmentId : _assessmentId),
    [claim, _assessmentId, claimId],
  )
  const { authUser: me, admin: isAdmin } = useAuth()
  const selectedAssessment = useAssessmentFromId(
    admin,
    me ? assessmentId : undefined,
  )

  const userId = useMemo(
    () => selectedAssessment?.patientId || _userId,
    [selectedAssessment, _userId],
  )

  const { data: assessments } = useUserAssessments(userId, true)
  const data = usePopulatedUser(userId, false, selectedAssessment?.midwifeId)

  const emailVerification = useUserEmailVerified(
    !data.loading && data.user && !data?.user?.isInvite ? userId : undefined,
  )
  const insuranceProvider = useAssessmentInsurer(selectedAssessment)

  const claims = useAssessmentClaims(assessmentId)

  const [viewedTemplateKey, setViewedTemplateKey] = useState<TemplateKey | null>(null)
  const viewedTemplate = useMemo<ViewedTemplate | null>(() => {
    switch (viewedTemplateKey) {
      case 'clinicals':
        return {
          templateType: 'clinicals',
          collection: clinicalsTemplatesCollection,
          id: insuranceProvider?.clinicalsTemplateId,
        }
      case 'authAppeals':
        return {
          templateType: 'authAppeals',
          collection: authAppealsTemplatesCollection,
          id: insuranceProvider?.authAppealsTemplateId,
        }
      case 'authInstructions':
        return {
          templateType: 'authInstructions',
          collection: authInstructionsTemplatesCollection,
          id: insuranceProvider?.authInstructionsTemplateId,
        }
      case 'invoiceAndReceipt':
        return {
          templateType: 'invoiceAndReceipt',
          collection: invoiceTemplatesCollection,
        }
      case 'claims':
        return {
          templateType: 'claims',
          collection: claimTemplatesCollection,
          id: insuranceProvider?.claimTemplateId,
        }
      case 'consentForm':
        return {
          templateType: 'consentForm',
          collection: consentFormTemplatesCollection,
        }
      default:
        return null
    }
  }, [viewedTemplateKey, insuranceProvider])

  return {
    ...data,
    viewedTemplate,
    setViewedTemplateKey,
    assessments,
    admin: isAdmin,
    selectedAssessment,
    emailVerification,
    claims,
    assessmentId,
    selectAssessment,
  }
}
