import { messageThreadCollections } from '../constants'
import { UserGroup, UserRoleItem, WithId } from '../types'
import { MessageThread, ThreadType } from '../types/messaging'
import { toSearchString } from './data'

export const getThreadMessageGroupCollectionPath = (
  threadType: ThreadType,
  threadId: string,
) => {
  const collectionId = messageThreadCollections[threadType]
  return `${collectionId}/${threadId}/messages`
}

export const getThreadPath = (
  threadType: ThreadType,
  threadId: string,
) => {
  const collectionId = messageThreadCollections[threadType]
  return `${collectionId}/${threadId}`
}

export const getThreadMessageGroupPath = (
  threadType: ThreadType,
  threadId: string,
  dateString: string,
) => {
  const collectionId = messageThreadCollections[threadType]
  return `${collectionId}/${threadId}/messages/${dateString}`
}

export const getThreadMemberSearchString = (
  m: WithId<UserRoleItem>,
) => `${toSearchString(m.fname || '')}|${toSearchString(m.lname || '')}|${m.nickname ? `${toSearchString(m.nickname)}|` : ''}${toSearchString(m.email || '')}`
export const getThreadSearchString = (
  title: string,
  members: Array<WithId<UserRoleItem>>,
) => `${toSearchString(title)}|${members.map(getThreadMemberSearchString).join('|')}`

export const getNewMessageThread = (
  type: ThreadType,
  accessLevel: UserGroup,
  title: string,
  members: Array<WithId<UserRoleItem>>,
): MessageThread => ({
  title,
  subscribers: members.map((m) => m.id),
  accessLevel,
  mostRecentMessages: [],
  mostRecentMessageBy: '',
  searchString: getThreadSearchString(title, members),
  mostRecentMessageOn: 0,
  readBy: [],
  unreadBy: [],
  type,
})
