import { MinusIcon } from '@chakra-ui/icons'
import {
  Box,
  Button,
  ButtonProps,
  Center,
  CircularProgress,
  forwardRef,
  HStack,
  Popover, PopoverContent,
  PopoverTrigger,
  StackDivider,
  Text,
  Tooltip,
  VStack,
} from '@chakra-ui/react'
import { PracticeInvoice, UpdateCallback, WithId } from '@hb/shared'
import React, {
  useCallback, useContext, useMemo, useState,
} from 'react'
import { setCustomFeePercentage } from '../../../backend'
import { PopUpMessageContext } from '../../../contexts'
import { ActionLog } from '../../ActionLog'

const SelectButton = forwardRef((props: ButtonProps, ref) => (
  <Button
    ref={ref}
    flex={1}
    variant='unstyled'
    cursor='pointer'
    borderRadius={0}
    fontSize='sm'
    minH='0'
    h='30px'
    py={1}
    px={2}
    _hover={{ bg: 'gray.200' }}
    {...props}
  />
))

export const CustomFeePercentPopover = ({
  assessmentId,
  paymentId,
  invoice,
}: {
  assessmentId: string
  paymentId: string
  invoice: WithId<PracticeInvoice>
}) => {
  const { processResponse } = useContext(PopUpMessageContext)
  const [loading, setLoading] = useState(false)
  const onChange = useCallback(
    async (updatedPercent: number | null): Promise<UpdateCallback> => {
      setLoading(true)
      try {
        await setCustomFeePercentage({
          assessmentId,
          paymentId,
          invoiceId: invoice.id,
          customFeePercent: updatedPercent,
        })
      } catch (err: any) {
        setLoading(false)
        console.error(err)
        return processResponse({ error: err?.message })
      }
      setLoading(false)
      return processResponse({ success: 'Updated fee percent' })
    },
    [assessmentId, paymentId, processResponse, invoice],
  )

  const invoicePaid = useMemo(() => invoice.status === 'paid', [invoice])
  const customFeePercent = invoice.customFeePercents?.[assessmentId]?.[paymentId]
  const assessmentFeePercent = invoice.assessments[assessmentId].listedFeePercent
  return (
    <Popover placement='top' strategy='fixed' trigger='hover'>
      <PopoverTrigger>
        <Button
          size='xs'
          color={customFeePercent ? 'green.500' : 'gray.500'}
          bg='white'
          border='1px solid #cdcdcd'
        >
          <Text>{`${customFeePercent?.percent || assessmentFeePercent}%`}</Text>
        </Button>
      </PopoverTrigger>
      <PopoverContent
        borderRadius={6}
        w='auto'
        overflow='hidden'
        boxShadow='0 0 4px black'
      >
        {loading ? (
          <Center p={2} w='100%'>
            <HStack>
              <CircularProgress size={5} isIndeterminate color='gray.500' />
              <Text color='gray.600' fontSize='sm'>
                Updating fee percent...
              </Text>
            </HStack>
          </Center>
        ) : (
          <VStack spacing={0}>
            {customFeePercent ? (
              <Box p={1}>
                <ActionLog
                  action='Custom fee percent set'
                  by={customFeePercent.updatedBy}
                  on={customFeePercent.updatedAt}
                />
              </Box>
            ) : (
              <Text fontSize='sm' py={1} px={2} color='gray.600'>
                Using calculated assessment fee: {assessmentFeePercent}%
              </Text>
            )}
            {!invoicePaid ? (
              <HStack
                bg='gray.100'
                w='100%'
                borderTop='1px solid #cdcdcd'
                divider={<StackDivider />}
                spacing={0}
              >
                <SelectButton
                  onClick={() => onChange(8)}
                  bg={
                    customFeePercent?.percent === 8
                      ? 'green.500'
                      : 'transparent'
                  }
                  color={customFeePercent?.percent === 8 ? 'white' : 'gray.600'}
                >
                  8%
                </SelectButton>
                <SelectButton
                  onClick={() => onChange(10)}
                  bg={
                    customFeePercent?.percent === 10
                      ? 'green.500'
                      : 'transparent'
                  }
                  color={
                    customFeePercent?.percent === 10 ? 'white' : 'gray.600'
                  }
                >
                  10%
                </SelectButton>
                {customFeePercent ? (
                  <Tooltip
                    bg='gray.500'
                    hasArrow
                    placement='top'
                    label='Remove custom fee percent'
                  >
                    <SelectButton
                      color='gray.600'
                      onClick={() => onChange(null)}
                    >
                      <MinusIcon />
                    </SelectButton>
                  </Tooltip>
                ) : null}
              </HStack>
            ) : null}
          </VStack>
        )}
      </PopoverContent>
    </Popover>
  )
}
