import { DeleteIcon, EmailIcon } from '@chakra-ui/icons'
import {
  HStack, IconButton, Text, VStack,
} from '@chakra-ui/react'
import { getFullName, getInitials } from '@hb/shared'
import React, { useMemo } from 'react'
import { useCachedUser } from '../../collections/hooks/cached'
import { ProfilePicturePopover } from '../Admins/ProfilePicturePopover'
import { UserBadge } from './UserBadge'

const isSmall = (size: 'xs' | 'sm' | 'md' | 'lg') => size === 'xs' || size === 'sm'

export const UserPreview = ({
  userId,
  size = 'md',
  onDelete,
  noEmail,
  noWrap,
  withProfilePicture,
}: {
  userId: string
  size?: 'xs' | 'sm' | 'md' | 'lg'
  onDelete?: () => void
  noWrap?: boolean
  noEmail?: boolean
  withProfilePicture?: boolean
}) => {
  const { data: user } = useCachedUser(userId)
  const { name } = useMemo(() => {
    if (user) {
      const fullName = getFullName(user)
      const i = getInitials(fullName)
      return { name: fullName, initials: i }
    }
    return {}
  }, [user])
  const small = useMemo(() => isSmall(size), [size])
  return (
    <VStack
      spacing={0}
      bg={small ? 'transparent' : 'gray.100'}
      p={1}
      boxShadow={small ? 'none' : 'md'}
      borderRadius={4}
      w="100%"
    >
      <HStack spacing={small ? 1 : 2} borderTopRadius={4} w="100%" px={2}>
        {withProfilePicture ? (
          <ProfilePicturePopover size={5} userId={userId} noPopover />
        ) : null}
        {small || withProfilePicture ? null : <UserBadge userId={userId} />}
        <Text whiteSpace={noWrap ? 'nowrap' : 'normal'} fontFamily="Open Sans" fontSize={size}>
          {name}
        </Text>
        {onDelete ? (
          <IconButton
            ml="auto"
            size="xs"
            aria-label="Remove user"
            color="red.600"
            icon={<DeleteIcon />}
            onClick={onDelete}
          />
        ) : null}
      </HStack>
      {noEmail ? null : (
        <VStack w="100%" align="flex-start" px={2}>
          <HStack spacing={small ? 1 : 2}>
            <EmailIcon color="gray.500" />
            <Text fontSize="sm">{user?.email}</Text>
          </HStack>
          {/* <Text>{user?}</Text> */}
        </VStack>
      )}
    </VStack>
  )
}
