import {
  Menu,
  MenuButton,
  MenuGroup,
  MenuItem,
  MenuList,
  Portal,
  Switch,
  Text,
  VStack,
} from '@chakra-ui/react'
import {
  cptCodes,
  FieldTypes,
  flattenBilledCharges,
  formatDollarValue,
  TextField,
} from '@hb/shared'
import React, { useContext, useMemo, useState } from 'react'
import { useField } from 'react-final-form'
import { ThemeContext } from '../../../../../../contexts/ThemeContext'
import { UserContext } from '../../../../../../contexts/UserContext'
import Input from '../../../../../forms/Input'

const manualEntryField: TextField = {
  type: FieldTypes.TEXT,
  placeholder: 'CPT Code',
}
export const HCPCField = ({ name }: { name: string }) => {
  const { input, meta } = useField(name, { subscription: { value: true } })
  const { value, onChange } = input
  const [focused, setFocused] = useState(false)
  const [manualEntry, setManualEntry] = useState(false)
  const { selectedAssessment } = useContext(UserContext)
  const { midwife } = selectedAssessment || {}
  const { billedCharges } = midwife || {}
  const flattenedBilledCharges = useMemo(
    () => flattenBilledCharges(billedCharges),
    [billedCharges],
  )

  return (
    <VStack px={2} w="100%" align="flex-start">
      <Text fontWeight={600} color="gray.500" fontSize="sm">
        CPT Code
      </Text>
      <Switch
        colorScheme="green"
        color="gray.600"
        isChecked={manualEntry}
        onChange={(e) => setManualEntry(e.target.checked)}
      >
        {manualEntry ? 'Manual Entry' : 'Select from List'}
      </Switch>
      {manualEntry ? (
        <ThemeContext.Provider
          value={{ placeholderAbove: false, theme: 'detailed' }}
        >
          <Input field={manualEntryField} input={input} meta={meta} />
        </ThemeContext.Provider>
      ) : (
        <Menu
          strategy="fixed"
          size="sm"
          onClose={() => setFocused(false)}
          onOpen={() => setFocused(true)}
          isOpen={focused}
        >
          <MenuButton
            w="100%"
            border="1px solid #cdcdcd"
            p={2}
            borderRadius={6}
            color={value ? 'gray.800' : 'gray.500'}
          >
            {value || 'CPT Code'}
          </MenuButton>
          <Portal appendToParentPortal>
            <MenuList overflowY="auto" maxH="300px">
              {cptCodes.map(({ name: groupName, codes }) => (
                <MenuGroup key={`group_${groupName}`} title={groupName}>
                  {codes.map((c, idx) => (
                    <MenuItem
                      key={`${c.code}_${idx}`}
                      onClick={() => onChange({ target: { value: c.code } })}
                    >
                      {c.code}
                      {c.hint ? ` | ${c.hint}` : ''}
                      {flattenedBilledCharges[`_${c.code}`]
                        ? ` - ${formatDollarValue(flattenedBilledCharges[`_${c.code}`])}`
                        : ' - No fee listed'}
                    </MenuItem>
                  ))}
                </MenuGroup>
              ))}
            </MenuList>
          </Portal>
        </Menu>
      )}
    </VStack>
  )
}
