import { ArrowForwardIcon } from '@chakra-ui/icons'
import {
  Box,
  Center,
  Divider,
  Flex,
  HStack,
  IconButton,
  Tooltip,
  VStack,
} from '@chakra-ui/react'
import { colors } from '@hb/shared/constants'
import { ThreadSearchResult } from '@hb/shared/types'
import React from 'react'
import { ExpandOnMount } from '../ExpandOnMount'
import { useThreadsList } from './contexts'
import { usePopulatedMessage } from './hooks'
import { ThreadMessageView } from './ThreadView/ThreadMessageView'
import { ThreadPreview } from './ThreadView/ThreadView'

const ThreadSearchResultView = ({ result }: { result: ThreadSearchResult }) => {
  const message = usePopulatedMessage(result.message)
  const { goToThread } = useThreadsList()
  return (
    <ExpandOnMount>
      <Box p={1} w="100%">
        <Flex
          position="relative"
          gap={3}
          align="stretch"
          p={1}
          boxShadow="1px 1px 3px #00000077"
          w="100%"
          bg="gray.50"
        >
          <VStack
            spacing={0}
            w="100%"
            divider={<Divider />}
            align="center"
            flexDir="column"
          >
            <HStack w="100%">
              <Box minW="0" flex={1}>
                <ThreadPreview
                  isChild
                  thread={{ ...result.thread, id: result.threadId }}
                />
              </Box>
              <Center px={2}>
                <Tooltip
                  placement="top"
                  hasArrow
                  label="Go To Thread"
                >
                  <IconButton
                    borderRadius="full"
                    size="sm"
                    bg="whiteAlpha.500"
                    _hover={{ bg: colors.green.hex, color: 'white' }}
                    boxShadow="1px 1px 3px #00000066"
                    icon={<ArrowForwardIcon />}
                    aria-label="View Thread"
                    onClick={() => {
                      goToThread({
                        id: result.threadId,
                        title: result.thread.title || '',
                        type: result.threadType,
                      })
                    }}
                  />
                </Tooltip>
              </Center>
            </HStack>
            <HStack position='relative' w="100%">
              <Flex position='relative' py={1} flex={1} minW="0">
                <ThreadMessageView isPreview horizontal message={message} />
              </Flex>
              <Center px={2}>
                <Tooltip
                  placement="top"
                  hasArrow
                  label="Go To Message"
                >
                  <IconButton
                    borderRadius="full"
                    size="sm"
                    bg="whiteAlpha.500"
                    boxShadow="1px 1px 3px #00000066"
                    icon={<ArrowForwardIcon />}
                    _hover={{ bg: colors.green.hex, color: 'white' }}
                    aria-label="View Thread"
                    onClick={() => {
                      goToThread({
                        id: result.threadId,
                        title: result.thread.title || '',
                        type: result.threadType,
                        searchResult: result,
                      })
                    }}
                  />
                </Tooltip>
              </Center>
            </HStack>
          </VStack>
        </Flex>
      </Box>
    </ExpandOnMount>
  )
}

export const DeepSearchThreads = () => {
  const { threads } = useThreadsList()
  const { deepSearchResults } = threads || {}

  return (
    <Flex direction="column" w="100%">
      {deepSearchResults
        && deepSearchResults.map((thread) => (
          <ThreadSearchResultView key={`${thread.threadId}-${thread.messageId}`} result={thread} />
        ))}
    </Flex>
  )
}
