import { RepeatIcon } from '@chakra-ui/icons'
import {
  Box,
  BoxProps,
  Button,
  Collapse,
  Flex,
  IconButton,
  Stack,
  Tooltip,
  VStack,
} from '@chakra-ui/react'
import {
  DocData,
  Field,
  FieldMap,
  isField,
  ListField,
  UploadProgress,
} from '@hb/shared'

import { FormApi, FORM_ERROR, ValidationErrors } from 'final-form'
import arrayMutators from 'final-form-arrays'
import React, {
  CSSProperties,
  PropsWithChildren,
  useMemo,
  useRef,
  useState,
} from 'react'
import {
  Form, FormProps, useForm, useFormState,
} from 'react-final-form'
import { ThemeContext } from '../../../contexts'
import { useResizeObserver } from '../../../hooks/useResizeObserver'
import { ActionLog } from '../../ActionLog'
import { SolidActionButton } from '../../Buttons'
import { CollapseError } from '../../CollapseError'
import { CollapseHorizontal } from '../../shared'
import { FormElement } from '../Input/index'
import { useFormSubmit } from './hooks/useFormSubmit'
import { useFormValidate } from './hooks/useFormValidate'
import { SubmitOnEnter } from './SubmitOnEnter'
import { UploadProgressView } from './UploadProgressView'

export const SimpleFormFooter = ({
  onCancel,
  submitText,
  hasResetButton,
  uploads,
  optional,
  canSubmitClean,
  children,
  theme,
}: PropsWithChildren<{
  onCancel?: () => void
  submitText: string
  hasResetButton?: boolean
  canSubmitClean?: boolean
  uploads?: Record<string, UploadProgress> | null
  theme?: 'basic' | 'detailed'
  optional?: boolean
}>) => {
  const {
    dirty,
    values,
    submitting,
    hasValidationErrors,
    errors,
    submitErrors,
  } = useFormState()
  const form = useForm()
  const stackRef = useRef<HTMLDivElement>(null)
  const formError = useMemo(
    () => errors?.[FORM_ERROR] || submitErrors?.[FORM_ERROR],
    [errors, submitErrors],
  )
  const { width: buttonWidth } = useResizeObserver(stackRef)
  return (
    <Flex w="100%" flexDir="column">
      <CollapseError error={formError} />
      <UploadProgressView uploads={uploads} />
      <Flex
        px={theme === 'detailed' ? 4 : 0}
        alignItems="center"
        gap={2}
        pb={2}
        w="100%"
      >
        <Box flex={1}>{children}</Box>
        {onCancel ? (
          <Button
            onClick={onCancel}
            variant="link"
            size="xs"
            fontSize="12px"
            lineHeight="12px"
          >
            CANCEL
          </Button>
        ) : null}
        <Collapse
          style={{
            alignItems: 'center',
            justifyContent: 'center',
            marginLeft: 'auto',
            display: 'flex',
          }}
          in={!!((values || optional) && (dirty || canSubmitClean))}
        >
          <CollapseHorizontal
            width={buttonWidth + 8}
            in={!!((values || optional) && (dirty || canSubmitClean))}
          >
            <Stack ref={stackRef} align="center" direction="row" gap={3} py={1}>
              {hasResetButton ? (
                <Tooltip
                  hasArrow
                  bg="whitesmoke"
                  color="gray.700"
                  label="Reset"
                  placement="top"
                >
                  <IconButton
                    aria-label="reset"
                    icon={<RepeatIcon w={3} h={3} />}
                    bg="white"
                    opacity={dirty || canSubmitClean ? 1 : 0}
                    onClick={() => form.restart()}
                    size="xs"
                    fontSize="10px"
                    lineHeight="10px"
                    border="1px solid #999999"
                    color="gray.500"
                  />
                </Tooltip>
              ) : null}
              <SolidActionButton
                // isDisabled={dirty && !canSubmitClean && hasValidationErrors}
                opacity={dirty && !canSubmitClean && hasValidationErrors ? 0.5 : 1}
                isLoading={submitting}
                onClick={form.submit}
                size="sm"
                fontSize="14px"
                lineHeight="12px"
              >
                {submitText}
              </SolidActionButton>
            </Stack>
          </CollapseHorizontal>
        </Collapse>
      </Flex>
    </Flex>
  )
}

const emptySubscription = {}
export const InnerForm = <T extends DocData>({
  children,
  data,
  onSubmit,
  field,
  setUploads,
}: {
  data: Omit<
    T & { updatedOn?: number | undefined; updatedBy?: string | undefined },
    'updatedOn' | 'updatedBy'
  >
  children: FormProps<T>['children']
  onSubmit: (
    submitted: any,
    onUploadProgress: (progress: Record<string, UploadProgress>) => void,
    form: FormApi<T>
  ) => Promise<ValidationErrors>
  field: Field | FieldMap | ListField
  setUploads: (updated: Record<string, UploadProgress> | null) => void
}) => {
  const validate = useFormValidate(field)

  const submit = useFormSubmit(field, onSubmit, setUploads)

  return (
    <Form<T>
      mutators={{ ...arrayMutators }}
      initialValues={data as T}
      validate={validate}
      onSubmit={submit}
      subscription={emptySubscription}
    >
      {(props) => (
        <form onSubmit={props.handleSubmit} style={{ display: 'contents' }}>
          {typeof children === 'function' ? children(props) : children}
        </form>
      )}
    </Form>
  )
}

export const SimpleForm = <T extends DocData>({
  value,
  onSubmit,
  field,
  boxProps,
  children,
  submitText = 'SUBMIT',
  onCancel,
  fieldStyle,
  readOnly,
  theme = 'detailed',
  canSubmitClean,
  hasResetButton = true,
  submitOnEnter,
  placeholderAbove = true,
}: {
  value?: T | null
  onSubmit: (
    data: any,
    onUploadProgress: (progress: Record<string, UploadProgress>) => void,
    form: FormApi<T>
  ) => Promise<ValidationErrors>
  children?: React.ReactNode
  field: Field | FieldMap | ListField
  fieldStyle?: CSSProperties
  canSubmitClean?: boolean
  placeholderAbove?: boolean
  readOnly?: boolean
  boxProps?: BoxProps
  theme?: 'basic' | 'detailed'
  submitOnEnter?: boolean
  onCancel?: () => void
  submitText?: string
  hasResetButton?: boolean
}) => {
  const { updatedOn, updatedBy, ...data } = (value || {}) as T & {
    updatedOn?: number
    updatedBy?: string
  }

  const [uploads, setUploads] = useState<Record<string, UploadProgress> | null>(
    null,
  )

  const themeData = useMemo(
    () => ({ theme, placeholderAbove }),
    [theme, placeholderAbove],
  )

  return (
    <ThemeContext.Provider value={themeData}>
      <InnerForm
        field={field}
        data={data}
        setUploads={setUploads}
        onSubmit={onSubmit}
      >
        {({ errors, submitErrors }) => (
          <>
            <VStack
              borderRadius={4}
              align="flex-start"
              bg="white"
              width="100%"
              spacing={0}
              pt={2}
              pb={2}
              px={2}
              shadow="md"
              {...boxProps}
            >
              <FormElement
                root
                index={0}
                active
                disabled={!!readOnly}
                style={fieldStyle}
                showName={false}
                name={isField(field) ? 'value' : ''}
                field={field}
              />
              <Box px={4}>
                <CollapseError
                  style={{ paddingTop: '0' }}
                  error={errors?.[FORM_ERROR] || submitErrors?.[FORM_ERROR]}
                />
              </Box>
              {updatedBy || updatedOn ? (
                <Box px={2} py={1}>
                  <ActionLog action="Updated" by={updatedBy} on={updatedOn} />
                </Box>
              ) : null}
              <SimpleFormFooter
                canSubmitClean={canSubmitClean}
                onCancel={onCancel}
                uploads={uploads}
                theme={theme}
                submitText={submitText}
                hasResetButton={hasResetButton}
              >
                {children}
              </SimpleFormFooter>
            </VStack>
            {submitOnEnter ? <SubmitOnEnter /> : null}
          </>
        )}
      </InnerForm>
    </ThemeContext.Provider>
  )
}
