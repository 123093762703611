import {
  createStandaloneToast,
} from '@chakra-ui/react'

import { chakraTheme } from './chakraTheme'

const { toast, ToastContainer } = createStandaloneToast({
  defaultOptions: { position: 'top' },
  theme: chakraTheme,

})

export const showSuccess = (successMessage: string) => toast({
  description: successMessage,
  status: 'success',
})

export const showError = (errorMessage: string) => toast({ description: errorMessage, status: 'error' })
export { ToastContainer }
