import {
  ASSESSMENT_SNIPPETS,
  getPracticeAssessmentCollectionPath,
} from '@hb/shared/collections'
import { messageThreadCollections } from '@hb/shared/constants'
import {
  AppName,
  FileDBValue,
  MessageThread,
  PopulatedThreadMessage,
  SendThreadMessageReply,
  ThreadType,
} from '@hb/shared/types'
import { getSelectedPracticeId } from '@hb/shared/utils'
import {
  arrayRemove,
  arrayUnion,
  collection,
  deleteField,
  doc,
  DocumentReference,
  getDoc,
  writeBatch,
} from 'firebase/firestore'
import { httpsCallable } from 'firebase/functions'
import {
  auth,
  db,
  deleteFile,
  functions,
  sendMessageFunction,
  updateWebPushNotificationSettings,
} from '../../backend'
import { useAuth } from '../../store'
import { assertPushNotificationToken } from '../../utils/pushNotifications'

export const sendThreadMessage = async (
  appName: AppName,
  threadType: ThreadType,
  threadId: string,
  message: string,
  replyTo: SendThreadMessageReply | null = null,
  attachedFiles: Record<string, FileDBValue> = {},
) => {
  const { data } = await sendMessageFunction({
    threadId,
    type: threadType,
    message,
    appName,
    replyTo,
    attachedFiles,
  })
  return data
}

const deleteMessageFiles = async (message: PopulatedThreadMessage) => {
  const { attachedFiles } = message
  const storagePaths = Object.values(attachedFiles || {})
    .map((file) => file.storagePath)
    .filter((s) => !!s)
  const promises = storagePaths.map((file) => deleteFile(file as string))

  return Promise.all(promises)
}

export const deleteThreadMessage = async (message: PopulatedThreadMessage) => {
  const {
    messageGroupId, threadId, createdOn, createdBy, threadType,
  } = message || {}
  const uid = auth.currentUser?.uid
  if (!uid) {
    throw new Error('User not authenticated')
  }
  if (uid !== createdBy) {
    throw new Error('User not authorized')
  }
  const threadRef = doc(
    collection(db, messageThreadCollections[threadType]),
    threadId,
  ) as DocumentReference<MessageThread>
  const threadMessageGroupRef = doc(
    collection(threadRef, 'messages'),
    messageGroupId,
  )

  const threadDoc = await getDoc(threadRef)

  const mostRecentMessageMatch = threadDoc
    .data()
    ?.mostRecentMessages?.find((m) => m.createdOn === createdOn)

  await deleteMessageFiles(message)

  const batch = writeBatch(db)
  batch.update(threadMessageGroupRef, `messages.${createdOn}`, deleteField())
  if (mostRecentMessageMatch) {
    batch.update(
      threadRef,
      'mostRecentMessages',
      arrayRemove(mostRecentMessageMatch),
    )
  }

  // batch.update(threadMessageGroupRef, `messages.${createdOn}.deletedOn`, Date.now())
  // batch.update(threadMessageGroupRef, `messages.${createdOn}.deletedBy`, uid)
  await batch.commit()
}

export const editThreadMessage = async (
  message: PopulatedThreadMessage,
  newMessage: string,
) => {
  const {
    messageGroupId, threadId, createdOn, createdBy, threadType,
  } = message || {}
  const uid = auth.currentUser?.uid
  if (!uid) {
    throw new Error('User not authenticated')
  }
  if (uid !== createdBy) {
    throw new Error('User not authorized')
  }
  const threadRef = doc(
    collection(db, messageThreadCollections[threadType]),
    threadId,
  ) as DocumentReference<MessageThread>
  const threadMessageGroupRef = doc(
    collection(threadRef, 'messages'),
    messageGroupId,
  )

  const threadDoc = await getDoc(threadRef)

  const mostRecentMessages = threadDoc.data()?.mostRecentMessages || []
  const mostRecentMessageMatchIndex = mostRecentMessages.findIndex(
    (m) => m.createdOn === createdOn,
  )

  const batch = writeBatch(db)
  const now = Date.now()
  batch.update(threadMessageGroupRef, `messages.${createdOn}.editedOn`, now)
  batch.update(threadMessageGroupRef, `messages.${createdOn}.updatedOn`, now)
  batch.update(threadMessageGroupRef, `messages.${createdOn}.editedBy`, uid)
  batch.update(threadMessageGroupRef, `messages.${createdOn}.updatedBy`, uid)
  batch.update(threadMessageGroupRef, `messages.${createdOn}.text`, newMessage)
  batch.update(
    threadMessageGroupRef,
    `messages.${createdOn}.unreadBy`,
    threadDoc.data()?.subscribers?.filter((s) => s !== uid) || [],
  )

  if (mostRecentMessageMatchIndex !== -1) {
    const updatedMostRecentMessages = mostRecentMessages.reduce(
      (acc, m) => {
        if (m.createdOn === createdOn) {
          return [...acc, {
            ...m, text: newMessage, updatedOn: now, editedOn: now, editedBy: uid, updatedBy: uid,
          }]
        }
        return [...acc, m]
      },
      [] as typeof mostRecentMessages,
    )

    batch.update(threadRef, 'mostRecentMessages', updatedMostRecentMessages)
  }

  await batch.commit()
}

export const reportThreadRead = async (
  appName: AppName,
  type: ThreadType,
  threadId: string,
) => {
  const uid = auth.currentUser?.uid
  if (!uid) {
    throw new Error('User not authenticated')
  }
  const batch = writeBatch(db)
  const threadRef = doc(
    collection(db, messageThreadCollections[type]),
    threadId,
  )
  batch.update(threadRef, 'readBy', arrayUnion(uid))
  batch.update(threadRef, 'unreadBy', arrayRemove(uid))
  if (type === ThreadType.ASSESSMENT) {
    if (appName === 'providers-app') {
      const authState = useAuth.getState()
      const practiceId = getSelectedPracticeId(
        authState.claims || {},
        authState.user,
      )
      if (practiceId) {
        const groupDocRef = doc(
          collection(db, getPracticeAssessmentCollectionPath(practiceId)),
          threadId,
        )
        batch.update(groupDocRef, 'unreadThreadMessages', 0)
      }
    } else {
      const groupDocRef = doc(collection(db, ASSESSMENT_SNIPPETS), threadId)
      batch.update(groupDocRef, 'unreadThreadMessages', 0)
    }
  }
  await batch.commit()
}

export const reportMessageRead = async (message: PopulatedThreadMessage) => {
  const {
    messageGroupId,
    threadId,
    threadType,
    createdOn: messageId,
  } = message || {}
  const uid = auth.currentUser?.uid
  if (!uid) {
    throw new Error('User not authenticated')
  }
  const collectionRef = collection(db, messageThreadCollections[threadType])
  const docRef = doc(collectionRef, `${threadId}/messages/${messageGroupId}`)
  if (message.createdBy === uid) return
  const batch = writeBatch(db)
  let viewershipUpdated = false
  if (!message.readBy?.includes(uid)) {
    batch.update(
      docRef,
      `messages.${messageId}.readBy`,
      arrayUnion(uid),
    )
    batch.update(
      docRef,
      `messages.${messageId}.unreadBy`,
      arrayRemove(uid),
    )
    batch.update(
      docRef,
      `messages.${messageId}.readOn.${uid}`,
      Date.now(),
    )
    viewershipUpdated = true
  }
  if (message.editedOn && !message.editedReadBy?.includes(uid)) {
    batch.update(
      docRef,
      `messages.${messageId}.editedReadBy`,
      arrayUnion(uid),
    )
    if (!viewershipUpdated) {
      batch.update(
        docRef,
        `messages.${messageId}.readOn.${uid}`,
        Date.now(),
      )
    }
  }
  await batch.commit()
}

export const markThreadReadStatus = async (
  appName: AppName,
  type: ThreadType,
  threadId: string,
  isRead: boolean,
) => {
  const uid = auth.currentUser?.uid
  if (!uid) {
    throw new Error('User not authenticated')
  }
  const collectionRef = collection(db, messageThreadCollections[type])
  const docRef = doc(collectionRef, threadId)
  const batch = writeBatch(db)
  batch.update(docRef, isRead ? 'unreadBy' : 'readBy', arrayRemove(uid))
  batch.update(docRef, isRead ? 'readBy' : 'unreadBy', arrayUnion(uid))
  if (type === ThreadType.ASSESSMENT) {
    if (appName === 'providers-app') {
      const authState = useAuth.getState()
      const practiceId = getSelectedPracticeId(
        authState.claims || {},
        authState.user,
      )
      if (practiceId) {
        const groupDocRef = doc(
          collection(db, getPracticeAssessmentCollectionPath(practiceId)),
          threadId,
        )
        batch.update(groupDocRef, 'unreadThreadMessages', isRead ? 0 : 1)
      }
    } else {
      const groupDocRef = doc(collection(db, ASSESSMENT_SNIPPETS), threadId)
      batch.update(groupDocRef, 'unreadThreadMessages', isRead ? 0 : 1)
    }
  }

  await batch.commit()
}

export const markAssessmentThreadRead = async (
  assessmentId: string,
  appId: string,
) => {
  const { data } = await httpsCallable(
    functions,
    'markMessageThreadRead',
  )({
    assessmentId,
    appId,
  })
  return data
}

export const enableMessagingWebPushNotifications = async () => {
  const hasPushNotifications = await assertPushNotificationToken()
  if (!hasPushNotifications) {
    throw new Error('User denied push notifications')
  }
  await updateWebPushNotificationSettings({ topic: 'messaging', enabled: true })
}

export const disableMessagingWebPushNotifications = async () => {
  await updateWebPushNotificationSettings({
    topic: 'messaging',
    enabled: false,
  })
}
