import { AddIcon } from '@chakra-ui/icons'
import { HStack, Text, VStack } from '@chakra-ui/react'
import { UserGroup } from '@hb/shared/types'
import React from 'react'
import { PatientIcon } from '../../icons'
import { SearchUsersPopover } from './SearchUsers'
import { UserPreview } from './UserPreview'

export const SearchUsersList = ({
  selected,
  label,
  group,
  type,
  existing,
  excludeMe,
  onChange,
}: {
  selected: Array<string>
  onChange: (updated: Array<string>) => void
  group?: UserGroup
  existing?: Array<string>
  excludeMe?: boolean
  type: 'user' | 'patient'
  label: string
}) => (
  <VStack px={2} spacing={1} align="flex-start" w="100%">
    <VStack spacing={0} align="flex-start" w="100%">
      <Text fontFamily='Hero-New' fontWeight={500} fontSize="sm" color="gray.500">
        {label}
      </Text>
      {selected.length ? (
        selected.map((userId) => (
          <UserPreview
            onDelete={() => onChange(selected.filter((i) => i !== userId))}
            key={userId}
            userId={userId}
          />
        ))
      ) : (
        <Text fontStyle="italic" color="gray.500">
          No users added
        </Text>
      )}
    </VStack>
    <HStack>
      <SearchUsersPopover
        placement="top"
        triggerText={`Add ${type === 'user' ? 'User' : 'Patient'}`}
        group={group}
        existing={[...(selected || []), ...(existing || [])]}
        excludeMe={excludeMe}
        icon={type === 'user' ? <AddIcon /> : <PatientIcon />}
        onSelect={(id) => onChange([...selected, id])}
      />
    </HStack>
  </VStack>
)
