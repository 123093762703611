import { Stack, Text } from '@chakra-ui/react'
import {
  FieldMapValue,
  getMostRecentAssessment,
  PopulatedAssessment,
  signOnFields,
} from '@hb/shared'
import {
  addMetadata,
  ASSESSMENTS_REF,
  BoxHeader,
  ContactButtons,
  DefaultModal,
  FloatingContainer,
  GenericEditModal,
  SolidActionButton,
  useApp,
  useUpdateDoc,
} from '@hb/shared-frontend'
import { FormsViewContext } from '@hb/shared-frontend/contexts/FormsViewContext'
import { doc } from 'firebase/firestore'
import React, { useContext, useMemo, useState } from 'react'
import { PostSignOnText } from './PostSignOnText'

export const SignOnPanel: React.FC<{
  assessment: PopulatedAssessment
  viewSignOnQuestionnaire: boolean
  setViewSignOnQuestionnaire: (view: boolean) => void
}> = ({ viewSignOnQuestionnaire, setViewSignOnQuestionnaire, assessment }) => {
  const update = useUpdateDoc('sign on data')
  const { appName } = useApp()
  const { signOnData, signedOnDate } = assessment
  const { assessmentId, assessments } = useContext(FormsViewContext)

  const hasPreviousSignOn = useMemo(
    () => Object.keys(assessments || {})
      .filter((aId) => aId !== assessmentId)
      .some((key) => assessments?.[key].signedOnDate),
    [assessments, assessmentId],
  )

  const initialValue = useMemo(
    () => signOnData
      || (assessments
        ? getMostRecentAssessment(
          Object.entries(assessments).reduce((acc, [key, data]) => {
            if (data.signOnData) return { ...acc, [key]: data }
            return acc
          }, {}),
        )?.signOnData
        : null),
    [signOnData, assessments],
  )
  const [postSignOnOpen, setPostSignOnOpen] = useState(false)

  return (
    <FloatingContainer alignItems="flex-start">
      {signedOnDate ? (
        <PostSignOnText />
      ) : (
        <>
          <Stack w="100%">
            <BoxHeader mb={2} color="gray.600">
              How would you like to proceed?
            </BoxHeader>
            {assessment.skippedQuestionnaire ? (
              <Text>
                You've been signed on by one of our administrators! We just need a little info
                before we officially get started.
              </Text>
            ) : null}
            <SolidActionButton
              variant="solid"
              flex={1}
              w={['100%', 'auto']}
              onClick={() => setViewSignOnQuestionnaire(true)}
              mt={0}>
              {signedOnDate ? 'Edit Sign-on Info' : 'Complete Sign-on Form'}
            </SolidActionButton>
            <ContactButtons
              height={[32, 32, 10]}
              mr="auto"
              width="100%"
              direction={['column', 'column', 'row']}
            />
          </Stack>
        </>
      )}
      {update ? (
        <GenericEditModal
          canSubmitClean={hasPreviousSignOn}
          field={signOnFields}
          onSubmit={(v: FieldMapValue) => update(
            doc(ASSESSMENTS_REF, assessmentId),
            '',
            {
              signOnData: addMetadata(v, appName, !assessment.signOnData),
              signedOnDate: Date.now(),
            },
          ).then((res) => {
            setPostSignOnOpen(true)
            return res
          })
          }
          data={initialValue || {}}
          isOpen={viewSignOnQuestionnaire}
          onClose={() => setViewSignOnQuestionnaire(false)}
        />
      ) : null}
      <DefaultModal
        overlayHeader
        bodyProps={{ p: 4 }}
        render={() => <PostSignOnText />}
        isOpen={postSignOnOpen}
        onClose={() => setPostSignOnOpen(false)}
      />
    </FloatingContainer>
  )
}
