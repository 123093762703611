import {
  PopulatedAssessment, PracticeWithAdmin, ShortcutArgs, User,
} from '../../types'
import { getPrimaryCoverage } from '../assessments'

export const getShortcutArgs = (
  user?: User | null,
  assessment?: PopulatedAssessment,
  practice?: PracticeWithAdmin | null,
): ShortcutArgs => {
  const primaryCoverage = getPrimaryCoverage(assessment?.mergedData)
  const oonData = primaryCoverage?.['out-of-network']
  const midwife = practice === undefined ? assessment?.midwife : practice
  const prm = assessment?.payments?.prm || midwife?.prm
  const retainer = Object.values(assessment?.payments?.due || {}).filter(
    (p) => p.type === 'retainer',
  )[0]?.amount || assessment?.midwife?.retainer
  const deposits = Object.values(assessment?.payments?.due || {}).filter(
    (p) => p.type === 'deposit',
  )

  // while live and dev are out of sync
  const reimbursement = oonData?.reimbursementOption || primaryCoverage?.['reimbursement-option']

  return {
    user: user || undefined,
    assessment,
    coinsurance: oonData?.coinsurance,
    deductible: oonData?.deductible,
    maximumReimbursableCharges:
      primaryCoverage?.maximumReimbursableCharges?.amount
      || oonData?.maximumReimbursableCharges?.amount,
    prm,
    retainer,
    deposits,
    practice: practice === undefined ? assessment?.midwife : practice,
    insuranceProvider: primaryCoverage?.insuranceProvider,
    insuranceProviderName: primaryCoverage?.insuranceProviderName,
    medicareRate: reimbursement?.medicareRate,
    rncRate: reimbursement?.reasonableAndCustomaryRate,
    reimbursementOption: reimbursement?.optionText,
  }
}
