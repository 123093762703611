import { ButtonProps } from '@chakra-ui/react'
import { CustomElement, EditorVersion, UserFieldItem } from '@hb/shared'
import React, {
  FC, useCallback, useContext, useState,
} from 'react'
import { useSlate } from 'slate-react'
import { PopUpMessageContext } from '../../contexts'
import { ActionButton } from '../Buttons'
import { TemplateViewProps } from './types'

export const useOnSave = (editorVersion: EditorVersion, onSave?: TemplateViewProps['onSave']) => {
  const { processResponse } = useContext(PopUpMessageContext)
  const [isLoading, setIsLoading] = useState(false)
  const handleSave = useCallback(
    async (text: (CustomElement & UserFieldItem)[]) => {
      if (onSave) {
        setIsLoading(true)
        const res = await onSave(text, editorVersion)
        setIsLoading(false)
        return processResponse(res)
      }
      return processResponse({ error: 'internal error' })
    },
    [onSave, processResponse, editorVersion],
  )

  return { isLoading, handleSave }
}

export const EditorSaveButton: FC<ButtonProps & ReturnType<typeof useOnSave>> = ({
  isLoading,
  isDisabled,
  handleSave,
}) => {
  const editor = useSlate()
  return (
    <ActionButton
      colorScheme='gray'
      size='xs'
      isLoading={isLoading}
      isDisabled={isDisabled}
      mt={0}
      opacity={0.9}
      pointerEvents={'auto'}
      onClick={() => handleSave(editor.children as any)}
    >
      Save
    </ActionButton>
  )
}
