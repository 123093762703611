import {
  InfoBox,
  InfoHeader,
  InfoText,
  InlineLink,
  MyAssessmentShortcut,
  PageContainer,
  PageHeader,
  ScheduleCallModal,
  useAppModals,
  ViewHeaderImage,
} from '@hb/shared-frontend'
import * as React from 'react'
import { useImagesLoaded } from '../../hooks/useImagesLoaded'
import providersImage from './providersImage.png'

const PatientsView = () => {
  const { loaded, onLoad } = useImagesLoaded(1)
  const { scheduleCall } = useAppModals()

  return (
    <PageContainer transition="opacity 300ms" opacity={loaded ? 1 : 0}>
      <PageHeader >Providers</PageHeader>
      <ViewHeaderImage onLoad={onLoad} w="100%" src={providersImage} />
      <InfoBox spacing={2}>
        <InfoHeader px={3} textAlign="left" fontSize={['md', 'xl']}>
          We make sure the bills get paid.
        </InfoHeader>
        <InfoText mb={2}>
          We have a long history supporting midwives from the financial/billing side. Our team does
          the heavy lifting on coverage and payments so you can focus on providing quality maternity
          care to patients.
        </InfoText>
        <InfoText>
          At Hamilton Billing, we respect and recognize the dedication midwives bring to families on
          their journey through pregnancy. We are here to advocate for and support you so your
          resources aren’t spread thin.
        </InfoText>
        <InfoText>
          <InlineLink onClick={() => scheduleCall.open('ProvidersView')}>
            Schedule a call with us
          </InlineLink>{' '}
          to connect on what services we provide and how we can help billing work best for your
          practice!
        </InfoText>
      </InfoBox>
      <ScheduleCallModal id="ProvidersView" />
      <MyAssessmentShortcut />
    </PageContainer>
  )
}

export default PatientsView
