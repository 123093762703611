import React, { useCallback, useMemo, useState } from 'react'

import { Button, HStack, VStack } from '@chakra-ui/react'
import {
  Collection, CollectionItem, DocData, FieldMapValue,
} from '@hb/shared'
import { ValidationErrors } from 'final-form'
import arrayMutators from 'final-form-arrays'
import { DraggableProvided, DraggableStateSnapshot } from 'react-beautiful-dnd'
import { Form } from 'react-final-form'
import { deleteItem } from '../collections/utils'
import { ThemeContext } from '../contexts'
import { useUpdateCollection } from '../hooks/useAppCollections'
import { Container } from './Container'
import EditHeader, { EditedProps } from './EditHeader'
import { DefaultModal } from './Modals'

export type ItemEditProps<T extends DocData> = {
  item: CollectionItem<T>
  width: number
  showRedFlag?: boolean
  placeholder: string
  size?: 'large' | 'medium' | 'small'
  onOpen?: () => void
  last?: boolean
  Component?: React.FC<EditedProps<CollectionItem<T>>>
  collection: Collection<T>
  itemName: string
  provided?: DraggableProvided
  snapshot?: DraggableStateSnapshot
  alwaysExpanded?: boolean
  initExpanded?: boolean
}

export const ItemEdit = <T extends DocData>({
  item,
  Component,
  placeholder,
  collection,
  showRedFlag,
  size = 'medium',
  onOpen,
  width,
  initExpanded,
  provided,
  last,
  snapshot,
  itemName,
}: ItemEditProps<T>) => {
  // const control = useEditedObject(item, { onChange })

  const [expanded, setExpanded] = useState(!!initExpanded)

  const { draggableProps, dragHandleProps, innerRef } = provided || {}
  const { isDragging } = snapshot || {}
  const { style } = draggableProps || {}
  const { saveItem } = useUpdateCollection(collection)
  // const { value } = control

  const onSave = useCallback(
    async (data: FieldMapValue): Promise<ValidationErrors> => {
      const result = await saveItem(item.id, data)
      if (!result) {
        setExpanded(false)
      }
      return result
    },
    [item, saveItem],
  )

  const onDelete = useCallback(async () => deleteItem(collection, item.id), [item, collection])

  const editedProps = useMemo<EditedProps<T>>(
    () => ({
      id: item.id,
      item,
      dragHandleProps: dragHandleProps || undefined,
      placeholder,
      expanded,
      itemName,
      width,
      size,
      onDelete,
    }),
    [
      item,
      dragHandleProps,
      placeholder,
      expanded,
      width,
      size,
      onDelete,
      itemName,
    ],
  )

  // const marginBottom = useMemo(() => {
  //   switch (size) {
  //     case 'small':
  //       return '3px'
  //     case 'large':
  //       return '7px'
  //     default:
  //       return '5px'
  //   }
  // }, [size])

  return (
    <ThemeContext.Provider value={{ theme: 'basic' }}>
      <Container
        ref={innerRef}
        {...draggableProps}
        style={{
          // marginBottom,
          borderRadius: '4px',
          alignItems: 'flex-start',
          height: 'auto',
          padding: '0 0.5rem',
          background: isDragging ? '#fafafa' : undefined,
          boxShadow: isDragging ? '0 0 4px #777' : undefined,
          userSelect: 'none',
          ...style,
        }}
      >
        <Container
          style={{
            justifyContent: 'flex-start',
            borderBottom: last ? undefined : '1px solid #cdcdcd',
            overflow: 'hidden',
            // transition: 'height 333ms',
          }}
        >
          <Form
            mutators={{ ...arrayMutators }}
            initialValues={item}
            onSubmit={onSave}
          >
            {({ handleSubmit, submitting }) => (
              <>
                <Container
                  style={{
                    height: 'auto',
                    display: 'block',
                    borderBottom: expanded ? '1px solid #cdcdcd' : undefined,
                  }}
                >
                  <EditHeader
                    showRedFlag={showRedFlag}
                    toggleExpanded={() => {
                      if (onOpen) {
                        onOpen()
                      } else if (Component) {
                        setExpanded(!expanded)
                      }
                    }}
                    {...editedProps}
                  />
                </Container>
                {expanded && !onOpen ? (
                  <DefaultModal
                    size="3xl"
                    bodyProps={{ p: 0 }}
                    scrollBehavior="inside"
                    overlayHeader
                    isOpen
                    onClose={() => setExpanded(false)}
                    render={() => (
                      <ThemeContext.Provider value={{ theme: 'detailed' }}>
                        <VStack pb={12} w="100%" align="flex-start">
                          {Component
                            ? React.createElement(Component, editedProps as any)
                            : null}
                          <HStack
                            bg="white"
                            position="absolute"
                            bottom="0"
                            borderTop="1px solid #cdcdcd"
                            w="100%"
                            px={3}
                            py={2}
                          >
                            <Button
                              color="white"
                              bg="green.500"
                              ml="auto"
                              isLoading={submitting}
                              onClick={handleSubmit}
                            >
                              SAVE
                            </Button>
                          </HStack>
                        </VStack>
                      </ThemeContext.Provider>
                    )}
                  />
                ) : null}
              </>
            )}
          </Form>
        </Container>
      </Container>
    </ThemeContext.Provider>
  )
}
