import {
  colors, FieldTypes,
} from '../../constants'
import { address } from '../../fields/address'
import { physicianField } from '../../fields/physician'
import {
  addressField,
  alternateEmailField,
  fnameField,
  lnameField,
  nineDigitZipAddress,
  notesField,
  stateField,
} from '../../fields/shared'
import { FieldMapValue, PracticeWithAdmin } from '../../types'
import {
  DollarAmountField,
  DropdownField,
  Field,
  FieldMap,
  ListField,
  PhoneField,
  TextAreaField,
  TextField,
} from '../../types/forms/fields'
import { formatDropdownValue } from '../../utils/data'
import { adminCollectionAccess, patientCollectionAccess } from '../constants'
import { CombinedCollection } from '../types/collection'

export const MIDWIVES = 'midwives'
export const MIDWIVES_ADMIN = 'midwives-admin'
export const PRACTICES = 'practices'
export const PRACTICE_ACCESS = 'practice-access'

export const taxIdField: TextField = {
  type: FieldTypes.TEXT,
  optional: true,
  placeholder: 'Tax ID',
}

export const hospitalField: FieldMap = {
  name: 'Hospital Info',
  toName: (v) => v?.name || 'Unnamed hospital',
  initExpanded: false,
  children: {
    name: {
      type: FieldTypes.TEXT,
      placeholder: 'Name',
    },
    npi: {
      type: FieldTypes.TEXT,
      placeholder: 'NPI',
    },
    taxID: {
      type: FieldTypes.TEXT,
      placeholder: 'Tax ID',
    },
    phone: {
      type: FieldTypes.PHONE,
      placeholder: 'Phone',
      optional: true,
    },
    placeOfServiceCode: {
      type: FieldTypes.TEXT,
      placeholder: 'Place of Service Code',
      optional: true,
    },
    providerHasDeliveryPrivileges: {
      type: FieldTypes.CHECKBOX,
      placeholder: 'Provider has delivery privileges at this hospital',
    },
    address: {
      ...address,
      placeholder:
        'Address (will be removed, switching to below address for ability)',
      optional: true,
    },
    serviceAddress: nineDigitZipAddress,
  },
}

export const affiliatedHospitalsField = {
  name: 'Affiliated Hospitals',
  itemName: 'Hospital',
  optional: true,
  itemFields: hospitalField,
}
const globalChargeCodes: Array<number> = [
  59400, 59425, 59426, 59409, 59410, 59614, 59430, 59510,
]

const transferHomeCodes = [
  99344, 99349, 84030, 99350, 99291, 99292, 90834, 99417,
]

type CPTCode = { code: string; hint?: string }
const establishedPatientCodes: Array<CPTCode> = [
  { code: '99349\n99391', hint: 'Subsequent visit 40 minutes' },
  { code: '99461' },
  { code: 'S9443', hint: 'Lactation visit (with Z39.1)' },
  { code: '99465', hint: '$500 newborn resuscitation' },
]

const establishedPatientOfficeCodes: Array<CPTCode> = [
  { code: '99215', hint: 'Subsequent visits 40 minutes' },
  { code: '99404', hint: 'Consult' },
]

const homeEstablishedPatientCodes: Array<CPTCode> = [
  { code: '99347', hint: '15 minutes, self-limited or minor problem' },
  { code: '93348', hint: '25 minutes, low to moderate problem' },
  { code: '99349', hint: '40 minutes, moderate to high problem' },
]

const hospitalCodes: Array<CPTCode> = [
  { code: '99223', hint: 'Admission' },
  { code: '99224', hint: '15 minutes' },
  { code: '99356\n99220' },
  { code: '99356' },
  { code: '99357' },
  { code: '99418' },
  { code: '99217', hint: 'Discharge' },
  { code: '90384' },
]

// 99391 (established newborn visit)
// 99460 (normal newborn initial care, facility)
// 99461 (normal newborn initial care, outpatient)
// 99342 (home visit of E&M of new patient)
// 99381 (new newborn visit)
const newbornCodes: Array<CPTCode> = [
  { code: '99391', hint: 'Established newborn visit' },
  { code: '99460', hint: 'Normal newborn initial care, facility' },
  { code: '99461', hint: 'Normal newborn initial care, outpatient' },
  { code: '99342', hint: 'Home visit of E&M of new patient' },
  { code: '99381', hint: 'New newborn visit' },
]

// Rhogam
// 90384 (rhogam)
// J2790 (rhogam for Medicaid)
const rhogamCodes: Array<CPTCode> = [
  { code: '90384', hint: 'Rhogam' },
  { code: 'J2790', hint: 'Rhogam for Medicaid' },
]

// 99401 (preventive medicine counseling and risk factor reduction/consultation, 15 min or more)
// 99341 (low complexity home visit, new patient)
// 99342 (home visit for E&M of a new patient)
const preventiveCodes: Array<CPTCode> = [
  { code: '99401', hint: 'Preventive medicine counseling and risk factor reduction/consultation, 15 min or more' },
  { code: '99341', hint: 'Low complexity home visit, new patient' },
  { code: '99342', hint: 'Home visit for E&M of a new patient' },
]

// Diagnosis/ICD-10 codes:
// Z39.1 (encounter for care and examination of lactating mother)
// O36.0131 (maternal care for anti-D(Rh) antibodies, third trimester)
// O36.0191 (maternal care for anti-D(Rh) antibodies for unspecified trimester)
const diagnosisCodes: Array<CPTCode> = [
  { code: 'Z39.1', hint: 'Encounter for care and examination of lactating mother' },
  { code: 'O36.0131', hint: 'Maternal care for anti-D(Rh) antibodies, third trimester' },
  { code: 'O36.0191', hint: 'Maternal care for anti-D(Rh) antibodies for unspecified trimester' },
]

export const cptCodes: Array<{ name: string; codes: Array<CPTCode> }> = [
  {
    name: 'Global Codes',
    codes: globalChargeCodes.map((c) => ({ code: c.toString() })),
  },
  {
    name: 'Transfer Home Codes',
    codes: transferHomeCodes.map((c) => ({ code: c.toString() })),
  },
  {
    name: 'Established Patient Codes',
    codes: establishedPatientCodes,
  },
  {
    name: 'Established Patient Office Codes',
    codes: establishedPatientOfficeCodes,
  },
  {
    name: 'Established Patient Home Codes',
    codes: homeEstablishedPatientCodes,
  },
  { name: 'Hospital Codes', codes: hospitalCodes },
  { name: 'Newborn Codes', codes: newbornCodes },
  { name: 'Rhogam Codes', codes: rhogamCodes },
  { name: 'Preventive Codes', codes: preventiveCodes },
  { name: 'Diagnosis Codes', codes: diagnosisCodes },
]

export const allCptCodes = cptCodes.reduce(
  (acc, curr) => [...acc, ...curr.codes],
  [] as Array<CPTCode>,
)

const getCodeField = (code: string, hint?: string): Field => {
  const field: Field = {
    placeholder: `${code}`,
    type: FieldTypes.DOLLAR_AMOUNT,
    optional: true,
  }
  if (hint) field.hints = [{ text: hint }]
  return field
}

const getChargeCodeFields = (codes: Array<number>): Record<string, Field> => codes.reduce(
  (acc, curr) => ({
    ...acc,
    [`_${curr}`]: getCodeField(curr.toString()),
  }),
  {},
)

const getChargeCodeFieldsWithHints = (
  codes: { code: string; hint?: string }[],
): Record<string, Field> => codes.reduce(
  (acc, { code, hint }) => ({
    ...acc,
    [`_${code}`]: getCodeField(code.toString(), hint),
  }),
  {},
)

const credentialCheckboxes = [
  ['CM', 'CM - Certified Midwife'],
  ['CNM', 'CNM - Certified Nurse Midwife'],
  ['CPM', 'CPM - Certified Professional Midwife'],
  ['WHNP', 'WHNP - Women’s Health Nurse Practitioner'],
  ['NP', 'NP - Nurse Practitioner'],
  ['RN', 'RN - Registered Nurse'],
  ['MFM', 'MFM - Maternal Fetal Medicine'],
  ['IBLCE', 'IBLCE - International Board Certified Lactation Consultant'],
  ['DONA', 'DONA - Doula'],
  ['Doula', 'Doula Certification (Other)'],
].reduce(
  (acc, curr) => ({
    ...acc,
    [typeof curr === 'string' ? curr : curr[0]]: {
      placeholder: typeof curr === 'string' ? curr : curr[1],
      type: FieldTypes.CHECKBOX,
    },
  }),
  {},
)

export const providerRedFlaggedField: FieldMap = {
  name: 'Red Flagged',
  children: {
    redFlagged: {
      type: FieldTypes.CHECKBOX,
      placeholder: 'Red flag this provider',
    },
    redFlaggedReason: {
      type: FieldTypes.TEXTAREA,
      placeholder: 'Red flagged reason',
      condition: (v) => v?.redFlagged,
    },
  },
}

export const providerMedicaidIdField: FieldMap = {
  name: 'Medicaid ID',
  toName: (v) => `${formatDropdownValue(v?.state, stateField.options)} - ${v?.id}`,
  initExpanded: false,
  children: {
    id: {
      type: FieldTypes.TEXT,
      placeholder: 'Medicaid ID',
    },
    state: stateField,
  },
}

export const providerLicenseField: FieldMap = {
  name: 'License',
  toName: (v?: FieldMapValue) => `${v?.state || 'NO STATE'} - ${v?.number || 'NO NUMBER'}`,
  initExpanded: false,
  children: {
    number: {
      type: FieldTypes.TEXT,
      placeholder: 'License number',
      optional: true,
    },
    state: stateField,
    credentials: {
      name: 'Credentials',
      initExpanded: true,
      children: credentialCheckboxes,
    },
  },
}

export const renderingProviderField: FieldMap = {
  name: 'Rendering provider',
  initExpanded: false,
  toName: (v) => (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      {v?.fname || 'NO FIRST NAME'} {v?.lname || 'NO LAST NAME'}
      <span
        style={{
          background: v?.inactive ? colors.orange.hex : colors.green.hex,
          fontSize: '0.8rem',
          marginLeft: '0.5rem',
          color: 'white',
          borderRadius: '4px',
          padding: '0.3rem',
          paddingBottom: '0.2rem',
        }}
      >
        {v?.inactive ? 'INACTIVE' : 'ACTIVE'}
      </span>
    </div>
  ),
  children: {
    inactive: {
      type: FieldTypes.CHECKBOX,
      placeholder: 'Inactive',
    },
    fname: fnameField,
    lname: lnameField,
    npi: {
      type: FieldTypes.TEXT,
      placeholder: 'NPI',
    },
    medicaidIds: {
      name: 'Medicaid IDs',
      itemName: 'Medicaid ID',
      optional: true,
      itemFields: providerMedicaidIdField,
    },
    licences: {
      name: 'Licenses',
      itemName: 'License',
      optional: true,
      itemFields: providerLicenseField,
    },
  },
}

export const specialtyField: TextField = {
  type: FieldTypes.TEXT,
  placeholder: 'Specialty',
  optional: true,
}

export const providerNpiField: TextField = {
  type: FieldTypes.TEXT,
  placeholder: 'NPI',
  optional: true,
}

export const groupNpiField: TextField = {
  type: FieldTypes.TEXT,
  placeholder: 'Group NPI',
  optional: true,
}
export const acceptedPaymentMethodsField: TextAreaField = {
  type: FieldTypes.TEXTAREA,
  placeholder: 'Accepted Payment Methods',
  optional: true,
}

export const isActiveField: Field = {
  type: FieldTypes.CHECKBOX,
  inputType: 'switch',
  placeholder: 'Inactive',
  yesText: 'Active',
  noText: 'Inactive',
}

export const midwiferyNameField: TextField = {
  type: FieldTypes.TEXT,
  placeholder: 'Practice Name',
}

export const providerPhoneField: PhoneField = {
  type: FieldTypes.PHONE,
  placeholder: 'Phone',
  optional: true,
}

export const providerOtherPhonesField: FieldMap = {
  name: 'Other Phone Numbers',
  children: {
    mobile: {
      type: FieldTypes.PHONE,
      placeholder: 'Mobile',
      optional: true,
    },
    whatsApp: {
      type: FieldTypes.PHONE,
      placeholder: 'WhatsApp',
      optional: true,
    },
  },
}

export const basicMidwifeInfo: FieldMap = {
  name: 'Midwife',
  children: {
    isGroup: {
      type: FieldTypes.CHECKBOX,
      placeholder: 'Is a group',
    },
    group: {
      name: 'Group Info',
      initExpanded: true,
      condition: (v) => !!v?.isGroup,
      children: {
        name: {
          type: FieldTypes.TEXT,
          placeholder: 'Group Name',
          optional: true,
        },
        npi: groupNpiField,
      },
    },
    name: midwiferyNameField,
    specialty: specialtyField,
    fname: {
      type: FieldTypes.TEXT,
      placeholder: 'First Name (Billing, referring provider)',
      optional: true,
    },
    lname: {
      type: FieldTypes.TEXT,
      placeholder: 'Last Name (Billing, referring provider)',
      optional: true,
    },
    // abilityId: abilityIdField,
    taxId: {
      optional: true,
      type: FieldTypes.TEXT,
      placeholder: 'Tax ID (Billing Provider)',
    },
    ssn: {
      optional: true,
      type: FieldTypes.TEXT,
      placeholder: 'SSN / Alternate',
    },
    renderingProviders: {
      optional: true,
      getTitle: (v) => `${v?.fname || 'NO FIRST NAME'} ${v?.lname || 'NO LAST NAME'}`,
      itemName: 'Rendering provider',
      name: 'Rendering providers',
      itemFields: renderingProviderField,
    },
    zipCode: {
      type: FieldTypes.TEXT,
      placeholder: 'ZIP Code',
      optional: true,
      // row: true,
    },
    npi: {
      type: FieldTypes.TEXT,
      placeholder: 'NPI',
      optional: true,
    },
    // servicingAddress: {
    //   optional: true,
    //   type: FieldTypes.TEXTAREA,
    //   placeholder: 'Servicing Address',
    // },
    // otherAddresses: {
    //   name: 'Other Addresses',
    //   children: {
    //     mailing: {
    //       placeholder: 'Mailing Address',
    //       type: FieldTypes.TEXTAREA,
    //       optional: true,
    //     },
    //     previous: {
    //       placeholder: 'Previous Address',
    //       type: FieldTypes.TEXTAREA,
    //       optional: true,
    //     },
    //   },
    // },
    email: alternateEmailField,
    phone: providerPhoneField,
    otherPhones: providerOtherPhonesField,
    acceptedPaymentMethods: acceptedPaymentMethodsField,
    redFlaggedReason: {
      placeholder: '⚠ Provider alert',
      type: FieldTypes.TEXT,
      optional: true,
    },
  },
}

export const providerLicenseCredentialsField: TextField = {
  type: FieldTypes.TEXT,
  placeholder: 'Credentials',
  optional: true,
}

export const providerSsnField: TextField = {
  type: FieldTypes.TEXT,
  placeholder: 'SSN',
  optional: true,
}

export const servicingAddressesField: ListField = {
  itemFields: addressField,
  itemName: 'Servicing Address',
  name: 'Servicing Addresses',
  optional: true,
}

export const servicingAddressesFieldMap: FieldMap = {
  name: 'Servicing Addresses',
  children: {
    servicingAddresses: servicingAddressesField,
  },
}

export const servicingAddressField: FieldMap = {
  ...nineDigitZipAddress,
  name: 'Servicing Address',
  initExpanded: false,
}

export const paymentsAddressField: FieldMap = {
  ...nineDigitZipAddress,
  name: 'Payments Address',
  initExpanded: true,
}
export const corporateAddressField: FieldMap = {
  ...nineDigitZipAddress,
  name: 'Corporate Address',
  initExpanded: true,
}
export const payToAddressField: FieldMap = {
  ...nineDigitZipAddress,
  name: 'Pay To Provider Address',
  initExpanded: false,
}
export const providerAddressNotesField: TextAreaField = {
  type: FieldTypes.TEXTAREA,
  placeholder: 'Address Notes',
  optional: true,
}

export const tinField: DropdownField = {
  type: FieldTypes.DROPDOWN,
  placeholder: 'TIN',
  options: [
    { id: 'ssn', text: 'SSN' },
    { id: 'ein', text: 'EIN' },
  ],
}

export const taxClassField: DropdownField = {
  type: FieldTypes.DROPDOWN,
  placeholder: 'Tax Class',
  options: [
    { id: 'sole-proprietorship', text: 'Sole Proprietorship/Single LLC' },
    { id: 'partnership', text: 'Partnership' },
    { id: 's-corp', text: 'S-Corp' },
    { id: 'c-corp', text: 'C-Corp' },
  ],
}

export const billedChargesField = {
  initExpanded: true,
  name: 'Billed Charges',
  children: {
    globalCodes: {
      name: 'Global Codes',
      children: getChargeCodeFields(globalChargeCodes),
    },
    transferHomeCodes: {
      name: 'Transfer Home Codes',
      children: getChargeCodeFields(transferHomeCodes),
    },
    homeVisits: {
      name: 'Home Visits Individual/Newborn',
      children: {
        _99344: getCodeField('99344', 'Very first visit'),
        establishedPatient: {
          name: 'Established Patient',
          children: getChargeCodeFieldsWithHints(establishedPatientCodes),
        },
      },
    },
    officeVisits: {
      name: 'Office Visits',
      children: {
        newPatient: {
          name: 'New Patient',
          children: { _99204: getCodeField('99204', '45 minutes') },
        },
        establishedPatient: {
          name: 'Established Patient',
          children: getChargeCodeFieldsWithHints(establishedPatientOfficeCodes),
        },
      },
    },
    homeEstablished: {
      name: 'Home Established Patient',
      children: getChargeCodeFieldsWithHints(homeEstablishedPatientCodes),
    },
    hospital: {
      name: 'Hospital / Labor',
      children: getChargeCodeFieldsWithHints(hospitalCodes),
    },
    // { name: 'Newborn Codes', codes: newbornCodes },
    // { name: 'Rhogam Codes', codes: rhogamCodes },
    // { name: 'Preventive Codes', codes: preventiveCodes },
    // { name: 'Diagnosis Codes', codes: diagnosisCodes },
    newborn: {
      name: 'Newborn',
      children: getChargeCodeFieldsWithHints(newbornCodes),
    },
    rhogam: {
      name: 'Rhogam',
      children: getChargeCodeFieldsWithHints(rhogamCodes),
    },
    preventive: {
      name: 'Preventive',
      children: getChargeCodeFieldsWithHints(preventiveCodes),
    },
    diagnosis: {
      name: 'Diagnosis',
      children: getChargeCodeFieldsWithHints(diagnosisCodes),
    },
  },
}

export const providerPrmField: DollarAmountField = {
  type: FieldTypes.DOLLAR_AMOUNT,
  placeholder: 'PRM during care',
  optional: true,
}

export const providerPrmNotesField: TextAreaField = {
  type: FieldTypes.TEXTAREA,
  placeholder: 'Notes on PRM during care...',
  optional: true,
}

export const providerRetainerField: DollarAmountField = {
  type: FieldTypes.DOLLAR_AMOUNT,
  placeholder: 'Retainer',
  optional: true,
}

export const providerRetainerNotesField: TextAreaField = {
  type: FieldTypes.TEXTAREA,
  placeholder: 'Retainer notes...',
  optional: true,
}

export const moreMidwifeInfo: FieldMap = {
  name: 'Additional Info',
  children: {
    prm: providerPrmField,
    prmNotes: providerPrmNotesField,
    retainer: providerRetainerField,
    retainerNotes: providerRetainerNotesField,
    servicingAddress: {
      placeholder: 'Servicing address (TO BE REPLACED)',
      type: FieldTypes.TEXTAREA,
      optional: true,
    },
    servicingAddresses: servicingAddressesField,
    homebirthRetainer: {
      type: FieldTypes.DOLLAR_AMOUNT,
      placeholder: 'Homebirth Retainer (Optional)',
      optional: true,
    },
    licenseAndCredentails: {
      name: 'License / Credentials',
      children: {
        licenseNumber: {
          type: FieldTypes.TEXT,
          placeholder: 'License Number',
        },
        credentials: {
          initExpanded: true,
          name: 'Credentials',
          children: credentialCheckboxes,
        },
      },
    },
    collaborativePhysicians: {
      name: 'Collaborative Physicians',
      itemName: 'Physician',
      itemFields: physicianField,
    },
    affiliatedHospitals: affiliatedHospitalsField,
    notes: notesField,
    billedCharges: billedChargesField,
  },
}

export const midwifeFields: FieldMap = {
  name: 'Midwife',
  children: {
    ...basicMidwifeInfo.children,
    ...moreMidwifeInfo.children,
  },
}
export const midwivesCollection: CombinedCollection<
  PracticeWithAdmin
> = {
  _type: 'combinedCollection',
  access: patientCollectionAccess,
  name: 'Practices',
  index: {
    _type: 'firestoreCollection',
    name: 'Practices',
    refPath: MIDWIVES,
    access: patientCollectionAccess,
    field: midwifeFields,
    defaultSort: 'name',
    propNames: [
      'name',
      'fname',
      'lname',
      'rank',
      'zipCode',
      'npi',
      'rank',
      'billedCharges',
    ],
  },
  otherCollections: {
    adminPractices: {
      _type: 'firestoreCollection',
      name: 'Admin Practices',
      refPath: MIDWIVES_ADMIN,
      access: adminCollectionAccess,
      defaultSort: 'name',
      propNames: [
        'abilityId',
        'acceptedPaymentMethods',
        'addressNotes',
        'affiliatedHospitals',
        'billedCharges',
        'claimsAddress',
        'collaborativePhysicians',
        'corporateAddress',
        'email',
        'fax',
        'feinTaxClass',
        'group',
        'isGroup',
        'isInactive',
        'licenses',
        'medicaidIds',
        'notes',
        'payToAddress',
        'paymentInfo',
        'paymentsAddress',
        'phone',
        'prm',
        'prmNotes',
        'redFlagged',
        'redFlaggedReason',
        'renderingProviders',
        'retainer',
        'retainerNotes',
        'servicingAddresses',
        'specialty',
        'ssn',
        'taxId',
        'taxIdIsSSN',
        'zipCode',
      ],
    },
  },
}
