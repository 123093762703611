import {
  AtSignIcon,
  CalendarIcon,
  ChatIcon,
  CloseIcon,
  EmailIcon,
  ExternalLinkIcon,
  PhoneIcon,
} from '@chakra-ui/icons'
import {
  Box,
  BoxProps,
  Button,
  Center,
  Flex,
  HStack,
  Image,
  Popover,
  PopoverArrow,
  PopoverCloseButton,
  PopoverContent,
  PopoverTrigger,
  Stack,
  StackDivider,
  Text,
  Tooltip,
} from '@chakra-ui/react'
import {
  AssessmentSnippet,
  AssessmentSortKey,
  ASSESSMENTS_ADMIN,
  CollectionFilter,
  colors,
  DataColumn,
  DataColumnRenderProps,
  DataColumns,
  formatDollarValue,
  formatPhoneNumber,
  getDateString,
  getDateTimeString,
  getPracticeAssessmentCollectionPath,
  getReverseName,
  LogEntry,
  objectToArray,
  populatePayments,
  PracticeAssessmentData,
  WithId,
} from '@hb/shared'
import { doc } from 'firebase/firestore'

import React, { FC, useCallback, useContext } from 'react'
import { Link } from 'react-router-dom'
import { db } from '../../backend/db'
import { DataListContext } from '../../contexts'
import { useUpdateDoc } from '../../hooks'
import { useLogInfo } from '../../hooks/backend/user/usePopulatedUser'
import assignmentIcon from '../../icons/assignment.svg'
import cashIcon from '../../icons/cash.svg'
import { ActionButton } from '../Buttons/ActionButton'
import { DisplayButton, DisplayButtonBase } from '../Buttons/DisplayButton'
import { SortButton } from '../DataList/SortButton'
import { RedFlagIcon, RedFlagPopover } from '../DataView/RedFlagPopover'
import { EllipsisText } from '../EllipsisText'
import { getStatusButton } from '../Status/hooks'
import { AppointmentPreview } from '../Users/Appointments/AppointmentPreview'
import { LogEntryPopover, LogEntryView } from '../Users/Profile/Log/LogEntry'
import { PaymentView } from '../Users/Profile/Payments/PaymentView'
import { getIsUrgent } from '../Users/Profile/UrgentPopover'
import { SortColorCircle } from '../Users/UrgentBadge'
import { ListSortColorPopover } from './users/ListSortColorPopover'
import { ListUrgentPopover } from './users/ListUrgentPopover'
import {
  getRenderPracticeAssessmentNextAction,
  insurerColumn,
  renderAssessmentNextAction,
} from './users/shared'

type AssessmentColumn = DataColumn<
  WithId<AssessmentSnippet | PracticeAssessmentData>,
  AssessmentSortKey
>

type AdminAssessmentColumn = DataColumn<
  WithId<AssessmentSnippet>,
  AssessmentSortKey
>

const providerColumn: AssessmentColumn = {
  title: 'Provider',
  Header: () => <SortButton sortKey="midwifeName">PROVIDER</SortButton>,
  sortKey: 'midwifeName',
  Render: ({ data }) => <EllipsisText text={data?.midwifeName || 'None'} />,
}

const getNameColor = (snippet: AssessmentSnippet) => {
  const { inquiryRank, stages } = snippet || {}

  switch (inquiryRank) {
    case 1:
      return 'green.500'
    case 2:
      return 'yellow.500'
    case 3:
      return stages.includes('Inquiry') ? 'red.500' : '#2D3748'
    default:
      return '#2D3748'
  }
}

const nextActionMobile: AssessmentColumn['mobile'] = {
  width: 275,
  Render: (props) => (
    <Center gap={2} pl={1} borderRadius={4} w="100%" flex={1}>
      <Text
        lineHeight={1}
        fontFamily="Hero-New"
        fontSize="xs"
        whiteSpace="nowrap"
        fontWeight={600}
        color="gray.500"
      >
        NEXT ACTION
      </Text>
      <Flex flex={1} minW="0">
        {renderAssessmentNextAction(props as any)}
      </Flex>
    </Center>
  ),
}

const getPracticeNextActionMobile = (
  practiceId: string,
): AssessmentColumn['mobile'] => ({
  width: 275,
  Render: (props) => (
    <Center gap={2} pl={1} borderRadius={4} w="100%" flex={1}>
      <Text
        lineHeight={1}
        fontFamily="Hero-New"
        fontSize="xs"
        whiteSpace="nowrap"
        fontWeight={600}
        color="gray.500"
      >
        NEXT ACTION
      </Text>
      <Flex flex={1} minW="0">
        {getRenderPracticeAssessmentNextAction(practiceId)(props as any)}
      </Flex>
    </Center>
  ),
})

export const assessmentNextActionColumn: AssessmentColumn = {
  title: 'Next Action Date',
  Header: () => (
    <SortButton defaultDir="asc" sortKey="nextActionDate">
      <Text fontSize="sm" color="gray.600" fontFamily="Hero-New">
        NEXT ACTION
      </Text>
    </SortButton>
  ),
  width: 250,
  isLazy: true,
  sortKey: 'nextActionDate',
  Render: renderAssessmentNextAction as AssessmentColumn['Render'],
  mobile: nextActionMobile,
}

const getPracticeAssessmentNextActionColumn = (
  practiceId: string,
): AssessmentColumn => ({
  title: 'Next Action Date',
  Header: () => (
    <SortButton defaultDir="asc" sortKey="nextActionDate">
      <Text fontSize="sm" color="gray.600" fontFamily="Hero-New">
        NEXT ACTION
      </Text>
    </SortButton>
  ),
  // width: 250,
  isLazy: true,
  sortKey: 'nextActionDate',
  Render: getRenderPracticeAssessmentNextAction(
    practiceId,
  ) as AssessmentColumn['Render'],
  mobile: getPracticeNextActionMobile(practiceId),
})

const OverduePaymentIndicator = ({
  hasOverduePayment,
  ...props
}: BoxProps & { hasOverduePayment?: boolean }) => (
  <Box px={1} {...props}>
    <Tooltip
      placement="top"
      hasArrow
      bg={hasOverduePayment ? colors.green.hex : 'yellow.600'}
      label={hasOverduePayment ? 'Overdue payment' : 'No overdue payments'}
    >
      <Image
        opacity={hasOverduePayment ? 1 : 0.7}
        filter={`grayscale(${hasOverduePayment ? 0 : 100}%)`}
        width="20px"
        alt="payment past due"
        src={cashIcon}
      />
    </Tooltip>
  </Box>
)

const assessmentNeededFilter: CollectionFilter = ['inquiryRank', '==', 1]
const signOnFormCompleteFilter: CollectionFilter = ['inquiryRank', '==', 2]
const signedOnByPracticeFilter: CollectionFilter = ['inquiryRank', '==', 3]
const StatusFilterButton = ({
  filters,
  filter,
  color,
  text,
  onFilterClick,
  onFilterRemove,
}: {
  filters: Array<CollectionFilter>
  filter: CollectionFilter
  color: string
  text: string
  onFilterClick: () => void
  onFilterRemove: () => void
}) => (
  <HStack>
    <Button onClick={onFilterClick} color={color} variant="link" size="sm">
      {text}
    </Button>
    {filters.includes(filter) ? (
      <Button
        px={2}
        bg={color}
        color="white"
        variant="link"
        size="sm"
        onClick={onFilterRemove}
      >
        FILTER
        <CloseIcon width={3} ml={2} />
      </Button>
    ) : null}
  </HStack>
)

const renderNameColumn = (isMobile: boolean) => ({
  data,
  app,
  tabName,
}: DataColumnRenderProps<
    WithId<AssessmentSnippet | PracticeAssessmentData>
  >) => {
  const { id, status } = data || {}
  const { appName } = app
  const { buttonText, buttonColor } = getStatusButton(
    'assessment',
    status || 'incomplete',
  )
  const color = getNameColor(data as AssessmentSnippet)
  return (
      <Flex w="100%" align="center">
        <Link
          style={{
            opacity: 1,
            flex: 1,
            minWidth: 0,
          }}
          to={`${
            appName === 'app' ? '/admin' : ''
          }/assessments/${id}/${tabName === 'Claim' ? 'claims' : 'assessment'}`}
        >
          <Tooltip placement="top" hasArrow bg={buttonColor} label={buttonText}>
            <Button
              fontWeight={500}
              justifyContent="flex-start"
              fontSize="md"
              w="100%"
              minW="0"
              alignItems="center"
              color={color}
              variant="link"
              textDecoration={isMobile ? 'underline' : 'none'}
            >
              <Text
                style={{
                  textDecoration: isMobile ? 'underline' : 'none',
                }}
                textAlign="left"
                isTruncated
              >
                {data ? getReverseName(data) : 'No name entered'}
              </Text>
            </Button>
          </Tooltip>
        </Link>
      </Flex>
  )
}

export const nameStatusColumn: AssessmentColumn = {
  title: 'Patient Name / Status',
  Header: () => {
    const { filterBy, filters, removeFilter } = useContext(DataListContext)
    return (
      <Flex align="center">
        <SortButton sortKey="lname">NAME</SortButton>
        <Text mr={3}>/</Text>
        <SortButton defaultDir="desc" sortKey="inquiryRank">
          STATUS
        </SortButton>
        <Popover isLazy trigger="hover">
          <PopoverTrigger>
            <ActionButton
              _hover={{ bg: `${colors.green.hex}88` }}
              bg={colors.green.hex}
              border="none"
              color="white"
              px={2}
              lineHeight="12px"
              size="xs"
              colorScheme="blue"
            >
              KEY
            </ActionButton>
          </PopoverTrigger>
          <PopoverContent w="auto">
            <PopoverArrow />
            <Stack spacing={1} p={2}>
              <StatusFilterButton
                color="green.500"
                filter={assessmentNeededFilter}
                filters={filters}
                text="Questionnaire submitted, assessment needed"
                onFilterClick={() => filterBy(assessmentNeededFilter)}
                onFilterRemove={() => removeFilter('inquiryRank')}
              />
              <StatusFilterButton
                color="yellow.500"
                filter={signOnFormCompleteFilter}
                filters={filters}
                text="Sign on form complete, but not signed on by practice"
                onFilterClick={() => filterBy(signOnFormCompleteFilter)}
                onFilterRemove={() => removeFilter('inquiryRank')}
              />
              <StatusFilterButton
                color="red.500"
                filter={signedOnByPracticeFilter}
                filters={filters}
                text="Signed on by practice, but not on auth list"
                onFilterClick={() => filterBy(signedOnByPracticeFilter)}
                onFilterRemove={() => removeFilter('inquiryRank')}
              />
            </Stack>
          </PopoverContent>
        </Popover>
      </Flex>
    )
  },
  sortKey: 'lname',
  width: 220,
  Render: renderNameColumn(false),
  mobile: {
    width: 220,
    Render: renderNameColumn(true),
  },
}

const renderPracticeSiteNameColumn = (isMobile: boolean) => ({
  data,
  app: { appName },
}: DataColumnRenderProps<
    WithId<AssessmentSnippet | PracticeAssessmentData>
  >) => (
    <Link
      style={{ opacity: 1, flex: 1 }}
      to={`${appName === 'app' ? '/admin' : ''}/assessments/${
        data.id
      }/assessment`}
    >
      <Tooltip
        placement="top"
        hasArrow
        bg={data.clearedOn ? colors.green.hex : 'gray.600'}
        label={
          data.clearedOn ? 'Cleared for coverage' : 'Not cleared for coverage'
        }
      >
        <Button
          fontWeight={500}
          justifyContent="flex-start"
          fontSize="md"
          px={1}
          gap={1}
          color={data.clearedOn ? colors.green.hex : 'gray.600'}
          variant="link"
          textDecor='underline'
        >
          <Text
            textAlign="left"
            textOverflow="ellipsis"
            textDecor={isMobile ? 'underline' : 'none'}
            overflow="hidden"
          >
            {data ? getReverseName(data) : 'No name entered'}
          </Text>
          <ExternalLinkIcon color="gray.600" width={3} />
        </Button>
      </Tooltip>
    </Link>
)

const practiceNameColumn: AssessmentColumn = {
  title: 'Patient Name',
  Header: () => <SortButton sortKey="lname">NAME</SortButton>,
  width: 200,
  sortKey: 'lname',
  Render: renderPracticeSiteNameColumn(false),
  mobile: {
    width: 200,
    Render: renderPracticeSiteNameColumn(true),
  },
}

export const redFlagsColumn: AdminAssessmentColumn = {
  title: 'Red Flags',
  Header: () => (
    <SortButton noArrow sortKey="redFlagText">
      <Center px="6px">
        <RedFlagPopover />
      </Center>
    </SortButton>
  ),
  flexProps: {
    justify: 'center',
  },
  width: 50,
  Render: ({ data: { redFlagText, id }, cell, preview: { openPreview } }) => (
    <Flex mx="auto">
      <RedFlagIcon
        onClick={(e) => openPreview({
          id,
          item: 'redFlags',
          position: { x: e.clientX, y: e.clientY },
          cell,
        })
        }
        color={redFlagText && redFlagText !== 'zzz' ? 'yellow.600' : 'gray.400'}
      />
    </Flex>
  ),
}

export const assessmentUrgentColumn: AssessmentColumn = {
  title: 'Urgent',
  Header: () => (
    <Tooltip label="URGENT">
      <Box py="0.2rem" px={1}>
        <ListUrgentPopover sortKey="urgentSort" />
      </Box>
    </Tooltip>
  ),
  sortKey: 'urgentSort',
  flexProps: { justify: 'center' },
  width: 40,
  Render: ({ data: user, cell, preview: { openPreview } }) => {
    const { urgentSort, id } = (user as WithId<AssessmentSnippet>) || {}
    const isUrgent = getIsUrgent(user)
    // const assignedAdmins = admins?.filter((i) => i.adminColor === urgentSort)
    return (
      <Center px={2}>
        {/* <Tooltip placement='top' hasArrow label={assignedAdmins?.map((a) => getFullName(a)).join(', ') || 'No admin assigned'}> */}
        <Box>
          <SortColorCircle
            onClick={(e) => openPreview({
              id,
              item: 'urgent',
              position: { x: e.clientX, y: e.clientY },
              cell,
            })
            }
            withAdmins
            px={1}
            color={urgentSort && urgentSort !== 'zzz' ? urgentSort : 'gray.300'}
            urgent={isUrgent}
          />
        </Box>
        {/* </Tooltip> */}
      </Center>
    )
  },
}

export const eddColumn: AssessmentColumn = {
  title: 'EDD',
  Header: () => (
    <Stack
      align="center"
      direction="row"
      mr={1}
      px={2}
      spacing={3}
      width="100%"
    >
      <Flex align="center" flexGrow={1}>
        <SortButton sortKey="edd">EDD</SortButton>
      </Flex>
    </Stack>
  ),
  // flexProps: { width: '100%' },
  width: 120,
  sortKey: 'edd',
  Render: (props) => (
    <Stack
      direction="row"
      spacing={3}
      pl={1}
      pr={3}
      width="100%"
      justify="flex-start"
      align="center"
    >
      <Text p={1}>
        {props.data?.edd ? getDateString(props.data.edd, 'short') : 'None'}
      </Text>
    </Stack>
  ),
  mobile: {
    width: 130,
    Render: ({ data }) => {
      const { edd } = data || {}
      return (
        <Center
          py={1}
          boxShadow="1px 1px 3px #00000077"
          gap={1}
          bg="gray.100"
          h="28px"
          borderRadius={4}
          w="100%"
          flex={1}
        >
          <Text
            lineHeight={1}
            fontFamily="Hero-New"
            fontSize="xs"
            fontWeight={600}
            color="gray.500"
          >
            EDD:
          </Text>
          <Text
            lineHeight={1}
            fontFamily="Open Sans"
            fontSize="sm"
            color="gray.600"
          >
            {edd ? getDateString(edd, 'short') : 'None'}
          </Text>
        </Center>
      )
    },
  },
}

export const overduePaymentColumn: AssessmentColumn = {
  title: 'Overdue Payment',
  Header: () => {
    const { sortKey } = useContext(DataListContext)
    return (
      <Stack align="center" direction="row" width="100%">
        <SortButton defaultDir="asc" noArrow sortKey="hasOverduePayment">
          <OverduePaymentIndicator
            hasOverduePayment={sortKey === 'hasOverduePayment'}
          />
        </SortButton>
      </Stack>
    )
  },
  // flexProps: { width: '100%' },
  width: 40,
  sortKey: 'hasOverduePayment',
  defaultSortDirection: 'asc',
  Render: ({ data: { hasOverduePayment } }) => (
    <Stack
      direction="row"
      spacing={3}
      width="100%"
      justify="flex-start"
      align="center"
    >
      <Center minW="28px">
        <OverduePaymentIndicator hasOverduePayment={hasOverduePayment} />
      </Center>
    </Stack>
  ),
  mobile: {
    width: 130,
    Render: ({ data }) => {
      const { hasOverduePayment } = data || {}
      return (
        <Center
          py={1}
          boxShadow="1px 1px 3px #00000077"
          gap={1}
          bg={hasOverduePayment ? 'yellow.500' : 'gray.100'}
          borderRadius={4}
          w="100%"
          h="28px"
          flex={1}
        >
          <OverduePaymentIndicator hasOverduePayment={hasOverduePayment} />
          <Text
            textShadow={hasOverduePayment ? '1px 1px 3px #00000077' : 'none'}
            lineHeight={1}
            fontFamily="Hero-New"
            fontSize="xs"
            fontWeight={600}
            color={hasOverduePayment ? 'white' : 'gray.500'}
          >
            {hasOverduePayment ? 'OVERDUE' : 'NOT OVERDUE'}
          </Text>
        </Center>
      )
    },
  },
}

const getRenderAssessmentLog = (
  collection: string,
  isPractice: boolean,
  isMobile: boolean,
): FC<DataColumnRenderProps<WithId<AssessmentSnippet>>> => ({ data }) => {
  const { phone, id } = data || {}

  const log = isPractice ? (data as any).log : data?.logSnippet
  const {
    lastCreatedKey, lastCreated, lastUpdated, lastUpdatedKey,
  } = useLogInfo(log)

  const formatted = formatPhoneNumber(phone)
  // if (isScrolling) {
  //   return (
  //     <Center w='100%'>
  //       <Spinner />
  //     </Center>
  //   )
  // }
  const reverseName = getReverseName(data)
  const update = useUpdateDoc()
  const onSave = useCallback(
    async (updated: LogEntry, createdOn?: number) => {
      const docRef = doc(db, collection, id)
      return update(docRef, 'log', {
        [createdOn || Date.now()]: updated,
      })
    },
    [update, id],
  )

  const chatIcon = <ChatIcon color={lastUpdated ? 'blue.400' : 'gray.600'} />

  return (
      <Stack flex={1} spacing={1} direction="row">
        <Popover closeOnBlur={false} isLazy strategy="fixed">
          <PopoverTrigger>
            <DisplayButtonBase
              size={isMobile ? 'sm' : 'md'}
              aria-label="log"
              icon={
                isMobile ? (
                  <Flex color="gray.600" gap={1} align="center">
                    {chatIcon}
                    <Text fontSize="xs">LOG</Text>
                  </Flex>
                ) : (
                  chatIcon
                )
              }
            />
          </PopoverTrigger>
          <PopoverContent w="auto" px={2} py={2}>
            <PopoverCloseButton />
            <PopoverArrow />
            <Text fontFamily="Hero-New" fontSize="md" fontWeight={600} px={2}>
              {reverseName}
            </Text>
            {log && lastCreatedKey ? (
              <Stack spacing={1} width="349px" divider={<StackDivider />}>
                <Box>
                  <Text
                    pt={1}
                    px={2}
                    fontWeight={500}
                    color="gray.600"
                    fontSize="sm"
                  >
                    Last created
                  </Text>
                  <LogEntryView
                    update={(updated) => onSave(updated, lastCreatedKey)}
                    createdOn={lastCreatedKey}
                    {...lastCreated!}
                  />
                </Box>
                {lastUpdatedKey && lastCreatedKey !== lastUpdatedKey ? (
                  <Box w="100%" position="relative">
                    <Text fontWeight={500} color="gray.600" fontSize="sm">
                      Last updated
                    </Text>
                    <LogEntryView
                      createdOn={lastUpdatedKey}
                      {...lastUpdated!}
                    />
                  </Box>
                ) : null}
              </Stack>
            ) : (
              <Text w='300px' px={2} color="gray.399">
                No log entries
              </Text>
            )}
            <LogEntryPopover
              isNew
              onSubmit={(added) => onSave(added)}
            >
              <Button
                aria-label="add log entry"
                ml="auto"
                fontWeight={499}
                size="xs"
                height="auto"
                py={1}
                color="gray.499"
                m={1}
                variant="outline"
              >
                + ADD ENTRY
              </Button>
            </LogEntryPopover>
          </PopoverContent>
        </Popover>
        {isMobile ? null : (
          <>
            <DisplayButton
              aria-label="email"
              icon={
                isMobile ? (
                  <Flex color="gray.600" gap={1} align="center">
                    <EmailIcon />
                    <Text fontSize="xs">COPY EMAIL</Text>
                  </Flex>
                ) : (
                  <EmailIcon />
                )
              }
              text={data?.email || 'None'}
              size={isMobile ? 'xs' : 'md'}
            />
            <DisplayButton
              aria-label="phone"
              icon={
                isMobile ? (
                  <Flex color="gray.600" gap={1} align="center">
                    <PhoneIcon />
                    <Text fontSize="xs">COPY PHONE</Text>
                  </Flex>
                ) : (
                  <PhoneIcon />
                )
              }
              text={formatted}
              size={isMobile ? 'xs' : 'md'}
            />
          </>
        )}
      </Stack>
  )
}

export const assessmentLogColumn: AdminAssessmentColumn = {
  title: 'Log / Contact Info',
  Header: () => <Text>LOG / CONTACT</Text>,
  isLazy: true,
  width: 160,
  Render: getRenderAssessmentLog(ASSESSMENTS_ADMIN, false, false),
  mobile: {
    width: 275,
    Render: getRenderAssessmentLog(ASSESSMENTS_ADMIN, false, true),
  },
}

export const getPracticeAssessmentLogColumn = (
  practiceId: string,
): AdminAssessmentColumn => ({
  title: 'Log / Contact Info',
  Header: () => <Text>LOG / CONTACT</Text>,
  isLazy: true,
  width: 160,
  Render: getRenderAssessmentLog(
    getPracticeAssessmentCollectionPath(practiceId),
    true,
    false,
  ),
  mobile: {
    width: 275,
    Render: getRenderAssessmentLog(
      getPracticeAssessmentCollectionPath(practiceId),
      true,
      true,
    ),
  },
})

const contactInfoColumn: AssessmentColumn = {
  title: 'Contact Info',
  Header: () => <Text>CONTACT INFO</Text>,
  width: 160,
  Render: ({ data }) => {
    const { email, phone } = data || {}
    const formatted = formatPhoneNumber(phone)
    return (
      <Stack flex={1} spacing={1} direction="row">
        <DisplayButton aria-label="email" icon={<AtSignIcon />} text={email} />
        <DisplayButton
          aria-label="phone"
          icon={<PhoneIcon />}
          text={formatted}
        />
      </Stack>
    )
  },
}

const sortColorColumn: AssessmentColumn = {
  title: 'Sort Color',
  Header: () => (
    <Flex px={1} py="2px">
      <ListSortColorPopover />
    </Flex>
  ),
  sortKey: 'sortColor',
  width: 44,
  Render: ({ data, preview: { openPreview }, cell }) => {
    const { sortColor, id } = (data as WithId<PracticeAssessmentData>) || {}
    return (
      <Center w="100%" flex={1}>
        <Center px={2}>
          <SortColorCircle
            onClick={(e) => openPreview({
              id,
              item: 'sortColor',
              position: { x: e.clientX, y: e.clientY },
              cell,
            })
            }
            withAdmins
            px={1}
            color={sortColor}
          />
        </Center>
      </Center>
    )
  },
}

const unreadMessagesColumn: AssessmentColumn = {
  title: 'Unread Messages',
  Header: () => (
    <SortButton defaultDir="asc" noArrow sortKey="unreadThreadMessages">
      <Tooltip placement="top" hasArrow label="Unread Messages">
        <EmailIcon width="24px" height="16px" color="gray.400" />
      </Tooltip>
    </SortButton>
  ),
  defaultSortDirection: 'asc',
  width: 36,
  sortKey: 'unreadThreadMessages',
  Render: ({ data }) => {
    const { unreadThreadMessages } = data || {}
    return (
      <Tooltip
        placement="top"
        hasArrow
        label={
          unreadThreadMessages
            ? `${unreadThreadMessages} unread message${
              unreadThreadMessages > 1 ? 's' : ''
            }`
            : 'No unread messages'
        }
      >
        <Center w="100%" flex={1}>
          <EmailIcon
            width="24px"
            height="16px"
            color={unreadThreadMessages ? colors.green.hex : 'gray.400'}
          />
        </Center>
      </Tooltip>
    )
  },
  mobile: {
    width: 100,
    Render: ({ data }) => {
      const { unreadThreadMessages } = data || {}
      return (
        <Tooltip
          placement="top"
          hasArrow
          label={
            unreadThreadMessages
              ? `${unreadThreadMessages} unread message${
                unreadThreadMessages > 1 ? 's' : ''
              }`
              : 'No unread messages'
          }
        >
          <Center
            boxShadow="1px 1px 3px #00000077"
            gap={1}
            bg={unreadThreadMessages ? colors.green.hex : 'gray.100'}
            borderRadius={4}
            w="100%"
            height="28px"
            flex={1}
          >
            <EmailIcon
              height="16px"
              color={!unreadThreadMessages ? 'gray.500' : 'white'}
            />
            <Text
              lineHeight={1}
              fontFamily="Hero-New"
              fontSize="xs"
              textShadow={
                unreadThreadMessages ? '1px 1px 3px #00000077' : 'none'
              }
              fontWeight={600}
              color={unreadThreadMessages ? 'white' : 'gray.500'}
            >
              {unreadThreadMessages} UNREAD
            </Text>
          </Center>
        </Tooltip>
      )
    },
  },
}

const nextAppointmentColumn: AssessmentColumn = {
  title: 'Next Appt.',
  Header: () => {
    const { sortKey } = useContext(DataListContext)
    return (
      <SortButton defaultDir="asc" sortKey="nextPatientAppointmentDate">
        <HStack spacing={1}>
          <CalendarIcon
            width="24px"
            height="16px"
            color={
              sortKey === 'nextPatientAppointmentDate'
                ? colors.green.hex
                : 'gray.400'
            }
          />
          <Text fontSize="sm" color="gray.600" fontFamily="Hero-New">
            NEXT APPT.
          </Text>
        </HStack>
      </SortButton>
    )
  },
  defaultSortDirection: 'asc',
  width: 190,
  sortKey: 'nextPatientAppointmentDate',
  Render: ({ data }) => {
    const { nextPatientAppointment, nextPatientAppointmentDate } = (data as PracticeAssessmentData) || {}
    return (
      <Popover isLazy trigger="hover" strategy="fixed" placement="left">
        <PopoverTrigger>
          {nextPatientAppointmentDate ? (
            <HStack
              cursor="pointer"
              bg="white"
              borderRadius={6}
              boxShadow="md"
              px={2}
              py={1}
            >
              <CalendarIcon color={colors.green.hex} />
              <Text color="gray.600" fontFamily="Hero-New">
                {getDateTimeString(nextPatientAppointmentDate)}
              </Text>
            </HStack>
          ) : (
            <Text color="gray.600">None</Text>
          )}
        </PopoverTrigger>
        <PopoverContent p={0} border="none">
          {nextPatientAppointment ? (
            <AppointmentPreview appointment={nextPatientAppointment} />
          ) : (
            <Text py={1} px={2} fontSize="sm" color="gray.600">
              No future appointments scheduled
            </Text>
          )}
          <PopoverArrow />
        </PopoverContent>
      </Popover>
    )
  },
}

const signedOnColumn: AssessmentColumn = {
  title: 'Signed On Date',
  Header: () => (
    <SortButton noArrow sortKey="signedOnDate">
      <Tooltip placement="top" hasArrow label="Patient completed sign-on forms">
        <Image filter="grayscale(100%)" height="20px" src={assignmentIcon} />
      </Tooltip>
    </SortButton>
  ),
  width: 30,
  sortKey: 'signedOnDate',
  Render: ({ data }) => {
    const { signedOnDate } = data || {}
    return (
      <Center width="100%">
        <Tooltip
          placement="top"
          hasArrow
          bg={signedOnDate ? colors.green.hex : '#777'}
          label={
            signedOnDate
              ? `Patient completed sign on forms on ${getDateString(signedOnDate, 'short')}`
              : 'Patient has not completed sign on forms'
          }
        >
          <Image
            filter={`grayscale(${signedOnDate ? 0 : 100}%)`}
            height="20px"
            src={assignmentIcon}
          />
        </Tooltip>
      </Center>
    )
  },
  mobile: {
    width: 160,
    Render: ({ data }) => {
      const { signedOnDate } = data || {}
      return (
        <Center
          height="28px"
          boxShadow="1px 1px 3px #00000077"
          gap={1}
          bg={signedOnDate ? colors.green.hex : 'gray.100'}
          borderRadius={4}
          w="100%"
          flex={1}
        >
          <Image
            filter={`brightness(${signedOnDate ? 300 : 100}%)`}
            height="18px"
            src={assignmentIcon}
          />
          <Text
            lineHeight={1}
            fontFamily="Hero-New"
            fontSize="xs"
            fontWeight={600}
            color={signedOnDate ? 'white' : 'gray.500'}
          >
            {signedOnDate ? 'SIGNED ON' : 'NOT SIGNED ON'}
          </Text>
        </Center>
      )
    },
  },
}

const nextDuePaymentColumn: AssessmentColumn = {
  title: 'Next Payment Due',
  Header: () => (
    // <SortButton defaultDir="asc" noArrow sortKey="nextDuePaymentDate">
    <HStack>
      <Text fontSize="sm" color="gray.600" fontFamily="Hero-New">
        NEXT PAYMENT
      </Text>
    </HStack>
    // </SortButton>
  ),
  defaultSortDirection: 'asc',
  width: 190,
  // sortKey: 'nextDuePaymentDate',
  Render: ({ data }) => {
    const { payments } = (data as PracticeAssessmentData) || {}
    const populatedPayments = objectToArray(populatePayments(payments || {}))
    const allPayments = populatedPayments
      .map((p) => objectToArray(p.payments))
      .flat()
    const unpaidPayments = allPayments.filter((p) => !p.paidOn)
    const nextDuePayment = unpaidPayments.sort((a, b) => {
      if (!a.dueDate) return 1
      if (!b.dueDate) return -1
      if (a.dueDate < b.dueDate) return -1
      if (a.dueDate > b.dueDate) return 1
      return 0
    })[0]

    return (
      <Popover isLazy trigger="hover" strategy="fixed" placement="left">
        <PopoverTrigger>
          {nextDuePayment ? (
            <HStack
              w="100%"
              justify="space-between"
              cursor="pointer"
              px={1}
              py={1}
            >
              <HStack spacing={1}>
                <CalendarIcon color="gray.500" />
                <Text fontSize="sm" color="gray.500" fontFamily="Hero-New">
                  {getDateString(nextDuePayment.dueDate || '', 'short')}
                </Text>
              </HStack>
              <Text
                fontWeight={600}
                bg="#dedede"
                px={2}
                borderRadius="full"
                color="gray.600"
              >
                {formatDollarValue(nextDuePayment.amount)}{' '}
              </Text>
            </HStack>
          ) : (
            <Text px={1} color="gray.600">
              None
            </Text>
          )}
        </PopoverTrigger>
        <PopoverContent w="auto" p={0} border="none">
          {nextDuePayment ? (
            <PaymentView payment={nextDuePayment} />
          ) : (
            <Text py={1} px={2} fontSize="sm" color="gray.600">
              No future payments scheduled
            </Text>
          )}
          <PopoverArrow />
        </PopoverContent>
      </Popover>
    )
  },
}

export const assessmentColumns: DataColumns<
  WithId<AssessmentSnippet | PracticeAssessmentData>,
  AssessmentSortKey
> = {
  urgent: assessmentUrgentColumn,
  unreadMessages: unreadMessagesColumn,
  overduePayment: overduePaymentColumn,
  signedOn: signedOnColumn,
  edd: eddColumn,
  name: nameStatusColumn,
  // // urgent: assessmentUrgentColumn,
  // redFlags: redFlagsColumn,
  insurer: insurerColumn as AssessmentColumn,
  midwife: providerColumn as AssessmentColumn,
  nextAction: assessmentNextActionColumn as AssessmentColumn,
  log: assessmentLogColumn as AssessmentColumn,
  redFlags: redFlagsColumn as AssessmentColumn,
}

export const getProviderAssessmentColumns = (
  practiceId: string,
): DataColumns<
  WithId<AssessmentSnippet | PracticeAssessmentData>,
  AssessmentSortKey
> => {
  const columns: DataColumns<
    WithId<AssessmentSnippet | PracticeAssessmentData>,
    AssessmentSortKey
  > = {
    unreadMessages: unreadMessagesColumn,
    overduePayment: overduePaymentColumn,
    signedOn: signedOnColumn,
    edd: eddColumn,
    name: practiceNameColumn,
    // insurer: insurerColumn as AssessmentColumn,
    nextAction: getPracticeAssessmentNextActionColumn(practiceId),
    log: getPracticeAssessmentLogColumn(practiceId) as AssessmentColumn,
  }
  return columns
}
