import {
  CircularProgress,
  Divider,
  Flex,
  HStack,
  Image,
  Text,
  VStack,
} from '@chakra-ui/react'
import {
  Assessment,
  AssessmentDocument,
  colors, ConsentForm, getCorrectedValue,
  getDateString,
  objectToArray,
  WithId,
} from '@hb/shared'
import React, { useMemo } from 'react'
import { useUserAssessments } from '../../../../hooks/backend/user/useUserAssessments'
import { useUserConsentForms } from '../../../../hooks/backend/user/useUserConsentForms'
import { DocumentsHeader } from '../Documents/DocumentsHeader'
import { DocumentsList } from '../Documents/DocumentsList'

const separateDocumentsByAssessment = <
  T extends AssessmentDocument | ConsentForm,
>(
    documents: WithId<T>[],
  ) => documents.reduce(
    (acc, doc) => {
      const { assessmentId } = doc
      if (!acc[assessmentId]) {
        acc[assessmentId] = []
      }
      acc[assessmentId].push(doc)
      return acc
    },
    {} as Record<string, WithId<T>[]>,
  )

// .reduce(
//   (acc, [assessmentId, docs]) => ({
//     ...acc,
//     [assessmentId]: separateDocumentsByType(docs),
//   }),
//     {} as Record<
//       string,
//       Record<AssessmentDocumentType, WithId<AssessmentDocument>[]>
//     >,
// )

const PatientAssessmentConsentForms = ({
  assessment,
  documents,
}: {
  assessment: Assessment
  documents?: WithId<ConsentForm>[]
}) => {
  // const typeKeys = useMemo(() => Object.keys(consent forms || {}), [consent forms])
  const name = useMemo(() => {
    const edd = getCorrectedValue(assessment, 'delivery.edd')
    const eddStr = edd
      ? `EDD: ${getDateString(edd, 'short')}`
      : 'EDD: Awaiting...'
    return assessment.name ? assessment.name : eddStr
  }, [assessment])
  return (
    <VStack
      // sx={{ ':nth-of-type(4n - 3)': { background: 'rgb(245,245,245)' } }}
      px={2}
      pt={1}
      pb={2}
      align="flex-start"
      w="100%"
      spacing={0}
    >
      <HStack justify="space-between" w="100%">
        <HStack py={1} spacing={1}>
          <Image src="/images/assessment.svg" height="20px" opacity={0.6} />
          <Text
            fontSize="md"
            position="relative"
            top="1px"
            fontFamily="Comfortaa"
            fontWeight={600}
            color="gray.600"
          >
            {name}
          </Text>
        </HStack>
      </HStack>
      <DocumentsList
        // key={type}
        // collection={typeToCollection[type as AssessmentDocumentType]}
        documents={documents}
      />
    </VStack>
  )
}

export const PatientConsentForms = ({
  patientId,
  adminView,
  showAllAssessments,
}: {
  patientId: string
  adminView?: boolean
  showAllAssessments?: boolean
}) => {
  const { data, loading } = useUserConsentForms(patientId)
  const { data: assessments, loading: loadingAssessments } = useUserAssessments(patientId)
  const dataArr = useMemo(() => objectToArray(data || {}), [data])

  const consentFormsByAssessment = useMemo(
    () => Object.entries(separateDocumentsByAssessment(dataArr)).reduce(
      (acc, curr) => {
        const [assessmentId, docs] = curr
        if (!docs.length && !showAllAssessments) return acc
        acc[assessmentId] = docs
        return acc
      },
        {} as Record<string, WithId<ConsentForm>[]>,
    ),
    [dataArr, showAllAssessments],
  )

  const assessmentKeys = useMemo(
    () => Object.keys(consentFormsByAssessment),
    [consentFormsByAssessment],
  )

  const noneText = showAllAssessments
    ? 'No assessments yet'
    : 'No consent forms yet'

  return (
    <Flex
      borderBottomRadius={6}
      bg="white"
      w="100%"
      boxShadow="md"
      borderRadius={6}
    >
      <VStack spacing={0} w="100%">
        <DocumentsHeader
          numDocs={dataArr.length}
          loading={loading}
          stackProps={{ borderBottom: '1px solid #cdcdcd' }}
        >
          {adminView ? 'Patient Consent Forms' : 'Your Consent Forms'}
        </DocumentsHeader>
        {!loading && !loadingAssessments ? (
          <VStack w="100%" divider={<Divider/>} spacing={1}>
            {assessmentKeys.length ? (
              assessmentKeys.map((assessmentId) => (assessments?.[assessmentId] ? (
                  <PatientAssessmentConsentForms
                    key={assessmentId}
                    assessment={assessments?.[assessmentId]}
                    documents={consentFormsByAssessment[assessmentId]}
                  />
              ) : null))
            ) : (
              <Text
                w="100%"
                p={3}
                bg="whiteAlpha.800"
                borderRadius={4}
                color="gray.500"
              >
                {loading ? 'Loading...' : noneText}
              </Text>
            )}
          </VStack>
        ) : (
          <HStack bg="white" p={3} borderRadius={6} boxShadow="md" w="100%">
            <CircularProgress
              color={colors.green.hex}
              isIndeterminate
              size={5}
            />
            <Text fontSize="md" fontFamily="Open Sans" color="gray.600">
              {adminView
                ? 'Loading patient consent forms...'
                : 'Loading your consent forms...'}
            </Text>
          </HStack>
        )}
      </VStack>
    </Flex>
  )
}
