import {
  Collection,
  FieldMapValue,
  MIDWIVES,
  Practice,
  PracticeConsentForm,
  Template,
  TemplateKey,
} from '@hb/shared'
import { ValidationErrors } from 'final-form'
import React, {
  FC, useCallback, useContext, useMemo,
} from 'react'
import { saveItem } from '../../collections/utils/write'
import {
  PopUpMessageContext,
  ScreenContext,
  useApp,
  usePracticeAccess,
} from '../../contexts'
import { useDocument } from '../../hooks/backend/useDocument'
import { DefaultModal } from '../Modals/DefaultModal'
import { TemplateEditorContext } from '../RichText/context'
import { useEditorWidth } from '../RichText/hooks'
import { TemplateEditor } from './TemplateEditor'

export type TemplateModalProps = {
  isOpen: boolean
  templateType: TemplateKey
  onClose: () => void
}

export interface BaseTemplateModalProps extends TemplateModalProps {
  loading?: boolean
  onSubmit?: (data: FieldMapValue) => Promise<any>
  template?: Template
  templateType: TemplateKey
}

const defaultSubmitFunction = async () => {
  console.error('No submit function')
  return undefined
}
export const BaseTemplateModal: FC<BaseTemplateModalProps> = ({
  isOpen,
  onClose,
  template,
  onSubmit = defaultSubmitFunction,
  templateType,
}) => {
  const { height: screenHeight } = useContext(ScreenContext)
  const height = useMemo(() => `${screenHeight - 160}px`, [screenHeight])
  const sizeProps = useEditorWidth()
  return (
    <DefaultModal
      isOpen={isOpen}
      isCentered={false}
      closeOnEsc={false}
      closeOnOverlayClick={false}
      overlayHeader
      contentProps={{ style: { padding: 0 }, width: 'auto' }}
      bodyProps={{ style: { padding: 0 }, width: 'auto' }}
      size="4xl"
      onClose={onClose}
      render={() => (
        <TemplateEditor
          templateType={templateType}
          style={{ height }}
          onSubmit={onSubmit}
          value={template}
          {...sizeProps}
        />
      )}
    />
  )
}

export const EditTemplateModal: FC<
  TemplateModalProps & {
    template?: Template | PracticeConsentForm
    collection: Collection<Template>
  }
> = ({ template, collection, ...props }) => {
  // const { templates: templatesData } = app || {}
  const { processResponse } = useContext(PopUpMessageContext)
  const { appName } = useApp()
  const [manualPracticeId, setManualPracticeId] = React.useState<string | null>(
    null,
  )
  const onSave = useCallback(
    async (data: any): Promise<ValidationErrors> => {
      const res = await saveItem(appName, collection, data)
      if (res.error) return processResponse(res)
      return processResponse({ success: 'Updated template!' })
      // console.error('something went wrong when saving')
    },
    [collection, processResponse, appName],
  )

  const { selectedPracticeId } = usePracticeAccess()
  const usedPracticeId = useMemo(
    () => (appName === 'providers-app' ? selectedPracticeId : manualPracticeId),
    [selectedPracticeId, appName, manualPracticeId],
  )
  const { data: practice } = useDocument<Practice>(MIDWIVES, usedPracticeId)
  const templateEditorContextData = useMemo(
    () => ({
      shortcutArgs: { practice },
      selectPractice: (pId: string) => setManualPracticeId(pId),
    }),
    [practice],
  )
  return (
    <TemplateEditorContext.Provider value={templateEditorContextData}>
      {template ? (
        <BaseTemplateModal template={template} onSubmit={onSave} {...props} />
      ) : null}
    </TemplateEditorContext.Provider>
  )
}
