import { CalendarIcon } from '@chakra-ui/icons'
import {
  Center, HStack, Image, Text, VStack,
} from '@chakra-ui/react'
import { colors } from '@hb/shared/constants'
import { Invoice, PracticeInvoice } from '@hb/shared/invoicing'
import { AssessmentInvoiceSnippet } from '@hb/shared/types'
import { getDateString } from '@hb/shared/utils'
import React from 'react'
import { ActionLog } from '../ActionLog'

const InvoiceStatusIcon = ({
  status,
  manuallyAddedBy,
  sentBy,
  sentOn,
}: {
  status: Invoice['status']
  manuallyAddedBy?: string
  sentOn?: string | null
  sentBy?: string | null
}) => {
  if (status === 'scheduled') {
    if (manuallyAddedBy) {
      return <Image src="/images/person.svg" width="20px" opacity={0.6} />
    }
    return <CalendarIcon color="gray.600" width={3} height={3} />
  }

  if (status === 'draft') {
    return <Image src="/images/draft.svg" height="20px" opacity={0.6} />
  }

  if (status === 'sent') {
    return <ActionLog on={sentOn} by={sentBy} action="Sent" />
  }
  if (status === 'pending-approval') {
    return <Image opacity={0.7} src="/images/person.svg" height="20px" />
  }
  return (
    <Center
      fontSize="xs"
      // border={`1px solid ${statusColor}`}
      bg={status === 'paid' ? colors.green.hex : 'transparent'}
      border={status === 'paid' ? 'none' : '1px solid #777'}
      color={status === 'paid' ? 'white' : '#777'}
      width="18px"
      borderRadius={4}
      height="18px"
      fontWeight={600}
    >
      $
    </Center>
  )
}

export const InvoiceStatusView = ({
  invoice,
}: {
  invoice: PracticeInvoice | AssessmentInvoiceSnippet
}) => {
  const status = (invoice as PracticeInvoice).status
    || (invoice as AssessmentInvoiceSnippet).invoiceStatus
  const { scheduledFor } = invoice
  const paidOn = (invoice as PracticeInvoice).paidOn
    || (invoice as AssessmentInvoiceSnippet).payment?.paidOn

  const { manuallyAddedBy } = (invoice as AssessmentInvoiceSnippet) || {}
  if (status === 'scheduled') {
    return (
      <HStack p={1} spacing={2}>
        <InvoiceStatusIcon
          sentBy={invoice.sentBy}
          sentOn={invoice.sentOn}
          status={status}
          manuallyAddedBy={manuallyAddedBy || undefined}
        />
        <Text fontSize='sm' fontWeight={600} color="gray.500" lineHeight={1}>
          Scheduled for{' '}
          {scheduledFor
            ? getDateString(scheduledFor, 'short')
            : 'Error: missing scheduled time'}
        </Text>
      </HStack>
    )
  }

  if (status === 'paid') {
    return (
      <HStack>
        <InvoiceStatusIcon status={status} />
        <Text fontSize='sm' fontWeight={600} color="gray.500" lineHeight={1}>
          Paid on {paidOn ? getDateString(paidOn) : 'Error: no paid on date'}
        </Text>
      </HStack>
    )
  }
  if (status === 'sent') {
    return (
      <HStack>
        <ActionLog on={invoice.sentOn} by={invoice.sentBy} action="Sent" />
      </HStack>
    )
  }
  if (status === 'draft') {
    return (
      <HStack spacing={1}>
        <InvoiceStatusIcon status={status} />
        <Text fontSize='sm' fontWeight={600} color="gray.500" lineHeight={1}>
          Draft
        </Text>
      </HStack>
    )
  }
  if (status === 'pending-approval') {
    return (
      <VStack align="flex-start" spacing={0}>
        <HStack spacing={1}>
          <InvoiceStatusIcon status={status} />
          <Text fontSize='sm' fontWeight={600} color="gray.500" lineHeight={1}>
            Pending Approval
          </Text>
        </HStack>
        <Text fontSize='sm' color="gray.500" lineHeight={1}>
          Scheduled for{' '}
          {scheduledFor
            ? getDateString(scheduledFor, 'short')
            : 'Error: missing scheduled time'}
        </Text>
      </VStack>
    )
  }
  return (
    <HStack>
      <InvoiceStatusIcon status={status} />
      <Text fontSize='sm'>Unknown Status</Text>
    </HStack>
  )
}
