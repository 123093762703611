import { ReactNode } from 'react'
import { Reaction } from '../types/reactions'

export const reactionIcons: Record<Reaction['type'], ReactNode> = {
  like: '👍',
  love: '❤️',
  laugh: '😂',
  confused: '😕',
  dislike: '👎',
  sad: '😢',
  thankful: '🙏',
  wow: '😲',
  angry: '😡',
  angel: '😇',
  problem: '😱',
  party: '🎉',
}
