import { adminRoles, getAppRole } from '@hb/shared'
import { BUG_REPORTS } from '@hb/shared/collections'
import { BugReport, CollectionFilter, CollectionSort } from '@hb/shared/types'
import {
  collection, orderBy, Query, query, where,
} from 'firebase/firestore'
import { useCallback, useMemo, useState } from 'react'
import { useHistory, useParams } from 'react-router'
import { db } from '../../../backend'
import { useApp } from '../../../contexts/AppContext'
import { usePracticeAccess } from '../../../contexts/PracticeAccess/PracticeAccessProvider'
import { useQuery } from '../../../hooks'
import { useAuth } from '../../../store'
import { notArchivedFilter, openFilter } from './constants'

export const useBugReports = () => {
  const [filters, setFilters] = useState<Array<CollectionFilter>>([
    openFilter,
    notArchivedFilter,
  ])
  const { appName } = useApp()
  const authUser = useAuth((s) => s.authUser)
  const claims = useAuth((s) => s.claims)
  const { selectedPracticeId } = usePracticeAccess()
  const appRole = useMemo(
    () => getAppRole(appName, authUser?.uid || null, claims, selectedPracticeId),
    [appName, claims, selectedPracticeId, authUser],
  )

  const [sort, setSort] = useState<CollectionSort>(['createdOn', 'asc'])
  const toggleFilter = useCallback(
    (filter: CollectionFilter) => {
      if (filters.includes(filter)) {
        setFilters(filters.filter((f) => f !== filter))
      } else {
        setFilters([...filters.filter((f) => filter[0] !== f[0] && (filter[1] !== '!=' && f[1] !== '!=')), filter])
      }
    },
    [filters],
  )

  const bugReportsQuery = useMemo(() => {
    if (!appRole) return null
    if (!adminRoles.includes(appRole)) {
      return null
    }
    let baseQuery = query(collection(db, BUG_REPORTS))
    filters.forEach((filter) => {
      baseQuery = query(baseQuery, where(...filter))
    })
    return query(baseQuery, orderBy(sort[0], sort[1])) as Query<BugReport>
  }, [filters, sort, appRole])

  const bugReports = useQuery<BugReport>(appRole && adminRoles.includes(appRole) ? bugReportsQuery : null)
  const { bugReportId: selectedBugReportId } = useParams<{bugReportId?: string}>()
  const history = useHistory()
  const selectBugReport = useCallback((id: string) => history.push(`/admin/${BUG_REPORTS}/${id}`), [history])
  const deselectBugReport = useCallback(() => history.push(`/admin/${BUG_REPORTS}`), [history])

  return useMemo(
    () => ({
      bugReportsQuery,
      filters,
      sort,
      bugReports,
      selectedBugReportId,
      selectBugReport,
      deselectBugReport,
      toggleFilter,
      setSort,
    }),
    [bugReportsQuery, filters, sort, toggleFilter, bugReports, selectedBugReportId, selectBugReport, deselectBugReport],
  )
}
