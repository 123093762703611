import { InputElement, InputProps, TextAreaField } from '@hb/shared'
import * as React from 'react'
import { forwardRef } from 'react'
import InputContainer from '../../Shared/InputContainer'
import { TextAreaElement } from './styles'

const TextArea: InputElement<TextAreaField> = forwardRef<
  HTMLTextAreaElement,
  InputProps<TextAreaField>
>(({ style, ...props }, ref) => {
  const { input, field, disabled } = props
  const { placeholder } = field
  return (
    <InputContainer
      {...props}
      style={{
        width: '100%',
        minWidth: '250px',
        boxShadow: 'inset 0.5px 0.5px 3px rgba(0, 0, 0, 0.3)',
        maxHeight: '350px',
        overflowY: 'auto',
        background: 'white',
        alignItems: 'flex-start',
        ...style,
      }}
    >
      <TextAreaElement
        ref={ref}
        disabled={disabled}

        style={{ overflow: 'hidden' }}
        minRows={2}
        placeholder={placeholder}
        {...input}
        onFocus={(e) => {
          // move cursor to end
          const { value } = e.target
          e.target.value = ''
          e.target.value = value
          input?.onFocus(e)
        }}
      />
    </InputContainer>
  )
})

export default TextArea
