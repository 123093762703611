import { FieldMapValue, ListField } from '@hb/shared'

export const getRowBackground = (index?: number) => {
  if (index === undefined) return 'transparent'
  return index % 2 === 0 ? 'rgb(249,249,249)' : 'white'
}
export const getListItemTitle = (
  field: ListField,
  value: FieldMapValue | undefined,
  index: number,
): string => {
  if (field.getTitle) return field.getTitle(value, index)
  const fallback = `${field.itemName} ${index + 1}`
  return field.displayedField
    ? value?.[field.displayedField] || fallback
    : fallback
}
