import { CustomRenderElementProps } from '@hb/shared'
import React from 'react'
import { ImageElement } from './ImageElement'
import { LinkElement } from './LinkElement'
import { DefaultElement } from './SpanTemplateElement'
import { VariableElement } from './VariableElement'

export const RenderNonFieldElement = (props: CustomRenderElementProps) => {
  switch (props.element.type) {
    case 'variable':
      return <VariableElement {...props} />
    case 'field':
    case 'image':
      return <ImageElement node={props.element} {...props} />
    case 'link':
      return <LinkElement {...props} />
    default:
      return <DefaultElement {...props} />
  }
}
