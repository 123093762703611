import {
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput as BaseNumberInput,
  NumberInputField,
  NumberInputStepper,
} from '@chakra-ui/react'
import { InputElement, InputProps, NumberField } from '@hb/shared'
import React, { forwardRef, useMemo } from 'react'

export const NumberInput: InputElement<NumberField> = forwardRef<
  HTMLInputElement,
  InputProps<NumberField>
>(({ input, field, meta }, ref) => {
  const {
    min, max, optional, defaultValue, step, precision, placeholder,
  } = field as NumberField

  const displayedValue = useMemo(() => {
    if (meta.active) {
      const value = precision
        ? parseFloat(input.value)
        : parseInt(input.value, 10)
      if (Number.isNaN(value)) return ''
      return input.value
    }

    return input.value
  }, [meta.active, input.value, precision])

  return (
    <BaseNumberInput
      {...input}
      onChange={(asString, value) => {
        const isNum = !Number.isNaN(value)
        if (!isNum) return input.onChange(undefined)
        if (precision) return input.onChange(asString)
        // remove leading zeros and trailing decimal point
        return input.onChange(value.toString().replace(/^0+|\.0+$/g, ''))
      }}
      value={displayedValue}
      onFocus={(e) => {
        input.onFocus(e)
        e.target.select()
      }}
      onBlur={input.onBlur}
      onMouseUp={(e) => {
        e.preventDefault()
        // e.currentTarget.focus()
      }}
      min={min}
      ref={ref}
      max={max}
      isRequired={!optional}
      defaultValue={defaultValue}
      step={step}
      precision={precision || 0}
      placeholder={placeholder}
    >
      <NumberInputField
        _focus={{
          boxShadow: 'none',
        }}
        fontSize="sm"
        placeholder={placeholder}
        borderColor="#cdcdcd"
      />
      {field.withStepper ? (
        <NumberInputStepper>
          <NumberIncrementStepper
            borderColor="#cdcdcd"
            opacity={0.5}
            _hover={{ opacity: 0.8 }}
          />
          <NumberDecrementStepper
            borderColor="#cdcdcd"
            opacity={0.5}
            _hover={{ opacity: 0.8 }}
          />
        </NumberInputStepper>
      ) : null}
    </BaseNumberInput>
  )
})
