import {
  defaultFormValidate, FieldMapValue, Form, FormStatus, isInfoStage,
} from '@hb/shared'

// export const getForm = (id: string, user?: User): Promise<Form | null> => {
//   return new Promise((resolve, reject) => {
//     db.collection('forms')
//       .doc(id)
//       .get()
//       .then((res) => {
//         resolve(formFromData(id, res.data() as DBForm))
//       })
//       .catch((err) => {
//         reject(err)
//       })
//   })
// }

export const getFormStatus = (
  form: Form | null,
  data?: FieldMapValue,
): FormStatus => {
  if (!form) return 'incomplete'
  const errors = defaultFormValidate(form, true)(data)
  if (errors) {
    const numErrors = Object.keys(errors).length
    const numRequired = Object.keys(form.stages).filter(
      (sName) => !isInfoStage(form.stages[sName]),
    ).length
    if (form && numErrors) {
      if (numErrors === numRequired) {
        return 'incomplete'
      }
      return 'in progress'
    }
  }
  return 'complete'
}
