import { DataColumns, Preview, WithId } from '@hb/shared'
import { createContext } from 'react'

// gg
export type GridContext<
  Snippet extends WithId,
  SortKey extends string,
  Populated extends WithId
> = {
  columns: DataColumns<Snippet, SortKey>
  width: number
  height: number
  itemHeight: number
  data: Record<string, WithId<Snippet>> | null
  collection: string
  display: (message: string) => void
  reverseName?: string
  clearMessage: () => void
  preview: Preview | null
  openPreview: (preview: Preview) => void
  closePreview: () => void
  item: WithId<Populated> | null
  scrollTop: number
}

export const DataGridContext = createContext<
  GridContext<
    WithId,
    //  AssessmentSnippet | Claim,
    string,
    WithId<any>
  >
  //  PopulatedAssessment | Claim>
>({
  columns: {},
  display: () => {},
  clearMessage: () => {},
  collection: '',
  preview: null,
  openPreview: () => {},
  closePreview: () => {},
  height: 0,
  width: 0,
  scrollTop: 0,
  reverseName: undefined,
  itemHeight: 0,
  data: null,
  item: null,
})
