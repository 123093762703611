import {
  Flex, Tooltip, TooltipProps,
} from '@chakra-ui/react'
import React, {
  PropsWithChildren, useCallback, useRef, useState,
} from 'react'

export const useCopied = (text: string) => {
  const [copied, setCopied] = useState(false)
  const copiedTimeout = useRef<any>(null)
  const onClick = useCallback(() => {
    setCopied(true)
    copiedTimeout.current = setTimeout(() => {
      setCopied(false)
    }, 2000)
    navigator.clipboard.writeText(text)
  }, [text])

  return { copied, onClick }
}

export const CopyTooltip = ({
  children, label, prefix, ...props
}: PropsWithChildren<TooltipProps & {
  label: string
  prefix?: string
}>) => {
  const { copied, onClick } = useCopied(label)
  return (
    <Tooltip
      whiteSpace='nowrap'
      placement='top'
      hasArrow
      closeOnClick={false}
      {...props}
      label={
        copied
          ? 'Copied!'
          : `${prefix ? `${prefix} ` : ''}${label} - click to copy`
      }
    >
      <Flex cursor='pointer' onClick={onClick}>{children}</Flex>
    </Tooltip>
  )
}
