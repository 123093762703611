import useObserver from '@react-hook/resize-observer'
import {
  RefObject, useEffect, useState,
} from 'react'

const getSize = (el: HTMLElement) => {
  const box = el.getBoundingClientRect()
  return { width: box.width, height: box.height }
}
const getSizeFromEntry = (entry: ResizeObserverEntry) => ({
  width: entry.contentRect.width,
  height: entry.contentRect.height,
})
export const useResizeObserver = (ref: RefObject<HTMLElement>) => {
  const [size, setSize] = useState<{ width: number; height: number }>({
    width: 0,
    height: 0,
  })

  useEffect(() => {
    if (ref.current) setSize(getSize(ref.current))
    setTimeout(() => {
      if (ref.current) setSize(getSize(ref.current))
    }, 0)
  }, [ref])

  // Where the magic happens
  useObserver(ref, (entry) => {
    setSize(getSizeFromEntry(entry))
  })
  return size
}
