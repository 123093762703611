import { VersionKey } from '@hb/shared'
import { FirebaseOptions } from 'firebase/app'

const productionConfig: FirebaseOptions = {
  apiKey: 'AIzaSyDf0uBAukL1Vnem9LPGMty2wbRYOknN5qs',
  authDomain: 'hamilton-billing.firebaseapp.com',
  projectId: 'hamilton-billing',
  storageBucket: 'hamilton-billing.appspot.com',
  messagingSenderId: '435590714434',
  appId: '1:435590714434:web:abd1be37bffcaaf206c9db',
  measurementId: 'G-WM98XXVDED',
}

const testConfig: FirebaseOptions = {
  apiKey: 'AIzaSyC2LsAZaTCljQ11nTtkVipx65UPGLRxKwM',
  authDomain: 'hamilton-billing-dev.firebaseapp.com',
  projectId: 'hamilton-billing-dev',
  storageBucket: 'hamilton-billing-dev.appspot.com',
  messagingSenderId: '439592730990',
  appId: '1:439592730990:web:063c7ebec07d91d59a817f',
}

type FirebaseConfig = typeof testConfig & { measurementId?: string }

type FirebaseFirebaseVersion = {
  firebaseConfig: FirebaseConfig;
  background: string,
  providersBackground: string
}

export const siteVersions: Record<VersionKey, FirebaseFirebaseVersion> = {
  dev: {
    firebaseConfig: testConfig,
    background:
      'linear-gradient(0deg, rgba(0,80,44,0.4) 39.5%, rgba(20,199,122,0.23) 100.2% )',
    providersBackground: 'linear-gradient(0deg, #00C77A 0%, #00C77A 100% )',
  },
  staging: {
    firebaseConfig: productionConfig,
    background:
      'linear-gradient(0deg, rgba(0,33,44,0.4) 39.5%, rgba(20,46,122,0.23) 100.2% )',
    providersBackground: 'linear-gradient(0deg, #00C77A 0%, #00C77A 100% )',
  },
  prod: {
    firebaseConfig: productionConfig,
    background: 'linear-gradient(180deg, #FEBCAD 39.5%, #ffe2db 100.2% )',
    providersBackground: 'linear-gradient(0deg, #00C77A 0%, #00C77A 100% )',
  },
}
