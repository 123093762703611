import {
  DropdownField,
  FieldTypes,
  IdField,
  InputElement,
  InputProps,
} from '@hb/shared'
import React, { forwardRef, useMemo } from 'react'
import { useCollection } from '../../../../collections/hooks/useCollection'
import { useAuth } from '../../../../store'
import { DropdownInput } from '../Select/DropdownInput'

const DatabaseDropdown: InputElement<IdField> = forwardRef<
  { focus:() => void },
  InputProps<IdField>
    >((props, ref) => {
      const { input, field, meta } = props
      const { collection, getItemText } = field

      const { items } = useCollection(collection)

      const isAdmin = useAuth((s) => s.admin)

      const sorted = useMemo(() => {
        const options = items
          ?.filter((i) => isAdmin || !i.isInactive)
          .map((i) => ({
            text: `${getItemText ? getItemText(i) : (i.name as string)}${i.isInactive ? ' (Inactive)' : ''}`,
            id: i.id,
          })) || []
        return options.sort((a, b) => (b.text < a.text ? 1 : -1))
      }, [items, getItemText, isAdmin])

      const dropdownField = useMemo<DropdownField>(
        () => ({
          ...field,
          type: FieldTypes.DROPDOWN,
          options: sorted,
          searchable: true,
        }),
        [field, sorted],
      )
      return <DropdownInput ref={ref} meta={meta} field={dropdownField} input={input} />
    })

export default DatabaseDropdown
