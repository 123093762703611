import { forwardRef, Text, TextProps } from '@chakra-ui/react'
import { formatDollarValue } from '@hb/shared'
import React from 'react'

export const FeeBadge = forwardRef<TextProps & {amount: number}, 'p'>(({
  amount,
  ...textProps
}, ref) => (
  <Text
    ref={ref}
    whiteSpace='nowrap'
    // borderRadius={4}
    // textAlign='center'
    width='80px'
    borderRadius={4}
    // color='white'
    fontFamily='Open Sans'
    color={amount > 0 ? 'green.600' : 'gray.500'}
    fontSize='sm'
    fontWeight={600}
    {...textProps}
  >
    {formatDollarValue(amount)}
  </Text>
))

// const IncrementBadge = ({ amount }: { amount: number }) => (
//   <Text
//     whiteSpace='nowrap'
//     borderRadius={4}
//     position='absolute'
//     textAlign='center'
//     width='80px'
//     bottom='80%'
//     border='1px solid #ababab'
//     bg={amount > 0 ? 'green.400' : 'gray.400'}
//     lineHeight={1}
//     fontSize='sm'
//     py='1px'
//     color='white'
//     fontWeight={600}
//   >
//     {amount < 0 ? '' : '+'}
//     {formatDollarValue(amount)}
//   </Text>
// )
