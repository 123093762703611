import { useEffect } from 'react'
import { useForm } from 'react-final-form'
import { xor } from '../../../utils'

export const SubmitOnEnter = ({ withShift }: {withShift?: boolean}) => {
  const { submit } = useForm()

  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      if (e.key === 'Enter' && xor(withShift, !e.shiftKey)) {
        e.preventDefault()
        submit()
      }
    }

    document.addEventListener('keydown', handleKeyDown)

    return () => {
      document.removeEventListener('keydown', handleKeyDown)
    }
  }, [submit, withShift])
  return null
}
