import { Center } from '@chakra-ui/react'
import { httpsCallable } from 'firebase/functions'
import React, { useCallback, useContext, useState } from 'react'
import { functions } from '../../../../backend/functions'
import { UserContext } from '../../../../contexts'
import { ActionButton } from '../../../Buttons'

const recoverFiles = httpsCallable(functions, 'recoverAssessmentFiles')
export const RecoverAssessmentFiles = () => {
  const { assessmentId } = useContext(UserContext)
  const [recovering, setRecovering] = useState(false)

  const recover = useCallback(async () => {
    if (!assessmentId) return
    setRecovering(true)
    try {
      await recoverFiles({ assessmentId })
    } catch (e) {
      console.error(e)
    }
    setRecovering(false)
  }, [assessmentId])
  return (
    <Center w='100%' py={1}>
      <ActionButton
        size='sm'
        bg='white'
        onClick={recover}
        isLoading={recovering}
        loadingText='Recovering...'
      >
        Recover Assessment Files
      </ActionButton>
    </Center>
  )
}
