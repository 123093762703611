import { CollectionFilter, DataListTab } from '@hb/shared/types'
import { FieldPath } from 'firebase/firestore'
import { createContext } from 'react'

export const DataListContext = createContext<{
  sortKey: string | FieldPath
  sortBy:(k: string | FieldPath, direction?: 'asc' | 'desc') => void
  toggleSortDirection: () => void
  filterBy: (filter: CollectionFilter) => void
  removeFilter: (filterKey: string | FieldPath) => void
  gridView: boolean
  filters: Array<CollectionFilter>
  userFilters: Array<CollectionFilter>
  tab: DataListTab<any, string> | null
  sortDesc: boolean
    }>({
      sortKey: 'urgentSort',
      filterBy: () => {},
      sortBy: () => {},
      removeFilter: () => {},
      toggleSortDirection: () => {},
      gridView: false,
      filters: [],
      userFilters: [],
      tab: null,
      sortDesc: true,
    })
