import { InfoIcon } from '@chakra-ui/icons'
import {
  Assessment, colors, FieldMap, InfoStage, UserFieldKey,
} from '@hb/shared'
import {
  Container, StatusButton, StatusButtonBase, useStage,
} from '@hb/shared-frontend'
import React from 'react'

export const StagePreview = ({
  id, formId, stage, assessment, index, onEditClick,
}: {
  id: string
  formId: UserFieldKey
  stage: FieldMap | InfoStage
  index: number
  assessment: Assessment
  onEditClick: () => void
}) => {
  const { status } = useStage(formId, id, assessment.data)

  return (
    <Container
      style={{
        height: 'auto',
        background: index % 2 === 0 ? 'rgba(0,0,0,0.02)' : undefined,
        flexFlow: 'row',
        justifyContent: 'flex-start',
        padding: '0.25rem 0.8rem',
      }}
    >
      {status !== 'info' ? (
        <StatusButtonBase
          style={{
            display: 'inline-block',
            textAlign: 'center',
            alignItems: 'center',
            width: 16,
            height: 16,
            padding: 0,
            pointerEvents: 'none',
          }}
          color={status === 'complete' ? colors.green.hex : '#cdcdcd'}
        >
          {status === 'complete' ? '✓' : ''}
        </StatusButtonBase>
      ) : (
        <InfoIcon color='gray.600' />
      )}
      <span
        style={{
          flexGrow: 1,
          fontSize: '16px',
          textAlign: 'left',
          marginLeft: '0.6rem',
          color: '#555',
        }}
      >
        {stage ? stage.name : null}
      </span>
      <StatusButton
        ml='auto'
        float='right'
        type='stage'
        onClick={onEditClick}
        status={status}
      />

      {/* {renderModal ? (
        isInfoStage(stage) ? (
          <TextViewModal
            isOpen={isEditing}
            onClose={onEditClose}
            value={stage.data}
            renderFooter={() => (
              <Flex>
                <ActionButton onClick={() => onSubmit()}>Next</ActionButton>
              </Flex>
            )}
          />
        ) : (
          <GenericEditModal
            isOpen={isEditing}
            renderFooter={
              assessment.submittedOn
                ? () => (
                    <Flex bg='blackAlpha.100' py={3} px={6}>
                      <Text>
                        You've already submitted this assessment,{' '}
                        <Link to='/contact'>
                          <InlineLink>get in touch</InlineLink>{' '}
                        </Link>
                        with us if you need to edit any information!
                      </Text>
                    </Flex>
                  )
                : undefined
            }
            onClose={onEditClose}
            onSubmit={onSubmit}
            field={stage}
            data={value}
            submitText={isLast ? 'Finish' : 'Next'}
          />
        )
      ) : null} */}
    </Container>
  )
}
