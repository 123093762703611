import { Box, BoxProps } from '@chakra-ui/react'
import { colors } from '@hb/shared/constants'
import React, { FC } from 'react'
import { ExpandOnMount } from '../ExpandOnMount'

// progress from 0 to 100
export const AnimatedLoadingBar: FC<
  { progress: number; bg?: string; color?: string } & BoxProps
> = ({
  progress, bg, color, ...boxProps
}) => (
  <ExpandOnMount>
    <Box w="100%" p="0.15rem 0.24rem" {...boxProps}>
      <Box
        bg={bg || 'gray.300'}
        h="16px"
        borderRadius="full"
        w="100%"
        position="relative"
        overflow="hidden"
      >
        <Box
          bg={color || colors.green.hex}
          h="100%"
          transition="width 0.3s"
          w={`${progress}%`}
        />
      </Box>
    </Box>
  </ExpandOnMount>
)
