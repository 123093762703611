import {
  Collection, Template, TemplateKey,
} from '@hb/shared'
import React, { useContext } from 'react'
import { useCollection } from '../../collections/hooks/useCollection'
import { ScreenContext } from '../../contexts/ScreenContext'
import { TemplateContext } from '../../contexts/TemplateContext'
import EditCollection from '../EditCollection/EditCollection'
import { TemplatePreview } from './TemplatePreview'

export const TemplatesSection: React.FC<{
  collection: Collection<Template>,
  templateType: TemplateKey,
}> = ({ collection, templateType }) => {
  const { name } = collection
  const collectionData = useCollection(collection)
  const { contentWidth } = useContext(ScreenContext)

  const { select } = useContext(TemplateContext)
  return collectionData ? (
    <EditCollection<Template>
      data={collectionData}
      width={contentWidth}
      collection={collection}
      onOpenItem={(id) => {
        if (select) select({ collection, templateId: id, templateType })
      }}
      ItemComponent={(props) => TemplatePreview({ ...props, collection, templateType })}
      alwaysExpanded={true}
      name={name}
      itemPlaceholder='Template Name'
      itemName='Template'
    />
  ) : null
}
