import { FieldMapValue, UpdateCallback } from '@hb/shared'
import { createContext } from 'react'

// test
export const DataViewContext = createContext<{
  updateField?:(path: string, value: any) => Promise<UpdateCallback>
  rootValue?: FieldMapValue
  inGrid?: boolean
  width?: number,
  childrenExpanded?: boolean
  hideRedFlags?: boolean
    }>({})
