import { WarningIcon } from '@chakra-ui/icons'
import {
  Box,
  Button,
  Center,
  CircularProgress,
  Divider,
  Flex,
  HStack,
  Text,
  VStack,
} from '@chakra-ui/react'
import {
  capitalizeFirstLetter,
  CLAIMS_ABILITY_DETAIL,
  colors,
  defaultStageValidate,
  DropdownField,
  FieldMap,
  FieldMapValue,
  FieldTypes,
  getDateString,
  getDefaultPatientRelateCode,
  medicaidProvidersCollection,
  policyOwnerRelationshipField,
  providersCollection,
} from '@hb/shared'

import { FORM_ERROR, ValidationErrors } from 'final-form'
import arrayMutators from 'final-form-arrays'
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { Form } from 'react-final-form'
import { PopUpMessageContext } from '../../../../../../contexts/PopUpMessage/PopUpMessageContext'
import { ScreenContext } from '../../../../../../contexts/ScreenContext'
import { ThemeContext } from '../../../../../../contexts/ThemeContext'
import { UserContext } from '../../../../../../contexts/UserContext'
import { submitNewClaim } from '../../../../../../hooks/backend/claims'
import { useCollectionItem } from '../../../../../../hooks/backend/useCollectionItem'
import { useDocument } from '../../../../../../hooks/backend/useDocument'
import { formatValue } from '../../../../../../utils/data'
import { Condition } from '../../../../../forms'
import { FormElement } from '../../../../../forms/Input'
import { DefaultModal } from '../../../../../Modals/DefaultModal'
import { Subheader2 } from '../../../../Text'

import { abilityDataToFormData, formDataToAbilityData } from '../../utils'
import {
  abilityClaimField,
  customRelateCodePrimary,
  customRelateCodeSecondary,
  diagnosisCodesField,
  servicesValidationField,
  shouldUseCustomRelateCodeField,
  shouldUseCustomRenderingProviderField,
} from '../fields'
import { AffiliatedHospitals } from './AffiliatedHospitals'
import { DeliveryDateView } from './DeliveryDateView'
import { DiagnosisCodesInput } from './DiagnosisCodes'
import { SubmitClaimPrimaryCoverage } from './PrimaryCoverage'
import { ReferringProvider } from './ReferringProvider'
import { RenderingProvider } from './RenderingProvider'
import { SaveDraftModal } from './SaveDraftModal'
import { SubmitClaimSecondaryCoverage } from './SecondaryCoverage'
import { SelectDraftMenu } from './SelectDraftMenu'
import { Services } from './Services'

const validationField: FieldMap = {
  name: 'New ability claim',
  children: {
    ...abilityClaimField.children,
    DiagnosisCodes: diagnosisCodesField,
    Services: servicesValidationField,
    shouldUseCustomRelateCodePrimary: shouldUseCustomRelateCodeField,
    customRelateCodePrimary,
    shouldUseCustomRelateCodeSecondary: shouldUseCustomRelateCodeField,
    customRelateCodeSecondary,
    shouldUseCustomRenderingProvider: shouldUseCustomRenderingProviderField,
  },
}

const AddAbilityClaimProvidersSection = () => {
  const { selectedAssessment } = useContext(UserContext)
  const { midwife } = selectedAssessment || {}
  return (
    <VStack
      borderRadius={4}
      border="1px solid #cdcdcd"
      spacing={0}
      bg="white"
      boxShadow="md"
      align="flex-start"
      w="100%"
      px={3}
      py={2}
    >
      <Text fontWeight={600} color="gray.500" fontSize="md">
        Provider
      </Text>
      {midwife ? (
        <>
          <ReferringProvider />
          <Divider pt={2} />
          <RenderingProvider />
          <Divider pt={2} />
          <AffiliatedHospitals />
        </>
      ) : (
        <Text color="red.500" fontSize="md">
          No provider listed for this assessment
        </Text>
      )}
    </VStack>
  )
}

const newbornClaimCondition = (data: FieldMapValue) => data?.serviceType === 'newborn'

const NewbornClaimInfo = () => {
  const { selectedAssessment } = useContext(UserContext)
  const { deliveredOn, delivery } = selectedAssessment || {}
  const { newbornFname, newbornLname, newbornSex } = delivery || {}
  return (
    <Condition basePath="" condition={newbornClaimCondition}>
      <Box w="100%" px={4}>
        <Flex
          py={1}
          px={3}
          borderRadius={6}
          border="1px solid #cdcdcd"
          bg="gray.50"
          flexFlow="column"
          w="100%"
        >
          {!deliveredOn || !newbornSex ? (
            <HStack
              bg="white"
              my={1}
              px={2}
              py={1}
              border="1px solid #880000"
              borderRadius={4}
            >
              <WarningIcon color="red.600" />
              <Flex flexFlow="column">
                <Subheader2 color="red.600" fontSize="sm">
                  Newborn claim requires delivery date and newborn sex
                </Subheader2>
                <Subheader2 color="red.600" fontSize="sm">
                  (Can be added on the delivery section of the profile header)
                </Subheader2>
              </Flex>
            </HStack>
          ) : null}
          <Subheader2 color="gray.600" fontSize="md">
            Newborn Claim - Using newborn information (from delivery)
          </Subheader2>
          <Flex gap={1} align="center">
            <Subheader2 w="80px" fontWeight={600}>
              First name
            </Subheader2>
            <Text borderLeft="1px solid #cdcdcd" pl={2} color="gray.600">
              {newbornFname || 'None - using default value "Baby"'}
            </Text>
          </Flex>
          <Flex gap={1} align="center">
            <Subheader2 w="80px" fontWeight={600}>
              Last name
            </Subheader2>
            <Text borderLeft="1px solid #cdcdcd" pl={2} color="gray.600">
              {newbornLname || 'None - using default value "Girl/Boy"'}
            </Text>
          </Flex>
          <Flex gap={1} align="center">
            <Subheader2 w="80px" fontWeight={600}>
              DOB
            </Subheader2>
            <Text
              borderLeft="1px solid #cdcdcd"
              pl={2}
              color={deliveredOn ? 'gray.600' : 'red.600'}
            >
              {deliveredOn ? getDateString(deliveredOn) : 'None'}
            </Text>
          </Flex>
          <Flex gap={1} align="center">
            <Subheader2 w="80px" fontWeight={600}>
              Sex
            </Subheader2>
            <Text
              borderLeft="1px solid #cdcdcd"
              pl={2}
              color={newbornSex ? 'gray.600' : 'red.600'}
            >
              {newbornSex?.toUpperCase() || 'None'}
            </Text>
          </Flex>
        </Flex>
      </Box>
    </Condition>
  )
}

export const AbilityClaimSubmission = (props: {
  initialValue?: FieldMapValue
  existingAbilityId?: string | null
  onClose?: () => void
}) => {
  const { assessmentId, selectedAssessment } = useContext(UserContext)
  const {
    insuranceProvider, mergedSignOnData, claimsDrafts, authorizations,
  } = selectedAssessment || {}
  const { initialValue, existingAbilityId, onClose } = props || {}
  const { showError } = useContext(PopUpMessageContext)
  const [selectedDraftId, setSelectedDraftId] = useState<string | null>(null)
  const [savingAsDraft, setSavingAsDraft] = useState<{
    id: string
    data: FieldMapValue
  } | null>(null)
  const onSaveDraftComplete = useCallback((id: string) => {
    setSavingAsDraft(null)
    setSelectedDraftId(id)
  }, [])

  const onSaveDraftClick = useCallback(
    async (data: FieldMapValue) => {
      if (!selectedAssessment?.adminRef) {
        showError('No assessment selected')
        return { [FORM_ERROR]: 'No assessment selected' }
      }
      const id = selectedDraftId || `${Date.now()}`
      setSavingAsDraft({ id, data })
      return undefined
    },
    [selectedAssessment, selectedDraftId, showError],
  )

  const onSubmit = useCallback(
    async (data: FieldMapValue): Promise<ValidationErrors> => {
      if (!assessmentId) {
        showError('No assessment selected')
        return { error: 'No assessment selected' }
      }
      const submitted: FieldMapValue = formDataToAbilityData(
        data,
        assessmentId,
        selectedDraftId,
      )
      if (existingAbilityId) submitted.claimId = existingAbilityId
      return submitNewClaim(submitted)
        .then(() => {
          if (onClose) onClose()
          return { success: 'test claim submitted' }
        })
        .catch((err: any) => {
          console.error(err)
          const msg = err.message
          showError(msg)
          return { [FORM_ERROR]: msg }
        })
    },
    [assessmentId, showError, onClose, selectedDraftId, existingAbilityId],
  )
  const {
    defaultRelateCode,
    policyOwner,
    hasSecondaryCoverage,
    defaultSecondaryRelateCode,
    secondaryPolicyOwner,
    secondaryInsurerId,
    secondaryInsurerIsMedicaid,
  } = useMemo(() => {
    const {
      secondaryCoverageDetails,
      policyOwnerRelationship,
      hasSecondaryCoverage: secondaryCoverageExists,
    } = mergedSignOnData || {}
    const secondaryIsMedicaid = mergedSignOnData?.secondaryCoverageDetails?.isMedicaid
    const secondaryInsurer = secondaryIsMedicaid
      ? mergedSignOnData?.secondaryCoverageDetails?.medicaidInsurerId
      : mergedSignOnData?.secondaryCoverageDetails?.insurerId
    const secondaryPolicyOwnerRelationship = secondaryCoverageDetails?.policyOwnerRelationship
    const secondaryRelateCode = secondaryPolicyOwnerRelationship
      ? getDefaultPatientRelateCode(secondaryPolicyOwnerRelationship)
      : 'None'
    if (!policyOwnerRelationship) {
      return {
        defaultRelateCode: 'None',
        secondaryInsurerId: secondaryInsurer,
        defaultSecondaryRelateCode: secondaryRelateCode,
        policyOwner: 'Missing policy owner relationship (sign on data)',
        hasSecondaryCoverage: secondaryCoverageExists,
        secondaryInsurerIsMedicaid: secondaryIsMedicaid,
        secondaryPolicyOwner:
          'Missing secondary coverage policy owner relationship (sign on data)',
      }
    }
    return {
      defaultRelateCode: getDefaultPatientRelateCode(policyOwnerRelationship),
      defaultSecondaryRelateCode: secondaryRelateCode,
      policyOwner: formatValue({
        field: policyOwnerRelationshipField,
        value: policyOwnerRelationship,
      }),
      hasSecondaryCoverage: secondaryCoverageExists,
      secondaryPolicyOwner: formatValue({
        field: policyOwnerRelationshipField,
        value: secondaryPolicyOwnerRelationship,
      }),
      secondaryInsurerIsMedicaid: secondaryIsMedicaid,
      secondaryInsurerId: secondaryInsurer,
    }
  }, [mergedSignOnData])

  const { item: secondaryInsurer } = useCollectionItem(
    secondaryInsurerIsMedicaid
      ? medicaidProvidersCollection
      : providersCollection,
    secondaryInsurerId,
  )

  const [initValue, setInitValue] = useState<FieldMapValue>(
    selectedDraftId
      ? claimsDrafts?.[selectedDraftId]?.data || {}
      : initialValue || {},
  )
  const [reinitializing, setReinitializing] = useState(false)

  useEffect(() => {
    if (selectedDraftId) {
      if (claimsDrafts?.[selectedDraftId]?.data) {
        setReinitializing(true)
        setTimeout(() => {
          const init = claimsDrafts?.[selectedDraftId]?.data || {}
          const { Services: initServices } = init || {}
          setInitValue({
            ...init,
            Services: initServices?.map((s) => ({
              ...s,
              DiagnosisPointer:
                typeof s.DiagnosisPointer === 'string'
                  ? [s.DiagnosisPointer]
                  : s.DiagnosisPointer,
            })),
          })
          setReinitializing(false)
        }, 200)
      }
    } else {
      setReinitializing(true)
      setTimeout(() => {
        setInitValue(initialValue || {})
        setReinitializing(false)
      }, 200)
    }
  }, [selectedDraftId, claimsDrafts, initialValue])

  const authorizationsField = useMemo<DropdownField>(
    () => ({
      placeholder: 'Authorization',
      type: FieldTypes.DROPDOWN,
      optional: true,
      options:
        Object.values(authorizations || {}).map((a) => ({
          text: `${a.referenceNumber} - ${capitalizeFirstLetter(
            a.serviceType,
          )}`,
          id: a.referenceNumber,
        })) || [],
    }),
    [authorizations],
  )

  const sufficientInfoCondition = useCallback(
    (data: FieldMapValue) => {
      if (data.serviceType !== 'newborn') return true
      const { deliveredOn, delivery } = selectedAssessment || {}
      const { newbornSex } = delivery || {}
      return !!deliveredOn && !!newbornSex
    },
    [selectedAssessment],
  )

  return (
    <>
      <ThemeContext.Provider value={{ theme: 'detailed' }}>
        <VStack w="100%" spacing={2} align="flex-start" px={4} pt={2} pb="60px">
          {reinitializing ? (
            <Center w="100%" minH="500px">
              <CircularProgress color="green.400" isIndeterminate />
            </Center>
          ) : (
            <Form
              onSubmit={onSubmit}
              initialValues={initValue}
              keepDirtyOnReinitialize={true}
              validate={defaultStageValidate(validationField)}
              mutators={{ ...(arrayMutators as any) }}
            >
              {({ handleSubmit, submitting, hasValidationErrors }) => (
                <>
                  <HStack
                    justify="space-between"
                    spacing={3}
                    px={2}
                    align="center"
                    w="100%"
                  >
                    <Text flex={1} color="gray.500" fontSize="lg">
                      {existingAbilityId
                        ? 'RE-SUBMIT CLAIM '
                        : 'SUBMIT NEW CLAIM'}
                    </Text>
                    <HStack flex={1} justify="center" position="relative">
                      <SelectDraftMenu
                        onSaveDraftClick={onSaveDraftClick}
                        selectedDraftId={selectedDraftId}
                        onSelect={setSelectedDraftId}
                      />
                    </HStack>
                    <HStack flex={1} justify="flex-end" align="center">
                      {onClose ? (
                        <Button
                          size="sm"
                          boxShadow="md"
                          onClick={onClose}
                          bg="white"
                          color={colors.green.hex}
                        >
                          CANCEL
                        </Button>
                      ) : null}
                      <Button
                        size="sm"
                        boxShadow="md"
                        isLoading={submitting}
                        onClick={handleSubmit}
                        bg={hasValidationErrors ? 'gray.300' : colors.green.hex}
                        color="white"
                      >
                        {existingAbilityId ? 'RE-SUBMIT' : 'SUBMIT'}
                      </Button>
                    </HStack>
                  </HStack>
                  <VStack w="100%">
                    <VStack maxW="800px" w="100%">
                      <VStack
                        borderRadius={4}
                        border="1px solid #cdcdcd"
                        bg="white"
                        boxShadow="md"
                        spacing={0}
                        width="100%"
                        align="flex-start"
                        flex={1}
                        px={2}
                        pb={3}
                        // py={1}
                      >
                        <FormElement
                          root
                          active
                          name=""
                          field={abilityClaimField}
                        />
                        <DiagnosisCodesInput />
                        <DeliveryDateView />
                        <Services />
                        <NewbornClaimInfo />
                      </VStack>
                      <Condition
                        basePath=""
                        condition={sufficientInfoCondition}
                      >
                        <AddAbilityClaimProvidersSection />
                        <SubmitClaimPrimaryCoverage
                          authorizationsField={authorizationsField}
                          insuranceProvider={insuranceProvider}
                          defaultRelateCode={defaultRelateCode}
                          policyOwner={policyOwner}
                        />
                        <SubmitClaimSecondaryCoverage
                          authorizationsField={authorizationsField}
                          secondaryInsurer={secondaryInsurer}
                          defaultSecondaryRelateCode={
                            defaultSecondaryRelateCode
                          }
                          secondaryPolicyOwner={secondaryPolicyOwner}
                          hasSecondaryCoverage={hasSecondaryCoverage}
                        />
                      </Condition>
                    </VStack>
                  </VStack>
                </>
              )}
            </Form>
          )}
        </VStack>
      </ThemeContext.Provider>

      <SaveDraftModal
        savingAsDraft={savingAsDraft}
        onClose={() => setSavingAsDraft(null)}
        onComplete={onSaveDraftComplete}
      />
    </>
  )
}

export const ResubmitAbilityClaim = (props: {
  existingAbilityId: string
  serviceType: string | null | undefined
  onClose?: () => void
}) => {
  const { existingAbilityId, serviceType, onClose } = props
  const { data: existingData, loading } = useDocument<FieldMapValue>(
    CLAIMS_ABILITY_DETAIL,
    existingAbilityId,
  )

  const initData = useMemo(
    () => ({
      serviceType: serviceType || '',
      ...abilityDataToFormData(existingData || {}),
    }),
    [existingData, serviceType],
  )
  if (loading) {
    return (
      <HStack p={3}>
        <CircularProgress size={5} color="gray" isIndeterminate />
        <Text>Loading submitted claim...</Text>
      </HStack>
    )
  }

  if (!existingData) {
    return (
      <HStack p={2}>
        <Text color="red.600">ERROR: Submitted claim not found</Text>
      </HStack>
    )
  }

  return (
    <AbilityClaimSubmission
      onClose={onClose}
      existingAbilityId={existingAbilityId}
      initialValue={initData}
    />
  )
}

export const AbilityClaimSubmissionModal = (props: {
  isOpen: boolean
  onClose: () => void
  existingAbilityId?: string
}) => {
  const screen = useContext(ScreenContext)
  return (
    <DefaultModal
      closeOnOverlayClick={false}
      closeOnEsc={false}
      overlayHeader
      {...props} // eA8geZtpl6eIR43DYrE1
      size="6xl"
      scrollBehavior="inside"
      bodyProps={{ maxH: screen.height, pt: 0 }}
      contentProps={{ pt: 0 }}
      render={() => (props.isOpen ? (
          <AbilityClaimSubmission {...props} />
      ) : (
          <HStack p={3}>
            <CircularProgress size={5} color="gray" isIndeterminate />
            <Text>Loading...</Text>
          </HStack>
      ))
      }
    />
  )
}
