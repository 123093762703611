import {
  Box, HStack, Text, VStack,
} from '@chakra-ui/react'
import {
  DateTimeField,
  getDateTimeString,
  InputElement as IInputElement,
  InputProps,
} from '@hb/shared'
import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useState,
} from 'react'
import { InputRef } from '../types'
import { DatePicker } from './DatePicker'
import { TimePicker } from './TimePicker'
import { deconstructTimeString, timeStringFromDate } from './utils'

export const DateTimeInput: IInputElement<DateTimeField> = forwardRef<
  InputRef,
  InputProps<DateTimeField>
>((props, ref) => {
  const {
    input,
    field,
    style,
    meta: { active },
  } = props
  const {
    onChange, value, name, onFocus, onBlur,
  } = input
  const {
    // maxDate,
    defaultToNow,
    isoFormat,
  } = field
  const [date, setDate] = useState(value ? new Date(value) : null)
  useImperativeHandle(ref, () => ({
    focus: onFocus,
    blur: onBlur,
  }))

  useEffect(() => {
    if (!value && defaultToNow) {
      const d = new Date()
      setDate(d)
      onChange(isoFormat ? d.toISOString() : d.getTime())
    }
  }, [value, defaultToNow, onChange, isoFormat])

  const handleTimeChange = useCallback(
    (time: string | undefined) => {
      const {
        amPm = 'am',
        hours = 0,
        minutes = 0,
      } = deconstructTimeString(time)
      const d = date || new Date()
      d.setHours(hours + (amPm === 'pm' ? 12 : 0))
      d.setMinutes(minutes)
      setDate(d)
      onChange(isoFormat ? d.toISOString() : d.getTime())
    },
    [onChange, isoFormat, date],
  )

  const handleDateChange = useCallback(
    (updated: number | undefined) => {
      if (updated === undefined) {
        onChange(undefined)
        return
      }
      const d = date || new Date()
      const hours = d.getHours()
      const minutes = d.getMinutes()
      d.setTime(updated)
      d.setHours(hours)
      d.setMinutes(minutes)
      setDate(d)
      onChange(isoFormat ? d.toISOString() : d.getTime())
    },
    [onChange, isoFormat, date],
  )

  const [dateOpen, setDateOpen] = useState(false)

  const timeString = useMemo(
    () => (value ? timeStringFromDate(value) : undefined),
    [value],
  )
  const [timezone] = useState(
    new Date()
      .toLocaleTimeString('en-us', { timeZoneName: 'short' })
      .split(' ')[2],
  )
  return (
    <VStack spacing={0} align="flex-start" style={style}>
      <HStack minW="370px">
        <Box w="150px">
          <DatePicker
            onFocus={() => {
              onFocus()
              setDateOpen(true)
            }}
            onBlur={() => {
              onBlur()
              setDateOpen(false)
            }}
            name={name}
            utc
            date={date}
            isOpen={!!active && dateOpen}
            field={field}
            value={value}
            onChange={(v) => handleDateChange(v as number | undefined)}
          />
        </Box>
        <TimePicker value={timeString} onChange={handleTimeChange} />
      </HStack>
      <Text fontSize="sm" color="gray.500">
        {date
          ? `${getDateTimeString(date)} ${timezone}`
          : `Using timezone ${timezone}`}
      </Text>
    </VStack>
  )
})
