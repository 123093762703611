import {
  Button,
  ButtonProps,
  forwardRef,
  HStack,
  Image,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Text,
} from '@chakra-ui/react'
import { colors, Fax, WithId } from '@hb/shared'
import React, { useContext, useState } from 'react'
import { FaxesViewContext } from './context'

const PatientNameCell = forwardRef<ButtonProps, 'button'>((props, ref) => (
  <Button
    ref={ref}
    size="xs"
    color="white"
    w="100%"
    fontWeight={500}
    textAlign="left"
    isTruncated
    bg={colors.green.hex}
    {...props}
  />
))

export const AssignToPatient = ({ fax }: { fax: WithId<Fax> }) => {
  const {
    type, assessmentId, assessmentName, patientFirst, patientLast, id,
  } = fax || {}

  const [unassigning, setUnassigning] = useState(false)
  const {
    faxAssignment: { assignToFax, unassignAssessmentFromFax },
    viewAssessment,
  } = useContext(FaxesViewContext)
  if (type === 'sent') {
    return (
      <PatientNameCell
        onClick={() => {
          if (assessmentId) viewAssessment(assessmentId)
        }}
        bg={assessmentId ? colors.green.hex : 'white'}
        color={assessmentId ? 'white' : colors.green.hex}
        border={assessmentId ? 'none' : `1px solid ${colors.green.hex}`}
      >
        {assessmentId
          ? `${patientLast || '[Last name]'}, ${patientFirst || '[First name]'}`
          : 'None'}
      </PatientNameCell>
    )
  }
  return assessmentId ? (
    <Popover strategy="fixed" isLazy>
      <PopoverTrigger>
        <PatientNameCell>
          {assessmentId ? `${patientLast}, ${patientFirst}` : 'None'}
        </PatientNameCell>
      </PopoverTrigger>
      <PopoverContent minW="260px">
        <PopoverArrow />
        <PopoverBody minW="260px">
          <HStack p={1} spacing={1}>
            <Image opacity={0.7} src="/images/assessment.svg" width="16px" />
            <Text color="gray.600" fontFamily="Hero-New" lineHeight={1}>
              {assessmentName}
            </Text>
          </HStack>
          <HStack spacing={1} w="100%">
            <Button
              size="xs"
              color="white"
              flex={1}
              fontWeight={500}
              textAlign="left"
              bg={colors.green.hex}
              onClick={() => {
                if (assessmentId) viewAssessment(assessmentId)
              }}
            >
              VIEW ASSESSMENT
            </Button>
            <Button
              size="xs"
              color={colors.green.hex}
              fontWeight={500}
              px={3}
              textAlign="left"
              isTruncated
              bg="white"
              border={`1px solid ${colors.green.hex}`}
              onClick={() => assignToFax(id)}
            >
              Reassign
            </Button>
            <Button
              size="xs"
              color={colors.green.hex}
              fontWeight={500}
              px={3}
              textAlign="left"
              isLoading={unassigning}
              isTruncated
              bg="white"
              border={`1px solid ${colors.green.hex}`}
              onClick={async () => {
                setUnassigning(true)
                await unassignAssessmentFromFax(id)
                setUnassigning(false)
              }}
            >
              Unassign
            </Button>
          </HStack>
        </PopoverBody>
      </PopoverContent>
    </Popover>
  ) : (
    <Button
      w="100%"
      onClick={() => assignToFax(id || null)}
      size="xs"
      bg="white"
      fontWeight={500}
      border={`1px solid ${colors.green.hex}`}
      color={colors.green.hex}
    >
      Assign to Patient
    </Button>
  )
}
