import {
  Flex, Stack,
  StackDivider,
} from '@chakra-ui/react'
import {
  ASSESSMENTS, FieldMap, primaryCoverageField,
} from '@hb/shared'

import { set } from 'nested-property'
import React, {
  useCallback, useContext, useMemo, useState,
} from 'react'
import { fixAssessmentFiles } from '../../backend'
import { PopUpMessageContext, ThemeContext } from '../../contexts'
import { UserContext } from '../../contexts/UserContext'
import { useUpdateDoc } from '../../hooks/backend/useUpdateDoc'
import { ActionButton } from '../Buttons'

import { DataView } from '../DataView'
import { BoxHeader } from '../Text'

export const InsuranceCardView = ({
  insuranceCard,
  updateField,
  baseStoragePath,
  field,
}: {
  insuranceCard: any
  updateField: (path: string, data: any) => Promise<any>
  baseStoragePath: string
  field: FieldMap
}) => (
  <DataView
    initExpanded={!!insuranceCard}
    updateField={updateField}
    baseStoragePath={baseStoragePath}
    field={field}
    data={insuranceCard}
  />
)

export const InsuranceCardsView = () => {
  const { selectedAssessment, assessmentId } = useContext(UserContext)

  const { mergedData, ref } = selectedAssessment || {}
  const { primaryCoverage, medicaidCoverage } = mergedData?.['insurance-info'] || {}
  const update = useUpdateDoc()
  const handleInsuranceCardUpload = useCallback(
    async (key: string, data: any) => {
      if (ref) {
        const m = {}
        set(m, key, data)
        return update(ref, `corrections.${key}`, data)
      }
      return { error: 'Unknown error' }
    },
    [update, ref],
  )

  const { showMessage } = useContext(PopUpMessageContext)

  const [isFixing, setIsFixing] = useState(false)
  const onFixFiles = useCallback(async () => {
    if (!assessmentId) return
    setIsFixing(true)
    try {
      const { data: { message } } = await fixAssessmentFiles({ assessmentId })
      showMessage({ text: 'Files fixed', subText: message, type: 'success' })
    } catch (err: any) {
      showMessage({ text: 'Error fixing files', subText: err.message, type: 'error' })
    }
    setIsFixing(false)
  }, [assessmentId, showMessage])

  const primaryCoverageInsuranceCardField = useMemo<FieldMap>(
    () => ({
      ...(primaryCoverageField.children.insuranceCard as FieldMap),
      name: 'Primary Coverage',
      initExpanded: !!primaryCoverage?.insuranceCard,
    }),
    [primaryCoverage],
  )

  const medicaidCoverageInsuranceCardField = useMemo<FieldMap>(
    () => ({
      ...(primaryCoverageField.children.insuranceCard as FieldMap),
      name: 'Medicaid Coverage',
      initExpanded: !!medicaidCoverage?.insuranceCard,
    }),
    [medicaidCoverage],
  )

  return (
    <ThemeContext.Provider value={{ placeholderAbove: false }}>
      <Stack
        w="100%"
        boxShadow="md"
        bg="white"
        px={3}
        py={2}
        spacing={0}
        borderRadius={6}
        divider={<StackDivider />}
      >
        <Flex align='center' w="100%">
          <BoxHeader>Insurance Cards</BoxHeader>
          <ActionButton onClick={onFixFiles} isLoading={isFixing} ml='auto' size='xs'>
            Fix Files
          </ActionButton>
        </Flex>
        <InsuranceCardView
          insuranceCard={primaryCoverage?.insuranceCard}
          updateField={(path, data) => handleInsuranceCardUpload(
            `insurance-info.primaryCoverage.insuranceCard.${path}`,
            data,
          )
          }
          baseStoragePath={`${ASSESSMENTS}/${assessmentId}/corrections/insurance-info/primaryCoverage/insuranceCard`}
          field={primaryCoverageInsuranceCardField}
        />
        <InsuranceCardView
          insuranceCard={medicaidCoverage?.insuranceCard}
          updateField={(path, data) => handleInsuranceCardUpload(
            `insurance-info.medicaidCoverage.insuranceCard.${path}`,
            data,
          )
          }
          baseStoragePath={`${ASSESSMENTS}/${assessmentId}/corrections/insurance-info/medicaidCoverage/insuranceCard`}
          field={medicaidCoverageInsuranceCardField}
        />
        {/* <DataView
            initExpanded={!!medicaidCoverage?.insuranceCard}
            baseStoragePath={`${ASSESSMENTS}/${assessmentId}/insurance-info/medicaidCoverage/insuranceCard`}
            updateField={(path, data) => handleInsuranceCardUpload(
              `insurance-info.medicaidCoverage.insuranceCard.${path}`,
              data,
            )
            }
            field={
              {
                ...medicaidCoverageField.children.insuranceCard,
                name: 'Medicaid Coverage',
                initExpanded: !!medicaidCoverage?.insuranceCard,
              } as FieldMap
            }
            data={medicaidCoverage?.insuranceCard}
          /> */}
      </Stack>
    </ThemeContext.Provider>
  )
}
