import React from 'react'
import { useApp } from '../../contexts'
import { useAuth } from '../../store/auth'
import { AssessmentHelpModal } from './AssessmentHelpModal'
import { ContactFormModal } from './Contact/ContactFormModal'
import { EmailVerificationModal } from './EmailVerificationModal'
import { TwoFactorAuthModal } from './MultiFactor/TwoFactorAuthModal'
import { NameEntryModal } from './NameEntryModal'
import { PushNotificationsSettingsModal } from './PushNotificationsSettingsModal'

export const RootModals = () => {
  const claims = useAuth((s) => s.claims)
  const { appName } = useApp()
  return (
    <>
      {appName === 'app' ? <AssessmentHelpModal /> : null}
      <PushNotificationsSettingsModal />
      <NameEntryModal />
      {claims?.email_verified ? null : <EmailVerificationModal />}
      <TwoFactorAuthModal />
      <ContactFormModal />
    </>
  )
}
