import { Form } from '../../types'
import { insuranceInfoStage } from './insuranceInfo'

import { deliveryStage } from './delivery'

export * from './delivery'
export * from './insuranceInfo'

const loqRequiredOptions = ['one-plan', 'two-plans']
export const introduction: Form = {
  name: 'Introduction',
  description: 'Tell us a little bit about your pregnancy',
  stages: {
    delivery: deliveryStage,
    'insurance-info': insuranceInfoStage,
  },
  getNextFormId: (admin, assessment) => {
    const { insuranceProvider, data: userAnswers, mergedData } = assessment || {}
    const data = admin ? mergedData : userAnswers
    if (data) {
      const insuranceInfo = data['insurance-info']
      const { option } = insuranceInfo || {}
      if (loqRequiredOptions.includes(insuranceInfo?.option)) {
        if (insuranceProvider?.loqNotRequired) return undefined
        return 'call-in'
      }
      if (option === 'medicaid') {
        return undefined
      }
      if (option === 'coverage-will-change') {
        const coverageWillChangeOption = insuranceInfo.coverageWillChangeDescription
        if (coverageWillChangeOption === 'employment-coverage-will-change') {
          return 'call-in'
        }
        if (
          coverageWillChangeOption === 'employment-coverage-will-change-unknown'
        ) {
          return 'schedule-call'
        }
        if (coverageWillChangeOption === 'coverage-will-drop') {
          return 'plan-options'
        }
        if (coverageWillChangeOption === 'added-to-another-plan') {
          return 'call-in'
        }
      }
      if (option === 'multiple-options') {
        // const loqOptions = [
        //   'partner-individual',
        //   'partner-employer',
        //   'multiple-employer',
        // ]
        // if (loqOptions.includes(insuranceInfo.multipleOptionsDescription)) {
        //   return 'call-in'
        // }
        return undefined
      }
      if (option === 'not-pregnant') {
        return 'plan-options'
      }
      if (option === 'no-coverage') {
        if (insuranceInfo?.noCoverageDescription === 'out-of-pocket') { return undefined }
        return 'plan-options'
      }
    }
    return undefined
  },
}
