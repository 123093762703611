import { CalendarIcon } from '@chakra-ui/icons'
import {
  Flex, FlexProps, HStack, Text,
} from '@chakra-ui/react'
import {
  Claim,
  colors,
  FieldTypes,
  getDateString,
  ListItem,
  NextAction,
  parseNextActionDate,
  TextAreaField,
} from '@hb/shared'

import { doc } from 'firebase/firestore'
import React, {
  FC, useCallback, useMemo, useState,
} from 'react'
import { db } from '../../../backend'
import { useMe } from '../../../hooks/backend/useMe'
import { useUpdateDoc } from '../../../hooks/backend/useUpdateDoc'
import { Editable } from '../../forms/Input/index'

const nextActionField: TextAreaField = {
  type: FieldTypes.TEXTAREA,
  placeholder: 'Next action',
  optional: true,
}
export const NextActionContent: FC<{
  collection: string
  id?: string
  item?: ListItem
}> = ({ item, collection, id }) => {
  // const { nextAction } = item || {}
  const { nextAction } = (item as { nextAction?: NextAction }) || {}
  // const nextAction = item && 'nextAction' in item ? item.nextAction : {}
  const update = useUpdateDoc()
  const me = useMe()
  const ref = useMemo(
    () => (id ? doc(db, collection, id) : null),
    [collection, id],
  )
  const [isEditing, setIsEditing] = useState(false)
  const handleSubmit = useCallback(
    async (text: string) => {
      if (!me || !ref) return { error: 'Internal error' }
      if (collection === 'claims') {
        return update(ref, '', {
          nextActionText: text,
          nextActionDate: parseNextActionDate(text),
          nextAction: {
            text,
            updatedOn: Date.now(),
            updatedBy: me?.uid || '',
          },
        })
      }
      return update(ref, 'nextAction', {
        text,
        updatedOn: Date.now(),
        updatedBy: me?.uid || '',
      })
    },
    [update, collection, me, ref],
  )
  return (
    <Flex align="flex-start" minW="0" flex={1}>
      <Editable
        onSubmit={handleSubmit}
        value={nextAction?.text}
        editableStackProps={{ bg: 'transparent', boxShadow: 'none' }}
        openCallback={() => setIsEditing(true)}
        closeCallback={() => setIsEditing(false)}
        dataCellProps={{
          fontSize: 'sm',
          borderRadius: '4px',
          border: '1px solid #cdcdcd',
          background: 'white',
        }}
        style={{
          flex: 1,
          minWidth: 0,
        }}
        field={nextActionField}
      />
      {nextAction?.updatedOn && !isEditing ? (
        <HStack ml={2} py={1} spacing={1}>
          <CalendarIcon width={3} color="gray.600" />
          <Text fontWeight={500} color="gray.600" fontSize="xs">
            {getDateString(nextAction.updatedOn, 'short', false)}
          </Text>
        </HStack>
      ) : null}
    </Flex>
  )
}

export const NextActionView = ({
  item,
  collection,
  id,
  ...props
}: FlexProps & { item?: ListItem; collection: string }) => (
  <Flex
    width="100%"
    px={1}
    bg={`${colors.pink.hex}aa`}
    // align='flex-start'
    align="center"
    py={1}
    {...props}
  >
    <Text
      whiteSpace="nowrap"
      fontSize="sm"
      position="relative"
      top="2px"
      px={2}
      color="gray.500"
      fontWeight={600}
    >
      Next Action:
    </Text>
    <NextActionContent id={id} collection={collection} item={item} />
  </Flex>
)

const getServiceType = (claim: Claim) => claim?.serviceType || claim?.legacy?.serviceType
export const ClaimNextAction = ({
  claimId,
  claim,
  ...props
}: FlexProps & {
  claim: Claim
  claimId: string
}) => (
  <Flex bg="gray.100" align="flex-start" py={1} w="300px" minW="0" {...props}>
    <Text
      px={2}
      pt={1}
      color="#777"
      whiteSpace="pre"
      fontSize="0.8rem"
      fontWeight={600}
    >
      {getServiceType(claim)?.toString().toUpperCase() || 'NO SERVICE TYPE'}
    </Text>
    <NextActionContent
      id={claimId}
      item={{ ...claim, id: claimId }}
      collection="claims"
    />
  </Flex>
)
