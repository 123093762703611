import {
  AssessmentStages,
  PracticeAssessmentStages,
} from '../types/assessments'
import { UserRole } from '../types/users/role'

export const assessmentStageKeys: AssessmentStages = [
  'Inquiry',
  'Authorization',
  'Send Claim',
  'Claims',
  'Complete',
]
export const practiceAssessmentStageKeys: PracticeAssessmentStages = [
  'Inquiry',
  'Current',
  'Postpartum',
  'Gyn',
  'Other',
  'Complete',
  'Discharged',
]

export const adminRoles: Array<UserRole> = ['admin', 'super-admin']

export const userAccess: UserRole[] = ['user', 'admin', 'super-admin']
export const adminAccess: UserRole[] = ['admin', 'super-admin']
export const superAdminAccess: UserRole[] = ['super-admin']
