import {
  Box, Divider, Text, VStack,
} from '@chakra-ui/react'
import { DropdownField, InsuranceProvider } from '@hb/shared'
import React from 'react'
import { Condition, FormElement } from '../../../../../forms'
import {
  customPayerIdPrimary,
  customRelateCodePrimary,
  shouldUseCustomPayerId,
  shouldUseCustomRelateCodeField,
} from '../fields'

export const SubmitClaimPrimaryCoverage = ({
  defaultRelateCode,
  policyOwner,
  insuranceProvider,
  authorizationsField,
}: {
  defaultRelateCode: string
  policyOwner: string
  insuranceProvider: InsuranceProvider | undefined
  authorizationsField: DropdownField
}) => (
  <VStack
    border='1px solid #cdcdcd'
    borderRadius={4}
    spacing={0}
    bg='white'
    boxShadow='md'
    align='flex-start'
    w='100%'
    px={3}
    py={2}
  >
    <Text fontWeight={600} color='gray.500' fontSize='md'>
      Primary coverage
    </Text>
    {insuranceProvider ? (
      <>
        <Text color='gray.600' fontSize='md'>
          {insuranceProvider?.name}
        </Text>
        <Divider my={2} />
        <FormElement
          field={authorizationsField}
          name='primaryCoverageAuthRef'
        />
        <Divider my={2} />
        <FormElement
          name='shouldUseCustomPayerIdPrimary'
          field={shouldUseCustomPayerId}
        />
        <Condition
          basePath=''
          condition={(v) => !v?.shouldUseCustomPayerIdPrimary}
        >
          {!insuranceProvider?.abilityId ? (
            <Text color='red.600' fontSize='md'>
              Insurance provider is missing Payer ID (Insurers Page)
            </Text>
          ) : (
            <Text>Payer ID: {insuranceProvider?.abilityId || 'None'}</Text>
          )}
        </Condition>
        <FormElement name='customPayerIdPrimary' field={customPayerIdPrimary} />
        <Divider my={2} />
        <FormElement
          name='shouldUseCustomRelateCodePrimary'
          field={shouldUseCustomRelateCodeField}
        />
        <Condition
          condition={(v) => !v?.shouldUseCustomRelateCodePrimary}
          basePath=''
        >
          <Box px={2}>
            <Text>Policy owner relationship: {policyOwner}</Text>
            <Text>Default relate code: {defaultRelateCode}</Text>
          </Box>
        </Condition>
        <FormElement
          name='customRelateCodePrimary'
          field={customRelateCodePrimary}
        />
      </>
    ) : (
      <Text color='red.500' fontSize='md'>
        No insurance provider listed for this assessment
      </Text>
    )}
  </VStack>
)
