import { BoxProps, Flex } from '@chakra-ui/react'
import React from 'react'

export const FloatingContainer = (props: BoxProps) => (
    <Flex
      py={4}
      px={5}
      mx={1}
      position="relative"
      width="100%"
      direction="column"
      borderRadius={6}
      boxShadow="1px 2px 7px #777"
      overflow="hidden"
      background="rgba(255,255,255,0.98)"
      {...props}
    />
)
