import {
  CollectionFilter, DropdownField, FieldMap, FieldTypes, FileField, ListField, PresetFilter, TextAreaField, TextField,
} from '@hb/shared'

export const closedFilter: CollectionFilter = ['status', '==', 'closed']
export const openFilter: CollectionFilter = ['status', '==', 'open']
export const notArchivedFilter: CollectionFilter = ['archived', '==', false]
export const archivedFilter: CollectionFilter = ['archived', '==', true]
export const bugReportPresetFilters: Array<PresetFilter> = [
  {
    label: 'Status',
    filters: [

      {
        filter: openFilter,
        label: 'Open',
      },
      {
        filter: closedFilter,
        label: 'Closed',
      },
    ],
  },
  {
    label: 'Priority',
    filters: [
      {
        filter: ['priority', '==', 'low'],
        label: 'Low Priority',
      },
      {
        filter: ['priority', '==', 'medium'],
        label: 'Medium Priority',
      },
      {
        filter: ['priority', '==', 'high'],
        label: 'High Priority',
      },
    ],
  },
  {
    label: 'Archived',
    filters: [
      {
        filter: notArchivedFilter,
        label: 'Not Archived',
      },
      {
        filter: archivedFilter,
        label: 'Archived',
      },
    ],
  },
]

export const bugReportTitleField: TextField = {
  type: FieldTypes.TEXT,
  placeholder: 'Title',
}

export const bugReportDescriptionField: TextAreaField = {
  type: FieldTypes.TEXTAREA,
  placeholder: 'Description',
}

export const bugReportPriorityField: DropdownField = {
  placeholder: 'Priority',
  type: FieldTypes.DROPDOWN,
  options: [
    { text: 'Low', id: 'low' },
    { text: 'Medium', id: 'medium' },
    { text: 'High', id: 'high' },
  ],
}

export const bugReportFileField: FileField = {
  type: FieldTypes.FILE,
  placeholder: 'File',
  name: 'file',
}

export const bugReportFilesField: ListField = {
  itemFields: bugReportFileField,
  itemName: 'relevant file',
  optional: true,
  name: 'Files',
}

export const bugReportField: FieldMap = {
  name: 'Bug Report',
  children: {
    title: bugReportTitleField,
    description: bugReportDescriptionField,
    priority: bugReportPriorityField,
    files: bugReportFilesField,
  },
}
