import { Circle } from '@chakra-ui/react'
import { colors } from '@hb/shared'
import React from 'react'

export const IndexCircle = ({ index }: {index: number}) => (
<Circle
              fontWeight={600}
              size={5}
              lineHeight={1}
              fontSize='sm'
              bg={colors.green.hex}
              color='white'
            >
              {index + 1}
            </Circle>
)
