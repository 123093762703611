import {
  Button,
  Flex,
  HStack,
  Image,
  Popover, PopoverArrow, PopoverContent,
  PopoverTrigger,
  Text,
  Tooltip,
  useDisclosure,
} from '@chakra-ui/react'
import { colors } from '@hb/shared'

import React, { useContext } from 'react'
import { ScreenContext, UserContext } from '../../../../contexts'
import deliveryIcon from '../../../../icons/breastfeeding_green.svg'
import { ReportDeliveryContent } from './ReportDeliveryContent'

export const ReportDeliveryPopover = () => {
  const { selectedAssessment } = useContext(UserContext)
  const { isOpen, onOpen, onClose } = useDisclosure()
  const { isMobile } = useContext(ScreenContext)
  const { deliveredOn } = selectedAssessment || {}
  return (
    <Popover
      closeOnBlur={false}
      isOpen={isOpen}
      onClose={onClose}
      onOpen={onOpen}
      isLazy
      placement={isMobile ? 'bottom' : 'left-start'}
      strategy="fixed"
    >
      <PopoverTrigger>
        <Flex w='auto'>
          <Tooltip
            hasArrow
            placement="bottom"
            label={
              deliveredOn
                ? 'Click to view / update'
                : 'Click to report delivery'
            }
          >
            <Button
              size={isMobile ? 'xs' : 'sm'}
              fontWeight={300}
              w='140px'
              fontFamily="hero-new"
              bg={deliveredOn ? colors.green.hex : 'gray.50'}
              color="gray.800"
              _hover={{ bg: deliveredOn ? 'gray.300' : 'green.300' }}
            >
              <HStack spacing={0}>
                <Image
                  width="21px"
                  src={deliveryIcon}
                  opacity={deliveredOn ? 1 : 0.5}
                  filter={
                    deliveredOn ? 'grayscale(100%) brightness(200%)' : 'none'
                  }
                />
                <Text
                  height="14px"
                  fontFamily="Comfortaa"
                  px={1}
                  fontSize={isMobile ? 'xs' : 'sm'}
                  fontWeight={600}
                  color={deliveredOn ? 'gray.50' : colors.green.hex}
                >
                  {deliveredOn ? 'DELIVERED' : 'DELIVERY'}
                </Text>
              </HStack>
            </Button>
          </Tooltip>
        </Flex>
      </PopoverTrigger>
      <PopoverContent
        h='auto'
        maxH='400px'
        w="400px"
        borderRadius={6}
        overflowY='auto'
        maxW="95%"
      >
        {isOpen ? <ReportDeliveryContent onClose={onClose}/> : null}
        <PopoverArrow bg={colors.green.hex} />
      </PopoverContent>
    </Popover>
  )
}
