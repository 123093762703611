import { EmailIcon } from '@chakra-ui/icons'
import {
  Box, HStack, Text, VStack,
} from '@chakra-ui/react'
import { getFullName } from '@hb/shared/utils'
import React from 'react'
import { useCachedUser } from '../../../collections/hooks/cached'
import { ProfilePicturePopover } from '../../Admins/ProfilePicturePopover'
import { DeleteButton } from '../../Buttons'

export const ThreadMemberView = ({
  userId,
  onDelete,
}: {
  userId: string
  onDelete?: () => Promise<void>
}) => {
  const { data: user } = useCachedUser(userId)
  return (
    <VStack
      bg="whiteAlpha.500"
      py={2}
      px={3}
      borderRadius={4}
      boxShadow="md"
      w="100%"
      align="flex-start"
      spacing={0}
    >
      <HStack w="100%">
        <ProfilePicturePopover userId={userId} />
        <VStack align='flex-start' spacing={0}>
          <Text lineHeight={1} fontSize="md" fontFamily="Hero-New">
            {getFullName(user)}
          </Text>
          <HStack>
            <EmailIcon color="gray.500" />
            <Text fontSize="sm">{user?.email || 'No email'}</Text>
          </HStack>
        </VStack>
        {onDelete ? (
          <Box ml="auto">
            <DeleteButton
              actionName="Remove"
              noConfirm
              itemName="thread member"
              onDelete={onDelete}
            />
          </Box>
        ) : null}
      </HStack>
    </VStack>
  )
}
