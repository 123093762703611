import { CollectionFilter } from '../../types/app/populated'
import { UserRoleItem } from '../../types/users/role'
import { adminCollectionAccess } from '../constants'
import { Collection } from '../types/collection'
import { USER_ROLES } from './patients'

export const adminsFilter: CollectionFilter = ['role', 'in', ['admin', 'super-admin']]
export const adminsCollection: Collection<UserRoleItem> = {
  _type: 'firestoreCollection',
  name: 'Admins',
  refPath: USER_ROLES,
  filters: [adminsFilter],
  access: adminCollectionAccess,
  noRanks: true,
  defaultSort: 'lname',
}
