import { CloseIcon } from '@chakra-ui/icons'
import {
  Box,
  Button,
  Center,
  CircularProgress,
  Flex,
  HStack,
  Text,
} from '@chakra-ui/react'
import { Descendant, getShortcutArgs, Template } from '@hb/shared'

import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { ReactEditor } from 'slate-react'
import { UserContext } from '../../contexts/UserContext'
import { useSelectedPractice } from '../../hooks/backend/auth/useSelectedPractice'
import { useCollectionItem } from '../../hooks/backend/useCollectionItem'
import { DefaultModalProps } from '../Modals/DefaultModal'
import { TemplateEditorContext } from '../RichText/context'
import { EditorModalContainer } from '../RichText/EditModalContainer'
import { TemplateToolbar } from '../RichText/TemplateToolbar'
import { TextEditor } from '../RichText/TextEditor'
import { EditorFaxButton, useOnFax } from './EditorFaxButton'
import { EditorSaveButton, useOnSave } from './EditorSaveButton'
import { EditorSubmitButton, useOnSubmit } from './EditorSubmitButton'
import { TemplateMenu } from './TemplateMenu'
import { TemplateViewProps } from './types'
import { getWithVariables } from './withVariables'

export const TemplateViewModal: React.FC<
  Omit<TemplateViewProps, 'editorSize'> & Omit<DefaultModalProps, 'render'>
> = ({
  admin,
  collection,
  id,
  initText,
  onFax,
  templateType,
  onSave,
  onSubmit,
  editorVersion,
  submitText,
  ...modalProps
}) => (
  <EditorModalContainer
    render={(editorSize, onBack) => (
      <AssessmentTemplateView
        admin={admin}
        collection={collection}
        templateType={templateType}
        editorVersion={editorVersion}
        onBack={onBack}
        id={id}
        initText={initText}
        onFax={onFax}
        onSubmit={onSubmit}
        onSave={onSave}
        editorSize={editorSize}
        submitText={submitText}
      />
    )}
    {...modalProps}
  />
)

const TemplateViewBody: React.FC<TemplateViewProps> = ({
  admin,
  collection,
  id,
  initText,
  onSave,
  onFax,
  editorVersion,
  onSubmit,
  editorSize,
  onBack,
  templateType,
  submitText,
}) => {
  const {
    shortcutArgs: { assessment },
  } = useContext(TemplateEditorContext)
  const [manualTemplateId, setManualTemplateId] = useState<string | undefined>()
  const { item: template, loading: templateLoading } = useCollectionItem(
    collection,
    manualTemplateId || id,
  ) as { item?: Template; loading?: boolean }
  const initTextUpdatedOn = useRef(Date.now())
  const manualTemplateSelectedOn = useRef(Date.now())

  const [initialText, setInitialText] = useState(
    initText || template?.templateText,
  )
  const [isRestarting, setIsRestarting] = useState(false)

  const t0 = useRef<ReturnType<typeof setTimeout> | undefined>()
  const restartWithText = useCallback((text: Descendant[]) => {
    setIsRestarting(true)
    if (t0.current) clearTimeout(t0.current)
    t0.current = setTimeout(() => {
      setInitialText(text)
      setIsRestarting(false)
    }, 250)
  }, [])
  useEffect(() => {
    if (initText?.length) {
      initTextUpdatedOn.current = Date.now()
      restartWithText(initText || [])
    }
  }, [initText, restartWithText])

  const usedVersion = useMemo(
    () => (template ? template?.editorVersion || 'v1' : editorVersion),
    [template, editorVersion],
  )

  const saveManager = useOnSave(usedVersion, onSave)

  const faxManager = useOnFax(onFax)
  const submitManager = useOnSubmit(
    templateType,
    usedVersion,
    onSubmit,
    submitText,
  )

  const ref = useRef<ReactEditor>(null)

  const decorators = useMemo(
    () => [getWithVariables(usedVersion)],
    [usedVersion],
  )

  const toolbars = useMemo(() => [TemplateToolbar], [])
  return isRestarting || templateLoading ? (
    <Center p={4}>
      <CircularProgress color="gray.500" size={5} isIndeterminate />
    </Center>
  ) : (
    <TextEditor
      readOnly={!admin}
      withDownload
      version={usedVersion}
      decorators={decorators}
      toolbars={toolbars}
      templateType={templateType}
      value={initialText}
      ref={ref}
      {...editorSize}
    >
      {admin ? (
        <HStack align="center" width="100%" p={2} zIndex={4} bg="white">
          <Box minW="0" flex={1}>
            <TemplateMenu

              insuranceProviderId={assessment?.insuranceProvider?.id}
              onChange={(t: Template) => {
                manualTemplateSelectedOn.current = Date.now()
                setManualTemplateId(t.id)
                restartWithText(t.templateText || [])
              }}
              template={template}
              templateType={templateType}
              collection={collection}
            />
          </Box>
          <Flex gap={1}>
            {onFax && !submitManager.submitConfirm ? (
              <EditorFaxButton {...faxManager} />
            ) : null}
            {onSubmit && !faxManager.faxSending ? (
              <EditorSubmitButton {...submitManager} />
            ) : null}
            {onSave && !submitManager.submitConfirm ? (
              <EditorSaveButton {...saveManager} />
            ) : null}
            {onBack ? (
              <Button
                variant="outline"
                onClick={onBack}
                size="xs"
                bg="gray.50"
                color="gray.500"
                alignItems="center"
                gap={1}
              >
                <CloseIcon position="relative" top="1px" w={2} />
                <Text>Close</Text>
              </Button>
            ) : null}
          </Flex>
        </HStack>
      ) : null}
    </TextEditor>
  )
}
export const AssessmentTemplateView: React.FC<TemplateViewProps> = (props) => {
  const { selectedAssessment: assessment, user } = useContext(UserContext)
  const { practice } = useSelectedPractice()
  const shortcutArgs = useMemo(
    () => getShortcutArgs(user, assessment, practice),
    [user, assessment, practice],
  )
  return (
    <TemplateEditorContext.Provider value={{ shortcutArgs }}>
      <TemplateViewBody {...props} />
    </TemplateEditorContext.Provider>
  )
}
