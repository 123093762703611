import {
  Field,
  FieldMap,
  FieldMapValue,
  InfoStage,
  isField,
  isInfoStage,
  isListField,
  ListField, UpdateCallback,
} from '@hb/shared'
import {
  deleteObject, getDownloadURL, getStorage,
  ref,
  uploadBytesResumable,
} from 'firebase/storage'
import { app } from './app'

export const storage = getStorage(app)

export function downloadBlob(blob: Blob, name = 'file.txt') {
  const blobUrl = URL.createObjectURL(blob)

  // Create a link element
  const link = document.createElement('a')

  // Set link's href to point to the Blob URL
  link.href = blobUrl
  link.download = name

  // Append link to the body
  document.body.appendChild(link)

  // Dispatch click event on the link
  // This is necessary as link.click() does not work on the latest firefox
  link.dispatchEvent(
    new MouseEvent('click', {
      bubbles: true,
      cancelable: true,
      view: window,
    }),
  )

  // Remove link from body
  document.body.removeChild(link)
}
export const deleteFile = async (
  path: string,
): Promise<{ error?: string; success?: boolean }> => {
  try {
    await deleteObject(ref(storage, path))
  } catch (err: any) {
    if (err.code === 'storage/object-not-found') return { success: true }
    return { error: `${err.message}` }
  }

  return { success: true }
}

export const uploadFile = async (
  path: string,
  file: File | Blob,
  onUploadProgress: (progress: number) => void,
): Promise<string> => new Promise((resolve, reject) => {
  const task = uploadBytesResumable(ref(storage, path), file)
  task.on('state_changed', async (snapshot) => {
    const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100
    if (onUploadProgress) onUploadProgress(progress)
    if (progress === 100) {
      resolve(snapshot.ref.fullPath)
    }
  }, (error) => {
    console.error(error)
    reject(error)
  })
})

export const deleteFieldMapFiles = async (
  fieldMap: FieldMap,
  value?: FieldMapValue,
) => {
  const fieldMapEntries = Object.entries(fieldMap.children)
  const promises = fieldMapEntries.map(async ([key, child]) => deleteFieldFiles(child, value?.[key]))
  await Promise.all(promises)
}

export const downloadFromStorage = async (
  path: string,
): Promise<UpdateCallback> => {
  try {
    const storageRef = ref(storage, path)
    const url = await getDownloadURL(storageRef)
    try {
      const res = await fetch(url)
      const pdf = await res.blob()
      downloadBlob(pdf, storageRef.name)
      return { success: 'Downloaded!' }
    } catch (err) {
      console.error(err)
      throw new Error('Error fetching pdf')
    }
  } catch (err) {
    console.log(err)
    return { error: 'Error downloading' }
  }
}

const unresizedTypes = ['image/gif', 'image/svg+xml']
const isImageFile = (type?: string): boolean => !!type && type.indexOf('image/') === 0 && !unresizedTypes.includes(type)

// example: /path/to/file.jpg -> /path/to/resized_file.jpg
export const getResizedStoragePath = (path: string, type: string | undefined) => {
  if (!isImageFile(type)) return path
  const pathParts = path.split('/').filter((part) => !!part)
  const fileName = pathParts.pop()
  if (!fileName) throw new Error('No file name')
  return `${pathParts.join('/')}/resized_${fileName}`
}
const deleteFieldFiles = async (
  field: FieldMap | Field | ListField | InfoStage,
  value?: any,
) => {
  if (isField(field)) {
    if (field.type === 'file') {
      try {
        await deleteFile(
          getResizedStoragePath(value.storagePath, value.type),
        )
      } catch (err: any) {
        console.error(err)
        if (err?.code !== 'storage/object-not-found') throw new Error('Error deleting file')
      }
    }
  } else if (isListField(field)) {
    const valueAsObject = typeof value === 'object' && value !== null && !Array.isArray(value)
      ? value
      : {}
    const promises = Object.entries(valueAsObject || {}).map(
      async ([, record]) => {
        await deleteFieldFiles(
          field.itemFields,
          record,
        )
      },
    )
    await Promise.all(promises)
  } else if (!isInfoStage(field)) {
    await deleteFieldMapFiles(field, value)
  }
}
