import {
  Box, Collapse, Divider, Text, VStack,
} from '@chakra-ui/react'
import { DropdownField, InsuranceProvider } from '@hb/shared'
import React from 'react'
import { Condition, FormElement } from '../../../../../forms'
import {
  customPayerIdSecondary,
  customRelateCodeSecondary,
  shouldIncludeSecondaryCoverage,
  shouldUseCustomPayerId,
  shouldUseCustomRelateCodeField,
} from '../fields'

export const SubmitClaimSecondaryCoverage = ({
  defaultSecondaryRelateCode,
  secondaryPolicyOwner,
  hasSecondaryCoverage,
  secondaryInsurer,
  authorizationsField,
}: {
  defaultSecondaryRelateCode: string
  secondaryPolicyOwner: string
  hasSecondaryCoverage: boolean
  secondaryInsurer: InsuranceProvider | undefined | null
  authorizationsField: DropdownField
}) => (
  <Collapse
    style={{ width: '100%' }}
    in={hasSecondaryCoverage}
    unmountOnExit={false}
  >
    <FormElement
      name='shouldIncludeSecondaryCoverage'
      field={shouldIncludeSecondaryCoverage}
    />
    <Condition
      condition={(v) => !!v?.shouldIncludeSecondaryCoverage}
      basePath=''
    >
      <VStack
        border='1px solid #cdcdcd'
        borderRadius={4}
        spacing={0}
        align='flex-start'
        bg='white'
        boxShadow='md'
        w='100%'
        px={3}
        py={2}
      >
        <Text fontWeight={600} color='gray.500' fontSize='md'>
          Secondary coverage
        </Text>
        {secondaryInsurer ? (
          <>
            <Text color='gray.600' fontSize='md'>
              {secondaryInsurer?.name}
            </Text>
            <Divider my={2} />
            <FormElement
              field={authorizationsField}
              name='secondaryCoverageAuthRef'
            />
            <Divider my={2} />
            <FormElement
              name='shouldUseCustomPayerIdSecondary'
              field={shouldUseCustomPayerId}
            />
            <Condition
              basePath=''
              condition={(v) => !v?.shouldUseCustomPayerIdSecondary}
            >
              {!secondaryInsurer?.abilityId ? (
                <Text color='red.600' fontSize='md'>
                  Insurance provider is missing Payer ID (Insurers Page)
                </Text>
              ) : (
                <Text>Payer ID: {secondaryInsurer?.abilityId || 'None'}</Text>
              )}
            </Condition>
            <FormElement
              name='customPayerIdSecondary'
              field={customPayerIdSecondary}
            />
            <VStack w='100%' py={2}>
              <Divider />
            </VStack>
            <FormElement
              name='shouldUseCustomRelateCodeSecondary'
              field={shouldUseCustomRelateCodeField}
            />
            <Condition
              condition={(v) => !v?.shouldUseCustomRelateCodeSecondary}
              basePath=''
            >
              <Box px={2}>
                <Text>Policy owner relationship: {secondaryPolicyOwner}</Text>
                <Text>Default relate code: {defaultSecondaryRelateCode}</Text>
              </Box>
            </Condition>
            <FormElement
              name='customRelateCodeSecondary'
              field={customRelateCodeSecondary}
            />
          </>
        ) : (
          <Text color='red.500' fontSize='md'>
            No secondary insurance provider listed for this assessment
          </Text>
        )}
      </VStack>
    </Condition>
  </Collapse>
)
