import React from 'react'
import { Text, TextProps } from '@chakra-ui/react'

export const Italic = (props: TextProps) => (
  <Text
    as='span'
    fontSize='md'
    mb={1}
    fontStyle='italic'
    color='gray.600'
    {...props}
  />
)
