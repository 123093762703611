import { EditIcon } from '@chakra-ui/icons'
import {
  Flex, IconButton, Stack, Text,
} from '@chakra-ui/react'
import { formatDollarValue } from '@hb/shared'
import React, { useContext } from 'react'
import { ScreenContext } from '../../../../../contexts'
import { DataCell } from '../../../../DataView/DataCell'
import { LabelCell } from '../../../../DataView/DataView'

import { PaymentsContext } from '../PaymentsProvider'
import { PaymentView } from '../PaymentView'

export const PaymentsDue = () => {
  const {
    byDate,
    payments,
    modal: { onOpen },
  } = useContext(PaymentsContext)
  const { prm } = payments || {}
  const { isMobile } = useContext(ScreenContext)
  return (
    <Flex w='100%' direction='column' bg={isMobile ? '#ffe8e3' : 'white'} shadow='md'>
      <Flex align='center' bg='#ffe8e3' borderBottom='1px solid #cdcdcd' p={1}>
        <Text px={2} fontWeight={600} color='blackAlpha.700'>
          Payments Due
        </Text>
        <IconButton
          ml='auto'
          // variant='ghost'
          boxShadow='md'
          bg='white'
          aria-label='edit'
          size='xs'
          onClick={onOpen}
          icon={<EditIcon />}
        />
      </Flex>
      <Flex bg='whiteAlpha.700' borderBottom='1px solid #cdcdcd' px={2} align='center' py={isMobile ? 0 : 1}>
        <LabelCell color='gray.600' fontSize='sm'>PRM</LabelCell>
        <DataCell color='gray.600' fontSize='md'>{formatDollarValue(prm)}</DataCell>
      </Flex>
      <Stack p={isMobile ? 2 : 0} spacing={0}>
        {Object.entries(byDate).map(([paymentId, payment], i) => (
          <PaymentView
            index={i + 1}
            id={paymentId}
            key={paymentId}
            payment={payment}
          />
        ))}
      </Stack>
    </Flex>
  )
}
