import { useDisclosure } from '@chakra-ui/react'
import {
  AssessmentPaymentsData, PaymentRecord, PopulatedPayment, populatePaymentsFromSorted,
  SortedPayments, sortPaymentTypes, sortPopulatedPaymentsByDate,
} from '@hb/shared'
import React, {
  createContext, PropsWithChildren, useContext, useMemo,
} from 'react'
import { UserContext } from '../../../../contexts/UserContext'

export type PaymentsContextData = {
  populated: SortedPayments<PopulatedPayment>
  payments?: AssessmentPaymentsData
  byDate: PaymentRecord<PopulatedPayment>
  modal: ReturnType<typeof useDisclosure>
  prm?: number
}

export const PaymentsContext = createContext<PaymentsContextData>({
  populated: {
    deposit: { payments: {}, total: 0 },
    retainer: { payments: {}, total: 0 },
    'home-birth-retainer': { payments: {}, total: 0 },
    other: { payments: {}, total: 0 },
  },
  modal: {
    getButtonProps: () => {},
    getDisclosureProps: () => {},
    isControlled: false,
    isOpen: false,
    onClose: () => {},
    onOpen: () => {},
    onToggle: () => {},
  },
  byDate: {},
})
export const usePaymentsContext = () => useContext(PaymentsContext)

export const usePayments = (): PaymentsContextData => {
  const { selectedAssessment } = useContext(UserContext)
  const { midwife, payments } = selectedAssessment || {}
  const { due, received } = payments || {}
  const sortedDue = useMemo(() => sortPaymentTypes(due), [due])
  const sortedReceived = useMemo(() => sortPaymentTypes(received), [received])
  const populated = useMemo(
    () => populatePaymentsFromSorted(sortedDue, sortedReceived),
    [sortedDue, sortedReceived],
  )

  const byDate = useMemo<PaymentRecord<PopulatedPayment>>(
    () => sortPopulatedPaymentsByDate(populated),
    [populated],
  )
  const prm = useMemo(() => payments?.prm || midwife?.prm, [payments, midwife])
  const modal = useDisclosure()
  return {
    populated, payments, byDate, modal, prm,
  }
}

export const PaymentsProvider = ({ children }: PropsWithChildren) => {
  const payments = usePayments()
  return (
    <PaymentsContext.Provider value={payments}>
      {children}
    </PaymentsContext.Provider>
  )
}
