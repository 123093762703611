import {
  Box, Collapse, Container, Flex,
} from '@chakra-ui/react'

import React, { useContext } from 'react'
import { useCookies } from 'react-cookie'
import { Redirect, useHistory, useParams } from 'react-router'
import { ScreenContext } from '../../contexts/ScreenContext'
import { useContentWidth } from '../../hooks'
import { signOut, useAuth } from '../../store'
import { ActionButton, SolidActionButton } from '../Buttons'
import { FloatingContainer } from '../Containers'
import { Header } from '../Header'
import { SignInContent } from './SignInContent'

export const SignInView: React.FC = () => {
  const [{ inviteId }] = useCookies()

  const history = useHistory()
  const { user } = useAuth()
  const { width } = useContext(ScreenContext)
  const contentWidth = useContentWidth(width, 400)

  return (
    <Container>
      <FloatingContainer
        style={{
          width: contentWidth,
          padding: 0,
        }}
      >
        {!user ? (
          <SignInContent onClose={() => history.push('/assessment')} />
        ) : null}

        <Collapse in={!!user}>
          <Box pt={2} pb={3} px={5}>
            <Header style={{ fontSize: '18px', color: '#222222', fontFamily: 'Hero-New' }}>
              Welcome to Hamilton Billing!
            </Header>
            <Flex gap={2} direction="column" mr="auto">
              <SolidActionButton
                style={{
                  color: 'white',
                  fontSize: '14px',
                  marginTop: '10px',
                  marginRight: 'auto',
                }}
                onClick={() => history.push('/assessment')}
              >
                Your Assessment
              </SolidActionButton>
              <ActionButton
                size="sm"
                style={{
                  marginRight: 'auto',
                }}
                onClick={() => signOut()}
              >
                Log Out
              </ActionButton>
            </Flex>
          </Box>
        </Collapse>
        {inviteId ? (
          <span
            style={{
              padding: '0 10px',
              fontWeight: 600,
              color: '#777',
              fontSize: '12px',
              paddingTop: '5px',
              paddingBottom: '10px',
            }}
          >
            INVITE ID: {inviteId.toUpperCase()}
          </span>
        ) : null}
      </FloatingContainer>
    </Container>
  )
}

export const SignInRedirect: React.FC = () => {
  const params = useParams() as { inviteId: string }
  const [, setCookie] = useCookies()
  const { inviteId } = params
  if (inviteId) {
    setCookie('inviteId', inviteId, { path: '/', sameSite: 'strict' })
  }
  return <Redirect to="/" />
}
