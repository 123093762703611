import { Box, Collapse } from '@chakra-ui/react'
import { FieldMapValue } from '@hb/shared'
import { get } from 'nested-property'
import React, { PropsWithChildren, useMemo } from 'react'
import { FormSpy } from 'react-final-form'

const ConditionBody = ({
  basePath,
  values,
  children,
  condition,
}: PropsWithChildren<{
  basePath: string
  values: FieldMapValue
  condition: (v?: any) => boolean
}>) => {
  const isIn = useMemo(() => {
    let bp = basePath
    if (bp.length) {
      bp = bp
        .substring(0, bp.length - 1)
        .replace(/\[/g, '.')
        .replace(/\]/g, '')
    }
    return condition(bp ? get(values, bp) : values)
  }, [basePath, values, condition])

  return (
    <Collapse style={{ width: '100%' }} unmountOnExit in={isIn}>
      <Box>{children}</Box>
    </Collapse>
  )
}
export const Condition = (props: PropsWithChildren<{
  basePath: string
  condition: (v?: any) => boolean
}>) => (
  <FormSpy subscription={{ values: true }}>
    {({ values }) => <ConditionBody values={values} {...props} />}
  </FormSpy>
)
