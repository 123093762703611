import { useAppModals, useAuth } from '../../../store'
import { useFullySignedIn } from './useFullySignedIn'

export const useMultiFactorOpen = () => {
  const { multiFactorAuth: { openId: openedByUser } } = useAppModals()
  const enrollingUser = useAuth((s) => s.enrollingUser)
  const selectedEnrollmentFactor = useAuth((s) => s.selectedEnrollmentFactor)
  const enrolledFactors = useAuth((s) => s.enrolledFactors)
  const fullySignedIn = useFullySignedIn()
  const { emailVerified } = enrollingUser || {}
  const needsToVerify = enrollingUser && !emailVerified

  return !(
    needsToVerify
    || (fullySignedIn && !openedByUser)
    || (!enrolledFactors && !selectedEnrollmentFactor && !openedByUser)
  )
}
