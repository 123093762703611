/* eslint-disable no-underscore-dangle */
import {
  Collection, DocData,
  FirestoreCollection,
} from './types'

export const getBaseCollection = <
  Data extends DocData = DocData,
>(
    collection: Collection<Data>,
  ): FirestoreCollection<Data> => {
  let curr: Collection<Data> = collection

  while (curr._type !== 'firestoreCollection') {
    switch (curr._type) {
      case 'derivedCollection':
        curr = curr.baseCollection as Collection<Data>
        break
      case 'combinedCollection':
        curr = curr.index as Collection<Data>
        break
      default:
    }
  }
  return curr as FirestoreCollection<Data>
}

// export const getFirestoreCollectionId = <Data extends DocData>(collection: FirestoreCollection<Data>): string => collection.refPath

// export const getDerivedCollectionId = <Data extends DocData>(collection: DerivedCollection<Data>): string => `${collection.name}_${getCollectionId(collection.baseCollection)}`

// export const getCombinedCollectionId = <Data extends DocData>(collection: CombinedCollection<Data>): string => `${collection.name}_${getCollectionId(collection.index)}_${Object.values(collection.otherCollections).map(getCollectionId).join('_')}`

function checksum(s: string) {
  let chk = 0x12345678
  const len = s.length
  for (let i = 0; i < len; i += 1) {
    chk += (s.charCodeAt(i) * (i + 1))
  }

  // eslint-disable-next-line no-bitwise
  return (chk & 0xffffffff).toString(16)
}
export const getCollectionId = <Data extends DocData>(collection: Collection<Data>) => {
  if (!collection) console.trace({ collection })
  return checksum(JSON.stringify(collection))
  // switch (collection._type) {
  //   case 'firestoreCollection':
  //     return getFirestoreCollectionId(collection)
  //   case 'derivedCollection':
  //     return getDerivedCollectionId(collection)
  //   case 'combinedCollection':
  //     return getCombinedCollectionId(collection)
  //   default:
  //     return ''
  // }
}
