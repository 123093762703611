import { AssessmentDocument } from '@hb/shared'
import {
  collection, orderBy, Query, query, where,
} from 'firebase/firestore'
import { useMemo } from 'react'
import { db } from '../../../backend/db'
import { useQuery } from '../useQuery'

export const usePatientDocuments = (patientId?: string, admin?: boolean) => {
  const ref = useMemo(
    () => {
      if (!patientId) return null
      let q = query(
        collection(db, 'users', patientId, 'documents'),
        where('assessmentArchived', '==', false),
      ) as Query<AssessmentDocument>
      if (!admin) {
        q = query(q, where('expired', '==', false))
      }
      q = query(q, orderBy('sentOn', 'desc'))
      return q
    },
    [patientId, admin],
  )
  return useQuery<AssessmentDocument>(ref)
}
