import { AddIcon } from '@chakra-ui/icons'
import {
  Button, forwardRef, IconButtonProps, Text,
} from '@chakra-ui/react'
import React from 'react'

export const AddItemButton = forwardRef(
  ({ itemName, ...props }: Omit<IconButtonProps, 'aria-label'> & { itemName: string }, ref) => (
    <Button
      ref={ref}
      border="1px solid gray"
      height="32px"
      width="auto"
      variant="ghost"
      aria-label={`Add ${itemName}`}
      borderRadius={0}
      bg='#efefef'
      ml={2}
      size="sm"
      gap={1}
      colorScheme="gray"
      {...props}
    >
      <AddIcon w={3} />
      <Text>{`NEW ${itemName.toUpperCase()}`}</Text>
    </Button>
  ),
)
