import {
  Box, Center, IconButton, IconButtonProps,
} from '@chakra-ui/react'
import React, { ReactElement } from 'react'

export const SwapIconButton = ({
  icon,
  swapIcon,
  size,
  onClick,
  isSwapped,
  ...rest
}: Omit<IconButtonProps, 'size'> & {
  swapIcon: ReactElement
  size: number
  isSwapped: boolean
}) => (
  <IconButton
    onClick={onClick}
    minW='0'
    minH='0'
    width={`${size}px`}
    height={`${size}px`}
    transition="all 300ms"
    icon={
      <Center width={`${size}px`} height={`${size}px`}>
        <Box
          transform={
            isSwapped
              ? 'translate(60%, 60%) rotate(45deg)'
              : 'translate(0,0): rotate(0deg)'
          }
          transition="all 300ms"
          opacity={isSwapped ? 0 : 1}
        >
          {icon}
        </Box>
        <Center
          transform={
            isSwapped
              ? 'translate(0,0): rotate(0deg)'
              : 'translate(60%, 60%) rotate(45deg)'
          }
          transition="all 300ms"
          position='absolute'
          w='100%'
          h='100%'
          left={0}
          top={0}
          opacity={isSwapped ? 1 : 0}
        >
          {swapIcon}
        </Center>
      </Center>
    }
    {...rest}
  />
)
