import { AddIcon, CloseIcon } from '@chakra-ui/icons'
import {
  Box,
  CircularProgress,
  Flex,
  HStack,
  IconButton,
  Stack,
  StackProps,
  Text,
  Tooltip,
  VStack,
} from '@chakra-ui/react'
import {
  ASSESSMENTS,
  ASSESSMENTS_ADMIN,
  capitalizeFirstLetter,
  Fax,
  FieldMap,
  FileDBValue,
  FileField,
  getPatientFileField,
  medicaidCoverageField,
  objectToArray,
  primaryCoverageField,
  UserGroup,
  userGroupToFileIdPrefix,
} from '@hb/shared'
import {
  deleteField,
  DocumentReference,
  query,
  setDoc,
  updateDoc,
  where,
} from 'firebase/firestore'
import { set } from 'nested-property'

import React, {
  useCallback, useContext, useMemo, useState,
} from 'react'
import { combineFilesIntoPdf, deleteFile } from '../../../../backend'
import {
  FAX_RECEIVED_REF,
  FAX_SENT_REF,
} from '../../../../collections/collections'
import { useApp } from '../../../../contexts/AppContext'
import { PopUpMessageContext } from '../../../../contexts/PopUpMessage/PopUpMessageContext'
import { ScreenContext } from '../../../../contexts/ScreenContext'
import { ThemeContext } from '../../../../contexts/ThemeContext'
import { UserContext } from '../../../../contexts/UserContext'
import { useQuery } from '../../../../hooks/backend/useQuery'
import { useUpdateDoc } from '../../../../hooks/backend/useUpdateDoc'
import { addMetadata } from '../../../../utils'
import { InsuranceCardsView } from '../../../Assessments/InsuranceCardsView'
import { ActionButton, SolidActionButton } from '../../../Buttons'
import { Expandable } from '../../../Expandable'
import { FaxPreview } from '../../../Faxes/FaxView'
import { EditableFileView } from '../../../forms'
import { Loading } from '../../../Loading'
import { CollapseHorizontal } from '../../../shared'
import { BoxHeader } from '../../../Text/BoxHeader'
import { RecoverAssessmentFiles } from './RecoverAssessmentFiles'

const AssessmentFaxes = () => {
  const { assessmentId } = useContext(UserContext)
  const sentFaxesQuery = useMemo(
    () => (assessmentId
      ? query(FAX_SENT_REF, where('assessmentId', '==', assessmentId))
      : null),
    [assessmentId],
  )
  const receivedFaxesQuery = useMemo(
    () => (assessmentId
      ? query(FAX_RECEIVED_REF, where('assessmentId', '==', assessmentId))
      : null),
    [assessmentId],
  )
  const { data: sentFaxes, loading: loadingSentFaxes } = useQuery<Fax>(sentFaxesQuery)
  const { data: receivedFaxes, loading: loadingReceivedFaxes } = useQuery<Fax>(receivedFaxesQuery)
  const sortedByDate = useMemo(() => {
    const arr = [
      ...objectToArray(sentFaxes || {}),
      ...objectToArray(receivedFaxes || {}),
    ]
    return arr.sort((a, b) => {
      const aDate = new Date(a.created_at).getTime()
      const bDate = new Date(b.created_at).getTime()
      if (!aDate || !bDate) return 0
      return bDate - aDate
    })
  }, [sentFaxes, receivedFaxes])

  return (
    <Expandable
      initExpanded
      borderRadius={6}
      boxShadow="md"
      px={2}
      py={1}
      bg="white"
      header={() => <BoxHeader>Faxes</BoxHeader>}
    >
      {loadingReceivedFaxes || loadingSentFaxes ? (
        <HStack>
          <CircularProgress isIndeterminate /> <Text>Loading faxes...</Text>
        </HStack>
      ) : (
        <VStack align="flex-start" py={2}>
          {sortedByDate?.length ? (
            sortedByDate.map((fax) => <FaxPreview key={fax.id} fax={fax} />)
          ) : (
            <Text fontStyle="italic" color="gray.600" px={2}>
              No faxes yet
            </Text>
          )}
        </VStack>
      )}
    </Expandable>
  )
}

const FilesListView = ({
  files,
  docRef,
  stackProps,
  mergeLoading,
  mergingFileIds,
  onFileSelect,
}: {
  files?: Record<string, FileDBValue>
  docRef: DocumentReference
  stackProps?: StackProps
  mergeLoading?: boolean
  mergingFileIds?: Array<string> | null
  onFileSelect?: (fileId: string, selected: boolean) => void
}) => {
  const { assessmentId } = useContext(UserContext)

  const fields = useMemo<Record<string, FileField> | null>(() => {
    if (!assessmentId) {
      return null
    }
    const children: Record<string, FileField> = {}
    if (files) {
      Object.keys(files).forEach((key) => {
        if (files[key]) {
          children[key] = getPatientFileField(key)
        }
      })
    }
    return children
  }, [files, assessmentId])

  const [isAddingFile, setIsAddingFile] = useState(false)

  const newFileId = useMemo(
    () => (isAddingFile ? `${Date.now()}` : null),
    [isAddingFile],
  )
  // const fieldKeys = useMemo(() => Object.keys(fields || {}), [fields])
  const { appName } = useApp()

  const handleFileUpload = useCallback(
    (key: string, data: any) => setDoc(
      docRef,
      { files: { [key]: addMetadata(data, appName, true) } },
      { merge: true },
    )
      .then(() => {
        setIsAddingFile(false)
        return { success: 'File uploaded' }
      })
      .catch((err) => ({
        error: err?.message || 'Error occurred uploading file',
      })),
    [setIsAddingFile, docRef, appName],
  )

  const newFileField = useMemo(
    () => (newFileId && assessmentId
      ? getPatientFileField(newFileId)
      : null),
    [newFileId, assessmentId],
  )
  const fileKeys = useMemo(
    () => Object.keys(files || {}).sort(
      (a, b) => new Date(files?.[a].uploadedOn || 0).getTime()
          - new Date(files?.[b].uploadedOn || 0).getTime(),
    ),
    [files],
  )

  const filePrefix = useMemo(() => {
    const [baseCollection] = docRef.path.split('/')
    switch (baseCollection) {
      case ASSESSMENTS:
        return `${ASSESSMENTS}/${assessmentId}/shared-files`
      case 'midwives':
        return `${ASSESSMENTS}/${assessmentId}/practice-files`
      case ASSESSMENTS_ADMIN:
        return `${ASSESSMENTS}/${assessmentId}/admin-files`
      default:
        return docRef.path
    }
  }, [docRef, assessmentId])

  const { isMobile } = useContext(ScreenContext)
  const handleDeleteFile = useCallback(
    async (key: string) => {
      if (docRef) {
        if (filePrefix) {
          const resizedFilePath = `${filePrefix}/resized_${key}`
          await deleteFile(resizedFilePath).catch(() => {})
          const originalFilePath = `${filePrefix}/${key}`
          await deleteFile(originalFilePath).catch(() => {})
        }
        await updateDoc(docRef, `files.${key}`, deleteField())
        return { success: 'File deleted' }
      }
      return { error: 'Unknown error' }
    },
    [docRef, filePrefix],
  )

  return (
    <VStack bg="gray.50" align="flex-start" p={2} {...stackProps}>
      {fileKeys.length || isAddingFile ? (
        fileKeys.map((k) => (fields?.[k] ? (
            <Box bg={isMobile ? 'white' : 'transparent'} boxShadow={isMobile ? '1px 1px 4px #00000077' : 'none'} borderRadius={6} w="100%" key={k} position="relative">
              <EditableFileView
                baseStoragePath={`${filePrefix}/${k}`}
                fieldPathSegments={[]}
                value={files?.[k]}
                stackProps={{ opacity: files?.[k].uploadedOn ? 1 : 0.7 }}
                onSubmit={(v) => (v ? handleFileUpload(k, v) : handleDeleteFile(k))
                }
                selectedIndex={mergingFileIds?.indexOf(k)}
                onSelect={
                  onFileSelect
                    ? (selected) => {
                      onFileSelect(k, selected)
                    }
                    : undefined
                }
                field={fields[k]}
              />
              {files?.[k].combiningIntoPdf || (mergingFileIds && mergingFileIds.includes(k) && mergeLoading) ? (
                <Flex
                  position="absolute"
                  top={0}
                  left={0}
                  right={0}
                  borderRadius={6}
                  bottom={0}
                  bg="rgba(255,255,255,0.5)"
                  justify="center"
                  align="center"
                >
                  <Loading text="Combining into pdf" />
                </Flex>
              ) : null}
            </Box>
        ) : null))
      ) : (
        <Text fontStyle="italic" px={2} color="gray.600">
          No files yet
        </Text>
      )}
      <Box pt={2} w="100%">
        {newFileField && newFileId ? (
          <HStack spacing={0} borderTop="1px solid #cdcdcd" w="100%">
            <EditableFileView
              focusOnMount
              baseStoragePath={`${filePrefix}/${newFileId}`}
              fieldPathSegments={[]}
              onSubmit={async (v) => {
                if (v) return handleFileUpload(newFileId, v)
                setIsAddingFile(false)
                return { success: 'cancelled' }
              }}
              stackProps={{ width: 'auto', flex: 1 }}
              field={newFileField}
            />
            <Tooltip placement="top" hasArrow label="Cancel">
              <IconButton
                aria-label="Cancel"
                icon={<CloseIcon />}
                onClick={() => setIsAddingFile(false)}
                size="xs"
              />
            </Tooltip>
          </HStack>
        ) : (
          <ActionButton
            bg="white"
            onClick={() => setIsAddingFile(true)}
            size="sm"
            gap={1}
          >
            <AddIcon w={3} />
            <Text>Upload New File</Text>
          </ActionButton>
        )}
      </Box>
    </VStack>
  )
}

const AssessmentFilesListView = ({
  docRef,
  files,
  access,
  assessmentId,
  canMerge,
}: {
  docRef: DocumentReference
  files: Record<string, FileDBValue> | undefined
  access: UserGroup
  assessmentId: string
  canMerge?: boolean
}) => {
  const [selectingForMerge, setSelectingForMerge] = useState(false)
  const [selectedFileIds, setSelectedFileIds] = useState<string[] | null>(null)
  const { processResponse } = useContext(PopUpMessageContext)
  const [expanded, setExpanded] = useState(true)

  const onCancelMerge = useCallback(() => {
    setSelectedFileIds(null)
    setSelectingForMerge(false)
  }, [])

  const onMergeStart = useCallback(() => {
    setSelectedFileIds([])
    setSelectingForMerge(true)
  }, [])

  const { appName } = useApp()

  const [mergingFiles, setMergingFiles] = useState(false)

  const onMergeConfirm = useCallback(async () => {
    if (selectedFileIds?.length) {
      const filePrefix = userGroupToFileIdPrefix(access)
      setMergingFiles(true)
      try {
        await combineFilesIntoPdf({
          assessmentId,
          fileIds: selectedFileIds.map((fId) => `${filePrefix}.${fId}`),
          appName,
          fileName: 'Combined Files',
        })
      } catch (err: any) {
        processResponse({ error: err.message })
      } finally {
        setMergingFiles(false)
        setSelectedFileIds(null)
        setSelectingForMerge(false)
      }
    } else {
      processResponse({ error: 'No files selected' })
    }
  }, [selectedFileIds, access, assessmentId, appName, processResponse])

  return (
    <Expandable
      borderRadius={6}
      initExpanded
      boxShadow="md"
      bg="white"
      closeCallback={() => {
        onCancelMerge()
        setExpanded(false)
      }}
      openCallback={() => setExpanded(true)}
      header={() => (
        <BoxHeader pr={2} w="100%">
          <HStack w="100%">
            <Text pl={2}>{capitalizeFirstLetter(access)} Files</Text>
            {canMerge && expanded ? (
              <Flex ml="auto" align="center">
                <CollapseHorizontal in={!selectingForMerge} width={150}>
                  <ActionButton
                    px={3}
                    size="xs"
                    onClick={(e) => {
                      e.stopPropagation()
                      onMergeStart()
                    }}
                  >
                    <Text>Combine files into PDF</Text>
                  </ActionButton>
                </CollapseHorizontal>
                <CollapseHorizontal in={selectingForMerge} width={210}>
                  <ActionButton
                    size="xs"
                    colorScheme="gray"
                    isDisabled={mergingFiles}
                    onClick={(e) => {
                      e.stopPropagation()
                      onCancelMerge()
                    }}
                  >
                    Cancel
                  </ActionButton>
                  <Tooltip
                    label={
                      selectedFileIds && selectedFileIds.length < 2
                        ? 'Select multiple files to merge'
                        : ''
                    }
                    hasArrow
                  >
                    <SolidActionButton
                      ml={2}
                      size="xs"
                      isLoading={mergingFiles}
                      opacity={(selectedFileIds?.length || 0) > 1 ? 1 : 0.7}
                      onClick={(e) => {
                        e.stopPropagation()
                        onMergeConfirm()
                      }}
                    >
                      Combine Selected
                    </SolidActionButton>
                  </Tooltip>
                </CollapseHorizontal>
              </Flex>
            ) : null}
          </HStack>
        </BoxHeader>
      )}
    >
      <FilesListView
        stackProps={{ borderBottomRadius: 6 }}
        docRef={docRef}
        onFileSelect={
          selectingForMerge
            ? (fileId, selected) => {
              setSelectedFileIds((prev) => {
                if (selected) {
                  return prev ? [...prev, fileId] : [fileId]
                }
                return prev?.filter((f) => f !== fileId) || null
              })
            }
            : undefined
        }
        mergingFileIds={selectedFileIds}
        mergeLoading={mergingFiles}
        files={files}
      />
    </Expandable>
  )
}

const getMedicaidCoverageCardsField = (initExpanded: boolean): FieldMap => ({
  ...(medicaidCoverageField.children.insuranceCard as FieldMap),
  name: 'Medicaid Coverage',
  initExpanded,
})

export const AssessmentFiles = () => {
  const { selectedAssessment, assessmentId } = useContext(UserContext)

  const { appName } = useApp()
  const {
    mergedData, adminRef, ref, practiceRef, adminFiles, practiceFiles,
  } = selectedAssessment || {}
  const { primaryCoverage, medicaidCoverage } = mergedData?.['insurance-info'] || {}

  const update = useUpdateDoc()

  const handleInsuranceCardUpload = useCallback(
    async (key: string, data: any, isNew: boolean) => {
      if (ref) {
        const m = {}
        set(m, key, data)
        return update(
          ref,
          `corrections.${key}`,
          typeof data === 'object' && data !== null
            ? addMetadata(data, appName, isNew)
            : data,
        )
      }
      return { error: 'Unknown error' }
    },
    [update, ref, appName],
  )

  const primaryCoverageInsuranceCardField = useMemo<FieldMap>(
    () => ({
      ...(primaryCoverageField.children.insuranceCard as FieldMap),
      name: 'Primary Coverage',
      initExpanded: !!primaryCoverage?.insuranceCard,
    }),
    [primaryCoverage],
  )

  const medicaidCardsField = useMemo(
    () => getMedicaidCoverageCardsField(!!medicaidCoverage?.insuranceCard),
    [medicaidCoverage],
  )

  return (
    <ThemeContext.Provider value={{ placeholderAbove: false }}>
      <Stack spacing={4} bg="gray.100">
        <InsuranceCardsView />
        {appName === 'app' && adminRef && assessmentId ? (
          <AssessmentFilesListView
            docRef={adminRef}
            assessmentId={assessmentId}
            files={adminFiles}
            access="admin"
            canMerge
          />
        ) : null}
        {practiceRef && assessmentId && selectedAssessment?.midwifeId ? (
          <AssessmentFilesListView
            docRef={practiceRef}
            assessmentId={assessmentId}
            files={practiceFiles}
            access="practice"
            canMerge
          />
        ) : null}
        {appName === 'app' ? (
          <>
            <AssessmentFaxes />
            <RecoverAssessmentFiles />
          </>
        ) : null}
      </Stack>
    </ThemeContext.Provider>
  )
}
