import React, {
  JSXElementConstructor,
  ReactElement,
} from 'react'
import { DefaultModal, DefaultModalProps } from '../Modals/DefaultModal'
import { EditorSize, useEditorWidth } from './hooks'

export const EditorModalContainer = ({
  render,
  contentProps,
  bodyProps,
  height,
  ...props
}: Omit<DefaultModalProps, 'render'> & {
  render: (
    editorSize: EditorSize,
    onBack: () => void
  ) => ReactElement<any, string | JSXElementConstructor<any>>
  height?: number
}) => {
  const editorSize = useEditorWidth(undefined, height)

  return (
    <DefaultModal
      isCentered={false}
      closeOnEsc={false}
      closeOnOverlayClick={false}
      overlayHeader
      size="4xl"
      closeDisabled
      render={() => render(editorSize, props.onClose)}
      contentProps={{
        p: 0,
        width: 'auto',
        maxW: 'unset',
        height: 'auto',
        maxH: 'unset',
        ...contentProps,
      }}
      bodyProps={{ p: 0, style: { width: 'auto', height: 'auto', ...bodyProps?.style }, ...bodyProps }}
      {...props}
    />
  )
}
