import {
  IconButton, IconButtonProps, Tooltip, TooltipProps,
} from '@chakra-ui/react'
import React from 'react'

export const CoolTooltip = (props: TooltipProps) => (
  <Tooltip
    // @ts-ignore
    bg='blackAlpha.700'
    placement='top'
    hasArrow
    fontSize='md'
    fontFamily='Hero-New'
    {...props}
  />
)

export const TooltipIconButton = ({
  label,
  ...props
}: Omit<IconButtonProps, 'aria-label'> & { label: string }) => (
  <CoolTooltip
    label={label}
  >
    <IconButton
    // @ts-ignore
      boxShadow='md'
      aria-label={label}
      size='sm'
      bg='blackAlpha.200'
      color='blackAlpha.700'
      _hover={{ bg: 'blackAlpha.400' }}
      opacity={0.8}
      {...props}
    />
  </CoolTooltip>
)
