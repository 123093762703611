import { DocData } from '@hb/shared'
import {
  Field,
  FieldMap,
  FieldMapValue,
  ListField,
  UploadProgress,
} from '@hb/shared/types'
import { isField } from '@hb/shared/utils'
import { FormApi, FORM_ERROR, ValidationErrors } from 'final-form'
import { useCallback, useContext } from 'react'
import { PopUpMessageContext } from '../../../../contexts/PopUpMessage/PopUpMessageContext'

export const useFormSubmit = <T extends DocData>(
  field: Field | FieldMap | ListField,
  onSubmit: (
    submitted: any,
    onUploadProgress: (progress: Record<string, UploadProgress>) => void,
    form: FormApi<T>
  ) => Promise<ValidationErrors>,
  setUploads: (updated: Record<string, UploadProgress> | null) => void,
) => {
  const { processResponse } = useContext(PopUpMessageContext)
  const onUploadProgress = useCallback(
    (updated: Record<string, UploadProgress>) => {
      setUploads({ ...updated })
    },
    [setUploads],
  )
  const submit = useCallback(
    async (updated: FieldMapValue, form: FormApi<T>) => {
      try {
        const updatedValue = isField(field) ? updated.value : updated
        const res = await onSubmit(updatedValue, onUploadProgress, form)
        if (!res) setUploads(null)
        return res
      } catch (e: any) {
        console.error(e)
        processResponse({ error: e?.message || 'An error occurred' })
        return { [FORM_ERROR]: e?.message || 'An error occurred' }
      }
    },
    [field, onUploadProgress, onSubmit, processResponse, setUploads],
  )

  return submit
}
