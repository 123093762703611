import { capitalizeFirstLetter, nameField, UpdateCallback } from '@hb/shared'
import React, { useMemo } from 'react'
import { GenericEditModal } from '../../DataView'

type NameData = {
  fname?: string
  lname?: string
  nickname?: string
}
export const NameEditModal = ({
  isOpen,
  onClose,
  userType,
  value,
  onSubmit,
}: {
  isOpen: boolean
  onClose: () => void
  userType: string
  value?: NameData | null
  onSubmit: (data: NameData) => Promise<UpdateCallback>
}) => {
  const field = useMemo(
    () => ({
      name: `${capitalizeFirstLetter(userType)} name`,
      children: nameField.children,
    }),
    [userType],
  )
  return (
    <GenericEditModal
      onClose={onClose}
      isOpen={isOpen}
      data={value || undefined}
      onSubmit={(data) => onSubmit({
        fname: data.fname || '',
        lname: data.lname || '',
        nickname: data.nickname || '',
      })
      }
      field={field}
    />
  )
}
