import React from 'react'
import { MidwifeInfo } from '../Practices/MidwifeInfo'
import { DefaultModal } from './DefaultModal'

export const PracticeViewModal = ({
  onClose, practiceId,
}: {
  onClose: () => void
  practiceId: string
}) => (
  practiceId
    ? <DefaultModal
    overlayHeader
    onClose={onClose}
    size='3xl'
    isOpen
    render={() => (
      <MidwifeInfo id={practiceId}/>
    )}
  /> : null
)
