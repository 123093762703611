import { MessageThread, PopulatedThreadMessage, ThreadType } from '@hb/shared/types'
import { DocumentReference } from 'firebase/firestore'
import { createContext } from 'react'

type ThreadViewContextData = {
  threadId: string
  threadType: ThreadType
  threadRef: DocumentReference<MessageThread> | null
  isOpen: boolean
  thread: MessageThread | null
  onBack: () => void
  handleMessageSelect: (messageId: string, fetchAll?: boolean) => void
  addingMembers: boolean
  setAddingMembers: (adding: boolean) => void
  messageListHeight: number
  bodyHeight: number
  replyingTo: PopulatedThreadMessage | null
  setReplyingTo: (replyingTo: PopulatedThreadMessage | null) => void
  editingMessage: PopulatedThreadMessage | null
  setEditingMessage: (editingMessage: PopulatedThreadMessage | null) => void
}

export const ThreadViewContext = createContext<ThreadViewContextData>({
  threadId: '',
  threadType: ThreadType.GROUP,
  handleMessageSelect: () => {},
  threadRef: null,
  thread: null,
  onBack: () => {},
  isOpen: false,
  addingMembers: false,
  setAddingMembers: () => {},
  messageListHeight: 0,
  bodyHeight: 0,
  replyingTo: null,
  setReplyingTo: () => {},
  editingMessage: null,
  setEditingMessage: () => {},
})
