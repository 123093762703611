import { Box, Center, Text } from '@chakra-ui/react'
import React, { PropsWithChildren, useContext, useState } from 'react'
import { UserContext } from '../../../contexts/UserContext'

export const SelectAssessmentMessage = () => (
  <Text p={4} fontSize='lg' fontWeight={500} color='gray.600'>
    Select an assessment...
  </Text>
)

export const SelectAssessmentBox = ({
  children,
  overlay,
}: PropsWithChildren<{overlay?: boolean}>) => {
  const { selectedAssessment } = useContext(UserContext)
  const [hovered, setHovered] = useState(false)
  return (
    <Box
      width='100%'
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
      pos='relative'
    >
      {children}
      <Center
        pointerEvents={selectedAssessment ? 'none' : 'auto'}
        opacity={!selectedAssessment && (overlay || hovered) ? 1 : 0}
        transition='opacity 333ms ease'
        top='0'
        left='0'
        pos='absolute'
        w='full'
        h='full'
        bg='whiteAlpha.900'
      >
        <SelectAssessmentMessage />
      </Center>
    </Box>
  )
}
