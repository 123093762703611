import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  HStack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  VStack,
} from '@chakra-ui/react'
import {
  ASSESSMENTS,
  colors,
  makeAllFieldsOptional,
  Note,
  signOnFields,
} from '@hb/shared'
import React, {
  useCallback, useContext, useMemo, useState,
} from 'react'
import { useApp } from '../../contexts/AppContext'
import { PopUpMessageContext } from '../../contexts/PopUpMessage/PopUpMessageContext'
import { ScreenContext } from '../../contexts/ScreenContext'
import { UserContext } from '../../contexts/UserContext'
import { useUpdateDoc } from '../../hooks/backend/useUpdateDoc'
import { addMetadata } from '../../utils'
import { DataView } from '../DataView/DataView'
import { NoteForm } from '../forms/NoteForm'
import { AssessmentPayments } from '../Users/Profile/Payments/AssessmentPayments'
import { SkipToSignOn } from '../Users/Profile/SkipToSignOn'
import { AssessmentFaxes } from './AssessmentFaxes'
import { AssessmentModal } from './AssessmentModal'
import { AssessmentPreview } from './AssessmentPreview'
import { InsuranceCardsView } from './InsuranceCardsView'
import { InsurancePlansView } from './InsurancePlansView'

const ArchivedAssessments = () => {
  const {
    assessmentId, selectAssessment, user, assessments,
  } = useContext(UserContext)
  const archivedAssessments = useMemo(
    () => Object.entries(assessments || {}).filter(
      ([id, val]) => !!val.archivedOn && id !== assessmentId,
    ),
    [assessments, assessmentId],
  )
  return user ? (
    <Accordion allowMultiple defaultIndex={[]} width="100%">
      <AccordionItem>
        <h2>
          <AccordionButton>
            <Box as="span" flex="1" textAlign="left">
              <Text>
                {`Archived assessments (${archivedAssessments.length})`}
              </Text>
            </Box>
            <AccordionIcon />
          </AccordionButton>
        </h2>
        <AccordionPanel pb={4}>
          {archivedAssessments.map(([id, assessment]) => (
            <AssessmentPreview
              id={id}
              key={id}
              buttonText={id === assessmentId ? 'Selected' : 'Select'}
              onSelect={() => selectAssessment(id)}
              user={user}
              assessment={assessment}
            />
          ))}
        </AccordionPanel>
      </AccordionItem>
    </Accordion>
  ) : null
}

const AssessmentNote = () => {
  const { selectedAssessment } = useContext(UserContext)
  const { practiceRef, adminRef, assessmentNote } = selectedAssessment || {}
  const { appName } = useApp()
  const update = useUpdateDoc('assessment note')
  const { processResponse } = useContext(PopUpMessageContext)
  const handleSubmit = useCallback(
    async (data: Note) => {
      if (!selectedAssessment) return processResponse({ error: 'No assessment selected' })
      const { text } = data
      const ref = appName === 'app' ? adminRef : practiceRef
      if (!ref) return processResponse({ error: 'No assessment selected' })
      const res = await update(
        ref,
        'assessmentNote',
        addMetadata({ text }, appName, !assessmentNote),
      )
      if (res.error) return processResponse({ error: res.error })
      return { success: 'Note saved' }
    },
    [
      selectedAssessment,
      appName,
      practiceRef,
      adminRef,
      assessmentNote,
      update,
      processResponse,
    ],
  )
  return (
    <NoteForm
      onSubmit={handleSubmit}
      note={selectedAssessment?.assessmentNote}
    />
  )
}
const AssessmentsView = () => {
  const {
    assessments,
    user,
    selectAssessment,
    assessmentId,
    selectedAssessment,
    admin,
  } = useContext(UserContext)
  const notSelected = useMemo(
    () => Object.entries(assessments || {}).filter(([id]) => id !== assessmentId),
    [assessments, assessmentId],
  )
  const notArchived = useMemo(
    () => notSelected.filter(([, val]) => !val.archivedOn),
    [notSelected],
  )

  const [assessmentModalIsOpen, setAssessmentModalIsOpen] = useState(false)
  return user ? (
    <VStack spacing={1} width="100%">
      {assessmentId ? (
        <>
          <Text fontWeight="semibold" color="gray.500">
            Selected assessment
          </Text>
          <AssessmentPreview
            id={assessmentId}
            selected
            buttonText="Open"
            onSelect={() => {
              setAssessmentModalIsOpen(true)
            }}
            user={user}
            assessment={selectedAssessment}
          />
          <AssessmentNote />
        </>
      ) : null}

      <Text fontWeight="semibold" color="gray.500">
        {assessmentId ? 'Other assessments' : 'Assessments'}
      </Text>
      {notArchived.map(([id, assessment]) => (
        <AssessmentPreview
          id={id}
          key={id}
          selected={id === assessmentId}
          buttonText={id === assessmentId ? 'Open' : 'Select'}
          onSelect={() => {
            if (id === assessmentId) setAssessmentModalIsOpen(true)
            else selectAssessment(id)
          }}
          user={user}
          assessment={assessment}
        />
      ))}
      <ArchivedAssessments />

      {user && assessmentModalIsOpen ? (
        <AssessmentModal
          assessment={selectedAssessment}
          admin={admin}
          user={user}
          id={assessmentId}
          isOpen={assessmentModalIsOpen}
          onClose={() => setAssessmentModalIsOpen(false)}
        />
      ) : null}
    </VStack>
  ) : (
    <Text color="red.500">Error: no patient</Text>
  )
}

export const AssessmentSummary: React.FC = () => {
  const { selectedAssessment, assessmentId } = useContext(UserContext)
  const {
    signOnData,
    signOnCorrections,
    ref,
    insuranceCoverage,
    selectedCoverage,
  } = selectedAssessment || {}
  const { appName } = useApp()

  const { memberId, planDesignText } = selectedCoverage || {}

  const update = useUpdateDoc('Sign on data')
  const optionalSignOnFields = useMemo(
    () => makeAllFieldsOptional(signOnFields),
    [],
  )

  const { isMobile } = useContext(ScreenContext)
  const tabFontSize = useMemo(() => (isMobile ? 'xs' : 'sm'), [isMobile])
  const [tabIndex, setTabIndex] = useState(0)
  return (
    <Box w="100%">
      <Tabs
        onChange={setTabIndex}
        index={tabIndex}
        size="sm"
        isLazy
        variant="soft-rounded"
      >
        <TabList px={4}>
          {appName === 'app' ? (
            <Tab
              mr={3}
              background="white"
              fontSize={tabFontSize}
              _selected={{ background: colors.pink.hex, color: '#333' }}
              shadow="md"
            >
              ASSESSMENT
            </Tab>
          ) : (
            <Tab
              mr={3}
              background="white"
              fontSize={tabFontSize}
              _selected={{ background: colors.pink.hex, color: '#333' }}
              shadow="md"
            >
              INSURANCE INFO
            </Tab>
          )}
          {appName === 'app' ? (
            <Tab
              background="white"
              mr={3}
              fontSize={tabFontSize}
              _selected={{ background: colors.pink.hex, color: '#333' }}
              shadow="md"
            >
              SIGN ON INFO
            </Tab>
          ) : (
            <Tab
              mr={3}
              background="white"
              fontSize={tabFontSize}
              _selected={{ background: colors.pink.hex, color: '#333' }}
              shadow="md"
            >
              ASSESSMENT
            </Tab>
          )}
          {appName === 'providers-app' ? (
            <Tab
              mr={3}
              background="white"
              fontSize={tabFontSize}
              _selected={{ background: colors.pink.hex, color: '#333' }}
              shadow="md"
            >
              PAYMENTS
            </Tab>
          ) : null}
          <Tab
            background="white"
            fontSize={tabFontSize}
            _selected={{ background: colors.pink.hex, color: '#333' }}
            shadow="md"
          >
            INSURANCE PLANS
          </Tab>
        </TabList>
        <TabPanels>
          {appName === 'app' ? (
            <TabPanel>
              <AssessmentsView />
            </TabPanel>
          ) : (
            <TabPanel>
              <VStack align="flex-start" w="100%">
                <HStack
                  px={3}
                  w="100%"
                  bg="white"
                  py={2}
                  color={colors.green.hex}
                  border={`1px solid ${colors.green.hex}`}
                  fontFamily="Comfortaa"
                  borderRadius={6}
                  boxShadow="md"
                >
                  <span style={{ marginRight: '4px', fontWeight: 600 }}>
                    {`${insuranceCoverage || '(No insurer set)'} Member ID `}
                  </span>
                  <span style={{ fontFamily: 'Hero-New' }}>
                    {typeof memberId === 'string' ? memberId || 'None' : 'None'}
                    {planDesignText ? ` - ${planDesignText}` : ''}
                  </span>
                </HStack>
                <Box w="100%" borderRadius={4} p={2} bg="white" boxShadow="md">
                  <DataView
                    initExpanded
                    withEditModal
                    childrenExpanded
                    onSubmit={(data) => update(
                      ref,
                      'signOnCorrections',
                      addMetadata(data, appName, !signOnCorrections),
                    )
                    }
                    baseStoragePath={`${ASSESSMENTS}/${assessmentId}/signOnCorrections`}
                    updateField={(path, value) => update(ref, `signOnCorrections.${path}`, value)
                    }
                    field={optionalSignOnFields}
                    corrections={signOnCorrections}
                    data={signOnData}
                  />
                </Box>
                <InsuranceCardsView />
              </VStack>
            </TabPanel>
          )}
          {appName === 'app' ? (
            <TabPanel>
              <VStack w="100%">
                <Box w="100%" borderRadius={4} p={2} bg="white" boxShadow="md">
                  <DataView
                    initExpanded
                    withEditModal
                    childrenExpanded
                    onSubmit={(data) => update(
                      ref,
                      'signOnCorrections',
                      addMetadata(data, appName, !signOnCorrections),
                    )
                    }
                    baseStoragePath={`${ASSESSMENTS}/${assessmentId}/signOnCorrections`}
                    updateField={(path, value) => update(ref, `signOnCorrections.${path}`, value)
                    }
                    field={optionalSignOnFields}
                    corrections={signOnCorrections}
                    data={signOnData}
                  />
                  <SkipToSignOn />
                </Box>
                <InsuranceCardsView />
                <AssessmentFaxes />
              </VStack>
            </TabPanel>
          ) : (
            <TabPanel>
              <AssessmentsView />
            </TabPanel>
          )}
          {appName === 'providers-app' ? (
            <TabPanel>
              <AssessmentPayments />
            </TabPanel>
          ) : null}
          <TabPanel>
            <InsurancePlansView />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Box>
  )
}
