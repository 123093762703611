import { getAppRole, View } from '@hb/shared'
import { useMemo } from 'react'
import { useApp, usePracticeAccess } from '../contexts'
import { useAuth } from '../store'

export const useFilteredViews = (views: View[]) => {
  const app = useApp()

  const { selectedPracticeId } = usePracticeAccess()
  const claims = useAuth((s) => s.claims)
  const authUser = useAuth((s) => s.authUser)
  const appRole = useMemo(
    () => getAppRole(
      app.appName,
      authUser?.uid || null,
      claims,
      selectedPracticeId,
    ),
    [app, claims, selectedPracticeId, authUser],
  )
  return useMemo<View[]>(
    () => views.reduce((prev, curr) => {
      const hasAccess = !curr.access || (appRole && curr.access.indexOf(appRole) !== -1)

      return hasAccess || (!authUser && curr.signIn)
        ? [...prev, { ...curr, onMenu: curr.onMenu && hasAccess === true }]
        : prev
    }, [] as View[]),
    [appRole, views, authUser],
  )
}
