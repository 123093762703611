import { Path } from 'slate'
import { Descendant, isText } from '../editor/types'
import { TemplateNodeField } from '../types/forms/shortcut'

const getUnusedId = (fields: Record<string, TemplateNodeField>, id: string) => {
  let idIdx = 1
  let newId = `${id}-${idIdx}`
  while (fields[newId]) {
    idIdx += 1
    newId = `${id}-${idIdx}`
  }
  return { id: newId, idx: idIdx }
}

export const getFieldsFromText = (
  text: Descendant[],
  basePath: Path = [],
  existing: Record<string, TemplateNodeField> = {},
) => {
  if (!text?.length) return {}
  const fields = text.reduce(
    (
      acc,
      node,
      nodeIdx,
    ) => {
      if (isText(node)) return acc
      if (node.type === 'field') {
        const { id, idx } = getUnusedId(acc, node.id)
        acc[id] = {
          ...node.field,
          path: [...basePath, nodeIdx],
          id,
          placeholder: `${node.field.placeholder} ${idx}`,
        } as TemplateNodeField
      } else if (node.type === 'span') {
        const spanFields = getFieldsFromText(
          node.children,
          [...basePath, nodeIdx],
          acc,
        )
        Object.assign(acc, spanFields)
      }
      return acc
    },
    existing,
  )
  return fields
}
