import { DateField, InputElement, InputProps } from '@hb/shared/types'
import React, { forwardRef } from 'react'
import { NativeDateInput } from './NativeDateInput'
import { PopoverDateInput } from './PopoverDateInput'

export const DateInput: InputElement<DateField> = forwardRef<
  { focus:() => void },
  InputProps<DateField>
    >((props, ref) => (props.field.nativeInput ? (
      <NativeDateInput {...props} ref={ref} />
    ) : (
      <PopoverDateInput {...props} ref={ref} />
    )))
