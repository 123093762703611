import { useEffect, useRef, useState } from 'react'

export const useTimeout = (
  initialValue: any | null = null,
  duration: number = 4000,
) => {
  const [value, setValue] = useState<any | null>(initialValue)
  const ref = useRef<any>()
  useEffect(() => {
    if (ref.current) clearTimeout(ref.current)
    if (value !== undefined) {
      ref.current = setTimeout(() => {
        setValue(null)
      }, duration)
    }

    return () => {
      if (ref.current) clearTimeout(ref.current)
    }
  }, [value, duration])

  return [value, setValue]
}

export const useLoadingTimeout = (loading: boolean, duration: number = 500) => {
  const [mountedOn] = useState(Date.now())
  const [delayedLoading, setDelayedLoading] = useState(loading)
  useEffect(() => {
    if (loading) {
      setDelayedLoading(true)
      return () => {}
    }
    const t = setTimeout(() => {
      setDelayedLoading(false)
    }, Math.max(duration, Date.now() - mountedOn))

    return () => {
      clearTimeout(t)
    }
  }, [loading, setDelayedLoading, duration, mountedOn])
  return delayedLoading
}
