import { CheckIcon } from '@chakra-ui/icons'
import {
  Box, Flex, IconButton, Tooltip,
} from '@chakra-ui/react'
import { colors, UpdateCallback } from '@hb/shared'
import React, { CSSProperties, FC, useState } from 'react'
import {
  getAdminsColorText,
  useAdminsWithColor,
} from '../hooks/backend/user/useAdminsWithColor'
import { filteredEvents } from '../utils/events'
import { Container } from './Container'
import { LoadingOverlay } from './LoadingOverlay'

const Strikethrough: FC<{ style?: CSSProperties }> = ({ style }) => (
  <svg
    viewBox="0 0 100 100"
    style={{ height: '100%', width: '100%', ...style }}
  >
    <path d="M 0 0 L 100 100" strokeWidth={9} stroke={colors.red.hex} />
  </svg>
)

export const ColorSelect: React.FC<{
  color?: string | null
  setColor: (c?: string) => void | Promise<UpdateCallback>
  style?: CSSProperties
  withAdmins?: boolean
}> = ({
  color, setColor, style, withAdmins,
}) => {
  const [active, setActive] = useState(false)
  const [loading, setLoading] = useState(false)
  const handleSubmit = async (c?: string) => {
    setLoading(true)
    await setColor(c)
    setLoading(false)
  }
  const { mapped } = useAdminsWithColor(withAdmins, color)
  return (
    <Flex
      cursor="pointer"
      height="24px"
      width="24px"
      position="relative"
      onClick={() => setActive(true)}
      style={style}
    >
      <Container
        borderRadius="full"
        onMouseLeave={() => setActive(false)}
        onMouseEnter={() => setActive(true)}
        style={{
          height: '24px',
          border: '3px solid #f5f5f5',
          boxShadow: '0 0 5px #00000088',
          width: '24px',
          background: color || '#efefef',
        }}
      >
        {color ? null : (
          <Strikethrough style={{ width: '15px', height: '15px' }} />
        )}
      </Container>
      <Container
        onMouseLeave={() => setActive(false)}
        onMouseEnter={() => setActive(true)}
        style={{
          position: 'absolute',
          pointerEvents: active ? 'auto' : 'none',
          left: 0,
          top: '16px',
          padding: '1rem',
          width: '150px',
          // border: '1px solid #cdcdcd',
          opacity: active ? 1 : 0,
        }}
      >
        <Flex
          bg="white"
          overflow="hidden"
          boxShadow="0 0 4px #333"
          borderRadius={4}
          w="150px"
          flexFlow="row wrap"
          height={active ? 'auto' : 0}
        >
          {color ? (
            <Container
              {...filteredEvents(handleSubmit)}
              title="Clear"
              style={{
                cursor: 'pointer',
                background: 'white',
                overflow: 'hidden',
                boxShadow: 'inset 0 0 4px #333',
                width: 25,
                height: 25,
                margin: '2.5px',
                borderRadius: 4,
              }}
            >
              <Strikethrough />
            </Container>
          ) : null}
          {Object.values(colors)
            .filter((c) => c.hex !== color)
            .map((c) => (
              <Box key={c.hex}>
                <Tooltip
                  placement="top"
                  hasArrow
                  label={`${c.name}${withAdmins ? ` - ${getAdminsColorText(mapped[c.hex])}` : ''}`}
                >
                  <IconButton
                    aria-label={c.name}
                    variant="unstyled"
                    margin="2px"
                    cursor="pointer"
                    background={c.hex}
                    width="26px"
                    minW="0"
                    height="26px"
                    boxShadow="inset 0 0 4px #333"
                    borderRadius={4}
                    color="transparent"
                    _hover={{ color: 'white' }}
                    icon={<CheckIcon filter="drop-shadow(0 0 3px #000000)" />}
                    {...filteredEvents(() => handleSubmit(c.hex))}
                  />
                </Tooltip>
              </Box>
            ))}
        </Flex>
        <LoadingOverlay loading={loading} />
      </Container>
    </Flex>
  )
}
