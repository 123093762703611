import { ArrowForwardIcon, EditIcon } from '@chakra-ui/icons'
import {
  Box,
  Button,
  Collapse,
  Divider,
  Flex,
  HStack,
  IconButton, Text,
  VStack,
} from '@chakra-ui/react'
import { colors, getFullName } from '@hb/shared'
import { MultiFactorInfo } from 'firebase/auth'

import React, {
  useCallback, useEffect, useMemo, useState,
} from 'react'
import { useHistory } from 'react-router'
import { useAppRole } from '../../hooks'
import { useMyRoleItem } from '../../hooks/backend/auth/useMyRoleItem'
import { signOut, useAppModals, useAuth } from '../../store'
import { ActionButton } from '../Buttons/ActionButton'
import { DefaultModal } from '../Modals/DefaultModal'
import { EmailEdit } from './EmailEdit'
import { PhoneEdit } from './PhoneEdit'
import { SignInContent } from './SignInContent'

const UserPanelTwoFactorView = ({
  factors,
}: {
  factors: MultiFactorInfo[] | null
}) => (
  <HStack px={2} py='0.25rem' bg={factors?.length ? 'green.600' : 'yellow.500'} w="100%">
    <Text
      fontSize="xs"
      color="white"
      fontWeight="medium"
      fontFamily="hero-new"
    >
      TWO-FACTOR AUTHENTICATION
    </Text>
    <Button ml='auto' onClick={() => useAppModals.getState().multiFactorAuth.open()} color='white' borderRadius='full' size='xs' bg='blackAlpha.200' _hover={{ bg: 'blackAlpha.300' }}>
      OPEN
    </Button>
  </HStack>
)

const UserPanelPushNotificationsView = () => {
  const appRole = useAppRole()
  const { data: roleItem } = useMyRoleItem()
  const { webPushNotificationsToken } = roleItem || {}

  if (appRole === 'user') return null

  return (
    <HStack px={2} py='0.25rem' bg={webPushNotificationsToken ? 'green.600' : 'gray.400'} w="100%">
      <Text
        fontSize="xs"
        color="white"
        fontWeight="medium"
        fontFamily="hero-new"
      >
        PUSH NOTIFICATIONS
      </Text>
      <Button ml='auto' onClick={() => useAppModals.getState().pushNotificationsSettings.open()} color='white' borderRadius='full' size='xs' bg='blackAlpha.200' _hover={{ bg: 'blackAlpha.300' }}>
        OPEN
      </Button>
    </HStack>

  )
}

export const UserPanel: React.FC<{ noSignOut?: boolean }> = ({ noSignOut }) => {
  const nameEntry = useAppModals((s) => s.nameEntry)
  const appRole = useAppRole()
  const {
    user, loading, authUser, enrolledFactors,
  } = useAuth()

  const history = useHistory()
  const handleSignOut = useCallback(() => {
    history.push('/')
    signOut()
  }, [history])

  const fullName = useMemo(() => getFullName(user), [user])
  const [modalIsOpen, setModalIsOpen] = useState(false)

  useEffect(() => {
    if (authUser && authUser.emailVerified) {
      setModalIsOpen(false)
    }
  }, [authUser])

  return (
    <Flex w="100%" direction="column" borderBottomRadius={4}>
      {user ? (
        <Collapse in={!loading && !!user}>
          <VStack
            spacing={0}
            divider={<Divider />}
            style={{
              alignItems: 'flex-start',
            }}
          >
            <Text
              px={2}
              py={1}
              fontSize="xs"
              w="100%"
              bg="green.600"
              color="white"
              fontWeight="medium"
              fontFamily="hero-new"
            >
              ACCOUNT
            </Text>
            <Flex w="100%" p={2} align="center">
              <Text
                fontFamily="Open Sans"
                fontSize="md"
                color={fullName ? 'gray.600' : 'gray.500'}
                flex={1}
              >
                {fullName || 'No name entered'}{' '}
              </Text>
              <IconButton
                ml={1}
                aria-label="edit"
                icon={<EditIcon />}
                onClick={() => {
                  nameEntry.open()
                }}
                size="xs"
                variant="ghost"
              />
            </Flex>
            <Box w="100%" px={2} py={1}>
              <PhoneEdit user={user} />
            </Box>
            <Box w="100%" p={2}>
              <EmailEdit />
            </Box>
            <UserPanelTwoFactorView factors={enrolledFactors} />
            {appRole === 'user' ? null : <UserPanelPushNotificationsView />}
          </VStack>
        </Collapse>
      ) : null}
      {noSignOut ? null : (
        <Flex
          px={authUser ? 2 : 0}
          borderTop={authUser ? '1px solid #dededeff' : '0px solid #dedede00'}
          transition="all 300ms"
          w="100%"
          bg={authUser ? 'gray.100' : 'transparent'}
          py={authUser ? 2 : 0}
        >
          <ActionButton
            ml="auto"
            border="none"
            boxShadow="none"
            id="sign-in-button"
            width={authUser ? 'auto' : '100%'}
            onClick={!user ? () => setModalIsOpen(true) : handleSignOut}
            borderRadius={4}
            background={colors.green.hex}
            _hover={{ background: 'green.300' }}
            size="sm"
            color="white"
            filter="drop-shadow(1px 1px 3px #00000066)"
            height={authUser ? '30px' : '50px'}
            mt={0}
          >
            <HStack filter="drop-shadow(1px 1px 4px #00000066)">
              <Text>{authUser ? 'Sign out' : 'Sign in / Register'}</Text>
              {authUser ? <ArrowForwardIcon /> : null}
            </HStack>
          </ActionButton>
        </Flex>
      )}

      <DefaultModal
        overlayHeader
        isOpen={modalIsOpen}
        closeOnOverlayClick={false}
        onClose={() => setModalIsOpen(false)}
        closeOnEsc={false}
        render={() => <SignInContent onClose={() => setModalIsOpen(false)} />}
      />
    </Flex>
    // </Container>
  )
}
