import { Text } from '@chakra-ui/react'
import { colors } from '@hb/shared/constants'
import React from 'react'

export const StepNumberSpan = ({ step }: { step: number }) => (
  <Text
    as="span"
    px={2}
    borderRadius="full"
    fontFamily="Hero-New"
    fontWeight={500}
    mr="0.35rem"
    bg={colors.green.hex}
    color="white"
    fontSize="sm"
  >
    Step {step}
  </Text>
)
