import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
  Flex,
  FlexProps,
  Modal,
  ModalBody,
  ModalBodyProps,
  ModalCloseButton,
  ModalContent,
  ModalContentProps,
  ModalFooter,
  ModalFooterProps,
  ModalOverlay,
  ModalProps,
  Text,
  TextProps,
} from '@chakra-ui/react'
import React, {
  ReactElement, useCallback, useRef, useState,
} from 'react'

export interface DefaultModalProps extends Omit<ModalProps, 'children'> {
  render: (props: Pick<ModalProps, 'onClose' | 'isOpen'>) => React.ReactElement
  renderFooter?: (
    props: Pick<ModalProps, 'onClose' | 'isOpen'>
  ) => React.ReactElement
  header?: string | ReactElement
  bodyProps?: ModalBodyProps
  closeDisabled?: boolean
  contentProps?: ModalContentProps
  headerTextProps?: TextProps
  headerProps?: FlexProps
  footerProps?: ModalFooterProps
  overlayHeader?: boolean
  confirmCloseMessage?: string
}

const ConfirmCloseDialog = ({
  isOpen,
  onConfirm,
  onClose,
}: {
  isOpen: boolean
  onConfirm: () => void
  onClose: () => void
}) => {
  const cancelRef = useRef<HTMLButtonElement>(null)
  return (
    <AlertDialog
      isOpen={isOpen}
      leastDestructiveRef={cancelRef}
      onClose={onClose}
    >
      <AlertDialogOverlay>
        <AlertDialogContent>
          <AlertDialogHeader fontSize="lg" fontWeight="bold">
            Delete Customer
          </AlertDialogHeader>

          <AlertDialogBody>
            Are you sure? You can't undo this action afterwards.
          </AlertDialogBody>

          <AlertDialogFooter>
            <Button ref={cancelRef} onClick={onClose}>
              Cancel
            </Button>
            <Button
              colorScheme="red"
              onClick={() => {
                onConfirm()
                onClose()
              }}
              ml={3}
            >
              Delete
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  )
}

export const BetterCloseButton = ({
  confirmMessage,
  onClose,
}: {
  confirmMessage?: string
  onClose: () => void
}) => {
  const [confirmMessageActive, setConfirmMessageActive] = useState(false)

  const handleClick = useCallback(() => {
    if (!confirmMessage) {
      onClose()
    }
  }, [onClose, confirmMessage])
  return (
    <>
      <ModalCloseButton
        onClick={handleClick}
        zIndex={3}
        top={2}
        right={3}
        sx={{
          ':focus:not(:focus-visible)': {
            shadow:
              '0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05) !important',
          },
        }}
        variant="link"
        borderRadius="50%"
        bg={'blackAlpha.300'}
        // style={{ filter: 'drop-shadow(0 0 4px #111' }}
        color="white"
      />
      {confirmMessage ? (
        <ConfirmCloseDialog
          isOpen={confirmMessageActive}
          onConfirm={() => onClose()}
          onClose={() => setConfirmMessageActive(false)}
        />
      ) : null}
    </>
  )
}

export const DefaultModal: React.FC<DefaultModalProps> = ({
  header,
  isOpen,
  render,
  onClose,
  renderFooter,
  footerProps,
  closeDisabled,
  bodyProps,
  contentProps,
  confirmCloseMessage,
  headerTextProps,
  headerProps,
  overlayHeader,
  ...props
}) => {
  let headerEl = null
  if (header) {
    if (typeof header === 'string') {
      headerEl = (
        <Text
          // dropShadow='md'
          // style={{ filter: `drop-shadow(0.5px 0.5px 1.5px black)` }}
          color="gray.600"
          fontSize="xl"
          fontWeight="600"
          fontFamily="Comfortaa"
          position="absolute"
          top={3}
          left={4}
          {...headerTextProps}
        >
          {header}
        </Text>
      )
    } else {
      headerEl = header
    }
  }
  return isOpen ? (
    <Modal
      isCentered
      blockScrollOnMount={false}
      trapFocus={false}
      // size='4xl'
      scrollBehavior="inside"
      portalProps={{ appendToParentPortal: true }}
      isOpen={isOpen}
      onClose={closeDisabled ? () => {} : onClose}
      {...props}
    >
      <ModalOverlay zIndex={1}>
        <ModalContent overflow="hidden" {...contentProps}>
          <Flex
            zIndex={3}
            overflow="visible"
            height={overlayHeader ? 0 : '50px'}
            minHeight={overlayHeader ? 0 : '50px'}
            borderBottom={
              header && !overlayHeader ? '1px solid #cdcdcd' : undefined
            }
            {...headerProps}
          >
            {headerEl}
            {closeDisabled ? null : (
              <BetterCloseButton
                confirmMessage={confirmCloseMessage}
                onClose={onClose}
              />
            )}
          </Flex>

          <ModalBody p={0} {...bodyProps}>
            {render({ onClose, isOpen })}
          </ModalBody>
          {renderFooter ? (
            <ModalFooter borderBottomRadius={'md'} pt={0} {...footerProps}>
              {renderFooter({ onClose, isOpen })}
            </ModalFooter>
          ) : null}
        </ModalContent>
      </ModalOverlay>
    </Modal>
  ) : null
}
