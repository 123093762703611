import {
  Box,
  Flex,
  HStack,
  Progress,
  Stack,
  StackDivider,
} from '@chakra-ui/react'
import {
  CollectionFilter, DataColumns, DataListTab, WithId,
} from '@hb/shared'
import useResizeObserver from '@react-hook/resize-observer'
import { FieldPath } from 'firebase/firestore'
import React, {
  RefObject,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { useHistory } from 'react-router'
import { FixedSizeList, VariableSizeGrid } from 'react-window'
import {
  DataListContext,
  ScreenContext,
  SearchBarContext,
} from '../../contexts'
import { useApp } from '../../contexts/AppContext'
import { useSortedCollection } from '../../hooks/backend/useSortedCollection'
import { useAuth } from '../../store'
import { GenericEditModal } from '../DataView'
import { DataListFooter } from './DataListFooter'
import { DataListTabs } from './DataListTabs'
import { DesktopDataList } from './DesktopDataList'
import { MobileDataList } from './MobileDataList'
import { DataListPresetFilters } from './PresetFilters'
import { SearchBar } from './SearchBar'
import { getFlexColumnWidth } from './utils'

const excludeOmittedBooleanFilters = (
  filters: CollectionFilter[],
  userFilters: CollectionFilter[],
) => filters.filter(
  (f) => !userFilters.find((uf) => uf[0] === f[0] && typeof uf[2] === 'boolean'),
)
const defaultItemHeight = 36
const numItems = 100

export const DataList = <Data extends WithId, SortKey extends string = string>({
  tabs,
  rootPath,
  tabIndex: _tabIndex,
  onRowClick,
  onTabChange,
  width: propWidth,
  height,
}: {
  tabs: Record<string, DataListTab<Data, SortKey>>
  rootPath: string
  tabIndex?: number
  onTabChange?: (index: number) => void
  onRowClick?: (id: string, tabIndex: number) => void
  width?: number
  height: number
}) => {
  const { width: screenWidth, isMobile } = useContext(ScreenContext)

  const fullWidth = useMemo(
    () => propWidth || Math.max(0, screenWidth - (isMobile ? 10 : 70)),
    [screenWidth, isMobile, propWidth],
  )

  const width = useMemo(() => {
    const tab = tabs[Object.keys(tabs)[0]]
    const hasNoFlexCols = Object.values(tab.columns).every((c) => !c.width)
    return hasNoFlexCols
      ? Object.values(tab.columns).reduce(
        (acc, curr) => acc + (curr.width || 0),
        0,
      )
      : fullWidth
  }, [fullWidth, tabs])

  const gridView = useMemo(() => width > 800, [width])

  const { searchQuery } = useContext(SearchBarContext)
  const history = useHistory()
  const selectItem = useCallback(
    (id: string | null) => history.push(id ? `/admin/${rootPath}/${id}` : `/admin/${rootPath}`),
    [history, rootPath],
  )

  const [userFilters, setUserFilters] = useState<Array<CollectionFilter>>([])
  const [autoTabIndex, setTabIndex] = useState(0)
  const handleTabChange = useCallback(
    (index: number) => {
      setUserFilters([])
      if (onTabChange) onTabChange(index)
      else setTabIndex(index)
    },
    [onTabChange],
  )
  const tabIndex = useMemo(
    () => (_tabIndex !== undefined ? _tabIndex : autoTabIndex),
    [_tabIndex, autoTabIndex],
  )
  const tabName = useMemo(() => Object.keys(tabs)[tabIndex], [tabIndex, tabs])
  const [sortKey, setSortKey] = useState<SortKey>(tabs[tabName]?.defaultSortKey)
  const [sortAsc, setSortAsc] = useState<boolean>(false)

  const sortBy = useCallback(
    (k: SortKey, direction?: 'asc' | 'desc') => {
      if (k === sortKey) {
        setSortAsc(!sortAsc)
      } else {
        if (direction) setSortAsc(direction === 'asc')
        else setSortAsc(false)
        setSortKey(k)
      }
    },
    [sortKey, sortAsc],
  )

  const filterBy = useCallback(
    (filter: CollectionFilter) => {
      setUserFilters((prev) => {
        const newFilters = prev.filter((f) => f[0] !== filter[0])
        newFilters.push(filter)
        return newFilters
      })
    },
    [setUserFilters],
  )

  const removeFilter = useCallback(
    (filterKey: string | FieldPath) => {
      setUserFilters((prev) => prev.filter((f) => f[0] !== filterKey))
    },
    [setUserFilters],
  )

  const tab = useMemo(
    () => tabs[tabName] || tabs[Object.keys(tabs)[0]],
    [tabName, tabs],
  )

  const filters = useMemo(
    () => [
      ...excludeOmittedBooleanFilters(tab.filters || [], userFilters),
      ...userFilters,
    ],
    [tab, userFilters],
  )

  const {
    columns, transformData, itemName, defaultSortKey, creation,
  } = tab

  const appData = useApp()

  const [newItemModalOpen, setNewItemModalOpen] = useState(false)

  useEffect(() => {
    const defaultKey = tabs[tabName]?.defaultSortKey
    const defaultColumn = Object.values(tabs[tabName]?.columns).find(
      (c) => c.sortKey === defaultKey,
    )
    const defaultDirection = defaultColumn?.defaultSortDirection || 'desc'
    if (defaultKey) {
      setSortKey(defaultKey)
      setSortAsc(defaultDirection === 'asc')
    }
  }, [tabName, tabs])
  const {
    items,
    loading,
    goNext,
    goPrev,
    canGoNext,
    canGoPrev,
    goToPage,
    pageNum,
    queryCount,
    totalPages,
  } = useSortedCollection(
    {
      query: searchQuery,
      sortKey,
      sortFunc: columns[sortKey]?.sortFunc,
      sortDesc: sortAsc,
      ...tabs[tabName],
      filters,
    },
    numItems,
  )
  const ref = useRef<VariableSizeGrid | FixedSizeList>(null)

  const hasMultipleTabs = useMemo(() => Object.keys(tabs).length > 1, [tabs])

  const contextData = useMemo(
    () => ({
      sortBy: sortBy as any,
      tab,
      filterBy,
      removeFilter,
      toggleSortDirection: () => setSortAsc(!sortAsc),
      filters,
      sortKey,
      userFilters,
      onRowClick,
      gridView,
      sortDesc: !sortAsc,
    }),
    [
      sortBy,
      sortAsc,
      filters,
      sortKey,
      onRowClick,
      gridView,
      filterBy,
      removeFilter,
      tab,
      userFilters,
    ],
  )

  const initialNewItemData = useMemo(() => {
    const initData = creation?.initialData
    if (!initData || !newItemModalOpen) return null
    return typeof initData === 'function' ? initData() : initData
  }, [creation, newItemModalOpen])

  const [headerHeight, setHeaderHeight] = useState(0)
  const [footerHeight, setFooterHeight] = useState(0)
  const headerRef = useRef<HTMLDivElement>(null)

  const onHeaderResize = useCallback(
    (entry: ResizeObserverEntry) => {
      setHeaderHeight(entry.contentRect.height)
    },
    [setHeaderHeight],
  )

  useResizeObserver(headerRef, onHeaderResize)

  const bodyHeight = useMemo(
    () => height - headerHeight - footerHeight - 50,
    [height, headerHeight, footerHeight],
  )

  // const [selectedIndex, setSelectedIndex] = useState(0)
  return (
    <DataListContext.Provider value={contextData}>
      <Flex
        height={`${height}px`}
        position="relative"
        direction="column"
        align="flex-start"
      >
        <Stack
          width={width}
          divider={<StackDivider />}
          spacing={0}
          height='100%'
          zIndex={1}
          boxShadow="0 0 6px rgba(0,0,0,0.5)"
          bg="white"
        >
          <Flex flexFlow="column" w="100%" ref={headerRef}>
            {hasMultipleTabs ? (
              <DataListTabs
                index={tabIndex}
                onChange={handleTabChange}
                tabs={tabs}
              />
            ) : null}
            <HStack px={1} spacing={0}>
              {tab.searchStringPath ? (
                <Box flex={1}>
                  <SearchBar
                    onAddClick={
                      creation ? () => setNewItemModalOpen(true) : undefined
                    }
                    itemName={itemName}
                  />
                </Box>
              ) : null}
              <DataListPresetFilters />
            </HStack>
            <Progress
              colorScheme="green"
              height="5px"
              isIndeterminate={loading}
            />
          </Flex>
          {!gridView ? (
            <MobileDataList
              ref={ref as RefObject<FixedSizeList>}
              active
              sortAsc={sortAsc}
              toggleSortDirection={() => setSortAsc(!sortAsc)}
              sortKey={sortKey}
              loading={loading}
              selectUser={selectItem}
              width={width}
              height={bodyHeight}
              onRowClick={onRowClick ? (id) => onRowClick(id, tabIndex) : undefined}
              numRows={numItems}
              tabName={tabName}
              collection={tabs[tabName].collection}
              defaultSortKey={defaultSortKey}
              columns={columns}
              transformData={transformData}
              sortBy={setSortKey as any}
              tab={tab}
              items={items}
            />
          ) : (
            <DesktopDataList
              ref={ref as RefObject<VariableSizeGrid>}
              active
              sortAsc={sortAsc}
              toggleSortDirection={() => setSortAsc(!sortAsc)}
              sortKey={sortKey}
              loading={loading}
              selectUser={selectItem}
              width={width}
              height={bodyHeight}
              numRows={numItems}
              tabName={tabName}
              onRowClick={onRowClick ? (id) => onRowClick(id, tabIndex) : undefined}
              collection={tabs[tabName].collection}
              defaultSortKey={defaultSortKey}
              columns={columns}
              transformData={transformData}
              sortBy={setSortKey as any}
              tab={tab}
              itemHeight={defaultItemHeight}
              // filtered={users}
              items={items}
            />
          )}
          <DataListFooter
            canGoNext={canGoNext}
            canGoPrev={canGoPrev}
            onGoNext={goNext}
            onGoPrev={goPrev}
            numItems={numItems}
            isGrid={!isMobile}
            onHeightChange={setFooterHeight}
            pageNum={pageNum}
            totalPages={totalPages}
            goToPage={goToPage}
            items={items}
            loading={loading}
            queryCount={queryCount}
            gridRef={ref}
          />
        </Stack>
      </Flex>
      {creation && newItemModalOpen ? (
        <GenericEditModal
          field={creation.field}
          isOpen={newItemModalOpen}
          data={initialNewItemData}
          onClose={() => setNewItemModalOpen(false)}
          submitText={creation?.submitText}
          onSubmit={(d) => creation.onCreate(d, appData)}
        />
      ) : null}
    </DataListContext.Provider>
  )
}

export const StandaloneRow = <T extends WithId>({
  columns,
  width,
  data,
}: {
  columns: DataColumns<T, string>
  width: number
  data: T
}) => {
  const flexColumnWidth = useMemo(
    () => getFlexColumnWidth({ width, columns }),
    [columns, width],
  )
  const auth = useAuth()
  const app = useApp()
  return (
    <Flex width={width}>
      {Object.values(columns).map((c, i) => (
        <Flex
          width={c.width ? `${c.width}px` : flexColumnWidth}
          key={`col_${i}`}
        >
          {c.Render({
            data,
            app,
            preview: {
              openPreview: () => {},
              closePreview: () => {},
              preview: null,
            },
            tabName: '',
            auth,
            isScrolling: false,
            cell: { rowIndex: 0, columnIndex: i },
          })}
        </Flex>
      ))}
    </Flex>
  )
}
