import { getPracticeUsersCollectionPath } from '@hb/shared/collections/collections/patients'
import { PracticeUserRoleItem, UserRole } from '@hb/shared/types'
import {
  collection, CollectionReference, query, where,
} from 'firebase/firestore'
import { useMemo } from 'react'
import { db } from '../../backend'
import { useQuery } from '../../hooks/backend/useQuery'

export const usePracticeUsers = (practiceId: string, roles?: UserRole[]) => {
  const usersQuery = useMemo(() => {
    const baseQuery = collection(db, getPracticeUsersCollectionPath(practiceId)) as CollectionReference<PracticeUserRoleItem>
    if (!roles?.length) return baseQuery
    if (roles.length === 1) return query(baseQuery, where('role', '==', roles[0]))
    return query(baseQuery, where('role', 'in', roles))
  }, [practiceId, roles])

  return useQuery(usersQuery)
}
