import { Collapse, Text, VStack } from '@chakra-ui/react'
import { getDateString } from '@hb/shared'
import React, { useContext, useMemo } from 'react'
import { UserContext } from '../../../../../../contexts/UserContext'
import { Condition } from '../../../../../forms/FinalForm/Condition'

export const DeliveryDateView = () => {
  const { selectedAssessment } = useContext(UserContext)
  const { deliveredOn } = selectedAssessment || {}
  const formatted = useMemo(
    () => (deliveredOn ? getDateString(deliveredOn) : ''),
    [deliveredOn],
  )
  return (
    <Condition basePath='' condition={(v) => !!v?.hospitalized}>
      <VStack px={3} pb={2} align='flex-start' spacing={0} w='100%'>
        <Collapse in={!!deliveredOn}>
          <Text color='gray.600'>Delivered on:</Text>
          <Text>{formatted}</Text>
        </Collapse>
        <Collapse in={!deliveredOn}>
          <Text p={1} color='red.500'>
            No delivery date on file (update on assessment claims tab)
          </Text>
        </Collapse>
      </VStack>
    </Condition>
  )
}
