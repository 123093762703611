import {
  AppName,
  AssessmentSnippet,
  AssessmentSortKey,
  AssessmentStage,
  Claim,
  ClaimSortKey,
  CollectionFilter,
  colors,
  DataListTab,
  getPracticeAssessmentCollectionPath,
  getPracticeUsersCollectionPath,
  newPatientField,
  newPracticePatientField,
  PracticeAssessmentData,
  PracticeAssessmentStage,
  PresetFilter,
  stateOptions,
  UserRoleItem,
  UserSortKey,
  USER_ROLES,
  WithId,
} from '@hb/shared'
import React, { useContext, useMemo } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { inviteUser } from '../../backend/auth'
import {
  AppFormFilesProvider, ScreenContext, SearchBarProvider, useApp,
} from '../../contexts'
import {
  assessmentColumns,
  claimsColumns,
  getProviderAssessmentColumns,
  userColumns,
} from '../columns'
import { DataList } from '../DataList'
import { getRowBackground } from '../DataView'
import { UserProfile, UserProfileTabName } from '../Users'
import { PageContainer } from '../Views/PageContainer'

const allAssessmentsTab: DataListTab<
  WithId<AssessmentSnippet | PracticeAssessmentData>,
  AssessmentSortKey
> = {
  collection: 'assessment-snippets',
  columns: assessmentColumns,
  archived: false,
  defaultSortKey: 'nextActionDate',
  itemName: 'Assessment',
}

const inquiryAssessmentsTab: DataListTab<
  WithId<AssessmentSnippet | PracticeAssessmentData>,
  AssessmentSortKey
> = {
  collection: 'assessment-snippets',
  stage: 'Inquiry',
  archived: false,
  columns: assessmentColumns,
  defaultSortKey: 'nextActionDate',
  itemName: 'Assessment',
}

const authTaskListTab: DataListTab<
  WithId<AssessmentSnippet | PracticeAssessmentData>,
  AssessmentSortKey
> = {
  collection: 'assessment-snippets',
  stage: 'Authorization',
  archived: false,
  columns: assessmentColumns,
  defaultSortKey: 'nextActionDate',
  itemName: 'Assessment',
}

const sendClaimListTab: DataListTab<
  WithId<AssessmentSnippet | PracticeAssessmentData>,
  AssessmentSortKey
> = {
  collection: 'assessment-snippets',
  stage: 'Send Claim',
  archived: false,
  columns: assessmentColumns,
  defaultSortKey: 'nextActionDate',
  itemName: 'Assessment',
}

// const claimsAssessmentTab: DataListTab<
//   WithId<AssessmentSnippet | PracticeAssessmentData>,
//   AssessmentSortKey> = {
//     collection: 'assessment-snippets',
//     stage: 'Claims',
//     archived: false,
//     columns: assessmentColumns,
//     defaultSortKey: 'nextActionDate',
//     itemName: 'Assessment',
//   }

const allClaimsTab: DataListTab<WithId<Claim>, ClaimSortKey> = {
  collection: 'claims',
  archived: false,
  columns: claimsColumns,
  defaultSortKey: 'nextActionDate',
  rightJustifyTab: true,
  itemName: 'Claim',
}

const claimsListTab: DataListTab<WithId<Claim>, ClaimSortKey> = {
  ...allClaimsTab,
  filters: [['assessmentStages', 'array-contains', 'Claims']],
  rightJustifyTab: false,
}

const completeAssessmentsTab: DataListTab<
  WithId<AssessmentSnippet | PracticeAssessmentData>,
  AssessmentSortKey
> = {
  collection: 'assessment-snippets',
  stage: 'Complete',
  archived: false,
  columns: assessmentColumns,
  defaultSortKey: 'nextActionDate',
  itemName: 'Assessment',
}

const archivedAssessmentsTab: DataListTab<
  WithId<AssessmentSnippet | PracticeAssessmentData>,
  AssessmentSortKey
> = {
  collection: 'assessment-snippets',
  archived: true,
  columns: assessmentColumns,
  defaultSortKey: 'nextActionDate',
  itemName: 'Assessment',
}

const patientsTab: DataListTab<UserRoleItem, UserSortKey> = {
  collection: 'user-roles',
  role: 'user',
  columns: userColumns,
  rightJustifyTab: true,
  archived: false,
  // creation: {
  //   field: newPatientField,
  // },
  defaultSortKey: 'lname',
  itemName: 'Patient',
}

// provider assessment tabs

const getPracticeAssessmentTab = (
  practiceId: string,
  stage: AssessmentStage | PracticeAssessmentStage,
): DataListTab<
  WithId<PracticeAssessmentData | AssessmentSnippet>,
  AssessmentSortKey
> => ({
  collection: getPracticeAssessmentCollectionPath(practiceId),
  columns: getProviderAssessmentColumns(practiceId),
  presetFilters: presetAssessmentFilters,
  secondarySortKey: 'nextActionDate',
  archived: false,
  stage,
  defaultSortKey: 'edd',
  itemName: 'Assessment',
})

const getCurrentTab = (practiceId: string) => getPracticeAssessmentTab(practiceId, 'Current')
const getPostpartumTab = (practiceId: string) => getPracticeAssessmentTab(practiceId, 'Postpartum')
const getGynTab = (practiceId: string) => getPracticeAssessmentTab(practiceId, 'Gyn')
const getOtherTab = (practiceId: string) => getPracticeAssessmentTab(practiceId, 'Other')
const getDischargedTab = (practiceId: string) => getPracticeAssessmentTab(practiceId, 'Discharged')

const presetAssessmentFilters: Array<PresetFilter> = [
  {
    label: 'Plan Design',
    filters: [
      {
        label: 'Fully-Insured',
        filter: ['planCoverageType', '==', 'Fully Insured'],
      },
      {
        label: 'Self-Funded',
        filter: ['planCoverageType', '==', 'Self-Funded'],
      },
      {
        label: 'Split-Funded',
        filter: ['planCoverageType', '==', 'Split-Funded'],
      },
    ],
  },
  {
    label: 'State of Plan',
    searchable: true,
    filters: stateOptions.map((state) => ({
      label: state.text,
      filter: ['planState', '==', state.id] as CollectionFilter,
    })),
  },
  {
    label: 'Medicaid/Marketplace',
    filters: [
      {
        label: 'Marketplace Plans',
        filter: ['isMarketplacePlan', '==', true],
      },
      {
        label: 'Medicaid Plans',
        filter: ['isMedicaidPlan', '==', true],
      },
    ],
  },
  {
    label: 'Complaints',
    filter: ['hasComplaints', '==', true],
  },
]

const getDeliveredBackgroundColor = (index: number) => (index % 2 === 0 ? `${colors.pink.hex}22` : `${colors.pink.hex}45`)
const getAssessmentTab = (
  baseTab: DataListTab<
    WithId<AssessmentSnippet | PracticeAssessmentData>,
    AssessmentSortKey
  >,
  midwifeId?: string,
): DataListTab<
  WithId<AssessmentSnippet | PracticeAssessmentData>,
  AssessmentSortKey
> => ({
  ...baseTab,
  searchStringPath: 'stringified',
  secondarySortKey: 'nextActionDate',
  collection: midwifeId
    ? getPracticeAssessmentCollectionPath(midwifeId)
    : baseTab.collection,
  defaultSortKey: midwifeId ? 'edd' : 'nextActionDate',
  presetFilters: presetAssessmentFilters,
  getItemBackgroundColor: (item, index) => (item.deliveredOn
    ? getDeliveredBackgroundColor(index)
    : getRowBackground(index)),
  columns: midwifeId
    ? getProviderAssessmentColumns(midwifeId)
    : baseTab.columns,
})

const getClaimTab = (
  baseTab: DataListTab<WithId<Claim>, ClaimSortKey>,
  midwifeId?: string,
): DataListTab<WithId<Claim>, ClaimSortKey> => {
  // TODO: remove hasComplaints global filter
  const filters: Array<CollectionFilter> = midwifeId
    ? [...(baseTab.filters || []), ['midwifeId', '==', midwifeId]]
    : baseTab.filters || []
  return {
    ...baseTab,
    searchStringPath: 'stringified',
    filters,
    secondarySortKey: 'nextActionDate',
    presetFilters: presetAssessmentFilters,
  }
}

const getPatientTab = (
  appName: AppName,
  baseTab: DataListTab<UserRoleItem, UserSortKey>,
  midwifeId?: string,
): DataListTab<UserRoleItem, UserSortKey> => ({
  ...baseTab,
  searchStringPath: 'stringified',
  collection: midwifeId
    ? getPracticeUsersCollectionPath(midwifeId)
    : USER_ROLES,
  creation: {
    ...baseTab.creation,
    onCreate: midwifeId
      ? (data) => inviteUser(appName, data, midwifeId)
      : (data) => inviteUser(appName, data),
    field: midwifeId ? newPracticePatientField : newPatientField,
  },
  role: midwifeId ? undefined : 'user',
})

const getTabs = (
  appName: AppName,
  midwifeId?: string,
): Record<string, DataListTab<any, string>> => {
  const tabs: Record<string, DataListTab<any, string>> = {}

  if (!midwifeId) {
    tabs['All Assessments'] = getAssessmentTab(allAssessmentsTab, midwifeId)
    tabs.Inquiry = getAssessmentTab(inquiryAssessmentsTab, midwifeId)
    tabs['Auth / Task List'] = getAssessmentTab(authTaskListTab, midwifeId)
    tabs['Send Claim'] = getAssessmentTab(sendClaimListTab, midwifeId)
    tabs.Claims = getClaimTab(claimsListTab, midwifeId)
    tabs.Complete = getAssessmentTab(completeAssessmentsTab, midwifeId)
  } else {
    tabs['All Pregnancies'] = getAssessmentTab(allAssessmentsTab, midwifeId)
    tabs.Inquiry = getPracticeAssessmentTab(midwifeId, 'Inquiry')
    tabs.Current = getCurrentTab(midwifeId)
    tabs.Postpartum = getPostpartumTab(midwifeId)
    tabs.Gyn = getGynTab(midwifeId)
    tabs.Other = getOtherTab(midwifeId)
    tabs.Discharged = getDischargedTab(midwifeId)
    tabs.Complete = getPracticeAssessmentTab(midwifeId, 'Complete')
  }
  tabs.Archived = getAssessmentTab(archivedAssessmentsTab, midwifeId)
  tabs.Patients = getPatientTab(appName, patientsTab, midwifeId)

  return tabs
}

export const AssessmentsList = ({ midwifeId }: { midwifeId?: string }) => {
  const {
    assessmentId, claimId, displayedItem, patientId,
  } = useParams() as {
    assessmentId?: string
    claimId?: string
    patientId?: string
    displayedItem: string
  }
  const { appName } = useApp()
  const history = useHistory()
  const tabs = useMemo(() => getTabs(appName, midwifeId), [midwifeId, appName])
  const basePath = useMemo(() => (midwifeId ? '' : '/admin'), [midwifeId])
  const { contentHeight } = useContext(ScreenContext)
  return (
    <PageContainer fullWidth>
      <AppFormFilesProvider>
        <SearchBarProvider>
          <DataList height={contentHeight - 70} rootPath="assessments" tabs={tabs} />
          <UserProfile
            tab={displayedItem as UserProfileTabName}
            onTabSelect={(tabName) => {
              if (patientId) {
                if (assessmentId) {
                  history.push(
                    `${basePath}/patients/${patientId}/${assessmentId}/${tabName}`,
                  )
                } else history.push(`${basePath}/patients/${patientId}/${tabName}`)
              } else if (assessmentId) {
                history.push(
                  `${basePath}/assessments/${assessmentId}/${tabName}`,
                )
              } else if (claimId) {
                history.push(`${basePath}/claims/${claimId}/${tabName}`)
              }
            }}
            selectAssessment={(id) => {
              if (id) {
                if (patientId) {
                  history.push(
                    `${basePath}/patients/${patientId}/${id}/assessment`,
                  )
                } else history.push(`${basePath}/assessments/${id}/assessment`)
              } else if (patientId) history.push(`${basePath}/patients`)
              else history.push(`${basePath}/assessments`)
            }}
            onClose={() => {
              if (patientId) {
                history.push(`${basePath}/patients`)
              } else if (assessmentId) {
                history.push(`${basePath}/assessments`)
              } else {
                history.push(`${basePath}/claims`)
              }
            }}
            userId={patientId}
            assessmentId={assessmentId}
            claimId={claimId}
          />
        </SearchBarProvider>
      </AppFormFilesProvider>
    </PageContainer>
  )
}
