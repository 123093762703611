import { ConsentForm, CONSENT_FORMS, USERS } from '@hb/shared'
import {
  collection, orderBy, Query, query,
} from 'firebase/firestore'
import { useMemo } from 'react'
import { db } from '../../../backend/db'
import { useQuery } from '../useQuery'

export const usePatientConsentForms = (patientId?: string) => {
  const ref = useMemo(
    () => {
      if (!patientId) return null
      let q = query(
        collection(db, USERS, patientId, CONSENT_FORMS),
        // where('assessmentArchived', '==', false),
      ) as Query<ConsentForm>
      q = query(q, orderBy('sentOn', 'desc'))
      return q
    },
    [patientId],
  )
  return useQuery<ConsentForm>(ref)
}
