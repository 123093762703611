import { Center, CenterProps } from '@chakra-ui/react'
import React from 'react'

export const CollapseHorizontal = ({
  width,
  children,
  in: active,
  duration = 400,
  withShadow,
  ...props
}: CenterProps & { width: number; in: boolean, duration?: number, withShadow?: boolean }) => (
  <Center
    width={`${active ? width : 0}px`}
    opacity={active ? 1 : 0}
    // transition={`width ${d
    // uration}ms ease ${active ? 0 : duration}ms, opacity ${duration}ms ease ${
    //   active ? duration : 0
    // }ms`}
    transition={`all ${duration}ms ease-in-out`}
    boxShadow={withShadow ? `1px 1px 4px ${active ? '#444444ff' : '#44444400'}` : 'none'}
    overflow='hidden'
    {...props}
  >
    {children}
  </Center>
)
