import {
  AssessmentStepKey,
  FieldMapValue,
  FormsViewData,
  isInfoStage, OnUploadProgress,
  UpdateCallback,
  UserFieldKey,
} from '@hb/shared'
import {
  processFieldMapData,
  updateAssessmentAnswers, useAssessmentFromId,
  useForm, usePopulatedUser, useStage, useUserAssessments,
} from '@hb/shared-frontend'
import { useAuth } from '@hb/shared-frontend/store/auth'
import { FormApi } from 'final-form'
import { set as nestedSet } from 'nested-property'
import {
  useCallback, useEffect, useMemo, useRef, useState,
} from 'react'
import { useParams } from 'react-router'

export const useFormsView = (): FormsViewData => {
  const user = useAuth((s) => s.user)
  const authUser = useAuth((s) => s.authUser)
  const { data: assessments, loading: assessmentsLoading } = useUserAssessments(
    authUser?.uid,
  )
  const [selected, setSelected] = useState<{
    formId: UserFieldKey
    stageId: string
  } | null>(null)

  const { assessmentId: _assessmentId } = useParams() as {
    assessmentId?: string
  }
  const index = useMemo(() => {
    const keys = Object.keys(assessments || {})
    if (_assessmentId) {
      const idx = keys.findIndex((id) => _assessmentId === id)
      return idx
    }
    return 0
  }, [assessments, _assessmentId])

  const assessmentId = useMemo(
    () => _assessmentId || (assessments && Object.keys(assessments)[0]) || undefined,
    [assessments, _assessmentId],
  )

  const populatedUser = usePopulatedUser(user?.id)
  // const { data: assessment } = useDocument<Assessment>('assessments', assessmentId)
  const assessment = useAssessmentFromId(false, assessmentId)
  const [step, selectStep] = useState<AssessmentStepKey>('questions')

  const { form } = useForm(assessment?.data, selected?.formId)
  const stage = useMemo(
    () => (form && selected?.stageId ? form.stages[selected.stageId] : null),
    [selected, form],
  )
  const active = useMemo(
    () => (form && stage ? { form, stage } : null),
    [form, stage],
  )
  const { value } = useStage(
    selected?.formId,
    selected?.stageId,
    assessment?.data,
  )
  const { nextStage } = useMemo(() => {
    const keys = Object.keys(form?.stages || {})
    let next: string | null = null
    if (selected?.stageId) {
      const currIdx = keys.indexOf(selected.stageId)
      next = keys[currIdx + 1] || null
    }
    return { stageKeys: keys, nextStage: next }
  }, [selected, form])

  const mountedAssessment = useRef<number | string | null>(assessment?.createdOn || null)
  useEffect(() => {
    if (mountedAssessment.current === assessment?.createdOn) return
    if (assessment?.resultsViewedOn) selectStep('signOn')
    else if (assessment?.results) selectStep('view')
    else {
      selectStep('questions')
    }
    mountedAssessment.current = assessment?.createdOn || null
  }, [assessment])
  const onSubmitStage = useCallback(
    async (
      data: FieldMapValue | undefined,
      onUploadProgress: OnUploadProgress,
      formApi?: FormApi,
    ): Promise<UpdateCallback> => {
      if (!assessmentId) {
        return { error: 'No id selected' }
      }
      if (!assessment) {
        return { error: 'no assessment selected' }
      }
      if (!stage || isInfoStage(stage)) {
        setSelected(
          selected && nextStage
            ? { stageId: nextStage, formId: selected.formId }
            : null,
        )
        return { success: 'Info stage' }
      }

      if (!selected) {
        return { error: 'No selected' }
      }
      if (!data) {
        setSelected(
          selected && nextStage
            ? { stageId: nextStage, formId: selected.formId }
            : null,
        )
        return { error: 'No data' }
      }
      const path = `${form && form.path ? `${form.path}.` : ''}${
        selected?.stageId
      }`
      const processed = await processFieldMapData(
        `assessments/${assessmentId}/data/${stage.storagePath}`,
        stage,
        data,
        assessment.data,
        onUploadProgress,
      )
      const updated = {}
      nestedSet(updated, path, processed)
      return updateAssessmentAnswers({ id: assessmentId, data: updated })
        .then(() => {
          setSelected(
            selected && nextStage
              ? { stageId: nextStage, formId: selected.formId }
              : null,
          )
          if (formApi) formApi.reset()
          return { success: 'Updated successfully!' }
        })
        .catch((err: any) => {
          console.error(err)
          return { error: err.message }
        })
    },
    [form, nextStage, selected, assessmentId, assessment, stage],
  )
  return {
    step,
    assessmentId,
    assessment,
    assessments,
    nextStage,
    selectStep,
    populatedUser,
    assessmentIndex: index,
    assessmentsLoading,
    selected,
    active,
    open: (formId, stageId) => setSelected({ formId, stageId }),
    close: () => setSelected(null),
    onSubmitStage,
    value,
  }
}
