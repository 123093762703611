import { FormsViewData } from '@hb/shared'
import { createContext } from 'react'

export const FormsViewContext = createContext<FormsViewData>({
  step: 'questions',
  selectStep: () => {},
  open: () => {},
  close: () => {},
  assessmentIndex: null,
  onSubmitStage: async () => ({ error: 'uninitialized' }),
  assessmentsLoading: false,
  assessments: null,
  nextStage: null,
  selected: null,
  active: null,
})
