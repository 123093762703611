import { ChatIcon, CloseIcon } from '@chakra-ui/icons'
import {
  Box,
  Center,
  forwardRef,
  Popover,
  PopoverContent,
  PopoverTrigger,
  Portal,
  Text,
} from '@chakra-ui/react'
import { colors } from '@hb/shared/constants'
import React, { useCallback, useContext, useMemo } from 'react'
import { ScreenContext, useApp } from '../../contexts'
import { useAuth } from '../../store/auth'
import { SwapIconButton } from '../Buttons/SwapIconButton'
import { useThreadsList } from './contexts'
import { MyThreadsList } from './MyThreadsList'

const MessagesWidgetButton = forwardRef<{ isOpen: boolean }, 'div'>(
  ({ isOpen }, ref) => {
    const {
      threads: { numUnreadThreads },
    } = useThreadsList()
    const { isMobile } = useContext(ScreenContext)
    const size = isMobile ? 36 : 46
    return (
      <Box ref={ref} width={`${size}px`} height={`${size}px`} position="relative">
        <SwapIconButton
          aria-label="Messages"
          borderRadius="full"
          position="absolute"
          filter="drop-shadow(1px 1px 4px #00000099)"
          size={size}
          isSwapped={isOpen}
          bg={isOpen ? 'gray.50' : colors.green.hex}
          _hover={{ bg: 'green.300' }}
          color={isOpen ? colors.green.hex : 'gray.50'}
          icon={<ChatIcon width={`${size * 0.5}px`} height={`${size * 0.5}px`} />}
          swapIcon={<CloseIcon color="gray.500" width={3} height={3} />}
        />

        {numUnreadThreads ? (
          <Center
            position="absolute"
            top="-5px"
            right="-5px"
            bg={isOpen ? colors.green.hex : 'white'}
            boxShadow="0 0 5px white"
            color={isOpen ? 'white' : colors.green.hex}
            borderRadius="50%"
            width="20px"
            height="20px"
          >
            <Text fontSize="xs" fontWeight={600} lineHeight={1}>
              {numUnreadThreads}
            </Text>
          </Center>
        ) : null}
      </Box>
    )
  },
)

const MessagesWidgetBody = () => {
  const accessLevel = useAuth((s) => s.accessLevel)
  const { appName } = useApp()
  const hasMessages = useMemo(() => {
    switch (accessLevel) {
      case 'admin':
        return true
      case 'practice':
        return appName === 'providers-app'
      default:
        return false
    }
  }, [accessLevel, appName])
  const { isMobile } = useContext(ScreenContext)
  const {
    isOpen,
    onClose,
    onOpen,
    widget: { tabsVisible },
  } = useThreadsList()
  const left = useMemo(() => {
    if (!isMobile) return '40px'
    return tabsVisible && appName === 'app' ? '28px' : '0px'
  }, [isMobile, tabsVisible, appName])

  const handleClose = useCallback(() => {
    onClose()
  }, [onClose])

  return hasMessages ? (
    <Portal appendToParentPortal={false}>
      <Box
        pointerEvents="none"
        position="fixed"
        w="100%"
        h="100%"
        right={0}
        top={0}
        zIndex={20}
      >
        <Box pointerEvents="auto" position="absolute" bottom={3} left={3}>
          <Popover
            closeOnBlur={false}
            onOpen={onOpen}
            onClose={handleClose}
            isOpen={isOpen}
            placement="top-start"
          >
            <PopoverTrigger>
              <Box>
                <MessagesWidgetButton isOpen={isOpen} />
              </Box>
            </PopoverTrigger>
            <Portal appendToParentPortal>
              <PopoverContent
                left={left}
                pointerEvents="auto"
                border="none"
                w="auto"
                style={{
                  transition: 'left 300ms',
                }}
                maxW="100vw"
                background="transparent"
                backgroundColor="transparent"
                borderRadius={6}
              >
                <MyThreadsList onClose={handleClose} />
              </PopoverContent>
            </Portal>
          </Popover>
        </Box>
      </Box>
    </Portal>
  ) : null
}

export const MessagesWidget = () => (
    <MessagesWidgetBody />
)
