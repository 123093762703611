import { FieldMapValue, UseDocument } from '@hb/shared'
import {
  deleteDoc,
  doc,
  DocumentReference,
  onSnapshot,
  setDoc,
} from 'firebase/firestore'
import {
  useCallback, useContext, useEffect, useMemo, useState,
} from 'react'
import { db } from '../../backend/db'
import { PopUpMessageContext } from '../../contexts/PopUpMessage/PopUpMessageContext'

const refetch = <T>(
  ref: DocumentReference<T>,
  onData: (data: T | null) => void,
  onError: (message: string) => void,
) => onSnapshot(
    ref,
    (res) => {
      const data = res.data()
      onData(data ? { ...data, id: res.id } : null)
    },
    (err) => {
      console.log(err)
      switch (err.code) {
        case 'permission-denied':
          onError('Insufficient permissions')
          break
        case 'unauthenticated':
          onError('Not authenticated')
          break
        default:
          onError('Error getting firestore document')
          break
      }
    },
  )

export const useDocument = <T>(
  collectionPath: string,
  id?: string | null,
  enabled: boolean = true,
): UseDocument<T> => {
  const { showError, showSuccess } = useContext(PopUpMessageContext)

  const [loading, setLoading] = useState(!!(enabled && collectionPath && id))
  const [error, setError] = useState<string | null>(null)
  const [data, setData] = useState<T | null>(null)

  const ref = useMemo(
    () => (enabled && collectionPath && id
      ? (doc(db, collectionPath, id) as DocumentReference<T>)
      : null),
    [collectionPath, id, enabled],
  )

  const handleError = useCallback(
    (message: string) => {
      console.log({ collectionPath, id })
      setError(message)
      showError(message)
    },
    [showError, collectionPath, id],
  )

  useEffect(() => {
    if (ref) {
      setLoading(true)
      return refetch(
        ref,
        (d) => {
          setData(d)
          setLoading(false)
        },
        handleError,
      )
    }
    setLoading(false)
    setData(null)
    return () => {}
  }, [ref, showError, handleError])

  const onSave = useCallback(
    async (updated: FieldMapValue): Promise<boolean> => {
      if (!collectionPath) {
        showError('No collection path when saving document')
        return false
      }
      if (!id) {
        showError('Document does not exists when saving document')
        return false
      }

      try {
        await setDoc(doc(db, collectionPath, id), updated)
      } catch (err) {
        showError('Error saving document')
        return false
      }
      showSuccess('Successfully saved!')
      setLoading(false)
      setError(null)
      return true
    },

    [id, collectionPath, showError, showSuccess],
  )

  const onDelete = useCallback(async (): Promise<boolean> => {
    if (!collectionPath) {
      setError('No collection path when deleting document')
      return false
    }
    if (!id) {
      setError('No id when deleting document')
      return false
    }
    try {
      await deleteDoc(doc(db, collectionPath, id))
    } catch (err) {
      console.log(err)
      console.trace()
      setLoading(false)
      setError('Error deleting document')
      return false
    }
    showSuccess('Successfully deleted')
    setLoading(false)
    return true
  }, [id, collectionPath, showSuccess])

  return useMemo(() => ({
    data,
    loading,
    error,
    refetch: () => {
      if (!ref) return
      setLoading(true)
      refetch(
        ref,
        (d) => {
          setData(d)
          setLoading(false)
        },
        handleError,
      )
    },
    onSave,
    onDelete,
    ref,
  }), [data, loading, error, ref, onSave, onDelete, handleError])
}
