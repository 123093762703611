import cloneDeep from 'lodash.clonedeep'
import { Text } from 'slate'
import { midwifeFields } from '../../collections/collections/practice'
import { FieldTypes } from '../../constants'
import {
  Descendant, FormattedText, ImageElement, VariableElement,
} from '../../editor/types'
import { getAssessmentFieldFromPath } from '../../fields/userFields'
import {
  Field, FileDBValue,
} from '../../types'
import { ShortcutArgs } from '../../types/admin/assessments'

import { Template } from '../../types/templates'
import {
  getDateString,
} from '../dates'
import { formatPhoneNumber, getFieldFromPath } from '../fields'
import { getShortcutValue } from './shortcuts'
import { getFieldValue } from './utils'

export function getTemplateValue(
  node: VariableElement,
  shortcutArgs: ShortcutArgs,
) {
  const { path, dataType } = node || {}
  const { user, assessment } = shortcutArgs
  if (!path) return undefined
  if (path === 'patient.fname' || path === 'fname') {
    return user?.fname
  }
  if (path === 'patient.lname' || path === 'lname') {
    return user?.lname
  }
  if (path === 'patient.dob' || path === 'dob') {
    return user?.dob ? getDateString(user.dob, 'short') : undefined
  }
  if (path === 'patient.phone' || path === 'phone') {
    return user?.phone ? formatPhoneNumber(user.phone) : undefined
  }
  if (node.shortcut) {
    return getShortcutValue(node, shortcutArgs)
  }
  if (node.path.startsWith('delivery.midwife')) {
    const subPath = node.path.split('.').slice(2).join('.')
    const field = getFieldFromPath(midwifeFields, subPath) || { type: dataType, placeholder: '' }
    return getFieldValue(field as Field, subPath, assessment?.midwife)
  }
  const field = getAssessmentFieldFromPath(node.path) || { type: dataType, placeholder: '' }
  return getFieldValue(field as Field, node.path, assessment?.mergedData)

  // return undefined
}

const populateFileVariableNode = (node: VariableElement, value: FileDBValue | undefined): {
  node: ImageElement | VariableElement,
  errors: Array<any>
} => {
  const { imageOptions: options = {}, children } = node
  if (!value) {
    return {
      node,
      errors: [node],
    }
  }
  const {
    type, storagePath,
  } = value || {}
  if (!type || !storagePath) {
    return {
      node,
      errors: [node],
    }
  }
  return {
    node: {
      type: 'image',
      value,
      children,
      options,
    },
    errors: [],
  }
}

export function populateNode(
  unpopulated: Descendant,
  shortcutArgs: ShortcutArgs,
): { node: any; errors: Array<any> } {
  const errorNodes: Array<any> = []
  if (unpopulated.type === 'variable') {
    const value = getTemplateValue(unpopulated, shortcutArgs)
    if (unpopulated.dataType && unpopulated.dataType === FieldTypes.FILE) {
      return populateFileVariableNode(unpopulated, value as FileDBValue)
    }
    const { text: _t, ...nodeArgs } = unpopulated.children?.[0] || {}
    let text = ''
    const isError = !value
    if (value) text = `${value}`
    else if (!unpopulated.optional) {
      text = `Error populating ${unpopulated.name}`
    }
    const node: FormattedText = {
      ...nodeArgs,
      text,
    }
    // if (unpopulated.isInline) node.isInline = true
    return { node, errors: isError ? [unpopulated] : [] }
  } if (!Text.isText(unpopulated) && unpopulated.children) {
    const populatedChildren = unpopulated.children?.map((n: any) => populateNode(n, shortcutArgs))
    const childErrors = populatedChildren.map((n: any) => n.errors).flat()
    errorNodes.push(...childErrors)
    return {
      node: {
        ...unpopulated,
        children: populatedChildren.map((n: any) => n.node),
      },
      errors: errorNodes,
    }
  }
  return { node: unpopulated, errors: errorNodes }
}

export function populateTemplate(
  template: Partial<Template>,
  shortcutArgs: ShortcutArgs,
): { populated: any[]; errors: any[] } {
  const { templateText } = cloneDeep(template)
  if (templateText) {
    return templateText.reduce(
      (acc, n) => {
        const { errors, node } = populateNode(
          n,
          shortcutArgs,
        )
        acc.populated.push(node)
        acc.errors.push(...errors)
        return acc
      },
      { errors: [] as any[], populated: [] as any[] },
    )
  }
  return {
    populated: [
      {
        type: 'span',
        children: [
          {
            text: 'No template text - go to templates to add text to this template',
          },
        ],
      },
    ],
    errors: [],
  }
}
