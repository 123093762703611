import { EditIcon } from '@chakra-ui/icons'
import { Box, Flex, Text } from '@chakra-ui/react'
import { adminRoles, colors } from '@hb/shared/constants'
import {
  AppName,
  DataColumn,
  DataListTab,
  FieldMapValue,
  PracticeVisit,
  PracticeVisitDraft,
  PracticeVisitDraftSortKey,
  PracticeVisitSortKey,
  ProviderAuthentication,
  UnsavedPracticeVisitDraft,
  UpdateCallback,
  User,
  WithId,
} from '@hb/shared/types'
import {
  formatTime,
  getDateTimeString,
  getPracticeAssessmentVisitsCollectionPath,
  getPracticeVisitDraftsCollectionPath,
  timeParse,
} from '@hb/shared/utils'
import {
  dateToDateString,
  dateToTimeString,
  getDurationString,
} from '@hb/shared/utils/dates'
import { deleteDoc, doc, setDoc } from 'firebase/firestore'
import React, {
  useCallback, useContext, useMemo, useState,
} from 'react'
import { addPracticeVisit } from '../../../../backend'
import { db } from '../../../../backend/db'
import { usePracticeAccess } from '../../../../contexts'
import { useApp } from '../../../../contexts/AppContext'
import { UserContext } from '../../../../contexts/UserContext'
import { useDocument } from '../../../../hooks/backend/useDocument'
import { ActionButton, DeleteButton } from '../../../Buttons'
import { DataList } from '../../../DataList/DataList'
import { SortButton } from '../../../DataList/SortButton'
import { SimpleForm } from '../../../forms'
import { Loading } from '../../../Loading'
import { DefaultModal } from '../../../Modals/DefaultModal'
import { AuthenticateAndSubmit } from './AuthenticateCheckbox'
import { VisitsContext } from './contexts'
import { newVisitField } from './fields'
import { SignVisitOrSaveDraft } from './SignVisitOrSaveDraft'
import { populateVisitDraft } from './utils'
import { VisitDraftView, VisitView } from './VisitView'

type PracticeVisitColumn = DataColumn<
  WithId<PracticeVisit>,
  PracticeVisitSortKey
>

type PracticeVisitDraftColumn = DataColumn<
  WithId<PracticeVisitDraft>,
  PracticeVisitDraftSortKey
>

const getRootPath = (appName: AppName, assessmentId: string) => (appName === 'providers-app'
  ? `assessments/${assessmentId}/visits`
  : `/admin/assessments/${assessmentId}/visits`)

/*
-'Date of Visit (choose date on calendar)

-Start Time of visit

-End time of visit

-Gravida (space for midwife to add one number),

-Para (place to add multiple numbers side by side),

-Current Pregnancy (select one of the following) 1)Singleton Pregnancy detected 2) Singleton pregnancy detected via FHR, U/S scheduled, 3) Singleton pregnancy confirmed via ultrasound

-History (can select any and all of the following options to auto-populate, which are): 1) No history of C/S, 2) No other surgical history 3) Prior pregnancy resulting in vaginal delivery 4) No prior pregnancy history (once autopopulates provider can continue to write in note box any additional history she wants to add).

-Plan (can select any of the following): 1) Patient aiming for spontaneous, vaginal delivery of newborn, no plan for induction 2) Interested in homebirth, criteria reviewed for home setting. Note box will be available for provider to add additional charting if needed.

-HOSPITAL: shows a space for provider to type in name of hospital). Below autopopulates:

*Nearest Hospital within reasonable distance in case need for transfer occurs from home

-Risk Assessment: (button for provider to choose indicating:

1) Low Risk (if they choose this it autopopulates: Appropriate for midwifery care at this time / Low-Risk

2) Further assessment needed (will populate this if chosen)
*/
const upcomingStartTimeColumn: PracticeVisitColumn = {
  sortKey: 'startTime',
  defaultSortDirection: 'asc',
  Header: () => (
    <SortButton defaultDir="asc" sortKey="startTime">
      Start Time
    </SortButton>
  ),
  Render: ({ data: { startTime } }) => (
    <Text px={1}>{getDateTimeString(startTime)}</Text>
  ),
  title: 'Start Time',
  mobile: {
    width: 220,
    Render: ({ data: { startTime } }) => (
      <Text px={1}>
        <span style={{ fontWeight: 600 }}>Starts at</span>{' '}
        {getDateTimeString(startTime)}
      </Text>
    ),
  },
  width: 170,
}

const upcomingEndTimeColumn: PracticeVisitColumn = {
  sortKey: 'endTime',
  defaultSortDirection: 'asc',
  Header: () => (
    <SortButton defaultDir="asc" sortKey="endTime">
      End Time
    </SortButton>
  ),
  Render: ({ data: { endTime } }) => (
    <Text px={1}>{getDateTimeString(endTime)}</Text>
  ),
  title: 'End Time',
  mobile: {
    width: 220,
    Render: ({ data: { endTime } }) => (
      <Text px={1}>
        <span style={{ fontWeight: 600 }}>Ends at</span>{' '}
        {getDateTimeString(endTime)}
      </Text>
    ),
  },
  width: 170,
}

const draftDateColumn: PracticeVisitDraftColumn = {
  sortKey: 'date',
  defaultSortDirection: 'desc',
  Header: () => (
    <SortButton defaultDir="desc" sortKey="date">
      Date
    </SortButton>
  ),
  Render: ({ data: { date } }) => <Text px={1}>{date}</Text>,
  title: 'Date',
  width: 150,
}

const draftStartTimeColumn: PracticeVisitDraftColumn = {
  title: 'Start Time',
  width: 150,
  Header: () => <Text>Start Time</Text>,
  Render: ({ data: { startTime } }) => (
    <Text px={1}>
      {typeof startTime === 'string' ? formatTime(startTime) : 'None'}
    </Text>
  ),
}

const draftEndTimeColumn: PracticeVisitDraftColumn = {
  title: 'End Time',
  width: 150,
  Header: () => <Text>End Time</Text>,
  Render: ({ data: { endTime } }) => (
    <Text px={1}>
      {typeof endTime === 'string' ? formatTime(endTime) : 'None'}
    </Text>
  ),
}

const pastStartTimeColumn: PracticeVisitColumn = {
  ...upcomingStartTimeColumn,
  defaultSortDirection: 'desc',
  Header: () => (
    <SortButton defaultDir="desc" sortKey="startTime">
      Start Time
    </SortButton>
  ),
}

const pastEndTimeColumn: PracticeVisitColumn = {
  ...upcomingEndTimeColumn,
  defaultSortDirection: 'desc',
  Header: () => (
    <SortButton defaultDir="desc" sortKey="endTime">
      End Time
    </SortButton>
  ),
}

const durationColumn: PracticeVisitColumn = {
  sortKey: 'duration',
  defaultSortDirection: 'asc',
  Header: () => <Text>Duration</Text>,
  Render: ({ data: { startTime, endTime } }) => (
    <Text px={1}>{getDurationString(startTime, endTime)}</Text>
  ),
  title: 'Duration',
  width: 150,
}

const patientNameColumn: PracticeVisitColumn = {
  sortKey: 'patientLast',
  defaultSortDirection: 'asc',
  Header: () => <SortButton sortKey="patientLast">Patient Name</SortButton>,
  Render: ({ data: { patientFirst, patientLast } }) => (
    <Text px={1}>
      {patientLast}, {patientFirst}
    </Text>
  ),
  title: 'Patient Name',
  // width: 150,
}

const getVisitsInitialData = () => {
  const d = new Date()
  return {
    date: dateToDateString(d),
    startTime: timeParse(dateToTimeString(d)),
  }
}

const getVisitsCreation = (
  onCreate: (data: FieldMapValue) => Promise<UpdateCallback>,
): DataListTab<WithId<PracticeVisit>, PracticeVisitSortKey>['creation'] => ({
  field: newVisitField,
  submitText: 'Submit Visit or Save Draft',
  onCreate,
  initialData: getVisitsInitialData,
})

const getUpcomingVisitsTab = (
  practiceId: string,
  assessmentId: string,
  onCreate: (data: FieldMapValue) => Promise<UpdateCallback>,
): DataListTab<WithId<PracticeVisit>, PracticeVisitSortKey> => ({
  collection: getPracticeAssessmentVisitsCollectionPath(practiceId),
  filters: [
    ['assessmentId', '==', assessmentId],
    ['startTime', '>=', Date.now()],
  ],
  searchStringPath: 'searchString',
  creation: getVisitsCreation(onCreate),
  columns: {
    patientName: patientNameColumn,
    startTime: upcomingStartTimeColumn,
    endTime: upcomingEndTimeColumn,
    duration: durationColumn,
  },
  defaultSortKey: 'startTime',
  itemName: 'visit',
})

const getPastVisitsTab = (
  practiceId: string,
  assessmentId: string,
  onCreate: (data: FieldMapValue) => Promise<UpdateCallback>,
): DataListTab<WithId<PracticeVisit>, PracticeVisitSortKey> => ({
  collection: getPracticeAssessmentVisitsCollectionPath(practiceId),
  searchStringPath: 'searchString',
  creation: getVisitsCreation(onCreate),
  filters: [
    ['assessmentId', '==', assessmentId],
    ['startTime', '<', Date.now()],
  ],
  columns: {
    patientName: patientNameColumn,
    startTime: pastStartTimeColumn,
    endTime: pastEndTimeColumn,
    duration: durationColumn,
  },
  defaultSortKey: 'startTime',
  itemName: 'visit',
})

const getVisitDraftsTab = (
  practiceId: string,
  assessmentId: string,
  onCreate: (data: FieldMapValue) => Promise<UpdateCallback>,
): DataListTab<WithId<PracticeVisitDraft>, PracticeVisitDraftSortKey> => ({
  collection: getPracticeVisitDraftsCollectionPath(practiceId),
  filters: [['assessmentId', '==', assessmentId]],
  searchStringPath: 'searchString',
  creation: getVisitsCreation(onCreate),
  columns: {
    patientName: patientNameColumn as any,
    date: draftDateColumn,
    startTime: draftStartTimeColumn,
    endTime: draftEndTimeColumn,
  },
  defaultSortKey: 'date',
  itemName: 'visit draft',
})

export const VisitViewModal = ({
  visitId,
  practiceId,
  onClose,
}: {
  visitId: string
  practiceId: string
  onClose: () => void
}) => {
  const collectionPath = useMemo(
    () => getPracticeAssessmentVisitsCollectionPath(practiceId),
    [practiceId],
  )
  const { data: visit, loading } = useDocument<PracticeVisit>(
    collectionPath,
    visitId,
  )
  let body = <></>
  if (visit) {
    body = (
      <Box w="100%" p={3}>
        <VisitView visit={visit} />
      </Box>
    )
  } else if (loading) {
    body = <Loading text="Loading visit..." />
  } else {
    body = (
      <Text color="red.600" p={3}>
        Could not load visit
      </Text>
    )
  }
  return (
    <DefaultModal
      isOpen
      size="2xl"
      overlayHeader
      onClose={onClose}
      contentProps={{ p: 0, bg: 'gray.50' }}
      render={() => body}
    />
  )
}

export const VisitDraftViewModal = ({
  draftId,
  practiceId,
  patient,
  assessmentId,
  onClose,
}: {
  draftId: string
  practiceId: string
  assessmentId: string
  patient: WithId<User>
  onClose: () => void
}) => {
  const collectionPath = useMemo(
    () => getPracticeVisitDraftsCollectionPath(practiceId),
    [practiceId],
  )
  const { data: draft, loading } = useDocument<
    PracticeVisitDraft | UnsavedPracticeVisitDraft
  >(collectionPath, draftId)

  const { practiceAccess } = usePracticeAccess()
  const canEdit = useMemo(() => {
    const practiceRole = practiceAccess?.[practiceId]
    if (!practiceRole) return false
    return adminRoles.includes(practiceRole)
  }, [practiceAccess, practiceId])

  const [editing, setEditing] = useState(false)
  const [authChecked, setAuthChecked] = useState(false)

  const onEditDraft = useCallback(
    async (updated: UnsavedPracticeVisitDraft) => {
      if (!assessmentId) return { error: 'No assessment selected' }
      if (!patient) return { error: 'No patient selected' }
      if (!draftId) return { error: 'No draft selected' }
      try {
        const populated = populateVisitDraft(updated, assessmentId, patient)
        await setDoc(doc(db, collectionPath, draftId), populated)
        setEditing(false)
        return { success: 'Draft updated' }
      } catch (e: any) {
        return { error: e.message || 'error' }
      }
    },
    [draftId, collectionPath, assessmentId, patient],
  )

  const [submitting, setSubmitting] = useState(false)
  const { appName } = useApp()
  const onSignAndSubmit = useCallback(
    async (authentication: ProviderAuthentication): Promise<void> => {
      if (!draft) {
        throw new Error('No draft selected')
      }
      if (!assessmentId) {
        throw new Error('No assessment selected')
      }
      setSubmitting(true)
      return addPracticeVisit({
        appName,
        practiceId,
        draftId,
        authentication,
        assessmentId,
        ...draft,
      })
        .then(() => {
          onClose()
        })
        .finally(() => {
          setSubmitting(false)
        })
    },
    [
      appName,
      practiceId,
      assessmentId,
      draft,
      onClose,
      draftId,
    ],
  )

  const onDelete = useCallback(async () => {
    if (!draftId) return { error: 'No draft selected' }
    try {
      await deleteDoc(doc(db, collectionPath, draftId))
      onClose()
      return { success: 'Draft deleted' }
    } catch (e: any) {
      return { error: e.message || 'error' }
    }
  }, [draftId, collectionPath, onClose])

  let body: React.JSX.Element = <> </>
  if (draft) {
    body = editing ? (
      <SimpleForm
        field={newVisitField}
        submitText="Save Edited Draft"
        onCancel={() => setEditing(false)}
        value={draft}
        onSubmit={onEditDraft}
      />
    ) : (
      <Flex p={5} flexFlow="column">
        <Box
          w="100%"
          borderRadius={6}
          bg="white"
          boxShadow="1px 1px 4px #00000055"
          p={3}
        >
          <VisitDraftView draft={draft} />
        </Box>
        {canEdit ? (
          <>
            <Flex w="100%" gap={2} mb={2} mt={3} align="center">
              <ActionButton
                isDisabled={submitting}
                flex={1}
                bg="white"
                gap={2}
                onClick={() => setEditing(true)}
              >
                <EditIcon color={colors.green.hex} />
                <Text>Edit Draft</Text>
              </ActionButton>
              <DeleteButton
                text="Delete Draft"
                borderColor="red.600"
                bg="white"
                isDisabled={submitting}
                borderWidth="1px"
                itemName="draft"
                onDelete={onDelete}
              />
            </Flex>
            <AuthenticateAndSubmit
              authChecked={authChecked}
              onChange={setAuthChecked}
              itemName="visit"
              onSubmit={onSignAndSubmit}
              practiceId={practiceId}
            />
          </>
        ) : null}
      </Flex>
    )
  } else if (loading) {
    body = <Loading text="Loading draft..." />
  } else {
    body = (
      <Text p={3} color="red.600">
        Could not load draft
      </Text>
    )
  }
  return (
    <DefaultModal
      isOpen
      size="2xl"
      overlayHeader
      contentProps={{ bg: 'gray.50' }}
      onClose={() => {
        if (editing) {
          setEditing(false)
        } else {
          onClose()
        }
      }}
      render={() => body}
    />
  )
}

export const Visits = ({
  width,
  height,
}: {
  width: number
  height: number
}) => {
  const { appName } = useApp()
  const {
    assessmentId, patientRef, selectedAssessment, user,
  } = useContext(UserContext)
  const { selectedPracticeId } = usePracticeAccess()
  const { midwifeId } = selectedAssessment || {}
  const [selectedVisit, setSelectedVisit] = useState<{
    id: string
    isDraft: boolean
  } | null>(null)

  const [visitDraft, setVisitDraft] = useState<PracticeVisitDraft | null>(null)
  const patientId = useMemo(() => patientRef?.id, [patientRef])
  const onCreateNew = useCallback(
    async (data: FieldMapValue) => {
      if (!patientId) return { error: 'No patient selected' }
      if (!assessmentId) return { error: 'No assessment selected' }
      setVisitDraft({ ...data, patientId } as PracticeVisitDraft)
      return { success: 'Created draft' }
    },
    [patientId, assessmentId],
  )
  const practiceId = useMemo(
    () => selectedPracticeId || midwifeId,
    [selectedPracticeId, midwifeId],
  )

  const rootPath = useMemo(
    () => getRootPath(appName, 'assessmentId'),
    [appName],
  )
  const tabs = useMemo(() => {
    if (appName === 'providers-app' && !selectedPracticeId) return null
    if (!practiceId) return null
    if (!assessmentId) return null
    return {
      Upcoming: getUpcomingVisitsTab(practiceId, assessmentId, onCreateNew),
      Past: getPastVisitsTab(practiceId, assessmentId, onCreateNew),
      Drafts: getVisitDraftsTab(practiceId, assessmentId, onCreateNew),
    }
  }, [appName, selectedPracticeId, practiceId, assessmentId, onCreateNew])

  const onSelect = useCallback((id: string, isDraft: boolean) => {
    setSelectedVisit({ id, isDraft })
  }, [])

  return (
    <VisitsContext.Provider value={{ selectedVisit, onSelect }}>
      <Flex flexFlow="column" p={3}>
        {/* <Text>Visits</Text> */}
        {tabs ? (
          <DataList<WithId<PracticeVisit | PracticeVisitDraft>>
            onRowClick={(id, idx) => onSelect(id, idx === 2)}
            width={width - 40}
            height={height}
            tabs={tabs as any}
            rootPath={rootPath}
          />
        ) : (
          <Text>Select a practice to view visits</Text>
        )}
        {selectedVisit && practiceId && !selectedVisit.isDraft ? (
          <VisitViewModal
            visitId={selectedVisit.id}
            practiceId={practiceId}
            onClose={() => {
              setSelectedVisit(null)
            }}
          />
        ) : null}
        {user
        && selectedVisit
        && practiceId
        && assessmentId
        && selectedVisit.isDraft ? (
          <VisitDraftViewModal
            assessmentId={assessmentId}
            draftId={selectedVisit.id}
            patient={user}
            practiceId={practiceId}
            onClose={() => {
              setSelectedVisit(null)
            }}
          />
          ) : null}
        {visitDraft && practiceId && assessmentId && user ? (
          <DefaultModal
            isOpen
            onClose={() => setVisitDraft(null)}
            size="2xl"
            contentProps={{ bg: 'gray.50' }}
            overlayHeader
            render={() => SignVisitOrSaveDraft({
              draft: visitDraft,
              practiceId,
              patient: user,
              assessmentId,
              onClose: () => setVisitDraft(null),
            })
            }
          />
        ) : null}
      </Flex>
    </VisitsContext.Provider>
  )
}
