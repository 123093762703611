import {
  DollarAmountField, formatDollarValue, InputElement, InputProps,
} from '@hb/shared'
import * as React from 'react'
import {
  forwardRef, useCallback, useEffect, useRef,
} from 'react'
import InputContainer from '../../Shared/InputContainer'
import { TextInputElement } from '../styles'

const DollarAmountInput: InputElement<DollarAmountField> = forwardRef<
  {focus:() => void},
  InputProps<DollarAmountField>
    >((props, ref) => {
      const { input, field, disabled } = props
      const {
        onBlur, onChange, value, ...inputProps
      } = input || {}
      const { placeholder } = field
      const text = useRef(formatDollarValue(input.value) || '$')
      const handleChange = useCallback(
        ({ target: { value: newVal } }: React.ChangeEvent<HTMLInputElement>) => {
          text.current = newVal
          if (inputRef.current) inputRef.current.value = text.current
        },
        [],
      )
      const inputRef = useRef<HTMLInputElement>(null)

      useEffect(() => {
        text.current = value
        if (inputRef.current) inputRef.current.value = text.current
      }, [value])

      const handleBlur = React.useCallback(
        (e: React.FocusEvent<HTMLInputElement>) => {
          e.target.value = text.current
          onChange(e)
          onBlur(e)
        },
        [onBlur, onChange],
      )
      React.useImperativeHandle(ref, () => ({
        focus: () => inputRef.current?.focus(),
      }))

      return (
    <InputContainer {...props}>
      <TextInputElement
        ref={inputRef}
        disabled={disabled}
        placeholder={`${placeholder} ($)`}
        onChange={handleChange}
        onBlur={handleBlur}
        {...inputProps}
      />
    </InputContainer>
      )
    })

export default DollarAmountInput
