import { Center, Tooltip } from '@chakra-ui/react'
import React, { CSSProperties, PropsWithChildren, useMemo } from 'react'

export const HeaderButton = ({
  active,
  children,
  onClick,
  size,
  label,
  width,
  style,
}: PropsWithChildren<{
  active?: boolean
  onClick?: () => void
  size?: 'small' | 'medium' | 'large'
  label: string
  width?: number
  style?: CSSProperties
}>) => {
  const sizeNum = useMemo(() => {
    switch (size) {
      case 'small':
        return 25
      case 'large':
        return 30
      default:
        return 28
    }
  }, [size])

  return (
    <Center
      as={onClick ? 'button' : 'div'}
      onClick={onClick}
      p="0.1rem"
      style={{
        opacity: active ? 1 : 0,
        height: sizeNum,
        transition: 'all 333ms',

        cursor: 'pointer',
        overflow: 'hidden',
        pointerEvents: active ? 'auto' : 'none',
        ...style,
      }}
    >
      <Tooltip label={label} hasArrow placement="top">
        <Center
          borderRadius={6}
          py={1}
          width={`${active ? width || sizeNum : 0}px`}
          _hover={{
            bg: 'rgba(0,0,0,0.1)',
          }}
        >
          {children}
        </Center>
      </Tooltip>
    </Center>
  )
}
