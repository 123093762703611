import React, { PropsWithChildren, createContext, useState } from 'react'

export type EditableState = {
  openedOn: number | null
  onClose: () => void
  registerPopover: (id: number) => void
}
export const EditableStateContext = createContext<EditableState>({
  openedOn: null,
  onClose: () => {},
  registerPopover: () => {},
})

export const EditableStateProvider = ({ children }: PropsWithChildren) => {
  const [openedOn, setOpenedOn] = useState<number | null>(null)
  const registerPopover = (id: number) => setOpenedOn(id)
  return (
    <EditableStateContext.Provider
      value={{ openedOn, registerPopover, onClose: () => setOpenedOn(null) }}
    >
      {children}
    </EditableStateContext.Provider>
  )
}
