import { Box, Center, Text } from '@chakra-ui/react'
import {
  DataListTab,
  Fax,
  FaxSortKey,
  FAX_RECEIVED_COLLECTION,
  FAX_SENT_COLLECTION,
  formatPhoneNumber,
  TELNYX_FAX_NUMBER_PROD,
} from '@hb/shared'

import React, { useContext, useState } from 'react'
import { ScreenContext, SearchBarProvider } from '../../contexts'
import { DataList } from '../DataList'
import { useProfileTabNames } from '../Users/Profile/hooks'
import { UserProfileTabName } from '../Users/Profile/types'
import {
  UserProfile,
} from '../Users/Profile/UserProfile'
import { PageContainer } from '../Views/PageContainer'

import { AssignFaxToAssessmentModal } from './AssignFaxToAssessmentModal'
import { receivedFaxColumns, sentFaxColumns } from './columns'
import { FaxesViewContext } from './context'
import { FaxesViewProvider } from './FaxesViewProvider'

export const sentFaxTab: DataListTab<Fax, FaxSortKey> = {
  collection: FAX_SENT_COLLECTION,
  columns: sentFaxColumns,
  searchStringPath: 'searchString',
  defaultSortKey: 'created_at',
  itemName: 'sent fax',
}

export const receivedFaxTab: DataListTab<Fax, FaxSortKey> = {
  collection: FAX_RECEIVED_COLLECTION,
  columns: receivedFaxColumns,
  searchStringPath: 'searchString',
  defaultSortKey: 'created_at',
  itemName: 'received fax',
}

export const faxesTabs: Record<string, DataListTab<Fax, FaxSortKey>> = {
  'Sent Faxes': sentFaxTab,
  'Received Faxes': receivedFaxTab,
}

// remove spaces from search query
const transformSearchQuery = (query: string) => query.replace(/\s/g, '')
const FaxesViewBody = () => {
  const { tabIndex, onChangeTab } = useContext(FaxesViewContext)
  const { contentHeight } = useContext(ScreenContext)
  return (
    <PageContainer>
      <Box position="relative">
        <SearchBarProvider transformQuery={transformSearchQuery}>
          <DataList
            tabIndex={tabIndex}
            height={contentHeight - 50}
            onTabChange={onChangeTab}
            rootPath="fax"
            tabs={faxesTabs}
          />
        </SearchBarProvider>
        <Center zIndex={2} position="absolute" top={0} right={0} py={2} px={3}>
          <Text fontFamily="Open Sans" color="gray.600">
            HB Fax #: {formatPhoneNumber(TELNYX_FAX_NUMBER_PROD)}
          </Text>
        </Center>
      </Box>
    </PageContainer>
  )
}

const FaxesViewAssessmentModal = () => {
  const { viewAssessment, viewedAssessmentId } = useContext(FaxesViewContext)
  const profileTabNames = useProfileTabNames()
  const [tabName, setTabName] = useState<UserProfileTabName>(profileTabNames[0])
  return (
    <UserProfile
      onClose={() => viewAssessment(null)}
      assessmentId={viewedAssessmentId || undefined}
      selectAssessment={viewAssessment}
      onTabSelect={setTabName}
      tab={tabName}
    />
  )
}

export const FaxesView = () => (
  <FaxesViewProvider>
    <FaxesViewBody />
    <FaxesViewAssessmentModal />
    <AssignFaxToAssessmentModal />
  </FaxesViewProvider>
)
