import { EmailField, InputElement, InputProps } from '@hb/shared'
import * as React from 'react'
import { forwardRef } from 'react'
import InputContainer from '../../Shared/InputContainer'
import { TextInputElement } from '../styles'

const EmailInput: InputElement<EmailField> = forwardRef<
  HTMLInputElement,
  InputProps<EmailField>
>((props, ref) => {
  const { input, field, disabled } = props
  const { placeholder } = field

  return (
    <InputContainer {...props}>
      <TextInputElement
        ref={ref}
        disabled={disabled}
        placeholder={placeholder}
        autoComplete={field.autocomplete}
        {...input}
      />
    </InputContainer>
  )
})

export default EmailInput
