import {
  CustomRenderElementProps,
  ImageElement as IImageElement,
  ImageElementOptions,
} from '@hb/shared'
import React, { CSSProperties, useMemo } from 'react'
import { getResizedStoragePath } from '../../../backend/storage'
import { useFile, UseFileData } from '../../../hooks/backend/storage/downloadFile'
import placeholderImage from '../../../icons/image.svg'
import { LoadingImage } from '../../shared/LoadingImage'

const baseStyle: CSSProperties = {
  boxSizing: 'border-box',
  zIndex: 2,
}

export const ImageElementBox = ({
  attributes,
  children,
  options,
  onPointerDown,
  style: styleProp,
}: {
  attributes: CustomRenderElementProps['attributes']
  children: CustomRenderElementProps['children']
  options?: ImageElementOptions
  onPointerDown?: (e: React.PointerEvent<Element>) => void
  style?: CSSProperties
}) => {
  const { width = 100, position } = options || {}

  const style = useMemo<CSSProperties>(() => {
    if (position) {
      return {
        ...baseStyle,
        position: 'absolute',
        top: position.y,
        left: position.x,
        userSelect: 'none',
        width,
        ...styleProp,
      }
    }
    return {
      ...baseStyle,
      width,
      display: 'inline-flex',
      position: 'relative',
      userSelect: 'none',
      ...styleProp,
    }
  }, [position, width, styleProp])
  return (
    <div
      {...attributes}
      contentEditable={false}
      onPointerDown={onPointerDown}
      style={style}
    >
      {children}
    </div>
  )
}

export const ImageElement = ({
  node,
  children,
  attributes,
}: Omit<CustomRenderElementProps, 'node'> & { node: IImageElement }) => {
  const { options, value } = node
  const { name, storagePath, type } = value || {}
  const { opacity = 1, rotate = 0, width = 100 } = options || {}

  const resizedStoragePath = useMemo(
    () => (storagePath ? getResizedStoragePath(storagePath, type) : null),
    [storagePath, type],
  )
  const useFileOptions = useMemo<UseFileData>(
    () => ({
      path: resizedStoragePath || undefined,
      pathLoading: false,
      value,
    }),
    [value, resizedStoragePath],
  )
  const { url, loading } = useFile(useFileOptions)
  return (
    <ImageElementBox options={options} attributes={attributes} >
      <LoadingImage
        containerStyle={{
          display: 'inline-block',
        }}
        opacity={opacity}
        transform={`rotate(${rotate}deg)`}
        src={url}
        style={{
          width: `${width}px`,
        }}
        placeholderSrc={placeholderImage}
        draggable={false}
        userSelect="none"
        loading={loading}
        alt={name}
        width={width}
      />
      <span style={{ display: 'none' }}>{children}</span>
    </ImageElementBox>
  )
}
