import { ArrowForwardIcon } from '@chakra-ui/icons'
import { Flex, Text } from '@chakra-ui/react'
import * as React from 'react'
import { CSSProperties } from 'react'
import loadingIcon from '../icons/loading.svg'
import { signOut } from '../store'
import { SolidActionButton } from './Buttons'
import { Container } from './Container'

export type LoadingProps = {
  loading?: boolean
  text?: string
  vertical?: boolean
  style?: CSSProperties
  height?: number
  withSignOut?: boolean
}

const LoadingIcon: React.FC<LoadingProps> = ({
  loading,
  text,
  vertical,
  style,
  withSignOut,
  height = 60,
}) => (
  <Container
    style={{
      position: 'absolute',
      opacity: loading ? 1 : 0,
      pointerEvents: loading ? 'auto' : 'none',
      height: '100%',
      top: 0,
      transition: 'opacity 500ms',
      fontSize: '14px',
      flexFlow: 'column',
      gap: '1rem',
      ...style,
    }}
  >
    <Flex
      flex={1}
      flexFlow={vertical ? 'column' : 'row'}
      alignItems="center"
      justifyContent="center"
    >
      <img
        src={loadingIcon}
        style={{ height, position: 'relative', right: '5px' }}
      />
      {text ? (
        <span
          style={{ whiteSpace: 'nowrap', position: 'relative', right: '10px' }}
        >
          {text}
        </span>
      ) : null}
    </Flex>
    {withSignOut ? (
      <Flex p={4} justify='center' w="100%" maxW="500px">
        <SolidActionButton gap={2} onClick={() => signOut()}>
          <Text>Sign Out</Text>
          <ArrowForwardIcon />
        </SolidActionButton>
      </Flex>
    ) : null}
  </Container>
)

export default LoadingIcon
