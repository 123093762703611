/* eslint-disable no-bitwise */
/* eslint-disable no-nested-ternary */
import { CircularProgress, HStack, Text } from '@chakra-ui/react'
import { colors } from '@hb/shared/constants'
import { ThreadType } from '@hb/shared/types'
import React, {
  useContext,
} from 'react'
import {
  UserContext,
} from '../../../../contexts'
import { ThreadPopover } from '../../../Messaging/ThreadPopover'

export const AssessmentThreadView = () => {
  const { assessmentId } = useContext(UserContext)
  return assessmentId ? <ThreadPopover id={assessmentId} threadType={ThreadType.ASSESSMENT} /> : (
    <HStack>
      <CircularProgress color={colors.green.hex} isIndeterminate size={4}/>
      <Text fontSize='sm' color='gray.500'>Loading assessment thread...</Text>
    </HStack>
  )
}
