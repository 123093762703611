import {
  Box, HStack, Text,
} from '@chakra-ui/react'
import { ALPHABET } from '@hb/shared'
import React, { useMemo } from 'react'
import { useField } from 'react-final-form'
import { useFieldArray } from 'react-final-form-arrays'
import { ActionButton } from '../../../../../Buttons/ActionButton'
import { DeleteButton } from '../../../../../Buttons/DeleteButton'
import { FormElement } from '../../../../../forms/Input'
import { customDiagnosisCodeField, diagnosisCodeSelectField } from '../fields'

export const DiagnosisCodeInput = ({
  name,
  index,
  onDelete,
}: {
  name: string
  index: number
  onDelete: () => void
}) => {
  const {
    input: { value },
  } = useField(name, { subscription: { value: true } })
  const field = useMemo(
    () => ({ ...diagnosisCodeSelectField, placeholder: `Diagnosis Code ${ALPHABET[index]}` }),
    [index],
  )

  const customCodeField = useMemo(
    () => ({
      ...customDiagnosisCodeField,
      placeholder: `Diagnosis Code ${ALPHABET[index]}`,
      condition: null,
    }),
    [index],
  )
  return (
    <HStack
      spacing={2}
      align='center'
      w='100%'
    >
      <FormElement name={`${name}.diagnosisCodeSelect`} field={field} />
      {value?.diagnosisCodeSelect === 'other' ? (
      <FormElement name={`${name}.customDiagnosisCode`} field={customCodeField} />
      ) : null }
      <Box position='relative' top='4px'>
      <DeleteButton noConfirm itemName='Diagnosis Code' onDelete={async () => onDelete()} />
      </Box>
    </HStack>
  )
}

export const DiagnosisCodesInput = () => {
  const { fields } = useFieldArray('DiagnosisCodes', { subscription: {} })

  return (
    <Box px={3} pb={3} w='100%'>
      <Text fontWeight={600} color='gray.500' fontSize='md'>
        Diagnosis Codes
      </Text>
      {fields.map((name, idx) => (
        <DiagnosisCodeInput
          index={idx}
          onDelete={() => fields.remove(idx)}
          key={name}
          name={name}
        />
      ))}
      <ActionButton mt={2} size='xs' onClick={() => fields.push({})}>
        + Add Diagnosis Code
      </ActionButton>
    </Box>
  )
}
