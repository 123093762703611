import { Divider, Text, VStack } from '@chakra-ui/react'
import {
  addressField,
  colors,
  DollarAmountField,
  faxField,
  FieldMap,
  FieldTypes,
  InvoicingSettings,
  INVOICING_SETTINGS,
  PercentageField,
  phoneField,
} from '@hb/shared'

import React from 'react'
import { useDocument, useUpdateDoc } from '../../hooks'
import { DataView } from '../DataView'
import { EditableRow } from '../forms'

const above12000FeePercentageField: PercentageField = {
  type: FieldTypes.PERCENTAGE,
  placeholder: 'Invoice fee ($12,000 or above)',
}

const below12000FeePercentageField: PercentageField = {
  type: FieldTypes.PERCENTAGE,
  placeholder: 'Invoice fee (under $12,000)',
}

const clearingHouseFeeField: DollarAmountField = {
  type: FieldTypes.DOLLAR_AMOUNT,
  placeholder: 'Clearing House Fee (Added to each monthly invoice)',
}

const invoiceHeaderAddressField: FieldMap = {
  ...addressField,
  name: 'Address',
}

const invoiceHeaderField: FieldMap = {
  name: 'Invoice Header (Appears on the top of every invoice)',
  children: {
    address: invoiceHeaderAddressField,
    phone: phoneField,
    fax: faxField,
    mobile: { ...phoneField, placeholder: 'Mobile' },
  },
}

export const InvoiceSettingsView = () => {
  const { data: feesSettings, ref: invoicingSettingsFeesRef } = useDocument<
    InvoicingSettings['fees']
  >(INVOICING_SETTINGS, 'fees')

  const { data: templateSettings, ref: invoicingSettingsTemplatesRef } = useDocument<InvoicingSettings['template']>(INVOICING_SETTINGS, 'template')

  const update = useUpdateDoc()
  return (
    <VStack w='100%' p={3} bg={`${colors.green.hex}77`} align='flex-start'>
      <VStack
        spacing={0}
        align='flex-start'
        p={3}
        borderRadius={6}
        boxShadow='md'
        bg='gray.50'
        w='100%'
      >
        <Text px={2} fontSize='lg' fontFamily='Comfortaa'>
          Fees
        </Text>
        <EditableRow
          label='Under $12,000'
          value={feesSettings?.under12000}
          onSubmit={(v) => update(invoicingSettingsFeesRef, 'under12000', v)}
          field={below12000FeePercentageField}
        />
        <EditableRow
          label='$12,000 or above'
          value={feesSettings?.other}
          onSubmit={(v) => update(invoicingSettingsFeesRef, 'other', v)}
          field={above12000FeePercentageField}
        />
        <EditableRow
          label='Clearing house fee'
          value={feesSettings?.clearingHouseFee}
          onSubmit={(v) => update(invoicingSettingsFeesRef, 'clearingHouseFee', v)
          }
          field={clearingHouseFeeField}
        />
        <Divider />
        <DataView
          alwaysExpanded
          data={templateSettings?.header}
          onSubmit={(v) => update(invoicingSettingsTemplatesRef, 'header', v)}
          field={invoiceHeaderField}
        />
      </VStack>
    </VStack>
  )
}
