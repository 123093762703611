import { CheckIcon } from '@chakra-ui/icons'
import {
  Flex,
  IconButton,
  Popover,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  Text,
} from '@chakra-ui/react'
import { FieldTypes, getReverseName, TextField } from '@hb/shared'

import { deleteField, DocumentReference } from 'firebase/firestore'
import React, { useContext } from 'react'
import { Field, Form } from 'react-final-form'
import { ThemeContext } from '../../../contexts/ThemeContext'
import { UserContext } from '../../../contexts/UserContext'
import { useUpdateDoc } from '../../../hooks/backend/useUpdateDoc'
import { DeleteButton } from '../../Buttons/DeleteButton'
import { ColorSelect } from '../../ColorSelect'
import Input from '../../forms/Input'
import { SortColorCircle } from '../UrgentBadge'

export const getIsUrgent = (item: any | null) => {
  if (item == null) return false
  if ('urgentReason' in item) return !!item.urgentReason
  return false
}

const urgentReasonField: TextField = {
  type: FieldTypes.TEXT,
  placeholder: 'Urgent reason',
}

export const UrgentPopoverContent = ({
  item,
  updateRef,
}: {
  item: any
  updateRef?: DocumentReference | null
}) => {
  const displayedName = getReverseName(item)
  const { urgentColor } = item || {}
  // const isUrgent = getIsUrgent(item)
  const update = useUpdateDoc()
  return (
    <ThemeContext.Provider value={{ theme: 'basic', placeholderAbove: true }}>
      <PopoverCloseButton />
      <PopoverHeader fontSize='sm' fontWeight={600}>
        <Flex align='center'>
          {item ? (
            <ColorSelect
              style={{ zIndex: 2, borderRadius: '100%' }}
              withAdmins
              setColor={(v) => {
                update(updateRef, '', {
                  urgentColor: v || deleteField(),
                })
              }}
              color={urgentColor}
            />
          ) : null}
          <Text color='gray.600' fontFamily='Comfortaa' ml={2}>
            {displayedName}
          </Text>
        </Flex>
      </PopoverHeader>
      <PopoverBody p={0}>
        {item ? (
          <Form
            onSubmit={({ reason }) => update(updateRef, 'urgentReason', reason)}
            initialValues={{
              reason: 'urgentReason' in item ? item?.urgentReason : '',
            }}
          >
            {({ handleSubmit, submitting }) => (
              <Flex px={2} align='flex-start'>
                <Flex py={2} flexGrow={1}>
                  <Field name='reason'>
                    {(props) => (
                      <Input
                        {...props}
                        field={urgentReasonField}
                      />
                    )}
                  </Field>
                </Flex>
                <Flex pt={1} ml='auto'>
                  <IconButton
                    variant='ghost'
                    size='xs'
                    aria-label='delete'
                    icon={<CheckIcon />}
                    onClick={handleSubmit}
                    isLoading={submitting}
                  />
                  <DeleteButton
                    itemName='Urgent reason'
                    onDelete={() => update(updateRef, 'urgentReason', deleteField())}
                  />
                </Flex>
              </Flex>
            )}
          </Form>
        ) : (
          <Text px={2} py={1}>
            Missing user
          </Text>
        )}

        {/* {isClaim ? null : <OtherReasons item={item} />} */}
      </PopoverBody>
    </ThemeContext.Provider>
  )
}

export const ProfileUrgentPopover = () => {
  const { selectedAssessment } = useContext(UserContext)
  const { urgentColor, adminRef } = selectedAssessment || {}
  const isUrgent = getIsUrgent(selectedAssessment)
  return (
    <Popover trigger='hover'>
      {/* @ts-ignore */}
      <PopoverTrigger>
        <SortColorCircle withAdmins urgent={isUrgent} color={urgentColor} />
      </PopoverTrigger>
      <PopoverContent>
        <UrgentPopoverContent item={selectedAssessment} updateRef={adminRef} />
      </PopoverContent>
    </Popover>
  )
}
