import {
  Badge, Divider, Flex, HStack, Text, Tooltip, VStack,
} from '@chakra-ui/react'
import {
  formatDollarValue,
  getDateString,
  kebabCaseToTitleCase,
  midwivesCollection,
  PracticeInvoice,
  WithId,
} from '@hb/shared'
import React, { useMemo } from 'react'
import { useApp } from '../../../contexts'
import { useCollectionItem } from '../../../hooks/backend/useCollectionItem'
import { InvoiceActions } from './InvoiceActions'

export const InvoiceSummary = ({
  invoice,
}: {
  invoice: WithId<PracticeInvoice>
}) => {
  const { item: practice } = useCollectionItem(midwivesCollection, invoice.practiceId)
  const { month, year } = useMemo(() => {
    const scheduleDate = invoice.scheduledFor
    if (!scheduleDate) return { month: '', year: '' }
    const date = new Date(scheduleDate)
    return {
      month: date.toLocaleString('default', { month: 'long' }),
      year: date.getFullYear(),
    }
  }, [invoice])

  const { appName } = useApp()
  return (
    <>
      <Flex minH="40px" py={1} w="100%" pr="48px" align="center">
        <Text
          flex={1}
          minW="0"
          isTruncated
          px={3}
          color="gray.800"
          // fontWeight='600'
          py={1}
          fontFamily="Comfortaa"
          fontSize="lg"
        >
          {practice?.name}
        </Text>
        <Badge
          // bg={colors.pink.hex}
          // color='white'
          color="gray.600"
          fontSize="sm"
          m={0}
        >
          {invoice?.invoiceNumber
            ? `Invoice #${invoice.invoiceNumber}`
            : 'Unsent invoice'}
        </Badge>
      </Flex>
      <Divider />
      <Flex
        fontFamily="Hero-New"
        px={3}
        bg="gray.50"
        py={1}
        w="100%"
        justify="space-between"
      >
        <Flex>
          {invoice.scheduledFor ? (
            <Flex flex={1} direction="column">
              <Text fontWeight={500} fontFamily='Open Sans' color="gray.500" fontSize="sm">
                Scheduled for
              </Text>
              <Text fontSize="sm" color="gray.600">
                {getDateString(invoice.scheduledFor, 'short')}
              </Text>
            </Flex>
          ) : null}
        </Flex>
        <Flex align="center" direction="column">
          <Text fontWeight={500} fontFamily='Open Sans' color="gray.500" fontSize="sm">
            Due Date
          </Text>
          <Text color="gray.600" fontSize="sm">
            {getDateString(invoice.dueDate, 'short')}
          </Text>
        </Flex>
        {invoice.paidOn ? (
          <Flex direction="column">
            <Text fontWeight={500} fontFamily='Open Sans' color="gray.500" fontSize="sm">
              Paid On
            </Text>
            <Text color="gray.600" fontSize="sm">
              {getDateString(invoice.paidOn, 'short')}
            </Text>
          </Flex>
        ) : null}
        <Flex align="center" direction="column">
          <Text fontWeight={500} fontFamily='Open Sans' color="gray.500" fontSize="sm">
            Amount Due
          </Text>
          <Text color="gray.600" fontSize="sm">
            {formatDollarValue(invoice?.amount)}
          </Text>
        </Flex>
        <Flex align="flex-end" direction="column">
          <Text fontWeight={500} fontFamily='Open Sans' color="gray.500" fontSize="sm">
            Status
          </Text>
          <Text color="gray.600" fontSize="sm">
            {kebabCaseToTitleCase(invoice?.status)}
          </Text>
        </Flex>
      </Flex>
      <Divider />

      <VStack spacing={1} px={3} py={2} bg="white" flex={1} w="100%">
        <Flex align="center" w="100%">
          <Flex flex={1} minW='0'>
            <Tooltip textAlign='center' label={`${month} ${year} invoice for ${practice?.name}`} placement='top' hasArrow>
            <Text
              isTruncated
              minW='0'
              flex={1}
              lineHeight={1.4}
              whiteSpace="nowrap"
              fontFamily="Open Sans"
              fontWeight={600}
              color="gray.500"
              fontSize="md"
            >
              {month} {year} invoice for {practice?.name}
            </Text>
            </Tooltip>
            {invoice?.deletedOn ? (
              <Badge
                bg="red.500"
                ml={2}
                fontSize="sm"
                fontWeight={500}
                color="red.200"
              >
                Deleted {getDateString(invoice.deletedOn, 'short')}
              </Badge>
            ) : null}
          </Flex>
          {appName === 'app' ? (
            <HStack px={2} justify="flex-end" flex={1} ml="auto">
              <InvoiceActions invoice={invoice} />
            </HStack>
          ) : null}
        </Flex>
      </VStack>
    </>
  )
}
