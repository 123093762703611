import { MIDWIVES } from '@hb/shared/collections'
import {
  PopulatedAssessment, PracticeWithAdmin, User,
} from '@hb/shared/types'
import { getShortcutArgs } from '@hb/shared/utils'
import { useMemo } from 'react'
import { useApp, usePracticeAccess } from '../../contexts'
import { useDocument } from '../backend/useDocument'

export const useShortcutArgs = (
  user?: User | null | undefined,
  assessment?: PopulatedAssessment | undefined,
) => {
  const { appName } = useApp()
  const { selectedPracticeId } = usePracticeAccess()
  const { data: practice } = useDocument<PracticeWithAdmin>(MIDWIVES, selectedPracticeId)

  return useMemo(() => {
    if (appName === 'providers-app') {
      return getShortcutArgs(user, assessment, practice)
    }
    return getShortcutArgs(user, assessment)
  }, [appName, user, assessment, practice])
}
