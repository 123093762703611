import { Screen } from '@hb/shared/types'
import { createContext } from 'react'

export type ScreenContextData = Screen & {
  isMobile: boolean
}
export const ScreenContext = createContext<ScreenContextData>({
  width: 0,
  height: 0,
  contentWidth: 0,
  contentHeight: 0,
  isMobile: false,
})
