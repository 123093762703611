import { InputProps } from '@hb/shared'
import React, { CSSProperties, PropsWithChildren, useContext } from 'react'
import { ThemeContext } from '../../../../contexts'
import { InputContainerBase } from '../styles'
import { useStatusColor } from './hooks'

const InputContainer = ({
  onClick, children, field, meta, style,
}: PropsWithChildren<{
  onClick?: () => void
  hideLabel?: boolean
  style?: CSSProperties
} & InputProps<any>>) => {
  const { theme } = useContext(ThemeContext)
  const boxShadow = useStatusColor(field, meta)

  return (
      <InputContainerBase
        onClick={onClick}
        px={theme === 'detailed' ? 3 : 2}
        py={theme === 'detailed' ? '0.3rem' : '0.15rem'}
        bg={theme === 'detailed' ? 'white' : undefined}
        boxShadow={boxShadow}
        style={style}
      >
        {children}
      </InputContainerBase>
  )
}

export default InputContainer
