import { View } from '@hb/shared/types/views'
import { useMemo } from 'react'

export const useNestedViews = (views: View[]) => useMemo(() => {
  const publicViews = []
  const userViews = []
  const adminViews = []
  const superAdminViews = []
  for (let i = 0; i < views.length; i += 1) {
    const v = views[i]
    if (v.onMenu) {
      if (v.access) {
        if (v.access.indexOf('user') !== -1) {
          userViews.push(v)
        } else if (v.access.indexOf('admin') !== -1) {
          adminViews.push(v)
        } else if (v.access.indexOf('super-admin') !== -1) {
          superAdminViews.push(v)
        }
      } else {
        publicViews.push(v)
      }
    }
  }

  return {
    publicViews, userViews, adminViews, superAdminViews,
  }
}, [views])
