import {
  Badge,
  BadgeProps,
  CircularProgress,
  Tooltip,
  TooltipProps,
} from '@chakra-ui/react'
import {
  AppName,
  colors,
  getDisplayedRole,
  getNameAndInitials,
  UserGroup,
  UserRole,
} from '@hb/shared'
import React, { useMemo } from 'react'
import { useCachedUser } from '../../collections/hooks/cached'
import { useApp } from '../../contexts/AppContext'

export const getBadgeColor = (
  appName: AppName,
  group?: UserGroup | 'Admin' | 'Practice Member' | 'Practice Admin' | 'Patient' | UserRole,
) => {
  switch (group) {
    case 'admin':
    case 'Admin':
    case 'super-admin':
      return appName === 'app' ? colors.green.hex : colors.pink.hex
    case 'Practice Member':
    case 'Practice Admin':
    case 'practice':
      return appName === 'app' ? colors.pink.hex : colors.green.hex
    case 'Patient':
    case 'user':
    default:
      return '#dbcca0'
  }
}

export const UserBadge = ({
  userId,
  tooltipProps,
  inverted,
  noTooltip,
  circular,
  ...props
}: BadgeProps & {
  userId: string
  circular?: boolean
  inverted?: boolean
  noTooltip?: boolean
  tooltipProps?: Omit<TooltipProps, 'children'>
}) => {
  const { data: user, loading } = useCachedUser(userId)
  const { appName } = useApp()
  const role = useMemo(
    () => (user ? getDisplayedRole(user) : 'Patient'),
    [user],
  )
  const { name, initials } = useMemo(
    () => getNameAndInitials(appName, user, true),
    [user, appName],
  )
  const color = useMemo(() => getBadgeColor(appName, role), [appName, role])
  const isPink = color === colors.pink.hex
  let textColor = 'white'
  if (inverted) textColor = color
  if (isPink) textColor = 'gray.800'
  const body = (
    <Badge
      cursor="pointer"
      opacity="0.8"
      alignItems="center"
      justifyContent="center"
      filter={inverted ? 'brightness(0.6) saturate(1.5)' : 'none'}
      boxShadow={inverted ? 'none' : '1px 1px 4px rgba(0,0,0,0.5)'}
      textShadow={inverted || isPink ? 'none' : '1px 1px 2px #00000066'}
      display="flex"
      fontWeight={500}
      width={circular ? '26px' : 'auto'}
      height={circular ? '26px' : 'auto'}
      borderRadius={circular ? '100%' : 0}
      fontFamily="Hero-New"
      color={textColor}
      letterSpacing="wider"
      px={inverted ? 0 : 1}
      bg={inverted ? 'transparent' : color}
      {...props}
    >
      {loading ? <CircularProgress isIndeterminate size={4} /> : initials}
    </Badge>
  )
  if (!initials) return null
  return noTooltip ? (
    body
  ) : (
    <Tooltip
      textShadow="1px 1px 3px black"
      placement="top"
      bg={color}
      color="white"
      hasArrow
      label={name}
      {...tooltipProps}
    >
      {body}
    </Tooltip>
  )
}
