import {
  Center,
  HStack,
  Image,
  Popover,
  PopoverArrow,
  PopoverContent,
  PopoverTrigger,
  Slider,
  SliderFilledTrack,
  SliderThumb,
  SliderTrack,
  Text,
  Tooltip,
  VStack,
} from '@chakra-ui/react'
import { colors } from '@hb/shared/constants'
import React, { useEffect } from 'react'
import useSound from 'use-sound'
import { useDebounce } from '../../../hooks/useDebounce'
import volumeIcon from '../../../icons/volume.svg'
import newMessageSound from '../../../sounds/ding.mp3'
import { useThreadsList } from '../contexts'

export const NotificationsVolumeControl = () => {
  const { widget } = useThreadsList()
  const {
    data: widgetData,
    updateLocalNotificationVolume,
    localNotificationVolume,
    update,
  } = widget || {}
  const { notificationVolume: volume } = widgetData || {}

  const debouncedValue = useDebounce(localNotificationVolume, 500)

  const [play] = useSound(newMessageSound, {
    volume: localNotificationVolume || 0.25,
  })
  useEffect(() => {
    if (debouncedValue && widgetData?.notificationVolume !== debouncedValue) {
      update({ notificationVolume: debouncedValue }).catch((err) => {
        console.log(err)
      })
    }
  }, [debouncedValue, widgetData, update])
  return (
    <VStack
      mr={4}
      p={2}
      userSelect="none"
      pr={4}
      spacing={0}
      align='flex-start'
      w='100%'
      boxShadow="1px 1px 3px #00000077"
      borderRadius={6}
      bg="white"
      overflow="hidden"
    >
      <Text fontFamily="Open Sans" fontSize="sm" color="gray.600">
        Web Notification Volume
      </Text>
      <HStack w="100%">
        <Image src={volumeIcon} w="22px" opacity={0.5} />
        <Slider
          value={(localNotificationVolume || volume || 0.25) * 100}
          max={100}
          min={0}
          onPointerUp={() => {
            play()
          }}
          step={1}
          colorScheme="gray"
          onChange={(e) => {
            updateLocalNotificationVolume(e / 100)
          }}
        >
          <SliderTrack
            overflow="hidden"
            borderRadius="full"
            h="6px"
            bg="blackAlpha.400"
          >
            <SliderFilledTrack bg={colors.green.hex} />
          </SliderTrack>
          <Tooltip
            label={((localNotificationVolume || volume || 0.25) * 100).toFixed(
              0,
            )}
            placement="top"
            hasArrow
            bg="gray.50"
            color="gray.600"
          >
            <SliderThumb />
          </Tooltip>
        </Slider>
      </HStack>
    </VStack>
  )
}

export const NotificationVolumePopover = () => (
    <Center position="relative">
      <Popover strategy="fixed" trigger="hover">
        <PopoverTrigger>
          <Image
            filter="invert(100%)"
            cursor="pointer"
            src={volumeIcon}
            w="22px"
            opacity={0.8}
          />
        </PopoverTrigger>
        <PopoverContent
          filter="drop-shadow(1px 1px 4px #00000077)"
          border="none"
          bg="transparent"
        >
          <NotificationsVolumeControl />
          <PopoverArrow />
        </PopoverContent>
      </Popover>
    </Center>
)
