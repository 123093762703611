import {
  defaultStageValidate, defaultValidation, Field, FieldMap, isField, isListField, ListField,
} from '@hb/shared'
import { useMemo } from 'react'

export const useFormValidate = (
  field: Field | FieldMap | ListField,
) => useMemo(() => {
  if (isField(field)) {
    return ({ value: v }: { value?: any }) => {
      if (field.optional && !v) return undefined
      const error = field.validate
        ? field.validate(v)
        : defaultValidation[field.type](v, field)
      if (error) return { value: error }
      return undefined
    }
  }
  if (isListField(field)) return () => undefined
  return field.validate || defaultStageValidate(field)
}, [field])
