import {
  Button,
  Center,
  Divider,
  Flex,
  HStack,
  Text,
  VStack,
} from '@chakra-ui/react'
import { colors } from '@hb/shared/constants'
import { getInvoiceSent } from '@hb/shared/invoicing'
import { AssessmentInvoiceSnippet } from '@hb/shared/types'
import { formatDollarValue } from '@hb/shared/utils'
import React, { useMemo } from 'react'
import { Link } from 'react-router-dom'
import { usePaymentInvoiceSnippets } from '../Users/Profile/Payments/hooks'
import { InvoiceStatusView } from './InvoiceStatusView'

const InvoicePaymentDetails = ({
  paymentId,
  assessmentInvoiceSnippet,
  isThisInvoice,
  removeLoading,
  onRemove,
}: {
  paymentId: string
  assessmentInvoiceSnippet: AssessmentInvoiceSnippet
  removeLoading?: boolean
  isThisInvoice?: boolean
  onRemove?: () => Promise<void>
}) => {
  const paymentSnippet = assessmentInvoiceSnippet.chargedPayments[paymentId]
  const invoiceSent = getInvoiceSent(assessmentInvoiceSnippet)
  const fallbackPercent = useMemo(() => {
    const snippetTotal = Object.values(
      assessmentInvoiceSnippet.chargedPayments,
    ).reduce((acc, payment) => acc + payment.amount, 0)
    return snippetTotal <= 12000 ? 8 : 10
  }, [assessmentInvoiceSnippet])
  if (!paymentSnippet) {
    return <Text color="red.600">Something went wrong: payment not found</Text>
  }
  return (
    <VStack w="100%" align="flex-start" spacing={0}>
      <Link to={`/super-admin/invoices/${assessmentInvoiceSnippet.invoiceId}`}>
        <Button
          variant="link"
          lineHeight={1}
          w="100%"
          pb={1}
          fontWeight={500}
          fontSize="sm"
          color="gray.600"
        >
          {assessmentInvoiceSnippet.invoiceNumber
            ? `INVOICE #${assessmentInvoiceSnippet.invoiceNumber}`
            : 'UPCOMING INVOICE'}{' '}
          {isThisInvoice ? '(This Invoice)' : ''}
        </Button>
      </Link>
      <Text
        w="100%"
        pb={1}
        lineHeight={1}
        fontWeight={600}
        fontSize="sm"
        color="gray.600"
      >
        {assessmentInvoiceSnippet.practiceName}
      </Text>
      <VStack
        border="1px solid #cdcdcd"
        borderRadius={4}
        w="100%"
        py={1}
        px={2}
        bg="white"
        divider={<Divider />}
        align="flex-start"
        spacing={1}
      >
        <Text fontWeight={600} color={colors.green.hex} fontSize="sm">
          {`${formatDollarValue(
            assessmentInvoiceSnippet.chargedPayments[paymentId].invoicedAmount,
          )} @ ${assessmentInvoiceSnippet.chargedPayments[paymentId].usedFeePercent || fallbackPercent}% = ${formatDollarValue(
            assessmentInvoiceSnippet.chargedPayments[paymentId].feeAmount,
          )}`}
        </Text>
        <InvoiceStatusView invoice={assessmentInvoiceSnippet} />
        {!assessmentInvoiceSnippet.manualPaymentIds?.includes(paymentId) ? (
          <Text>Automatically scheduled by system</Text>
        ) : (
          <VStack align="flex-start" width="100%" spacing={0}>
            <Text>Manually added to invoice</Text>
            {invoiceSent || !onRemove ? null : (
              <Button
                onClick={onRemove}
                isLoading={removeLoading}
                variant="ghost"
                color="red.600"
                size="xs"
                fontSize="sm"
              >
                <Center
                  mr={1}
                  color="red.600"
                  width="12px"
                  height="12px"
                  borderRadius={4}
                  border="1px solid #cc0000"
                  as="span"
                >
                  <Text
                    fontSize="lg"
                    position="relative"
                    bottom="1px"
                    fontWeight={600}
                  >
                    -
                  </Text>
                </Center>
                <Text>Remove from Invoice</Text>
              </Button>
            )}
          </VStack>
        )}
      </VStack>
    </VStack>
  )
}

export const InvoicePaymentHistoryBody = ({
  invoiceSnippets,
  paymentId,
  thisInvoiceId,
  onRemove,
  removeLoading,
}: {
  invoiceSnippets: Record<string, AssessmentInvoiceSnippet>
  onRemove?: (invoiceId: string) => Promise<void>
  removeLoading?: boolean
  thisInvoiceId?: string
  paymentId: string
}) => {
  const sortedByDate = useMemo(() => {
    const sorted = Object.entries(invoiceSnippets).sort(
      ([, a], [, b]) => {
        if (!b.scheduledFor) return -1
        if (!a.scheduledFor) return 1
        return new Date(b.scheduledFor).getTime() - new Date(a.scheduledFor).getTime() < 0 ? 1 : -1
      },
    )
    return sorted
  }, [invoiceSnippets])

  const { totalInvoiced, totalFee } = useMemo(() => {
    let invoiced = 0
    let fee = 0
    sortedByDate.forEach(([, invoice]) => {
      const payment = invoice.chargedPayments[paymentId]
      invoiced = Math.max(invoiced, payment.amount)
      fee += payment.feeAmount
    })
    return { totalInvoiced: invoiced, totalFee: fee }
  }, [sortedByDate, paymentId])
  const roundedPercent = useMemo(() => {
    if (totalInvoiced === 0) return 0
    return ((totalFee / totalInvoiced) * 100).toFixed(1)
  }, [totalFee, totalInvoiced])
  return (
    <VStack divider={<Divider />} flexDirection="column">
      {sortedByDate.map(([invoiceId, invoiceSnippet]) => (
        <InvoicePaymentDetails
          key={invoiceId}
          onRemove={onRemove ? () => onRemove(invoiceId) : undefined}
          removeLoading={removeLoading}
          paymentId={paymentId}
          isThisInvoice={thisInvoiceId === invoiceId}
          assessmentInvoiceSnippet={invoiceSnippet}
        />
      ))}
      <Flex flexDirection="column" w="100%" align="flex-start">
        <HStack w="100%" spacing={1}>
          <Text color="gray.600" fontSize="sm" fontWeight={600}>
            Total Amount:
          </Text>
          <Text color="green.600" fontWeight={600} fontSize="sm">
            {formatDollarValue(totalInvoiced)}
          </Text>
          {totalInvoiced ? (
            <Flex align="center" ml="auto">
              <Text
                bg={colors.green.hex}
                color="white"
                textShadow="1px 1px 1px #00000077"
                borderRadius="full"
                px={1}
                fontWeight={600}
                fontSize="sm"
              >
                {roundedPercent}%
              </Text>
            </Flex>
          ) : null}
        </HStack>
        <HStack spacing={1}>
          <Text color="gray.600" fontSize="sm" fontWeight={600}>
            Total Fee:
          </Text>
          <Text color="green.600" fontWeight={600} fontSize="sm">
            {formatDollarValue(totalFee)}
          </Text>
        </HStack>
      </Flex>
    </VStack>
  )
}

export const InvoicePaymentHistory = ({
  paymentId,
  assessmentInvoiceSnippets,
  thisInvoiceId,
  onRemove,
  removeLoading,
}: {
  paymentId: string
  thisInvoiceId?: string
  assessmentInvoiceSnippets: Record<string, AssessmentInvoiceSnippet>
  onRemove?: (invoiceId: string) => Promise<void>
  removeLoading?: boolean
}) => {
  const paymentInvoiceSnippets = usePaymentInvoiceSnippets(
    assessmentInvoiceSnippets,
    paymentId,
  )
  return (
    <InvoicePaymentHistoryBody
      invoiceSnippets={paymentInvoiceSnippets}
      paymentId={paymentId}
      thisInvoiceId={thisInvoiceId}
      removeLoading={removeLoading}
      onRemove={onRemove}
    />
  )
}
