import { Collapse } from '@chakra-ui/react'
import {
  NestedFieldItem, TemplateFieldElement, Toolbar, VariableElement as IVariableElement,
} from '@hb/shared'

import {
  isTemplateField, Shortcut, TemplateField, WithId,
} from '@hb/shared/types'
import { isVariableOrFieldNode } from '@hb/shared/utils'
import React, { useCallback, useContext, useMemo } from 'react'
import { Editor, Path, Transforms } from 'slate'
import { useSlate, useSlateSelection } from 'slate-react'
import { Container } from '../../Container'
import { TemplateEditorContext } from '../context'
import { NestedOptionSelect } from './NestedOptionSelect'
import { getDropdownItems } from './utils'

export const TemplateToolbar: Toolbar = ({
  mode,
  version,
  document,
  templateType,
}) => {
  const {
    shortcutArgs: { assessment },
  } = useContext(TemplateEditorContext)
  const editor = useSlate()
  const selection = useSlateSelection()

  const { dropdownItems, numChildren, numAllChildren } = useMemo(() => {
    const items = getDropdownItems(assessment, templateType || document?.type)
    const num = Object.keys(items).map((key) => {
      const item = items[key]
      return Object.keys(item.children).length
    })
    const numAll = num.reduce((acc, curr) => acc + curr, 0)
    return { dropdownItems: items, numChildren: num, numAllChildren: numAll }
  }, [assessment, document, templateType])

  const insertVariableOrField = useCallback(
    (v: NestedFieldItem) => {
      const item = Object.keys(v).reduce(
        (acc, curr) => (v[curr] ? { ...acc, [curr]: v[curr] } : acc),
        {} as WithId<TemplateField | Shortcut>,
      )

      const createdNode = isTemplateField(item)
        ? ({
          field: item,
          id: item.id,
          children: [{ text: v.id }],
          type: 'field',
        } as TemplateFieldElement)
        : ({
          ...item,
          children: [{ text: v.name }],
          dataType: item.type,
          isInline: true,
          type: 'variable',
        } as IVariableElement)

      if (selection) {
        if (version === 'v1') {
          Transforms.insertNodes(editor, [
            createdNode,
            {
              type: 'span',
              children: [{ text: '' }],
            },
          ])
        } else {
          // if selection is variable element, insert node after it
          const [currentNode, nodePath] = Editor.node(
            editor,
            selection.focus.path,
          )
          const [parentNode, parentPath] = Editor.parent(editor, nodePath)
          if (isVariableOrFieldNode(currentNode)) {
            Transforms.insertNodes(editor, createdNode, {
              at: Path.next(nodePath),
              select: true,
            })
          } else if (isVariableOrFieldNode(parentNode)) {
            Transforms.insertNodes(editor, [createdNode], {
              at: Editor.after(editor, parentPath),
              select: true,
            })
          } else {
            Transforms.insertNodes(editor, createdNode, { select: true })
          }
        }
      }
    },
    [selection, editor, version],
  )

  return (
    <Collapse unmountOnExit in={mode === 'Edit'} style={{ width: '100%' }}>
      <Container
        style={{
          height: 'auto',
          justifyContent: 'flex-start',
          flexFlow: 'row wrap',
          borderBottom: '1px solid #cdcdcd',
          boxSizing: 'border-box',
          background: 'white',
          zIndex: 2,
        }}
      >
        {Object.keys(dropdownItems).map((itemId, index) => (
          <NestedOptionSelect
            values={assessment?.mergedData?.[itemId]}
            key={`${itemId}_${index}`}
            id={itemId}
            zIndex={
              2
              + numAllChildren
              - index
              - numChildren.slice(0, index).reduce((acc, curr) => acc + curr, 0)
            }
            onItemClick={insertVariableOrField}
            field={dropdownItems[itemId]}
          />
        ))}
      </Container>
    </Collapse>
  )
}
