import { BoxProps, Stack, Text } from '@chakra-ui/react'
import { colors } from '@hb/shared'
import { ContactButtons } from '@hb/shared-frontend'
import React from 'react'

export const PostSignOnText = (props: BoxProps) => (
  <Stack p={2} {...props}>
    <Text
      mb={2}
      fontFamily='Comfortaa'
      color={colors.green.hex}
      fontWeight={400}
      fontSize='18px'
    >
      Thank you!
    </Text>
    <Text >
    Thank you for confirming you would like to sign on!
    If you haven't already, please reach out to the midwife in order to schedule an appointment.
    </Text>
    <Text >
    As always, please don't hesitate to schedule a call with us
    or reach out via email if you have any questions about your assessment.
    </Text>
    <Text>
    As a friendly reminder, the initial retainer is due by your first visit.
    Accepted payment methods are listed at the bottom of your assessment.
    </Text>
    <ContactButtons pt={4}/>
  </Stack>
)
