import { PathPrefix } from '@hb/shared'
import React, { PropsWithChildren } from 'react'
import { FormFilesContext } from './FormFilesContext'

export type UsePopulateAppPrefixResult =
  | {
      item: string
      error?: never
    }
  | {
      item?: never
      error: string
    }

export const getFormFilesProvider = (
  populatePathPrefixHook: () => (pathPrefix: PathPrefix) => UsePopulateAppPrefixResult,
) => ({ children }: PropsWithChildren<{}>) => {
  const populatePrefixFunction = populatePathPrefixHook()
  return (
      <FormFilesContext.Provider value={{ populatePrefixFunction }}>
        {children}
      </FormFilesContext.Provider>
  )
}
