import { forwardRef } from '@chakra-ui/react'
import React, { useContext } from 'react'
import { ThemeContext } from '../../../../contexts'

export const TextInputElement = forwardRef<
  React.DetailedHTMLProps<
    React.InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  >,
  'input'
>(({ style, ...props }, ref) => {
  const { theme } = useContext(ThemeContext)
  return (
  <input
    ref={ref}
    style={{
      width: '100%',
      fontSize: '14px',
      outline: 'none',
      background: 'transparent',
      padding: theme === 'basic' ? '0 0.15rem' : '0 0.25rem',
      border: 'none',
      color: props.disabled ? '#777' : '#111',
      height: theme === 'basic' ? '1.2rem' : '2rem',
      ...style,
    }}
    {...props}
  />
  )
})
