import { Comment } from '@hb/shared/types'
import { DocumentReference, setDoc, updateDoc } from 'firebase/firestore'
import { useCallback, useContext, useState } from 'react'
import { useApp } from '../../contexts/AppContext'
import { PopUpMessageContext } from '../../contexts/PopUpMessage/PopUpMessageContext'
import { addMetadata } from '../../utils/data'

export const useSaveComment = (docRef: DocumentReference, fieldPath: string) => {
  const { processResponse } = useContext(PopUpMessageContext)
  const { appName } = useApp()
  const [savingComment, setSavingComment] = useState(false)
  const saveComment = useCallback(async (id: string | null, text: string) => {
    setSavingComment(true)
    const usedId = id || `${Date.now()}`
    const comment: Comment = addMetadata({
      text,
      reactions: [],
      replies: {},
    }, appName, id === null)
    try {
      if (id === null) {
        await setDoc(docRef, {}, { merge: true })
      }
      await updateDoc(docRef, `${fieldPath}.${usedId}`, comment)
    } catch (err: any) {
      processResponse({ error: err?.message || 'An error occurred adding the comment' })
    }
    setSavingComment(false)
    return usedId
  }, [docRef, fieldPath, processResponse, appName])
  return { saveComment, savingComment }
}
