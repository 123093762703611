import {
  Button, Flex, Stack, StackDivider, Tooltip,
} from '@chakra-ui/react'
import { Field } from '@hb/shared'
import React, { FC, useMemo } from 'react'
import { useFormState } from 'react-final-form'
import { formatValue } from '../../../utils'

export const Presets:FC<{
  field: Field,
  onClickPreset: (val: any) => void
}> = ({ field, onClickPreset }) => {
  const { values } = useFormState({ subscription: { values: true } })
  const presets = useMemo(() => (typeof field.presets === 'function' ? field.presets(values) : field.presets), [field, values])
  return (
    presets?.length
      ? <Flex w='100%'>
      <Stack overflow='hidden' spacing={0} border='1px solid #cdcdcd' borderColor='green.300' borderRadius={4} my={1} divider={<StackDivider/>} direction='row'>
        {presets.map((p) => (
          <Tooltip placement='top' hasArrow colorScheme='green' key={`${p.title}`} label={formatValue({ field, value: p.data })}>

        <Button
          onClick={() => onClickPreset(p.data)}
          variant='ghost'
          borderRadius={0}
          bg='green.100'
          colorScheme='green'
          size='xs'
          >
            {p.title}
          </Button>
          </Tooltip>
        ))}
      </Stack>
    </Flex> : null
  )
}
