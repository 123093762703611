import {
  CircularProgress, Flex, HStack, Text,
} from '@chakra-ui/react'
import {
  PageContainer, ScreenContext, useAuth,
} from '@hb/shared-frontend'
import { PatientConsentForms } from '@hb/shared-frontend/components/Users/Profile/ConsentForms/PatientConsentForms'
import React, { useContext } from 'react'
import SignInToView from 'src/User/SignInToView'

export const MyConsentForms = () => {
  const { contentWidth } = useContext(ScreenContext)
  const { authUser, loading } = useAuth()
  if (!authUser) {
    return <SignInToView message='Sign in to view your documents' />
  }
  const body = loading ? (
    <HStack p={2} bg='white' borderRadius={6} boxShadow='md'>
      <CircularProgress isIndeterminate />
      <Text> Loading your account... </Text>
    </HStack>
  ) : (
    <Flex
      flex={1}
      p={2}
      w='100%'
      overflowY='auto'
      width={contentWidth}
      position='relative'
      direction='column'
      align='center'
    >
      <PatientConsentForms patientId={authUser.uid} />
    </Flex>
  )

  return (
    <PageContainer>
      {body}
    </PageContainer>
  )
}
