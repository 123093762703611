import { Flex, Stat, StatArrow } from '@chakra-ui/react'
import React, { forwardRef, useContext } from 'react'
import { DataListContext } from '../../contexts/DataListContext'

export const SortButton = forwardRef<
  HTMLDivElement,
  {
    sortKey: string
    noArrow?: boolean
    children?: React.ReactNode
    defaultDir?: 'asc' | 'desc'
  }
>(({
  sortKey, children, noArrow, defaultDir,
}, ref) => {
  const {
    sortBy,
    sortKey: currKey,
    sortDesc: desc,
  } = useContext(DataListContext)
  const active = currKey === sortKey
  const onClick = () => sortBy(sortKey, defaultDir)
  return (
    <Flex pl={2} ref={ref} align="center" onClick={onClick} cursor="pointer">
      {/* <Button size='sm'  fontWeight={active ? 600 : 400} variant='unstyled'> */}
      <Flex lineHeight="10px" whiteSpace='nowrap' align="center">
        {children}
        {/* </Button> */}
      </Flex>
      {!noArrow ? (
        <Flex
          justify="center"
          minW={active ? '30px' : 0}
          w={active ? '30px' : 0}
          transition="all 333ms"
          opacity={active ? 1 : 0}
          // overflow="hidden"
          align="center"
        >
          <Stat>
            <StatArrow
              transition="width 333ms"
              width={3}
              height={3}
              ml={active ? 2 : 0}
              cursor="pointer"
              _hover={{ color: 'blackAlpha.900' }}
              color={active ? 'blackAlpha.800' : 'blackAlpha.600'}
              type={desc ? 'decrease' : 'increase'}
            />
          </Stat>
        </Flex>
      ) : null}
    </Flex>
  )
})
