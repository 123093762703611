import { medicaidProvidersCollection, providersCollection } from '../collections/collections/insurer'
import { FieldTypes } from '../constants'
import {
  CheckboxField,
  DateField,
  DropdownField,
  EmailField,
  Field,
  FieldCondition,
  FieldMap,
  IdField,
  PhoneField,
  TextField,
} from '../types'
import { addressField, fnameField, lnameField } from './shared'

const parentPartnerCondition: FieldCondition = (v) => ['partner', 'parent'].includes(v?.policyOwnerRelationship)
export const policyOwnerSexField: DropdownField = {
  type: FieldTypes.DROPDOWN,
  placeholder: 'Policy Owner Sex',
  hints: [{ text: 'To submit claims to your insurer, you must pick one' }],
  options: [
    { text: 'Male', id: 'M' },
    { text: 'Female', id: 'F' },
  ],
}

const policyOwnerFields: Record<string, Field | FieldMap> = {
  policyOwnerName: {
    name: 'Policy Owner Name',
    children: {
      fname: fnameField,
      lname: lnameField,
    },
  },
  policyOwnerSex: policyOwnerSexField,
  policyOwnerDob: {
    type: FieldTypes.DATE,
    placeholder: 'Policy owner DOB',
    labels: [{ text: 'What is the date of birth of the policy owner?' }],
  },
  policyOwnerSameAddress: {
    optional: true,
    type: FieldTypes.CHECKBOX,
    labels: [{ text: 'What is the address of the policy owner?' }],
    placeholder: 'Policy owner address same as patient address',
  },
  policyOwnerAddress: {
    condition: (v) => !v?.policyOwnerSameAddress,
    ...addressField,
    name: 'Policy Owner Address',
    labels: [
      {
        text: "What is the policy owner's address (as listed with the insurer)?",
      },
    ],
  },
  policyOwnerPhone: {
    type: FieldTypes.PHONE,
    labels: [{ text: "What is the policy owner's phone number?" }],
    placeholder: 'Policy Owner Phone Number',
  },
  policyOwnerEmail: {
    type: FieldTypes.EMAIL,
    labels: [{ text: "What is the policy owner's email?" }],
    placeholder: 'Policy Owner Email',
  },
}

export const patientAddressField = {
  ...addressField,
  name: 'Patient address',
  labels: [
    {
      text: 'What is your address (as listed with your insurer)?',
    },
  ],
}

export const patientPhoneField: PhoneField = {
  type: FieldTypes.PHONE,
  labels: [
    { text: 'What is your phone number (as listed with your insurer)?' },
  ],
  placeholder: 'Phone Number',
}

export const policyOwnerRelationshipField: DropdownField = {
  type: FieldTypes.DROPDOWN,
  placeholder: 'Policy Owner Relationship',
  labels: [
    { text: 'What is the relationship between you and the policy owner?' },
  ],
  options: [
    {
      text: 'Myself',
      id: 'self',
    },
    { text: 'Partner', id: 'partner' },
    { text: 'Parent', id: 'parent' },
  ],
}

export const partnerNameField: TextField = {
  type: FieldTypes.TEXT,
  placeholder: "Partner's Name",
}
export const partnerEmailField: EmailField = {
  optional: true,
  type: FieldTypes.EMAIL,
  placeholder: "Partner's Email",
}
export const partnerPhoneField: PhoneField = {
  type: FieldTypes.PHONE,
  placeholder: "Partner's Phone",
  optional: true,
}
export const partnerContactField: FieldMap = {
  condition: (v) => v?.hasPartner,
  name: 'Partner Contact',
  children: {
    name: partnerNameField,
    phone: partnerPhoneField,
    email: partnerEmailField,
  },
}

const initialServiceDateField: DateField = {
  type: FieldTypes.DATE,
  optional: true,
  labels: [{ text: 'When did you first consult with your midwife?' }],
  placeholder: 'Initial date of service (consultation)',
}

const initialPrenatalVisitDateField: DateField = {
  type: FieldTypes.DATE,
  optional: true,
  labels: [{ text: 'When was your first prenatal visit with your midwife? (Could be in the future)' }],
  placeholder: 'Date of first prenatal visit',
}

const homeAddressIsSameAsPatientAddressField: CheckboxField = {
  type: FieldTypes.CHECKBOX,
  placeholder: 'Home address same as above address',
}

const homeAddressField: FieldMap = {
  name: 'Home Address',
  condition: (v) => !v?.homeAddressSameAsPatientAddress,
  children: addressField.children,
}

export const insurerIdField: IdField = {
  labels: [
    {
      text: 'Who is your secondary insurer?',
    },
  ],
  type: FieldTypes.ID,
  collection: providersCollection,
  placeholder: 'Insurance Provider',
  allowOther: true,
  condition: (v) => !v?.isMedicaid,
}

export const medicaidInsurerIdField: IdField = {
  labels: [
    {
      text: 'Who is your secondary insurer? (Medicaid)',
    },
  ],
  type: FieldTypes.ID,
  condition: (v) => !!v?.isMedicaid,
  collection: medicaidProvidersCollection,
  placeholder: 'Insurance Provider (Medicaid)',
  allowOther: true,
}

export const signOnFields: FieldMap = {
  name: 'Sign-on Form',
  children: {
    birthType: {
      type: FieldTypes.DROPDOWN,
      placeholder: 'Birth Location',
      labels: [
        { text: 'What type of birth are you planning/leaning towards?' },
      ],
      options: [
        { id: 'home', text: 'Home birth' },
        { id: 'hospital', text: 'Hospital birth' },
        { id: 'birthing-center', text: 'Birthing center birth' },
      ],
    },
    address: patientAddressField,
    homeAddressSameAsPatientAddress: homeAddressIsSameAsPatientAddressField,
    homeAddress: homeAddressField,
    phone: patientPhoneField,
    initialServiceDate: initialServiceDateField,
    initialPrenatalVisitDate: {
      ...initialPrenatalVisitDateField,
      placeholder: 'Date of first prenatal visit',
    },
    hasPartner: {
      type: FieldTypes.BOOLEAN,
      placeholder: 'Patient has Partner',
      labels: [{ text: 'Do you have a partner?' }],
    },
    partnerContact: partnerContactField,
    policyOwnerRelationship: policyOwnerRelationshipField,
    selfPolicyOwnerDetails: {
      type: FieldTypes.DROPDOWN,
      condition: (v) => v?.policyOwnerRelationship === 'self' || v?.policyOwnerRelationship === 'parent',
      placeholder: 'Marital Status',
      labels: [{ text: 'Please choose one' }],
      options: [
        { text: 'I am legally married', id: 'married' },
        { text: 'I have a domestic partnership', id: 'domestic-partnership' },
        { text: 'I am single', id: 'single' },
      ],
    },
    partnerPolicyOwnerDetails: {
      type: FieldTypes.DROPDOWN,
      condition: (v) => v?.policyOwnerRelationship === 'partner',
      placeholder: 'Marital Status',
      labels: [{ text: 'Please choose one' }],
      options: [
        { text: 'I am legally married', id: 'married' },
        { text: 'I have a domestic partnership', id: 'domestic-partnership' },
      ],
    },
    policyOwnerInfo: {
      name: 'Policy Owner Details',
      condition: parentPartnerCondition,
      children: policyOwnerFields,
    },
    hasSecondaryCoverage: {
      type: FieldTypes.BOOLEAN,
      labels: [{ text: 'Do you have secondary coverage?' }],
      placeholder: 'Secondary Coverage',
    },
    secondaryCoverageDetails: {
      name: 'Secondary Coverage Details',
      condition: (v) => v?.hasSecondaryCoverage,
      children: {
        policyOwnerRelationship: policyOwnerRelationshipField,
        isMedicaid: {
          type: FieldTypes.BOOLEAN,
          placeholder: 'Is your secondary coverage through Medicaid?',
        },
        insurerId: insurerIdField,
        medicaidInsurerId: medicaidInsurerIdField,
        memberId: {
          type: FieldTypes.TEXT,
          placeholder: 'Member ID for Secondary Coverage',
        },
        initialServiceDate: {
          ...initialServiceDateField,
          placeholder: 'Initial Date of Service (Secondary Coverage)',
        },
        initialPrenatalVisitDate: {
          ...initialPrenatalVisitDateField,
          placeholder: 'Date of First Prenatal Visit (Secondary Coverage)',
        },
        ...policyOwnerFields,
      },
    } as FieldMap,
  },
}
