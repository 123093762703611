import {
  Flex, HStack, Text, VStack,
} from '@chakra-ui/react'
import {
  addressToString,
  CheckboxField,
  DropdownField,
  FieldTypes,
  resolveProviderClaimsAddress,
} from '@hb/shared'
import React, { useContext, useMemo } from 'react'
import { ThemeContext } from '../../../../../../contexts/ThemeContext'
import { UserContext } from '../../../../../../contexts/UserContext'
import { FormElement } from '../../../../../forms/Input'

const omitPayToAddressField: CheckboxField = {
  type: FieldTypes.CHECKBOX,
  inputType: 'switch',
  placeholder: 'Omit Pay To Address',
  optional: true,
}
export const ReferringProvider = () => {
  const { selectedAssessment } = useContext(UserContext)
  const { midwife } = selectedAssessment || {}
  const midwifeListedAs = useMemo(() => {
    if (!midwife?.fname) {
      return 'Provider missing first name for billing (edit in Providers page)'
    }
    if (!midwife.lname) {
      return 'Provider missing last name for billing (edit in Providers page)'
    }
    return `${midwife.lname}, ${midwife.fname}`
  }, [midwife])

  const claimsAddress = useMemo(
    () => resolveProviderClaimsAddress(midwife),
    [midwife],
  )

  const { servicingAddresses, payToAddress } = midwife || {}
  const servicingAddressField = useMemo<DropdownField>(
    () => ({
      type: FieldTypes.DROPDOWN,
      placeholder: 'Servicing Address',
      options:
        servicingAddresses?.map((a, i) => ({
          id: `${i}`,
          text: addressToString(a),
        })) || [],
      optional: true,
    }),
    [servicingAddresses],
  )

  return (
    <Flex direction="column">
      <Text fontWeight={600} color="gray.500" fontSize="sm">
        Billing/Referring provider
      </Text>
      <Text>{midwife?.name}</Text>
      <Text
        color={midwife?.fname && midwife.lname ? 'gray.600' : 'red.500'}
        fontSize="sm"
      >
        {midwife?.fname && midwife.lname
          ? `Listed as: ${midwifeListedAs}`
          : midwifeListedAs}
      </Text>
      <HStack>
        <Text fontWeight={600} color="gray.500" fontSize="sm">
          NPI
        </Text>
        {midwife?.isGroup ? (
          <Text
            color={midwife?.group?.npi ? 'gray.600' : 'red.500'}
            fontSize="sm"
          >
            {midwife?.group?.npi
              ? `Using group NPI: ${midwife.group?.npi}`
              : 'Missing practice NPI (edit in providers page)'}
          </Text>
        ) : (
          <Text fontSize="sm" color={midwife?.npi ? 'gray.600' : 'red.500'}>
            {midwife?.npi || 'Missing provider NPI (edit in providers page)'}
          </Text>
        )}
      </HStack>
      <HStack>
        <Text fontWeight={600} color="gray.500" fontSize="sm">
          Billing Address
        </Text>
        <Text color={claimsAddress ? 'gray.600' : 'red.500'} fontSize="sm">
          {claimsAddress
            ? addressToString(claimsAddress)
            : 'Missing claims address on provider profile'}
        </Text>
      </HStack>
      <HStack spacing={1} w="100%">
        <Text
          whiteSpace="nowrap"
          fontWeight={600}
          color="gray.500"
          fontSize="sm"
        >
          Servicing Address
        </Text>
        {servicingAddresses?.length ? (
          <Flex w="100%" direction="column">
            {servicingAddresses?.length > 1 ? (
              <ThemeContext.Provider value={{ theme: 'basic' }}>
                <FormElement
                  field={servicingAddressField}
                  name="servicingAddressIndex"
                />
              </ThemeContext.Provider>
            ) : (
              <Text fontSize="sm" color="gray.600">
                {addressToString(servicingAddresses[0])}
              </Text>
            )}
          </Flex>
        ) : (
          <Text color="red.500" fontSize="sm">
            Missing claims address on provider profile
          </Text>
        )}
      </HStack>
      <VStack align="flex-start" spacing={0} w="100%">
        <HStack>
          <Text fontWeight={600} color="gray.500" fontSize="sm">
            Pay To Address
          </Text>
          <Text color={payToAddress ? 'gray.600' : 'gray.500'} fontSize="sm">
            {payToAddress
              ? addressToString(payToAddress)
              : 'No Pay To Address on provider profile'}
          </Text>
        </HStack>
        {payToAddress ? (
          <HStack pt={1}>
            <FormElement
              name="omitPayToAddress"
              field={omitPayToAddressField}
            />
          </HStack>
        ) : null}
      </VStack>
    </Flex>
  )
}
