import { User } from '@hb/shared'

export const getTypedSignatureError = (firstName: string, middleInitial: string, lastName: string, user: User | null) => {
  let newError = ''
  if (!firstName && !lastName && !middleInitial) newError = ''
  else if (!firstName) newError = 'First name required'
  else if (!lastName) newError = 'Last name required'
  else if (firstName !== user?.fname) newError = 'First name does not match your name on file'
  else if (lastName !== user?.lname) newError = 'Last name does not match your name on file'
  else if (middleInitial && middleInitial.length !== 1) newError = 'Middle initial must be one character'
  return newError
}
