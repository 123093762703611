import { ChakraProvider, ColorModeScript } from '@chakra-ui/react'
import {
  AppProvider, chakraTheme, PopUpMessageProvider, ToastContainer,
} from '@hb/shared-frontend'
import { MainWindowProvider } from '@hb/shared-frontend/contexts/MainWindowContext'
import 'focus-visible/dist/focus-visible'
import React from 'react'
import { CookiesProvider } from 'react-cookie'
import { createRoot } from 'react-dom/client'
import { BrowserRouter as Router } from 'react-router-dom'
import App from './App'
import { APP_NAME } from './constants'

const container = document.getElementById('root')!
const root = createRoot(container)
root.render(
  <>
  <ColorModeScript initialColorMode={chakraTheme.config.initialColorMode} />
  <MainWindowProvider>
    <ChakraProvider
      theme={chakraTheme}
      // @ts-ignore
      portalZIndex={3}
      toastOptions={{ defaultOptions: { position: 'top' } }}>
      <Router>
        <CookiesProvider>
          <PopUpMessageProvider>
            <AppProvider appName={APP_NAME}>
              <App />
            </AppProvider>
          </PopUpMessageProvider>
        </CookiesProvider>
      </Router>
      {/* </StylesProvider> */}
    </ChakraProvider>
    <ToastContainer />
  </MainWindowProvider>
  </>,
)
