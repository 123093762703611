import {
  Badge, BadgeProps, Tooltip, TooltipProps,
} from '@chakra-ui/react'
import React from 'react'

export interface SquareBadgeProps extends Partial<BadgeProps> {
  label?: string
  tooltipProps?: Partial<TooltipProps>
}
export const SquareBadge: React.FC<SquareBadgeProps> = ({
  label,
  tooltipProps,
  ...props
}) => {
  const body = (
    <Badge
      display="flex"
      alignItems="center"
      justifyContent="center"
      w="20px"
      h="20px"
      {...props}
    />
  )
  if (label) {
    return (
      <Tooltip placement="top" label={label} {...tooltipProps}>
        {body}
      </Tooltip>
    )
  }
  return body
}
