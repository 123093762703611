import {
  HStack, Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Text,
} from '@chakra-ui/react'
import React, { useContext } from 'react'

import { SearchAssessments } from '../Assessments/SearchAssessments'
import { FaxesViewContext } from './context'
// uses FaxesViewContext to prompt the user to select a
// patient and then an assessment belonging to that patient
export const AssignFaxToAssessmentModal = () => {
  const {
    faxAssignment: {
      assigningToFaxId,
      assignToFax,
      onSubmit,
    },
  } = useContext(FaxesViewContext)

  return (
    <Modal
      isOpen={!!assigningToFaxId}
      onClose={() => assignToFax(null)}
      size="xl"
      scrollBehavior="inside"
    >
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton />
        <ModalBody borderRadius={6} p={0} bg="gray.100">
          <HStack py={3} px={4}>
            <Text>Assign Fax to Assessment</Text>
          </HStack>
          <SearchAssessments
            onSubmit={async (assessmentId) => {
              await onSubmit(assessmentId)
            }}
            />
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}
