import { UserRoleItem, UserWithPracticeData } from '../../types'
import { adminCollectionAccess, practiceCollectionAccess } from '../constants'
import { Collection } from '../types/collection'
import { MIDWIVES } from './practice'

export const USER_ROLES = 'user-roles'
export const getPracticePatientDocPath = (
  midwifeId: string,
  patientId: string,
) => `${MIDWIVES}/${midwifeId}/${USER_ROLES}/${patientId}`

export const getPracticeUsersCollectionPath = (midwifeId: string) => `${MIDWIVES}/${midwifeId}/${USER_ROLES}`

export const patientsCollection: Collection<UserRoleItem> = {
  _type: 'firestoreCollection',
  name: 'Patients',
  refPath: USER_ROLES,
  access: adminCollectionAccess,
  filters: [['role', '==', 'user']],
  noRanks: true,
  defaultSort: 'lname',
  shouldNotFetchAll: true,
}

export const getPracticePatientsCollection: (practiceId: string) => Collection<UserWithPracticeData> = (practiceId) => ({
  _type: 'firestoreCollection',
  name: 'Patients',
  refPath: getPracticeUsersCollectionPath(practiceId),
  access: practiceCollectionAccess,
  filters: [['role', '==', 'user']],
  noRanks: true,
  defaultSort: 'lname',
  shouldNotFetchAll: true,
})
