import { EmailIcon, TimeIcon } from '@chakra-ui/icons'
import {
  Box,
  CircularProgress,
  HStack,
  Image,
  Stack,
  Text,
  Tooltip,
  VStack,
} from '@chakra-ui/react'
import {
  AssessmentDocument,
  capitalizeFirstLetterOfEachWord,
  colors,
  ConsentForm,
  Fax,
  FieldMapValue,
  getDateString,
  getDateTimeString,
  isConsentForm,
  TemplateCollectionId,
  templateCollectionToName,
  TemplateKey,
  templateKeyToCollection,
  templateTypeNames,
  WithId,
} from '@hb/shared'
import React, {
  useCallback, useContext, useMemo, useState,
} from 'react'
import { PopUpMessageContext } from '../../../../contexts/PopUpMessage/PopUpMessageContext'
import { ScreenContext } from '../../../../contexts/ScreenContext'
import { signConsentForm } from '../../../../hooks/backend/consentForms'
import { reportDocumentViewed } from '../../../../hooks/backend/documents/utils'
import { useMe } from '../../../../hooks/backend/useMe'
import signatureIcon from '../../../../icons/signature.svg'
import signatureGreenIcon from '../../../../icons/signature_green.svg'
import { FaxPreview } from '../../../Faxes/FaxView'
import { TextViewModal } from '../../../RichText/TextViewModal'

const modalHeaders: Partial<Record<TemplateCollectionId, string>> = {
  authAppealsTemplates: 'Auth Appeal',
  authInstructionsTemplates: 'Auth Instructions',
  invoicesReceiptsTemplates: 'Invoice / Receipt',
}

const DocumentExpiredView = ({
  document,
}: {
  document: AssessmentDocument
}) => {
  const { isMobile } = useContext(ScreenContext)
  const fullText = `${document.expired ? 'Expired' : 'Expires'} at ${getDateTimeString(document.expiresOn, 'short')}`
  return document.expiresOn < Infinity ? (
    <Tooltip
      placement="top"
      hasArrow
      bg="gray.500"
      color="white"
      label={fullText}
    >
      <Stack
        w={isMobile ? '100%' : 'auto'}
        direction={isMobile ? 'column' : 'row'}
        borderRadius={4}
        spacing={1}
        px={2}
        py="0.1rem"
        bg="gray.400"
      >
        <HStack spacing={1}>
          <TimeIcon w="14px" color="gray.50" />
          <Text
            color="white"
            fontSize="sm"
            height="18px"
            fontWeight={600}
            fontFamily="Comfortaa"
          >
            {getDateString(document.expiresOn, 'short')}
          </Text>
        </HStack>
        {isMobile ? (
          <Text
            color="white"
            fontSize="sm"
            height="18px"
            fontWeight={600}
            fontFamily="Comfortaa"
          >
            {fullText}
          </Text>
        ) : null}
      </Stack>
    </Tooltip>
  ) : null
}

const ConsentFormSignedView = ({ document }: { document: ConsentForm }) => {
  const { isMobile } = useContext(ScreenContext)
  const signedText = useMemo(() => {
    if (!document.signedOn) return 'Unsigned'
    const formattedTime = getDateTimeString(document.signedOn, 'short')
    return isMobile ? `Signed at ${formattedTime}` : formattedTime
  }, [document, isMobile])
  return (
    <Tooltip
      placement="top"
      hasArrow
      bg="gray.500"
      color="white"
      label={signedText}
    >
      <Stack
        w={isMobile ? '100%' : 'auto'}
        direction={isMobile ? 'column' : 'row'}
      >
        <HStack
          borderRadius={4}
          spacing={1}
          px={2}
          py="0.1rem"
          bg={document.signedOn ? colors.green.hex : 'gray.400'}
        >
          <Image
            filter="invert(100%) brightness(3) drop-shadow(1px 1px 3px #00000077)"
            src={signatureIcon}
            height="14px"
          />
          <Text
            color="white"
            fontSize="sm"
            height="18px"
            isTruncated
            textShadow="1px 1px 3px #00000077"
            fontWeight={600}
            lineHeight={1.3}
            fontFamily="Comfortaa"
          >
            {signedText}
          </Text>
        </HStack>
      </Stack>
    </Tooltip>
  )
}

const AssessmentDocumentViewedIndicator = ({
  viewedOn,
  reportingViewed,
}: {
  viewedOn?: number
  reportingViewed: boolean
}) => (
  <Tooltip
    placement="top"
    label={
      viewedOn ? `Viewed ${getDateTimeString(viewedOn, 'short')}` : 'Unread'
    }
  >
    <Box>
      {reportingViewed ? (
        <CircularProgress color={colors.green.hex} isIndeterminate size={4} />
      ) : (
        <Image
          src={
            viewedOn
              ? '/images/svg/mail_read.svg'
              : '/images/svg/mail_unread.svg'
          }
          opacity={viewedOn ? 0.5 : 1}
          height="20px"
        />
      )}
    </Box>
  </Tooltip>
)

const ConsentFormSignedIndicator = ({
  document,
}: {
  document: ConsentForm
}) => (
  <Tooltip
    placement="top"
    label={
      document.signedOn
        ? `Signed at ${getDateTimeString(document.signedOn, 'short')}`
        : 'Unsigned'
    }
  >
    <Box>
      <Image
        src={document.signedOn ? signatureGreenIcon : signatureIcon}
        opacity={document.signedOn ? 1 : 0.7}
        height="20px"
      />
    </Box>
  </Tooltip>
)

const DocumentSentOnView = ({
  document,
}: {
  document: AssessmentDocument | ConsentForm
}) => {
  const { isMobile } = useContext(ScreenContext)
  const text = useMemo(() => {
    const timeString = getDateTimeString(document.sentOn, 'short')
    return isMobile ? `Sent at ${timeString}` : timeString
  }, [document, isMobile])
  return (
    <Tooltip
      placement="top"
      hasArrow
      bg={colors.green.hex}
      color="white"
      label={`Sent at ${getDateTimeString(document.sentOn, 'short')}`}
    >
      <HStack
        borderRadius={4}
        spacing={1}
        px={2}
        py="0.1rem"
        bg={colors.green.hex}
      >
        <EmailIcon
          filter="drop-shadow(1px 1px 3px #00000077)"
          color="gray.50"
        />
        <Text
          color="white"
          fontSize="sm"
          height="18px"
          fontWeight={600}
          textShadow="1px 1px 3px #00000077"
          fontFamily="Comfortaa"
        >
          {text}
        </Text>
      </HStack>
    </Tooltip>
  )
}

const AssessmentDocumentView = ({
  document,
}: {
  document: WithId<AssessmentDocument | ConsentForm>
}) => {
  const me = useMe()
  const [isViewing, setIsViewing] = useState(false)
  const collection = useMemo(
    () => templateKeyToCollection[document.type],
    [document],
  )
  const modalHeader = useMemo(
    () => (collection ? modalHeaders[collection] : undefined),
    [collection],
  )
  const capitalizedName = useMemo(
    () => document.name
      || capitalizeFirstLetterOfEachWord(templateCollectionToName[collection]),
    [collection, document],
  )
  const { viewedOn } = document
  const [reportingViewed, setReportingViewed] = useState(false)
  const onViewClick = useCallback(async () => {
    if (!me) return
    setIsViewing(true)
    if (
      !viewedOn
      && me.uid === document.patientId
      && document.type !== 'consentForm'
    ) {
      setReportingViewed(true)
      try {
        await reportDocumentViewed({ documentId: document.id })
      } catch (err: any) {
        // processResponse({
        //   error: err?.message || 'Error reporting document viewed',
        // })
      }
      setReportingViewed(false)
    }
  }, [viewedOn, document, me])

  const { processResponse } = useContext(PopUpMessageContext)

  const onSignConsentForm = useCallback(
    async (formData: FieldMapValue) => {
      if (document.type !== 'consentForm') return { error: 'Not a consent form' }
      try {
        await signConsentForm({ consentFormId: document.id, formData })
      } catch (err: any) {
        return processResponse({
          error: err?.message || 'Error signing consent form',
        })
      }
      return { success: 'Signed consent form!' }
    },
    [document, processResponse],
  )

  const canDownload = useMemo(() => {
    if (document.type === 'invoiceAndReceipt') return true
    if (isConsentForm(document) && document.signedOn) return true
    return false
  }, [document])
  const { isMobile } = useContext(ScreenContext)

  return (
    <Stack
      border="1px solid #cdcdcd"
      borderRadius={4}
      px={2}
      direction={isMobile ? 'column' : 'row'}
      onClick={onViewClick}
      cursor="pointer"
      aria-label="View document"
      py={1}
      bg="white"
      w="100%"
      _hover={{ bg: 'gray.50', border: `1px solid ${colors.green.hex}` }}
      justify="space-between"
    >
      <HStack minW="0" flex={1}>
        {isConsentForm(document) ? (
          <ConsentFormSignedIndicator document={document} />
        ) : (
          <AssessmentDocumentViewedIndicator
            viewedOn={viewedOn}
            reportingViewed={reportingViewed}
          />
        )}
        <Text
          position="relative"
          top="1px"
          color="gray.600"
          fontSize="sm"
          lineHeight={1}
          fontWeight={600}
          fontFamily="Comfortaa"
          minW="0"
          isTruncated
        >
          {capitalizedName}
        </Text>
      </HStack>
      <Stack direction={isMobile ? 'column' : 'row'}>
        <DocumentSentOnView document={document} />
        {!isConsentForm(document) ? (
          <DocumentExpiredView document={document} />
        ) : (
          <ConsentFormSignedView document={document} />
        )}
      </Stack>
      {isViewing ? (
        <TextViewModal
          onFormSubmit={
            me
            && isConsentForm(document)
            && !document.signedBy
            && me.uid === document.toBeSignedByUser
              ? onSignConsentForm
              : undefined
          }
          header={modalHeader}
          version={document.editorVersion || 'v1'}
          withDownload={canDownload}
          value={document.text}
          document={document}
          isOpen
          onClose={() => setIsViewing(false)}
        />
      ) : null}
    </Stack>
  )
}

const isFax = (d: WithId<AssessmentDocument> | ConsentForm | Fax): d is Fax => (d as Fax).type === 'sent' || (d as Fax).type === 'received'

export const DocumentsList = ({
  documents,
  faxes,
  category,
}: {
  documents?: Array<WithId<AssessmentDocument | ConsentForm>>
  faxes?: Array<WithId<Fax>>
  category?: TemplateKey
}) => {
  const docType = useMemo(
    () => (category && templateTypeNames[category]) || 'documents',
    [category],
  )

  const byDate = useMemo(() => {
    if (!documents) return []
    return [...documents, ...(faxes || [])].sort((a, b) => {
      const aAsFax = a as Fax
      const aAsDocument = a as AssessmentDocument
      const bAsFax = b as Fax
      const bAsDocument = b as AssessmentDocument

      const aDate = new Date(aAsFax.created_at || aAsDocument.sentOn).getTime()
      const bDate = new Date(bAsFax.created_at || bAsDocument.sentOn).getTime()
      if (!aDate || !bDate) return 0
      return bDate - aDate
    })
  }, [documents, faxes])

  return (
    <VStack w="100%" mb={2} pt={1} spacing={1} px={2}>
      {byDate?.length ? (
        byDate.map((d) => (isFax(d) ? (
            <FaxPreview key={d.id} fax={d} />
        ) : (
            <AssessmentDocumentView key={d.id} document={d} />
        )))
      ) : (
        <Text
          bg="blackAlpha.100"
          borderRadius={6}
          py={1}
          px={2}
          w="100%"
          color="gray.600"
          fontStyle="italic"
        >
          No {docType} yet
        </Text>
      )}
    </VStack>
  )
}
