import {
  HStack,
  Text,
  VStack,
} from '@chakra-ui/react'
import { FieldTypes } from '@hb/shared/constants'
import { TextField } from '@hb/shared/types'
import {
  TotpMultiFactorGenerator, TotpMultiFactorInfo,
} from 'firebase/auth'
import React, {
  useCallback, useEffect, useState,
} from 'react'
import { signInCallback, useAuth } from '../../../store'
import { SolidActionButton } from '../../Buttons'
import { CollapseError } from '../../CollapseError'
import { StandaloneInput } from '../../forms'

const codeField: TextField = {
  type: FieldTypes.TEXT,
  placeholder: 'Verification Code',
}

export const AuthenticatorVerify = ({
  factor,
}: {
  factor: TotpMultiFactorInfo
}) => {
  const [code, setCode] = useState('')
  const [verifying, setVerifying] = useState(false)
  const [error, setError] = useState('')
  const resolver = useAuth((s) => s.multiFactorResolver)

  const handleVerify = useCallback(() => {
    if (!code) return
    if (!resolver) {
      setError('Error - No session found')
      return
    }
    setVerifying(true)
    const multiFactorAssertion = TotpMultiFactorGenerator.assertionForSignIn(
      factor.uid,
      code,
    )

    resolver
      .resolveSignIn(multiFactorAssertion)
      .then((credential) => signInCallback(credential.user))
      .catch((e) => {
        setError(e.message)
      }).finally(() => setVerifying(false))
  }, [code, factor, resolver])

  useEffect(() => {
    const onEnter = (e: KeyboardEvent) => {
      e.stopPropagation()
      if (e.key === 'Enter') {
        handleVerify()
      }
    }

    window.addEventListener('keydown', onEnter)
    return () => window.removeEventListener('keydown', onEnter)
  }, [handleVerify])

  return (
    <VStack px={1} w="100%" align="flex-start">
      <VStack align="flex-start" w="100%" p={1}>
        <Text>Enter the code on your authenticator app:</Text>
        <StandaloneInput
          theme="detailed"
          field={codeField}
          value={code}
          onChange={setCode}
        />
        <CollapseError error={error} />
        <HStack w="100%">
          <SolidActionButton
            isLoading={verifying}
            ml="auto"
            onClick={handleVerify}
          >
            Verify
          </SolidActionButton>
        </HStack>
      </VStack>
    </VStack>
  )
}
