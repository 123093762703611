import React, {
  createContext,
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react'

export const MainWindowContext = createContext(false)

export const useIsMainWindow = () => useContext(MainWindowContext)

const generateRandomId = () => Math.random().toString(36).substring(7)

const MAIN_WINDOW_ID = 'hamiltonbilling-mainWindowId'

export const useIsMainWindowState = () => {
  const windowId = useRef<string>(generateRandomId())
  const [isMainWindow, setIsMainWindow] = useState(document.visibilityState === 'visible')
  const broadcastChannel = useRef(
    new BroadcastChannel('mainWindowChannel'),
  )

  const checkMainWindow = useCallback(() => {
    const mainWindowId = localStorage.getItem(MAIN_WINDOW_ID)
    if (!mainWindowId || mainWindowId !== windowId.current) {
      localStorage.setItem(MAIN_WINDOW_ID, windowId.current)
      setIsMainWindow(true)
      broadcastChannel.current.postMessage({ type: 'mainWindowChange', id: windowId.current })
    }
  }, [])
  useEffect(() => {
    if (document.visibilityState === 'visible') {
      checkMainWindow()
    }
  }, [checkMainWindow])

  const handleFocusOrVisibilityChange = useCallback(() => {
    if (document.visibilityState === 'visible') {
      checkMainWindow()
    }
  }, [checkMainWindow])

  useEffect(() => {
    window.addEventListener('focus', handleFocusOrVisibilityChange)
    document.addEventListener('visibilitychange', handleFocusOrVisibilityChange)
    const channel = broadcastChannel.current
    const onMessage = (message: MessageEvent) => {
      if (
        message.data.type === 'mainWindowChange'
        && message.data.id !== windowId.current
      ) {
        setIsMainWindow(false)
      }
    }
    channel.addEventListener('message', onMessage)

    return () => {
      window.removeEventListener('focus', handleFocusOrVisibilityChange)
      document.removeEventListener(
        'visibilitychange',
        handleFocusOrVisibilityChange,
      )
      channel.removeEventListener('message', onMessage)
    }
  }, [handleFocusOrVisibilityChange, broadcastChannel, windowId])

  return isMainWindow
}

export const MainWindowProvider = ({ children }: PropsWithChildren) => {
  const isMain = useIsMainWindowState()

  return (
    <MainWindowContext.Provider value={isMain}>
      {children}
    </MainWindowContext.Provider>
  )
}
