import { PopulatePrefixFunction } from '@hb/shared/types'
import React, { createContext, PropsWithChildren } from 'react'

export const FormFilesContext = createContext<{
  populatePrefixFunction: PopulatePrefixFunction
}>({
  populatePrefixFunction: () => {
    throw new Error('Form files context not initialized')
  },
})

export const FormFilesProvider = ({
  populatePrefix,
  children,
}: PropsWithChildren<{ populatePrefix: PopulatePrefixFunction }>) => (
  <FormFilesContext.Provider value={{ populatePrefixFunction: populatePrefix }}>
    {children}
  </FormFilesContext.Provider>
)
