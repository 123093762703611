import { EditIcon } from '@chakra-ui/icons'
import {
  Badge,
  Box, Center,
  Circle,
  CircularProgress, Image,
  Popover,
  PopoverContent,
  PopoverTrigger,
  Text,
  Tooltip,
  useDisclosure,
  VStack,
} from '@chakra-ui/react'
import { colors } from '@hb/shared/constants'
import {
  getNameAndInitials,
  getUserGroup,
  userGroupLabels,
} from '@hb/shared/utils'
import { ref, uploadBytesResumable } from 'firebase/storage'
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo, useState,
} from 'react'
import { reportProfilePictureUpload } from '../../backend/functions'
import { storage } from '../../backend/storage'
import { useCachedUser } from '../../collections/hooks/cached'
import { PopUpMessageContext, useApp } from '../../contexts'
import { useFile } from '../../hooks/backend/storage/downloadFile'
import { useRecentlyUploaded } from '../../hooks/backend/storage/useRecentlyUploaded'
import personIcon from '../../icons/person.svg'
import { DefaultModal } from '../Modals/DefaultModal'
import { SimpleImageUpload } from '../shared/SimpleImageUpload'
import { getBadgeColor } from '../Users/UserBadge'

const getUploadPath = (userId: string) => `users/${userId}/profilePicture/image`
const getDownloadPath = (userId: string) => `users/${userId}/profilePicture/resized_image`
const UploadProfilePictureModal = ({
  userId,
  onClose,
  src,
}: {
  userId: string
  onClose: () => void
  src?: string
}) => {
  const { data } = useCachedUser(userId)
  const { profilePicture } = data || {}
  const { uploadedOn } = profilePicture || {}
  const uploadPath = useMemo(
    () => (userId ? getUploadPath(userId) : null),
    [userId],
  )

  const recentlyUploaded = useRecentlyUploaded(uploadedOn)
  const { appName } = useApp()
  const { processResponse } = useContext(PopUpMessageContext)
  const onUpload = useCallback(
    async (uploaded: File) => {
      if (!uploadPath) return
      const storageRef = ref(storage, uploadPath)
      try {
        await uploadBytesResumable(storageRef, uploaded, {
          contentType: uploaded.type,
        })
        await reportProfilePictureUpload({
          appName,
          contentType: uploaded.type,
          id: userId,
        })
      } catch (err: any) {
        processResponse({ error: err?.message || 'Error uploading file' })
        throw err
      }
    },
    [uploadPath, processResponse, appName, userId],
  )

  return (
    <DefaultModal
      isOpen
      size="xs"
      onClose={onClose}
      overlayHeader
      render={() => (
        <VStack bg="gray.100" py={3}>
          <Text
            color="gray.600"
            // textShadow="1px 1px 2px #00000077"
            fontSize="lg"
            fontFamily="Open Sans"
          >
            Profile Picture
          </Text>
          <SimpleImageUpload
            src={src}
            label="Profile Picture"
            recentlyUploaded={recentlyUploaded}
            onUpload={onUpload}
          />
        </VStack>
      )}
    />
  )
}

export const ProfilePicturePopover = ({
  userId,
  canEdit,
  size = 10,
  noPopover,
}: {
  userId?: string | null
  size?: number
  noPopover?: boolean
  canEdit?: boolean
}) => {
  const { data } = useCachedUser(userId || null)
  const { appName } = useApp()
  const { profilePicture } = data || {}
  const { uploadedOn } = profilePicture || {}
  const userColor = useMemo(
    () => getBadgeColor(appName, data?.role),
    [appName, data],
  )

  const [recentlyUploaded, setRecentlyUploaded] = useState(false)
  useEffect(() => {
    if (uploadedOn) {
      const now = Date.now()
      if (now - uploadedOn < 2000) {
        setRecentlyUploaded(true)
        setTimeout(() => {
          setRecentlyUploaded(false)
        }, 2000)
      }
    }
  }, [uploadedOn])

  const downloadPath = useMemo(
    () => (!recentlyUploaded && userId && uploadedOn
      ? getDownloadPath(userId)
      : null),
    [userId, uploadedOn, recentlyUploaded],
  )

  const fileArgs = useMemo(
    () => ({
      path: downloadPath || '',
      pathLoading: recentlyUploaded,
      noFetch: !uploadedOn,
    }),
    [downloadPath, recentlyUploaded, uploadedOn],
  )
  const { url, loading } = useFile(fileArgs)
  const { initials, name } = useMemo(() => getNameAndInitials(appName, data), [data, appName])
  const [hovered, setHovered] = useState(false)
  const { onOpen, isOpen, onClose } = useDisclosure()

  const triggerBody = (
    <Circle
      cursor={noPopover ? 'default' : 'pointer'}
      overflow="hidden"
      size={size}
      bg={userColor}
      position='relative'
      boxShadow="1px 1px 3px #00000077"
      // border={`2px solid ${userColor}`}
    >
      <Center position='relative' height='100%' w="100%">
        {profilePicture ? (
          <Image src={url} opacity={url ? 1 : 0} transition='opacity 300ms' width="100%" height='100%' objectFit="cover" />
        ) : (
          <Center w="100%" h="100%">
            <Text
              color="white"
              fontSize="md"
              fontWeight={700}
              textShadow="1px 1px 2px #00000077"
              fontFamily="Open Sans"
              lineHeight={1}
            >
              {initials}
            </Text>
          </Center>
        )}
        <CircularProgress
          color={colors.green.hex}
          isIndeterminate={loading}
          size={4}
          opacity={loading ? 1 : 0}
          position="absolute"
          transition="opacity 300ms ease"
        />
      </Center>
    </Circle>
  )
  if (noPopover) return triggerBody
  return (
    <Box>
      <Popover isLazy trigger="hover" strategy="fixed">
        <PopoverTrigger>{triggerBody}</PopoverTrigger>
        <PopoverContent
          filter="drop-shadow(0 0 6px #00000077)"
          bg={userColor}
          border="none"
          borderRadius="full"
          w="auto"
        >
          <Center
            w={url ? '120px' : '80px'}
            h={url ? '120px' : '80px'}
            borderRadius="full"
            border={`4px solid ${userColor}`}
          >
            {url ? (
              <Image
                src={url}
                width="100%"
                height="100%"
                objectFit="cover"
                borderRadius="full"
              />
            ) : (
              <Image
                filter="grayscale(100%) brightness(180%)"
                opacity={canEdit ? 0.3 : 0.7}
                w="40px"
                src={personIcon}
              />
            )}
            {canEdit ? (
              <Tooltip
                bg="whitesmoke"
                color="gray.600"
                placement="top"
                hasArrow
                label="Click to upload profile picture"
              >
                <VStack
                  w="100%"
                  h="100%"
                  onPointerEnter={() => setHovered(true)}
                  onPointerLeave={() => setHovered(false)}
                  onClick={onOpen}
                  justify="center"
                  bg={hovered ? 'blackAlpha.300' : 'transparent'}
                  position="absolute"
                  borderRadius="full"
                  cursor="pointer"
                  color={hovered ? 'gray.50' : 'blackAlpha.500'}
                  transition="all 300ms"
                  fontSize="sm"
                  spacing={1}
                >
                  <EditIcon w={6} h={6} />
                </VStack>
              </Tooltip>
            ) : null}
            <CircularProgress
              color={userColor}
              isIndeterminate={loading}
              pointerEvents="none"
              size={7}
              opacity={loading ? 1 : 0}
              position="absolute"
              transition="opacity 300ms ease"
            />
            <Badge
              position="absolute"
              top="-12px"
              bg={userColor}
              color="white"
              fontSize="sm"
              px={2}
              textShadow="1px 1px 2px #00000077"
              fontFamily="Open Sans"
              py="0.25rem"
              lineHeight={1}
              borderRadius={4}
            >
              {userGroupLabels[getUserGroup(data)]}
            </Badge>
            <Badge
              position="absolute"
              bottom="-12px"
              bg={userColor}
              color="white"
              fontSize="sm"
              px={2}
              textShadow="1px 1px 2px #00000077"
              fontFamily="Open Sans"
              border={`1px solid ${userColor}`}
              py="0.25rem"
              lineHeight={1}
              borderRadius={4}
            >
              {name || ''}
            </Badge>
          </Center>
        </PopoverContent>
      </Popover>
      {isOpen ? (
        <UploadProfilePictureModal
          src={url}
          userId={userId || ''}
          onClose={onClose}
        />
      ) : null}
    </Box>
  )
}
