import { FlexProps } from '@chakra-ui/react'
import { CSSProperties, FC } from 'react'
import { UpdateCallback } from '../firebase/shared'
import { FieldMap } from '../forms/fields'
import { UserRoleItem, UserSortKey } from '../users/role'
import { FieldMapValue, WithId } from '../utils'
import { AppData } from './app'
import { AuthData } from './auth'
import { type CollectionFilter, type UseCollectionArgs } from './populated'

export interface DataListProps<Snippet extends WithId, SortKey extends string> {
  selectUser: (userId: string) => void
  sortBy: (k: SortKey) => void
  items?: Record<string, Snippet> | null
  active: boolean
}

export type Preview = {
  id: string
  item: string
  position: { x: number; y: number }
  cell: { rowIndex: number; columnIndex: number }
}

export type BasePresetFilter = {
  label: string
  filter: CollectionFilter
}

export type DropdownPresetFilter = {
  label: string
  filters: BasePresetFilter[]
  searchable?: boolean
  filter?: never
}

export type PresetFilter = BasePresetFilter | DropdownPresetFilter

export const isDropdownPresetFilter = (
  filter: PresetFilter,
): filter is DropdownPresetFilter => (filter as DropdownPresetFilter).filter === undefined

export interface GridChildComponentProps<T = any> {
  columnIndex: number
  rowIndex: number
  style: CSSProperties
  data: T
  isScrolling?: boolean | undefined
}
export type UserCellComponentProps<
  Snippet extends WithId = WithId,
  SortKey extends string = string,
> = {
  items: Snippet[]
  auth: AuthData
  tab: DataListTab<any, any>
  preview: {
    openPreview: (p: Preview) => void
    closePreview: () => void
    preview: Preview | null
  }
  onRowClick?: (id: string) => void
  app: AppData
  tabName: string
  columns: Record<string, DataColumn<Snippet, SortKey>>
  flexColWidth: number
}

export type UserRowComponentProps<
  Snippet extends WithId = WithId,
  SortKey extends string = string,
> = {
  items: Snippet[]
  auth: AuthData
  tab: DataListTab<any, any>
  preview: {
    openPreview: (p: Preview) => void
    closePreview: () => void
    preview: Preview | null
  }
  app: AppData
  tabName: string
  onRowClick?: (id: string) => void
  blockColumns: Record<string, DataColumn<Snippet, SortKey>>
  inlineColumns: Record<string, DataColumn<Snippet, SortKey>>
  flexColWidth: number
}

export type DataListTab<Data extends WithId, SortKey extends string> = Pick<
  UseCollectionArgs<Data, SortKey>,
  'role' | 'stage' | 'archived' | 'filters'
> &
  Pick<
    DataTableProps<Data, SortKey>,
    | 'columns'
    | 'defaultSortKey'
    | 'presetFilters'
    | 'getItemBackgroundColor'
    | 'secondarySortKey'
    | 'searchStringPath'
  > & {
    collection: string
    transformData?: DataTableProps<Data, SortKey>['transformData']
    rightJustifyTab?: boolean
    itemName: string
    creation?: {
      field: FieldMap
      initialData?: Partial<FieldMapValue> | (() => Partial<FieldMapValue>)
      submitText?: string
      onCreate: (data: Partial<FieldMapValue>, appData: AppData) => Promise<UpdateCallback>
    }
  }

export type TransformData<InData, OutData> = (data: InData) => OutData
export type DataTableProps<
  Snippet extends WithId,
  SortKey extends string,
> = DataListProps<Snippet, SortKey> & {
  columns: Record<string, DataColumn<Snippet, SortKey>>
  // renderCell?: FC<GridChildComponentProps<Data[]>>,
  transformData?: TransformData<Snippet[], Snippet[]>
  presetFilters?: Array<PresetFilter>
  tab: DataListTab<Snippet, SortKey>
  onRowClick?: (id: string) => void
  defaultSortKey: SortKey
  secondarySortKey?: SortKey
  width: number
  height: number
  searchStringPath?: keyof Snippet
  loading: boolean
  itemHeight?: number
  tabName: string
  sortKey: string
  numRows: number
  getItemBackgroundColor?: (item: Snippet, index: number) => string
  collection: string
  sortAsc: boolean
  toggleSortDirection: () => void
  // filtered: Record<string, UserRoleItem> | null
}

export type UserDataTableProps = DataTableProps<UserRoleItem, UserSortKey>

export type DataColumnRenderProps<Snippet extends WithId> = {
  data: Snippet
  auth: AuthData
  app: AppData
  preview: {
    openPreview: (p: Preview) => void
    closePreview: () => void
    preview: Preview | null
  }
  tabName: string
  isScrolling?: boolean
  cell: { rowIndex: number; columnIndex: number }
}
export interface DataColumn<Snippet extends WithId, SortKey extends string> {
  isLazy?: boolean
  Header: FC
  title: string
  width?: number
  Render: FC<DataColumnRenderProps<Snippet>>
  mobile?: {
    width: number
    flexProps?: FlexProps
    Render: FC<{
      data: Snippet
      auth: AuthData
      app: AppData
      preview: {
        openPreview: (p: Preview) => void
        closePreview: () => void
        preview: Preview | null
      }
      tabName: string
      isScrolling?: boolean
      cell: { rowIndex: number; columnIndex: number }
    }>
  }
  flexProps?: FlexProps
  sortKey?: SortKey
  defaultSortDirection?: 'asc' | 'desc'
  sortFunc?: (a: WithId<Snippet>, b: WithId<Snippet>) => number
}

export type DataColumns<Data extends WithId, SortKey extends string> = Record<
  string,
  DataColumn<Data, SortKey>
>
