import { PathPrefix } from '@hb/shared'
import { useCallback, useContext } from 'react'
import { FormsViewContext } from '../contexts/FormsViewContext'
import { UsePopulateAppPrefixResult } from '../contexts/getFormFilesProvider'
import { UserContext } from '../contexts/UserContext'

export const usePopulateAppPrefix = () => {
  const { user, assessmentId: _assessmentId } = useContext(UserContext)
  const { assessmentId: formViewAssessmentId } = useContext(FormsViewContext)

  return useCallback(
    (pathPrefix: PathPrefix): UsePopulateAppPrefixResult => {
      const { item, location } = pathPrefix
      if (item === 'user') {
        const uid = user?.id
        if (!uid) return { item: undefined, error: 'User ID not found' }
        if (location !== 'id') {
          return {
            item: undefined,
            error: `Unimplemented in path prefix: ${location}`,
          }
        }
        return { item: `users/${uid}` }
      }
      if (item === 'assessment') {
        const assessmentId = formViewAssessmentId || _assessmentId
        if (!assessmentId) {
          return { item: undefined, error: 'Assessment ID not found' }
        }
        if (location !== 'id') {
          return {
            item: undefined,
            error: `Unimplemented in path prefix: ${location}`,
          }
        }
        return { item: `assessments/${assessmentId}` }
      }
      const error = `Need to implement populatePathPrefix for ${item}`
      return { item: undefined, error }
    },
    [_assessmentId, formViewAssessmentId, user],
  )
}
