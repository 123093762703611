import { ChevronDownIcon, LinkIcon } from '@chakra-ui/icons'
import {
  Box,
  Button,
  CircularProgress,
  Code,
  Collapse,
  Flex,
  HStack,
  IconButton,
  Image,
  Input,
  Spinner,
  Stack,
  StackDivider,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  Tooltip,
  VStack,
} from '@chakra-ui/react'
import {
  AbilityClaim,
  AbilityClaimError,
  Claim,
  colors, Field,
  FieldMap,
  FieldMapValue,
  FieldTypes,
  formatDropdownValue,
  getClaimIsProcessing,
  getDateString,
  getDateTimeString,
  getInovalonClaimStatusColor,
  LinkClaimArgs,
  UpdateCallback,
} from '@hb/shared'
import {
  deleteDoc,
  deleteField,
  doc,
  getDoc,
  setDoc,
  updateDoc,
} from 'firebase/firestore'

import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { CLAIMS_REF } from '../../../../collections/collections'
import { PopUpMessageContext, UserContext } from '../../../../contexts'
import { getClaim, linkClaim } from '../../../../hooks/backend/claims'
import { useDocument } from '../../../../hooks/backend/useDocument'
import { useUpdateDoc } from '../../../../hooks/backend/useUpdateDoc'
import { useFormattedValue } from '../../../../hooks/useFormattedValue'

import { ActionButton, DeleteButton } from '../../../Buttons'
import { FloatingContainer } from '../../../Containers'
import { CopyId } from '../../../CopyId'
import { DataView, GenericEditModal } from '../../../DataView'
import { Expandable } from '../../../Expandable'
import { NoteForm } from '../../../forms/NoteForm'
import { DefaultModal } from '../../../Modals/DefaultModal'
import { AssessmentDocuments } from '../Documents/AssessmentDocuments'
import { DraftView } from '../DraftView'
import { ReferenceNumbersEdit } from '../ReferenceNumberEdit'
import { serviceTypeField, serviceTypeOtherField } from './Ability/fields'
import {
  AbilityClaimSubmission,
  ResubmitAbilityClaim,
} from './Ability/SubmitNewClaim/AddAbilityClaimModal'
import { ClaimSubmissionForm } from './Ability/SubmitNewClaim/ClaimSubmissionForm'

type ClaimsViewData = {
  selectedId: string | null
  select: (id: string) => void
  updatingAbilityId: string | null
  setUpdatingAbilityId: (id: string | null) => void
  creatingNewLegacy: boolean
  setCreatingNewLegacy: (val: boolean) => void
  creatingNewAbilityClaim: boolean
  setCreatingNewAbilityClaim: (val: boolean) => void
}

const ClaimsViewContext = React.createContext<ClaimsViewData>({
  selectedId: null,
  select: () => {},
  updatingAbilityId: null,
  setUpdatingAbilityId: () => {},
  creatingNewLegacy: false,
  setCreatingNewLegacy: () => {},
  creatingNewAbilityClaim: false,
  setCreatingNewAbilityClaim: () => {},
})

const status: Field = {
  placeholder: 'Status',
  type: FieldTypes.DROPDOWN,
  options: [
    { id: 'processing', text: 'Processing' },
    { id: 're-processing', text: 'Re-processing' },
    { id: 'processed-in', text: 'Processed In-Net' },
    { id: 'processed-oon', text: 'Processed OON' },
    { id: 'paid', text: 'Paid' },
  ],
}

export const toClaimName = (data: FieldMapValue) => `${
  data?.serviceType?.toString().toUpperCase() || 'NO SERVICE TYPE'}${
  data.serviceType === 'other' && data.serviceTypeOther
    ? ` (${data.serviceTypeOther})`
    : ''
}: ${data.status?.toString().toUpperCase()} - ${
  data.serviceDates || 'No dates of service'
} - ${data.cptCodes || 'No cpt codes'}`

const claimField: FieldMap = {
  name: 'Claim',
  toName: toClaimName,
  children: {
    serviceType: serviceTypeField,
    serviceTypeOther: serviceTypeOtherField,
    status,
    submissionDate: {
      type: FieldTypes.DATE,
      placeholder: 'Submission date',
    },
    serviceDates: {
      optional: true,
      type: FieldTypes.TEXTAREA,
      placeholder: 'Date(s) of service...',
    },
    cptCodes: {
      optional: true,
      type: FieldTypes.TEXTAREA,
      placeholder: 'CPT Codes/Billed Charges',
    },
    // billedCharges: {
    //   optional: true,
    //   type: FieldTypes.TEXTAREA,
    //   placeholder: 'Billed charges',
    // },
    sessionNumber: {
      optional: true,
      type: FieldTypes.TEXT,
      placeholder: 'Session #',
    },
    claimNumber: {
      optional: true,
      type: FieldTypes.TEXT,
      placeholder: 'Claim #',
    },
    notes: {
      type: FieldTypes.TEXTAREA,
      optional: true,
      placeholder: 'Notes...',
    },
  },
}

const ClaimInfo = ({
  header,
  children,
}: {
  header: string
  children: React.ReactNode
}) => (
  <VStack flex={1} p={1} pr={3} align="flex-start" spacing={0}>
    <Text isTruncated color="gray.500" fontSize="xs" fontWeight={600}>
      {header}
    </Text>
    {children}
  </VStack>
)

const AbilityClaimErrorView = ({ error }: { error: AbilityClaimError }) => {
  const { messsage, name, related_fields } = error
  const fieldName = useMemo(() => {
    const [field] = related_fields || []
    return field?.name || ''
  }, [related_fields])
  const [expanded, setExpanded] = useState(false)
  const statusColor = error.override_enabled === 'Y' ? 'yellow.500' : 'red.500'
  return (
    <VStack
      spacing={0}
      borderRadius={4}
      w="100%"
      borderWidth="2px"
      borderColor={statusColor}
    >
      <HStack w="100%" p={2} bg={statusColor} justify="space-between">
        <VStack align="flex-start" spacing={0}>
          {fieldName ? (
            <Text fontSize="sm" color="white">
              {fieldName.toUpperCase()}{' '}
            </Text>
          ) : null}
          <Text color="white">{name}</Text>
        </VStack>
        <IconButton
          minH="0"
          minW="0"
          _hover={{ bg: 'whiteAlpha.400' }}
          h="auto"
          p={2}
          bg="transparent"
          onClick={() => setExpanded(!expanded)}
          aria-label={expanded ? 'collapse' : 'expand'}
          color="white"
          icon={<ChevronDownIcon />}
        />
      </HStack>
      <Collapse in={expanded} style={{ width: '100%' }}>
        <Text px={2} py={1} whiteSpace="pre-wrap">
          {decodeURI(messsage)}
        </Text>
      </Collapse>
    </VStack>
  )
}
const AbilityClaimErrors = ({
  errors,
}: {
  errors?: Array<AbilityClaimError>
}) => {
  const { overridden, notOverridden } = useMemo(() => {
    const over: Array<AbilityClaimError> = []
    const notOver: Array<AbilityClaimError> = []
    errors?.forEach((e) => {
      if (e.override_enabled === 'Y') over.push(e)
      else notOver.push(e)
    })
    return { overridden: over, notOverridden: notOver }
  }, [errors])
  if (!errors?.length) return null
  return (
    <VStack w="100%" align="flex-start">
      <Text color="red.600" decoration="underline">
        ERRORS
      </Text>
      {notOverridden.map((e, i) => (
        <AbilityClaimErrorView key={`error_${i}`} error={e} />
      ))}
      <Expandable
        header={() => (
          <Text py={1} textDecor="underline" color="yellow.600">
            OVERRIDDEN ERRORS
          </Text>
        )}
      >
        <VStack w="100%">
          {overridden.map((e, i) => (
            <AbilityClaimErrorView key={`error_${i}`} error={e} />
          ))}
        </VStack>
      </Expandable>
    </VStack>
  )
}

const useFetchedClaim = (id: string, testMode: boolean) => {
  const [data, setData] = useState<AbilityClaim | null>(null)
  const [fetching, setFetching] = useState(false)
  const [error, setError] = useState<string | null>(null)
  const lastFetched = useRef(data)
  const refetch = useCallback(async () => {
    if (lastFetched.current && !getClaimIsProcessing(lastFetched.current)) {
      return
    }
    setFetching(true)
    try {
      const { data: fetched } = await getClaim({ abilityId: id, testMode })
      setData(fetched || null)
    } catch (err: any) {
      console.log([err?.message, err?.response?.data])
      setError(err?.message || 'Error occurred fetching claim')
    }
    setFetching(false)
  }, [id, testMode])
  useEffect(() => {
    refetch()
    const interval = setInterval(
      () => {
        refetch()
      },
      2 * 60 * 1000,
    )
    return () => clearInterval(interval)
  }, [refetch])
  return { data, fetching, error }
}

const AbilityClaimContent = ({
  claim,
  error,
  systemClaim,
  withErrors,
}: {
  claim: AbilityClaim | null
  systemClaim?: Claim | null
  error: string | null
  withErrors?: boolean
}) => {
  const { setUpdatingAbilityId } = useContext(ClaimsViewContext)
  const firstService = useMemo(
    () => Object.values(claim?.Services || {})[0],
    [claim],
  )
  const serviceType = useFormattedValue(
    serviceTypeField,
    systemClaim?.serviceType,
  )
  const statusColor = useMemo(
    () => getInovalonClaimStatusColor(claim?.TransStatus),
    [claim],
  )

  const canResubmit = useMemo(
    () => claim
      && ['ERROR', 'VALIDATED', 'ACK_PENDING'].includes(claim?.TransStatus || ''),
    [claim],
  )
  if (error) {
    return <Text color="red">{error}</Text>
  }
  if (claim) {
    return (
      <>
        {withErrors ? <AbilityClaimErrors errors={claim?.errors} /> : null}
        <ClaimInfo header="SERVICE TYPE">
          <Text isTruncated>{serviceType || 'NONE'}</Text>
        </ClaimInfo>
        <ClaimInfo header="PATIENT CONTROL NO.">
          <Text isTruncated>{claim.PatientCtlNo || 'NONE'}</Text>
        </ClaimInfo>
        {/* <ClaimInfo header='ID'>
          <Text>{claim.TransID || 'NONE'}</Text>
        </ClaimInfo> */}
        <ClaimInfo header="STATUS">
          <HStack>
            <Text color={statusColor}>{claim.TransStatus || 'NONE'}</Text>
            {canResubmit ? (
              <Button
                bg={colors.green.hex}
                color="white"
                onClick={() => setUpdatingAbilityId(systemClaim?.abilityId || null)
                }
                size="xs"
              >
                RESUBMIT
              </Button>
            ) : null}
          </HStack>
        </ClaimInfo>
        <ClaimInfo header="SERVICE DATE">
          <Text>
            {firstService?.FromDate
              ? `${getDateString(firstService.FromDate, 'short')}`
              : 'NONE'}
          </Text>
        </ClaimInfo>
        <ClaimInfo header="CHARGE">
          <Text>${claim.ClaimCharge}</Text>
        </ClaimInfo>
        <ClaimInfo header="CREATED ON">
          <Text isTruncated>
            {claim.CreateDate
              ? getDateTimeString(new Date(claim.CreateDate), 'short')
              : 'NONE'}
          </Text>
        </ClaimInfo>
        <Tooltip
          bg="#d6cf38"
          color="#251f21"
          hasArrow
          placement="top"
          label="Open in Inovalon (Log into Ease All-Payer first)"
        >
          <IconButton
            variant="ghost"
            borderRadius="full"
            aria-label="Open in Inovalon"
            onClick={(e) => {
              e.stopPropagation()
              window.open(
                `https://portal.focusedi.com/claim/view/${systemClaim?.abilityId}/prof`,
                '_blank',
              )
            }}
            icon={
              <Image
                height="20px"
                src="/images/Inovalon-Logo.svg"
                alt="Ability Logo"
              />
            }
          />
        </Tooltip>
      </>
    )
  }
  return <Text color="red">Cannot find ability claim</Text>
}

const AbilityClaimMoreContent = ({
  claim,
  loading,
}: {
  claim: AbilityClaim | null
  loading: boolean
}) => {
  if (claim) {
    return (
      <>
        <ClaimInfo header="CLAIM NO.">
          <Text isTruncated>{claim?.ClaimID?.ClaimNo || 'NONE'}</Text>
        </ClaimInfo>
        <ClaimInfo header="CREATE MODE">
          <Text isTruncated>{claim.CreateMode || 'NONE'}</Text>
        </ClaimInfo>

        {/* <ClaimInfo header='ID'>
          <Text>{claim.TransID || 'NONE'}</Text>
        </ClaimInfo> */}
      </>
    )
  }
  if (loading) {
    return (
      <HStack>
        <CircularProgress
          isIndeterminate
          size="24px"
          color={colors.green.hex}
        />
        <Text>Loading...</Text>
      </HStack>
    )
  }
  return <Text color="red">Cannot find ability claim</Text>
}

const ClaimBeingValidated = ({ claim }: { claim: Claim }) => (
  <HStack spacing={4} py={2} px={5}>
    <Spinner color={colors.green.hex} />
    <VStack spacing={0} align="flex-start">
      <Text color="gray.700">Claim is being validated by Inovalon</Text>
      {claim.validationCheckedOn ? (
        <Text color="gray.500" fontSize="sm">
          VALIDATION CHECKED {getDateTimeString(claim.validationCheckedOn)}
        </Text>
      ) : null}
    </VStack>
  </HStack>
)

const LegacyClaimContent = ({ claim }: { claim: Claim }) => {
  const {
    serviceType: legacyServiceType,
    serviceTypeOther,
    serviceDates,
    status: claimStatus,
    submittedOn,
    billedCharges,
  } = claim?.legacy || {}

  const serviceType = useFormattedValue(
    serviceTypeField,
    legacyServiceType,
  )

  return (
    <>
      <ClaimInfo header="SERVICE TYPE">
        <Text isTruncated>
          {legacyServiceType === 'other'
            ? `OTHER - ${serviceTypeOther}`
            : serviceType || 'NONE'}
        </Text>
      </ClaimInfo>
      {/* <ClaimInfo header='ID'>
              <Text>{claim.TransID || 'NONE'}</Text>
            </ClaimInfo> */}
      <ClaimInfo header="STATUS">
        <Text isTruncated>
          {claimStatus
            ? formatDropdownValue(claimStatus, status.options)
            : 'None'}
        </Text>
      </ClaimInfo>
      <ClaimInfo header="SERVICE DATES">
        <Text isTruncated>{serviceDates || 'None'}</Text>
      </ClaimInfo>
      <ClaimInfo header="BILLED CHARGES">
        <Text isTruncated>{billedCharges || 'None'}</Text>
      </ClaimInfo>
      <ClaimInfo header="SUBMISSION DATE">
        <Text isTruncated>
          {submittedOn ? getDateString(submittedOn, 'short') : 'None'}
        </Text>
      </ClaimInfo>
    </>
  )
}

const LegacyClaimView = ({
  claim,
  onClick,
}: {
  claim: Claim
  id: string
  onClick: () => void
}) => (
  <Flex
    onClick={onClick}
    borderRadius={4}
    cursor="pointer"
    minH="100%"
    flex={1}
    px={2}
    // w='100%'
    _hover={{ bg: 'rgba(255,199,188,0.2)' }}
    border="1px solid #cdcdcd"
  >
    {claim.abilityClaimStatus === 'submitting' ? (
      <ClaimBeingValidated claim={claim} />
    ) : (
      <LegacyClaimContent claim={claim} />
    )}
  </Flex>
)

const AbilityClaimPreview = ({
  abilityId,
  onClick,
  systemClaim,
  testMode,
}: {
  testMode?: boolean
  abilityId: string
  systemClaim: Claim
  onClick: () => void
}) => {
  const { data, fetching, error } = useFetchedClaim(abilityId, !!testMode)

  return (
    <HStack
      minH="100%"
      onClick={onClick}
      w="100%"
      borderRadius={4}
      cursor="pointer"
      _hover={{ bg: 'rgba(255,199,188,0.2)' }}
      border="1px solid #cdcdcd"
      divider={<StackDivider />}
      px={3}
    >
      {fetching ? (
        <HStack w="100%" p={2}>
          <Spinner color={colors.green.hex} />
          <Text ml={2} color="gray.500">
            Loading claim from ability
          </Text>
        </HStack>
      ) : (
        <AbilityClaimContent
          systemClaim={systemClaim}
          error={error}
          claim={data}
        />
      )}
    </HStack>
  )
}

const ClaimView = ({
  claim,
  id,
  onClick,
}: {
  claim: Claim
  id: string
  onClick: () => void
}) => {
  const body = claim.abilityId ? (
    <AbilityClaimPreview
      testMode={claim.testMode}
      systemClaim={claim}
      abilityId={claim.abilityId}
      onClick={onClick}
    />
  ) : (
    <LegacyClaimView claim={claim} id={id} onClick={onClick} />
  )

  return (
    <HStack spacing={2} w="100%" align="flex-start">
      <Flex
        bg="white"
        borderRadius={6}
        minW="0"
        overflow="hidden"
        position="relative"
        flex={1}
      >
        {body}
      </Flex>
      <Flex height="40px" width="25px" position="relative">
        <CopyId
          bottom="unset"
          top="0.25rem"
          left="0"
          right="unset"
          item={{ id }}
        />
      </Flex>
    </HStack>
  )
}

// provide assessment id for new claim, claim ID for existing claim
const LinkAbilityClaim = ({
  claimId,
  assessmentId,
  claim,
  isRelink,
  alwaysOpen,
  onCancel,
  onComplete,
}: {
  claimId: string | null
  assessmentId: string | null
  claim?: Claim
  onCancel?: () => void
  alwaysOpen?: boolean
  onComplete?: () => void
  isRelink?: boolean
}) => {
  const [id, setId] = useState('')
  const [loading, setLoading] = useState(false)
  const [linking, setLinking] = useState(!!alwaysOpen)
  const { showSuccess, showError } = useContext(PopUpMessageContext)

  const onSubmit = useCallback(() => {
    if (!claimId && !assessmentId) {
      showError('no claim or assessment ID provided')
      return
    }
    if (claimId && assessmentId) {
      showError('both claim and assessment ID provided')
      return
    }
    if (!id) {
      showError("Enter the claim ID from ability's system")
      return
    }
    setLoading(true)
    linkClaim({ dbId: claimId, abilityId: id, assessmentId } as LinkClaimArgs)
      .then(() => {
        setLoading(false)
        if (onComplete) onComplete()
        showSuccess('Linked!')
      })
      .catch((err: any) => {
        setLoading(false)
        console.error(err)
        showError(
          err.message === 'Not found'
            ? "Couldn't find that Inovalon claim"
            : 'Internal error',
        )
      })
  }, [id, claimId, showError, showSuccess, onComplete, assessmentId])
  // if (claim?.submittedToAbility) {
  //   return (
  //     <HStack p={2}>
  //       <Spinner color={colors.green.hex} />
  //       <Text>Claim is being validated by Inovalon</Text>
  //     </HStack>
  //   )
  // }
  return (
    <VStack align="flex-start" w="100%">
      {claim?.submittedToAbility ? (
        <HStack p={2}>
          <Spinner color={colors.green.hex} />
          <Text>Claim is being validated by Inovalon</Text>
        </HStack>
      ) : null}
      <Text fontWeight={600} fontSize="sm" color="gray.500">
        {isRelink ? 'RE-LINK TO ABILITY' : 'LINK TO ABILITY'}
      </Text>
      <Stack width={isRelink ? 'auto' : '100%'} direction="row">
        <Button
          disabled={loading}
          _hover={{ bg: '#cedf40' }}
          onClick={() => {
            if (isRelink) {
              if (onCancel) onCancel()
            } else setLinking(!linking)
          }}
          bg={isRelink || linking ? '#ababab' : '#afbd20'}
          color="white"
          px={isRelink ? 2 : 4}
          fontSize="sm"
          size="sm"
        >
          {linking || isRelink ? (
            'CANCEL'
          ) : (
            <>
              <LinkIcon mr={isRelink ? 0 : 2} />
              {isRelink ? null : 'LINK CLAIM'}
            </>
          )}
        </Button>
        <Stack
          flex={1}
          direction="row"
          opacity={isRelink || linking ? 1 : 0}
          pointerEvents={isRelink || linking ? 'auto' : 'none'}
        >
          <Input
            disabled={(!linking && !isRelink) || loading}
            placeholder="Inovalon claim ID"
            size="sm"
            variant="outline"
            value={id}
            onChange={({ target: { value } }) => setId(value)}
          />
          <Button
            onClick={onSubmit}
            isLoading={loading}
            px={4}
            fontSize="sm"
            size="sm"
            bg="#afbd20"
            color="white"
          >
            {isRelink || claim?.submittedToAbility ? 'RE-LINK' : 'LINK'}
          </Button>
        </Stack>
      </Stack>
    </VStack>
  )
}

const AbilityClaimView = ({
  claim,
  withMore,
  claimId,
}: {
  claim?: Claim
  withMore?: boolean
  claimId: string | null
}) => {
  const { abilityId } = claim || {}
  const {
    data: abilityClaim,
    loading,
    error,
  } = useDocument<AbilityClaim>('claims-ability-detail', abilityId)
  const [isRelinking, setIsRelinking] = useState(false)
  if (!claimId) return null
  return (
    <VStack spacing={0} width="100%" align="flex-start">
      {loading ? (
        <HStack justify="center" w="100%" p={2} align="center">
          <Spinner color="gray.400" />
          <Text color="gray.600">Loading claim from ability...</Text>
        </HStack>
      ) : (
        <VStack w="100%" position="relative" spacing={0} align="flex-start">
          {abilityId && !isRelinking ? (
            <>
              <Tooltip bg="#afbd20" label="Re-link ability claim">
                <Box pos="absolute" right={0} top={0}>
                  <Button
                    disabled={loading}
                    _hover={{ bg: '#cedf40' }}
                    onClick={() => setIsRelinking(true)}
                    bg="#afbd20"
                    size="xs"
                  >
                    <LinkIcon color="white" />
                  </Button>
                </Box>
              </Tooltip>
              <AbilityClaimContent
                withErrors
                systemClaim={claim}
                error={error}
                claim={abilityClaim}
              />
            </>
          ) : (
            <LinkAbilityClaim
              onCancel={() => setIsRelinking(false)}
              isRelink={isRelinking}
              onComplete={() => setIsRelinking(false)}
              claim={claim}
              claimId={claimId}
              assessmentId={null}
            />
          )}
          {abilityClaim && withMore && !isRelinking ? (
            <AbilityClaimMoreContent loading={loading} claim={abilityClaim} />
          ) : null}
        </VStack>
      )}
    </VStack>
  )
}

const ClaimModal = ({
  selectedId,
  selected,
  select,
  onClose,
}: {
  selectedId: string | null
  selected?: Claim
  select: (id: string) => void
  onClose: () => void
}) => {
  const { user, assessmentId } = useContext(UserContext)
  const { processResponse } = useContext(PopUpMessageContext)
  const onSave = useCallback(
    async (data: FieldMapValue): Promise<UpdateCallback> => {
      if (!user?.id) return { error: 'no user selected' }
      if (!selectedId) return { error: 'no claim selected' }
      if (!assessmentId) return { error: 'no assessment selected' }
      try {
        const newRef = selectedId === 'new' ? doc(CLAIMS_REF) : doc(CLAIMS_REF, selectedId)
        await setDoc(
          newRef,
          { legacy: data, patientId: user.id, assessmentId },
          { merge: true },
        )
        select(newRef.id)
        return { success: 'Saved' }
      } catch (err: any) {
        return { error: err.message }
      }
    },
    [selectedId, user, assessmentId, select],
  )

  const onDeleteLegacy = useCallback(async (): Promise<UpdateCallback> => {
    if (!selectedId) return { error: 'no claim selected' }
    try {
      const docRef = doc(CLAIMS_REF, selectedId)
      const currentDoc = await getDoc(docRef)
      const currentData = currentDoc.data() as Claim | undefined
      if (currentData?.abilityId) {
        await updateDoc(docRef, 'legacy', deleteField())
      } else await deleteDoc(doc(CLAIMS_REF, selectedId))
      onClose()
      return { success: 'Claim deleted' }
    } catch (err: any) {
      console.error(err)
      return { error: err?.message || 'Error deleting claim' }
    }
  }, [selectedId, onClose])
  const submittedToAbility = useMemo(
    () => (selected as Claim)?.submittedToAbility,
    [selected],
  )
  return (
    <DefaultModal
      size="6xl"
      bodyProps={{ minHeight: '440px' }}
      overlayHeader
      isOpen={!!selectedId}
      onClose={onClose}
      render={() => (
        <Tabs
          isLazy
          defaultIndex={selectedId === 'new' || !selected?.abilityId ? 2 : 0}
        >
          <TabList pt={2}>
            <Tab>CLAIM</Tab>
            {selected?.submittedArgs ? <Tab>SUBMISSION FORM</Tab> : null}
            {submittedToAbility ? <Tab>SUBMITTED TO ABILITY</Tab> : null}
            <Tab>LEGACY</Tab>
            <Tab>DELETE CLAIM</Tab>
          </TabList>
          <TabPanels>
            <TabPanel p={2}>
              <VStack>
                <AbilityClaimView
                  withMore
                  claimId={selectedId}
                  claim={selected as Claim | undefined}
                />
              </VStack>
            </TabPanel>
            {selected?.submittedArgs ? (
              <TabPanel p={0}>
                <ClaimSubmissionForm claim={selected} />
              </TabPanel>
            ) : null}
            {submittedToAbility ? (
              <TabPanel p={0}>
                <Code minW="100%" p={4} whiteSpace="pre-wrap">
                  {JSON.stringify(submittedToAbility, null, 2)}
                </Code>
              </TabPanel>
            ) : null}
            <TabPanel padding={0}>
              <DataView
                initEditing={selectedId === 'new'}
                onSubmit={onSave}
                boxProps={{ px: 2 }}
                withEditModal
                field={claimField}
                onEditingChange={() => {
                  if (selectedId === 'new') onClose()
                }}
                onDelete={async () => {
                  if (selectedId === 'new') {
                    onClose()
                    return { success: 'true' }
                  }
                  return onDeleteLegacy()
                }}
                data={
                  selectedId === 'new'
                    ? { submissionDate: Date.now() }
                    : selected?.legacy
                }
              />
            </TabPanel>
            <TabPanel>
              <HStack>
                <DeleteButton
                  itemName="claim"
                  text="DELETE CLAIM"
                  size="md"
                  onDelete={async () => {
                    if (!selectedId) return
                    try {
                      await deleteDoc(doc(CLAIMS_REF, selectedId))
                      onClose()
                    } catch (err: any) {
                      processResponse({
                        error: `Error deleting claim: ${err.message}`,
                      })
                    }
                  }}
                />
                <Text color="gray.600">
                  This will delete the claim from the Hamilton Billing system,
                  but not from Inovalon
                </Text>
              </HStack>
            </TabPanel>
          </TabPanels>
        </Tabs>
      )}
    />
  )
}

const LinkClaimModal = ({ onClose }: { onClose: () => void }) => {
  const { assessmentId } = useContext(UserContext)
  return (
    <DefaultModal
      overlayHeader
      isOpen
      onClose={onClose}
      render={() => (
        <VStack p={3} w="100%">
          <LinkAbilityClaim
            assessmentId={assessmentId || ''}
            alwaysOpen
            claimId={null}
            onCancel={onClose}
          />
        </VStack>
      )}
    />
  )
}

const NewClaimModal = ({
  select,
  isOpen,
  onClose,
}: {
  select: (id: string) => void
  isOpen: boolean
  onClose: () => void
}) => {
  const { assessmentId, user } = useContext(UserContext)
  const onSave = useCallback(
    async (data: FieldMapValue): Promise<UpdateCallback> => {
      if (!user?.id) return { error: 'no user selected' }
      if (!assessmentId) return { error: 'no assessment selected' }
      try {
        const newRef = doc(CLAIMS_REF)
        await setDoc(
          newRef,
          { legacy: data, patientId: user.id, assessmentId },
          { merge: true },
        )
        select(newRef.id)
        return { success: 'Saved' }
      } catch (err: any) {
        return { error: err.message }
      }
    },
    [user, assessmentId, select],
  )

  return (
    <GenericEditModal
      field={claimField}
      onSubmit={onSave}
      isOpen={isOpen}
      onClose={onClose}
    />
  )
}

const ClaimsTabSubmitClaim = ({
  existingAbilityId,
  claim,
  onClose,
}: {
  existingAbilityId?: string | null
  claim: Claim | undefined
  onClose: () => void
}) => (existingAbilityId ? (
    <ResubmitAbilityClaim
      serviceType={claim?.serviceType}
      existingAbilityId={existingAbilityId}
      onClose={onClose}
    />
) : (
    <AbilityClaimSubmission onClose={onClose} />
))

const ClaimsTabContent = ({
  onLegacyCreate,
  onClaimSubmit,
  onLinkClaim,
  selectedClaimId,
  onSelectClaim,
}: {
  onLegacyCreate: () => void
  onClaimSubmit: () => void
  onLinkClaim: () => void
  selectedClaimId: string | null
  onSelectClaim: (id: string | null) => void
}) => {
  const {
    selectedAssessment,
    assessmentId,
    claims: { data: claims },
  } = useContext(UserContext)
  const {
    insuranceProvider,
    claimsNote,
    adminRef,
    patientId,
    drafts,
  } = selectedAssessment || {}
  const sortedClaims = useMemo(
    () => (claims ? Object.entries(claims) : []),
    [claims],
  )
  const selected = useMemo(
    () => (selectedClaimId !== null ? claims?.[selectedClaimId] : undefined),
    [selectedClaimId, claims],
  )

  // const { processResponse } = useContext(PopUpMessageContext)
  // const { appName } = useApp()

  // const handleSubmitDeliveryDate = useCallback(
  //   async (d: FieldMapValue) => {
  //     if (!assessmentId) {
  //       return { error: 'Internal error' }
  //     }

  //     return updateAssessmentDeliveryStatus({
  //       deliveredOn: d.value || null,
  //       assessmentId,
  //       appName,
  //     })
  //       .then(() => processResponse({ success: 'Report delivery updated' }))
  //       .catch((err: any) => {
  //         console.error(err)
  //         return processResponse({
  //           error: err?.message || 'Error updating report delivery',
  //         })
  //       })
  //   },
  //   [processResponse, assessmentId],
  // )

  const update = useUpdateDoc()
  return (
    <VStack spacing={0} px={2} align="flex-start" w="100%">
      <FloatingContainer p={0} style={{ padding: 0 }} bg="white" w="100%">
        <Stack
          px={4}
          pt={4}
          pb={3}
          bg={`${colors.pink.hex}88`}
          pos="relative"
          w="100%"
        >
          <HStack w="100%">
            <ActionButton
              flex={1}
              size="sm"
              bg="white"
              color="green.500"
              fontFamily="Hero-New"
              fontWeight={500}
              onClick={onLegacyCreate}
            >
              + ADD CLAIM (LEGACY)
            </ActionButton>
            <Button
              // colorScheme='telegram'
              size="sm"
              bg="green.300"
              color="white"
              overflow="hidden"
              _hover={{ bg: 'green.200' }}
              onClick={onLinkClaim}
            >
              <Image
                position="absolute"
                opacity={0.2}
                zIndex={0}
                height="80px"
                src="/images/Inovalon-Logo.svg"
                alt="Ability Logo"
              />
              <LinkIcon mr={1} color="white" />
              <Text
                textShadow="1px 1px 2px #555"
                fontFamily="Hero-New"
                fontWeight={500}
                zIndex={2}
              >
                LINK CLAIM
              </Text>
            </Button>
            <Button
              // colorScheme='telegram'
              size="sm"
              bg="green.300"
              color="white"
              overflow="hidden"
              _hover={{ bg: 'green.200' }}
              onClick={onClaimSubmit}
            >
              <Image
                position="absolute"
                opacity={0.2}
                zIndex={0}
                height="80px"
                src="/images/Inovalon-Logo.svg"
                alt="Ability Logo"
              />
              <Text
                textShadow="1px 1px 2px #555"
                fontFamily="Hero-New"
                fontWeight={500}
                zIndex={2}
              >
                SUBMIT CLAIM
              </Text>
            </Button>
          </HStack>
          <VStack w="100%">
            {sortedClaims.map(([claimKey, claimData]) => (
              <ClaimView
                id={claimKey}
                key={claimKey}
                onClick={() => onSelectClaim(claimKey)}
                claim={claimData}
              />
            ))}
          </VStack>
        </Stack>
      </FloatingContainer>
      <ReferenceNumbersEdit id="claimsReferenceNumbers" />
      <NoteForm
        note={claimsNote}
        boxProps={{ mt: 2 }}
        onSubmit={(data) => update(adminRef, 'claimsNote', data)}
      />
      <Flex py={2} gap={2} flexFlow="column" w="100%">
        <DraftView
          name="Claims Template"
          templateKey="claims"
          draft={drafts?.claimAppealsTemplates}
        />
        {assessmentId && patientId ? (
          <AssessmentDocuments
            adminView
            patientId={patientId}
            category="claims"
            assessmentId={assessmentId}
          />
        ) : null}
      </Flex>
      <Box width="100%" p={2}>
        <Text fontWeight={600} color="gray.700">
          {insuranceProvider ? `${insuranceProvider.name} ` : 'Insurer '}
          Claims Cheat Sheet
        </Text>
        <Text
          fontStyle={insuranceProvider?.claimsSheet ? 'normal' : 'italic'}
          color={insuranceProvider?.claimsSheet ? 'black' : 'gray.700'}
          whiteSpace="pre-wrap"
        >
          {insuranceProvider
            ? insuranceProvider.claimsSheet
              || `No claims cheat sheet for ${insuranceProvider.name}`
            : 'No insurance provider'}
        </Text>
      </Box>
      {selectedClaimId ? (
        <ClaimModal
          select={onSelectClaim}
          selected={selected}
          selectedId={selectedClaimId}
          onClose={() => onSelectClaim(null)}
        />
      ) : null}
    </VStack>
  )
}

export const AssessmentClaims = () => {
  const [creatingNewAbilityClaim, setCreatingNewAbilityClaim] = useState(false)
  const [isLinking, setIsLinking] = useState(false)

  const [creatingNewLegacy, setCreatingNewLegacy] = useState(false)
  const [selectedClaimId, setSelectedClaimId] = useState<string | null>(null)
  const [updatingAbilityId, setUpdatingAbilityId] = useState<string | null>(
    null,
  )

  const { claims } = useContext(UserContext)
  const updatingAbilityClaim = useMemo(() => {
    if (!updatingAbilityId) return undefined
    return (
      Object.values(claims.data || {}).find(
        (c) => c.abilityId === updatingAbilityId,
      ) || undefined
    )
  }, [updatingAbilityId, claims])

  const contextData = useMemo<ClaimsViewData>(
    () => ({
      creatingNewAbilityClaim,
      setCreatingNewAbilityClaim,
      select: setSelectedClaimId,
      selectedId: selectedClaimId,
      creatingNewLegacy,
      setCreatingNewLegacy,
      updatingAbilityId,
      setUpdatingAbilityId,
    }),
    [
      selectedClaimId,
      setSelectedClaimId,
      setUpdatingAbilityId,
      updatingAbilityId,
      creatingNewAbilityClaim,
      creatingNewLegacy,
    ],
  )

  return (
    <ClaimsViewContext.Provider value={contextData}>
      <VStack p={2} pos="relative" w="100%">
        {creatingNewAbilityClaim || updatingAbilityId ? (
          <ClaimsTabSubmitClaim
            claim={updatingAbilityClaim}
            onClose={() => {
              setCreatingNewAbilityClaim(false)
              setUpdatingAbilityId(null)
              setSelectedClaimId(null)
            }}
            existingAbilityId={updatingAbilityId}
          />
        ) : (
          <ClaimsTabContent
            selectedClaimId={selectedClaimId}
            onSelectClaim={setSelectedClaimId}
            onLinkClaim={() => setIsLinking(true)}
            onClaimSubmit={() => setCreatingNewAbilityClaim(true)}
            onLegacyCreate={() => setCreatingNewLegacy(true)}
          />
        )}

        {creatingNewLegacy ? (
          <NewClaimModal
            isOpen
            onClose={() => setCreatingNewLegacy(false)}
            select={setSelectedClaimId}
          />
        ) : null}

        {isLinking ? (
          <LinkClaimModal onClose={() => setIsLinking(false)} />
        ) : null}
      </VStack>
    </ClaimsViewContext.Provider>
  )
}
