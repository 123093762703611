import { AssessmentSnippet, PracticeAssessmentData } from '../../types'
import { adminCollectionAccess, practiceCollectionAccess } from '../constants'
import { ASSESSMENT_SNIPPETS } from '../names'
import { Collection } from '../types/collection'
import { MIDWIVES } from './practice'

export const getPracticeAssessmentDocPath = (
  midwifeId: string,
  assessmentId: string,
) => `${MIDWIVES}/${midwifeId}/${ASSESSMENT_SNIPPETS}/${assessmentId}`
export const getPracticeAssessmentCollectionPath = (midwifeId: string) => `${MIDWIVES}/${midwifeId}/${ASSESSMENT_SNIPPETS}`
export const assessmentsCollection: Collection<AssessmentSnippet> = {
  _type: 'firestoreCollection',
  name: 'Assessments',
  refPath: ASSESSMENT_SNIPPETS,
  access: adminCollectionAccess,
  noRanks: true,
  defaultSort: 'lname',
  shouldNotFetchAll: true,
}

export const getPracticeAssessmentsCollection: (practiceId: string) => Collection<PracticeAssessmentData> = (practiceId) => ({
  _type: 'firestoreCollection',
  name: 'Assessments',
  refPath: getPracticeAssessmentCollectionPath(practiceId),
  access: practiceCollectionAccess,
  noRanks: true,
  defaultSort: 'lname',
  shouldNotFetchAll: true,
})
