import { DeleteIcon } from '@chakra-ui/icons'
import {
  HStack,
  IconButton,
  Image,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
  Tooltip,
  useToast,
} from '@chakra-ui/react'
import { FieldMapValue, getDateString } from '@hb/shared'
import { ValidationErrors } from 'final-form'
import { deleteField } from 'firebase/firestore'
import React, {
  useCallback, useContext, useMemo, useState,
} from 'react'
import { UserContext } from '../../../../../../contexts/UserContext'
import { useUpdateDoc } from '../../../../../../hooks/backend/useUpdateDoc'
import { SaveAsDraftButton } from './SaveAsDraftButton'

export const SelectDraftMenu = ({
  selectedDraftId,
  onSelect,
  onSaveDraftClick,
}: {
  selectedDraftId: string | null
  onSaveDraftClick: (data: FieldMapValue) => Promise<ValidationErrors>
  onSelect: (updated: string | null) => void
}) => {
  const { selectedAssessment } = useContext(UserContext)
  const { claimsDrafts } = selectedAssessment || {}
  const [isOpen, setIsOpen] = useState(false)
  const options = useMemo(
    () => Object.entries(claimsDrafts || {}).map(([claimKey, { name }]) => ({
      id: claimKey,
      text: name || getDateString(parseInt(claimKey, 10)),
    })),
    [claimsDrafts],
  )
  const selectedDraft = useMemo(
    () => (selectedDraftId ? claimsDrafts?.[selectedDraftId] : undefined),
    [selectedDraftId, claimsDrafts],
  )
  const toast = useToast()
  const [deletingDraftId, setDeletingDraftId] = useState<string | null>(null)
  const onUpdate = useUpdateDoc()
  const handleDeleteDraft = useCallback(
    async (id: string) => {
      if (!selectedAssessment?.adminRef) {
        return
      }
      setDeletingDraftId(id)
      try {
        const { error } = await onUpdate(
          selectedAssessment?.adminRef,
          'claimsDrafts',
          { [id]: deleteField() },
        )
        if (error) {
          console.error(error)
        }
      } catch (err: any) {
        toast({
          status: 'error',
          description: err.message || 'An unknown error occurred',
        })
      }
      setDeletingDraftId(null)
    },
    [onUpdate, selectedAssessment, toast],
  )
  const label = useMemo(() => {
    if (selectedDraft) return selectedDraft.name
    if (options.length > 1) return `${options.length} drafts`
    if (options.length === 1) return '1 draft'
    return 'No drafts'
  }, [selectedDraft, options])
  return (
    <HStack spacing={0} position='relative'>
      <Menu isOpen={isOpen} onClose={() => setIsOpen(false)}>
        <Tooltip
          placement='top'
          hasArrow
          bg='gray.100'
          color='gray.600'
          label={options.length ? 'Drafts' : 'No drafts'}
        >
          <MenuButton
            aria-label='Drafts'
            bg='gray.100'
            shadow='sm'
            padding={1}
            onClick={() => {
              if (!options.length) setIsOpen(false)
              else setIsOpen(!isOpen)
            }}
          >
            <Image opacity={0.5} src='/images/draft.svg' height='22px' />
          </MenuButton>
        </Tooltip>
        <MenuList>
          {options.map((o) => (
            <MenuItem as='div' key={o.id} onClick={() => onSelect(o.id)}>
              <HStack w='100%'>
                <Text flex={1} fontFamily='hero-new' fontSize='sm'>
                  {o.text}
                </Text>
                <IconButton
                  ml='auto'
                  size='xs'
                  bg='red.600'
                  color='white'
                  isLoading={deletingDraftId === o.id}
                  aria-label='Delete draft'
                  icon={<DeleteIcon />}
                  onClick={() => handleDeleteDraft(o.id)}
                />
              </HStack>
            </MenuItem>
          ))}
        </MenuList>
      </Menu>
      <Text
        right='40px'
        width='auto'
        whiteSpace='nowrap'
        position='absolute'
        fontSize='xs'
        fontFamily='hero-new'
        color='gray.500'
      >
        {label}
      </Text>
      <SaveAsDraftButton onSave={onSaveDraftClick} />
    </HStack>
  )
}
