import { DataColumn, DataColumns, WithId } from '@hb/shared/types'

export const getFlexColumnWidth = <T extends WithId>({
  width,
  columns,
}: {
  width: number
  columns: DataColumns<T, string>
}) => {
  const { widthColumns, flexColumns } = Object.values(columns).reduce(
    (acc, curr) => {
      if (curr.width) {
        acc.widthColumns.push(curr as DataColumn<any, any> & { width: number })
      } else {
        acc.flexColumns.push(curr)
      }
      return acc
    },
    { widthColumns: [], flexColumns: [] } as {
      widthColumns: Array<DataColumn<any, any> & { width: number }>
      flexColumns: DataColumn<any, any>[]
    },
  )
  const widthColumnsWidth = widthColumns.reduce(
    (acc, curr) => acc + curr.width,
    0,
  )

  // enforce a width of at least 100 for flex columns
  return Math.max(100, (width - widthColumnsWidth) / flexColumns.length)
}
