import { createContext } from 'react'

export type VisitsContextData = {
  selectedVisit: {id: string, isDraft: boolean} | null
  onSelect: (id: string, isDraft: boolean) => void
}
export const VisitsContext = createContext<VisitsContextData>({
  selectedVisit: null,
  onSelect: () => {},
})
