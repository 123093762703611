import { WarningIcon } from '@chakra-ui/icons'
import {
  BadgeProps, Box, Center, Circle, forwardRef, Tooltip,
} from '@chakra-ui/react'
import React from 'react'
import { useAdminsWithColor } from '../../hooks/backend/user/useAdminsWithColor'

export const SortColorCircle = forwardRef<
  Omit<BadgeProps, 'color'> & { urgent?: boolean, withAdmins?: boolean, color?: string | null },
  'span'
>(({
  color, urgent, withAdmins, ...props
}, ref) => {
  const { text } = useAdminsWithColor(withAdmins, color)
  return (
    <Box p="3px" bg="#efefef" borderRadius="full" boxShadow="0 0 5px #00000088">
      <Tooltip
        bg={color || undefined}
        color="white"
        textShadow="1px 1px 3px black"
        placement="top"
        hasArrow
        label={text}
      >
        <Center pos='relative'>
          <Circle
            ref={ref}
            cursor="pointer"
            bg={color || 'rgb(200,200,200)'}
            size="18px"
            {...props}
          />
          {
            urgent ? (
              <WarningIcon
                pos="absolute"
                pointerEvents='none'
                color='whiteAlpha.800'
                w={3}
                h={3}
              />
            ) : null
          }
        </Center>
      </Tooltip>
    </Box>
  )
})

export const UrgentBadge = ({
  urgent,
  ...props
}: BadgeProps & { urgent?: boolean, color: string }) => (
    <SortColorCircle withAdmins urgent={urgent} {...props} />
)
