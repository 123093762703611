import {
  ASSESSMENT_THREADS, DIRECT_MESSAGE_THREADS, GROUP_THREADS,
} from '../collections/names'
import { type ThreadType } from '../types'

export const messageThreadCollections: Record<ThreadType, string> = {
  assessment: ASSESSMENT_THREADS,
  group: GROUP_THREADS,
  direct: DIRECT_MESSAGE_THREADS,
}
