import { Box, Flex, Text } from '@chakra-ui/react'
import React, { useContext } from 'react'
import { UserContext } from '../../contexts'

export const InsurancePlansView = () => {
  const { selectedAssessment } = useContext(UserContext)

  if (!selectedAssessment) {
    return (
      <Flex flexFlow="column" p={3}>
        <Text>Select an assessment to see plans</Text>
      </Flex>
    )
  }
  return (
      <Flex
        bg="gray.50"
        boxShadow="1px 1px 4px #00000055"
        borderRadius={6}
        flexFlow="column"
      >
        <Flex
          borderTopRadius={6}
          bg="white"
          borderBottom="1px solid #cdcdcd"
          px={3}
          py={2}
          w="100%"
        >
          <Text fontWeight={600}>Insurance Plans</Text>
        </Flex>
        <Flex flexFlow="column" p={3}>
          <Text>From Assessment Questionnaire</Text>
          <Box p={2}></Box>
          <Text>Additional Insurance Plans</Text>
          <Box p={2} border="1px solid #cdcdcd"></Box>
        </Flex>
      </Flex>
  )
}
