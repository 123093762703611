import {
  Collection,
  DocData,
} from '@hb/shared'
import {
  useCallback, useContext, useMemo, useState,
} from 'react'
import { addItem, updateItem } from '../collections/utils/write'
import { useApp } from '../contexts/AppContext'
import { PopUpMessageContext } from '../contexts/PopUpMessage/PopUpMessageContext'

export const useUpdateCollection = <Data extends DocData>(
  collection: Collection<Data>,
) => {
  const { appName } = useApp()
  const { processResponse } = useContext(PopUpMessageContext)
  const [loading, setLoading] = useState(false)
  const updateFunc = useCallback(
    (itemId: string, path: string, data: any) => {
      setLoading(true)
      return updateItem(appName, collection, itemId, path, data)
        .then(() => processResponse({ success: 'Updated!' }))
        .catch((e: any) => processResponse({
          error: `Error adding item: ${e?.message || ''}`,
        }))
        .finally(() => setLoading(false))
    },
    [appName, processResponse, collection],
  )
  const addFunc = useCallback(
    (data: DocData) => {
      setLoading(true)
      return addItem(appName, collection, data)
        .then(() => processResponse({ success: 'Added!' }))
        .catch((e: any) => processResponse({
          error: `Error adding item: ${e?.message || ''}`,
        }))
        .finally(() => setLoading(false))
    },
    [appName, collection, processResponse],
  )
  const saveFunc = useCallback(
    (itemId: string, data: DocData) => {
      setLoading(true)
      return updateItem(appName, collection, itemId, '', data)
        .then(() => processResponse({ success: 'Saved!' }))
        .catch((e: any) => processResponse({
          error: `Error saving item: ${e?.message || ''}`,
        }))
    },

    [appName, collection, processResponse],
  )
  const deleteFunc = useCallback(
    (itemId: string) => updateItem(appName, collection, itemId, '', null)
      .then(() => processResponse({ success: 'Deleted!' }))
      .catch((e: any) => processResponse({
        error: `Error deleting item: ${e?.message || ''}`,
      })),
    [appName, collection, processResponse],
  )
  return useMemo(
    () => ({
      updateItem: updateFunc,
      addItem: addFunc,
      saveItem: saveFunc,
      loading,
      deleteItem: deleteFunc,
    }),
    [updateFunc, addFunc, saveFunc, deleteFunc, loading],
  )
}
