import { CircularProgress, Image } from '@chakra-ui/react'
import { colors } from '@hb/shared/constants'
import React, { CSSProperties, FC, useState } from 'react'

type ImageComponentType = typeof Image
type ImageProps = Omit<React.ComponentProps<ImageComponentType>, 'loading'>

const LoadingImagePlaceholder = ({
  isVisible,
  ...props
}: ImageProps & { isVisible: boolean }) => (
  <div
    style={{
      position: 'absolute',
      top: 0,
      width: '100%',
      height: '100%',
      display: 'flex',
      borderRadius: '5px',
      overflow: 'hidden',
      justifyContent: 'center',
      alignItems: 'center',
      transition: 'opacity 300ms ease',
      opacity: isVisible ? 1 : 0,
      pointerEvents: 'none',
      left: 0,
    }}
  >
    <Image opacity={0.5} height="80%" width="80%" {...props} />
  </div>
)

export const LoadingImage: FC<
  {
    loading: boolean
    placeholderSrc?: string
    containerStyle?: CSSProperties
  } & ImageProps
> = ({
  loading,
  placeholderSrc,
  opacity,
  containerStyle,
  // @ts-ignore
  ...props
}) => {
  const [loaded, setLoaded] = useState(false)
  const mainVisible = loading || !loaded || !props.src
  return (
    <div
      draggable={false}
      style={{
        position: 'relative',
        height: !loaded ? (props.width as CSSProperties['width']) : 'auto',
        ...containerStyle,
      }}
    >
      <Image
        opacity={mainVisible ? 0 : opacity}
        transition="all 300ms"
        onLoad={() => setLoaded(true)}
        {...props}
      />
      {placeholderSrc ? (
        <LoadingImagePlaceholder isVisible={!loaded} src={placeholderSrc} />
      ) : null}
      <div
        style={{
          position: 'absolute',
          top: 0,
          width: '100%',
          height: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          opacity: loading ? 1 : 0,
          transition: 'opacity 300ms ease',
          // backgroundColor: 'rgba(0, 0, 0, 0.4)',
          pointerEvents: 'none',
          left: 0,
        }}
      >
        <CircularProgress isIndeterminate color={colors.green.hex} size={5} />
      </div>
    </div>
  )
}
