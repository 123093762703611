import merge from 'lodash.merge'
import { PopulatedAssessment } from '../types/app/populated'
import { FieldMap } from '../types/forms/fields'
import { Form } from '../types/forms/form'
import { UserFieldKey } from '../types/users/user'
import {
  defaultStageValidate,
  getFieldFromPath,
  getNestedFields,
  makeAllFieldsAdmin,
  makeFieldsOptional,
} from '../utils/fields'
import { callIn } from './callIn'
import { introduction } from './introduction'
import { signOnFields } from './signOn'

export const assessmentFields: Record<UserFieldKey, Form | string> = {
  introduction,
  // 'insurance-info-summary': summarizedInsuranceInfo,
  'call-in': callIn,
  'plan-options': 'planOptions',
  'schedule-call': 'scheduleCall',
  // insuranceDetails
}

export const getAssessmentFields = (
  admin: boolean,
  assessment?: Omit<
    PopulatedAssessment,
    'patient' | 'patientId' | 'availableForms' | 'stages'
  >,
): Record<UserFieldKey, string | Form> => {
  let currKey: UserFieldKey | undefined = 'introduction'
  let res: Record<string, Form | string> = {}
  while (currKey) {
    const curr: Form | string = assessmentFields[currKey]
    if (curr) {
      res = { ...res, [currKey]: curr }
      if (curr && typeof curr !== 'string' && curr.getNextFormId) {
        currKey = curr.getNextFormId(admin, assessment)
      } else {
        currKey = undefined
      }
    } else currKey = undefined
  }
  return res as Record<UserFieldKey, Form | string>
}
export const nestedAssessmentFields = getNestedFields({
  ...assessmentFields,
})

export const getAssessmentFieldFromPath = (path: string) => getFieldFromPath(
  {
    ...nestedAssessmentFields,
    children: {
      ...nestedAssessmentFields.children,
      signOn: signOnFields,
    },
  },
  path,
)

export const getNestedUserFields = (
  admin: boolean,
  assessment?: Omit<
    PopulatedAssessment,
    'availableForms' | 'patient' | 'patientId' | 'stages' | 'midwifeId'
  >,
): FieldMap => {
  const nestedFields = getNestedFields(getAssessmentFields(admin, assessment))

  return makeFieldsOptional(
    nestedFields,
    admin
      ? [
        'insurance-info.primaryCoverage.insuranceCard.front',
        'insurance-info.primaryCoverage.insuranceCard.back',
        'insurance-info.medicaidCoverage.insuranceCard.front',
        'insurance-info.medicaidCoverage.insuranceCard.back',
      ]
      : [],
  )
}

export const validateAssessmentAnswers = (
  admin: boolean,
  assessment: PopulatedAssessment,
) => {
  const { data, corrections } = assessment
  const validated = {}
  merge(validated, data)
  if (admin) merge(validated, corrections)
  const nestedFields = getNestedFields(getAssessmentFields(admin, assessment))
  return defaultStageValidate(
    admin ? makeAllFieldsAdmin(nestedFields) : nestedFields,
  )(validated)
}
