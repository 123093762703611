import { type PracticeInvoice } from '../../invoicing'
import { adminCollectionAccess } from '../constants'
import { Collection } from '../types/collection'

export const INVOICES = 'invoices'
export const invoicesCollection: Collection<PracticeInvoice> = {
  _type: 'firestoreCollection',
  name: 'Invoices',
  refPath: INVOICES,
  access: adminCollectionAccess,
  noRanks: true,
  defaultSort: 'dueDate',
  shouldNotFetchAll: true,
}
