import { BaseLabel, colors } from '@hb/shared'
import React, { FC } from 'react'
import { Container } from '../../Container'

const Label: FC<BaseLabel> = ({ text, style }) => (
    <span
      style={{
        height: 'auto',
        padding: '0.25rem 0',
        alignItems: 'flex-start',
        boxSizing: 'border-box',
        fontFamily: 'hero-new',
        color: colors.green.hex,
        // fontWeight: 600,
        fontSize: '15px',
        ...style,
      }}
    >
      {text}
    </span>
)

const renderLabel = (label: BaseLabel, index?: number) => (typeof label === 'string' ? (
    <Label key={`label_${index}`} text={label} />
) : (
    <Label key={`label_${index}`} {...label} />
))

const Labels: FC<{ labels?: BaseLabel[] }> = ({ labels }) => {
  if (!labels) return null
  return (
    <Container
      style={{
        paddingBottom: '5px',
        display: 'block',
        alignItems: 'flex-start',
      }}
    >
      {labels.map((l, index) => renderLabel(l, index))}
    </Container>
  )
}

export default Labels
