import { CloseIcon, SearchIcon } from '@chakra-ui/icons'
import {
  Flex, FlexProps, IconButton, Input, Tooltip,
} from '@chakra-ui/react'
import { capitalizeFirstLetter, makePlural } from '@hb/shared'
import React, {
  forwardRef,
  useCallback,
  useContext,
  useEffect,
  useImperativeHandle,
  useRef,
} from 'react'
import { SearchBarContext } from '../../contexts'
import { InputRef } from '../forms/Input/types'
import { AddItemButton } from './AddItemButton'

export const SearchBar = forwardRef<
  InputRef,
  {
    onAddClick?:() => void
    itemName: string
    flexProps?: FlexProps
    onClear?: () => void
    onFocus?: () => void
    onBlur?: () => void
    autoSubmit?: boolean
      }
      >(({
        onAddClick, itemName, onClear, flexProps, autoSubmit, onFocus, onBlur,
      }, ref) => {
        const inputRef = useRef<HTMLInputElement>(null)

        useImperativeHandle(ref, () => ({
          focus: () => {
            if (inputRef.current) {
              inputRef.current.focus()
            }
          },
          blur: () => {
            if (inputRef.current) {
              inputRef.current.blur()
            }
          },
        }))

        const { query, updateQuery } = useContext(SearchBarContext)
        useEffect(() => {
          const onSearch = (e: KeyboardEvent) => {
            if (e.key === 'Enter') {
              if (inputRef.current && inputRef.current === document.activeElement) {
                updateQuery(inputRef.current.value)
              }
            }
          }
          document.addEventListener('keypress', onSearch)
          return () => {
            document.removeEventListener('keypress', onSearch)
          }
        }, [updateQuery, autoSubmit])

        const clear = useCallback(() => {
          updateQuery('')
          if (inputRef.current) {
            inputRef.current.value = ''
          }
        }, [updateQuery])

        useEffect(() => {
          if (!inputRef.current) return
          inputRef.current.value = query
        }, [query])
        return (
    <Flex
      pos="relative"
      align="center"
      width="100%"
      opacity={0.7}
      px={2}
      py={1}
      {...flexProps}
    >
      <Flex align="center" flex={1} pl={1} pos="relative">
        <SearchIcon width={4} height={4} />
        <Input
          ref={inputRef}
          // onChange={({ target }) => {
          //   setTyped(target.value)
          // }}
          // value={typed}
          value={autoSubmit ? query : undefined}
          _focus={{
            boxShadow: 'none',
            border: '1px solid #999999',
          }}
          onChange={
            autoSubmit
              ? ({ target }) => {
                updateQuery(target.value)
              }
              : undefined
          }
          background="white"
          onFocus={onFocus}
          onBlur={onBlur}
          ml={3}
          placeholder={`Search ${makePlural(itemName.toLowerCase())}...`}
          size="sm"
        />
        <IconButton
          zIndex={2}
          right={2}
          position="absolute"
          aria-label="clear"
          onClick={() => {
            clear()
            if (onClear) {
              onClear()
            }
          }}
          size="xs"
          borderRadius="full"
          variant="ghost"
          opacity={0.7}
          icon={<CloseIcon width={2} height={2} />}
        />
      </Flex>
      {onAddClick ? (
        <Tooltip
          placement="top"
          label={`+ New ${capitalizeFirstLetter(itemName)}`}
        >
          <AddItemButton itemName={itemName} onClick={onAddClick} />
        </Tooltip>
      ) : null}
    </Flex>
        )
      })
