import { AddIcon, EditIcon } from '@chakra-ui/icons'
import {
  Button,
  IconButton,
  Popover,
  PopoverArrow,
  PopoverContent,
  PopoverTrigger,
  Text,
} from '@chakra-ui/react'
import {
  DollarAmountField,
  FieldMap,
  FieldMapValue,
  FieldTypes,
  PracticeInvoiceLineItem,
  TextAreaField,
  UpdateCallback,
} from '@hb/shared'
import React, { useCallback, useContext, useState } from 'react'
import { PopUpMessageContext } from '../../../contexts/PopUpMessage/PopUpMessageContext'
import { SimpleForm } from '../../forms/FinalForm/SimpleForm'

const lineItemAmountField: DollarAmountField = {
  placeholder: 'Amount',
  min: -10000,
  type: FieldTypes.DOLLAR_AMOUNT,
}

const lineItemReceivedAmountField: DollarAmountField = {
  placeholder: 'Received by Midwife (optional)',
  type: FieldTypes.DOLLAR_AMOUNT,
  optional: true,
}

const lineItemDescriptionField: TextAreaField = {
  placeholder: 'Description',
  type: FieldTypes.TEXTAREA,
}

const lineItemField: FieldMap = {
  name: 'Line Item',
  children: {
    amount: lineItemAmountField,
    receivedAmount: lineItemReceivedAmountField,
    description: lineItemDescriptionField,
  },
}

type SubmittedArgs = PracticeInvoiceLineItem & { lineItemId: string | null }
export const LineItemPopover = ({
  onSubmit,
  value,
  lineItemId,
  buttonText = 'ADD LINE ITEM',
}: {
  lineItemId?: string
  buttonText?: string
  onSubmit: (updated: SubmittedArgs) => Promise<UpdateCallback>
  value?: PracticeInvoiceLineItem
}) => {
  const [loading, setLoading] = useState(false)
  const { showError } = useContext(PopUpMessageContext)

  const [isOpen, setIsOpen] = useState(false)
  const addLineItem = useCallback(
    async (updated: FieldMapValue): Promise<UpdateCallback> => {
      setLoading(true)
      try {
        const submitted = { ...updated as PracticeInvoiceLineItem, lineItemId: lineItemId || null }
        await onSubmit(submitted)
        setIsOpen(false)
        setLoading(false)
        return { success: 'Added line item' }
      } catch (err: any) {
        console.error(err)
        showError(err.message)
        setLoading(false)
        return { error: err.message }
      }
    },
    [onSubmit, showError, lineItemId],
  )

  return (
    <Popover
      isOpen={isOpen}
      onClose={() => setIsOpen(false)}
      onOpen={() => setIsOpen(true)}
      placement='top'
      strategy='fixed'
    >
      <PopoverTrigger>
        {lineItemId ? (
          <IconButton
            size='xs'
            variant='ghost'
            icon={<EditIcon />}
            aria-label='Edit line item'
          />
        ) : (
          <Button
            w='100%'
            minH='24px'
            h='24px'
            _hover={{ bg: 'green.400' }}
            py={0}
            size='xs'
            variant='ghost'
            borderRadius={0}
            isLoading={loading}
            color='gray.100'
            bg='green.500'
          >
            <AddIcon color='gray.100' />
            <Text ml={1}>{buttonText}</Text>
          </Button>
        )}
      </PopoverTrigger>
      <PopoverContent p={0}>
        <PopoverArrow />
        {isOpen ? (
          <SimpleForm
            value={value}
            theme='detailed'
            field={lineItemField}
            onSubmit={addLineItem}
          />
        ) : null}
      </PopoverContent>
    </Popover>
  )
}
