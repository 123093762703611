import { CollectionItem } from '@hb/shared'
import cloneDeep from 'lodash.clonedeep'
import { DropResult } from 'react-beautiful-dnd'

export function reorder<T = any>(
  items: CollectionItem<T>[],
  dropResult: DropResult,
) {
  const result = cloneDeep(items)
  if (dropResult.destination && dropResult.source) {
    const [removed] = result.splice(dropResult.source.index, 1)
    result.splice(dropResult.destination.index, 0, removed)
    result.forEach((res, index) => { res.rank = index })
  }
  return result
}
