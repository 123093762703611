import { ViewIcon } from '@chakra-ui/icons'
import {
  Box, Button, Flex, HStack, Text, VStack,
} from '@chakra-ui/react'
import {
  colors,
  midwifeId, midwivesCollection, MIDWIVES_ADMIN,
} from '@hb/shared'

import React, { useContext, useMemo, useState } from 'react'
import { useCollection } from '../../collections/hooks/useCollection'
import {
  AppContext, PopUpMessageContext,
  PracticeContext,
  usePracticeAccess,
  UserContext,
} from '../../contexts'
import { useDocument } from '../../hooks/backend/useDocument'
import { useSelectedItem } from '../../hooks/backend/useSelectedItem'
import { useUpdateDoc } from '../../hooks/backend/useUpdateDoc'

import { SimpleForm } from '../forms'
import { LegacyMidwifeView } from './LegacyMidwifeView'
import { PracticeDataView } from './PracticeDataView'
import { ProviderAlertPopover } from './ProviderAlertPopover'

export const MidwifeInfo = ({ id }: { id?: string }) => {
  const practices = useCollection(midwivesCollection)

  const { appName } = useContext(AppContext)
  const { selectedPracticeId } = usePracticeAccess()
  // const { midwives: adminMidwives } = admin || {}
  const provider = useSelectedItem(practices?.items, id)
  const practiceAdminDocId = useMemo(
    () => (appName === 'providers-app'
      && selectedPracticeId
      && id === selectedPracticeId
      ? selectedPracticeId
      : null),
    [appName, selectedPracticeId, id],
  )
  const { data } = useDocument(MIDWIVES_ADMIN, practiceAdminDocId)
  const mergedData = useMemo(
    () => (provider && ((appName === 'providers-app' && data) || appName === 'app')
      ? { ...(data || {}), ...provider }
      : undefined),
    [data, provider, appName],
  )
  const { fname, lname } = mergedData || {}
  const [viewLegacy, setViewLegacy] = useState(false)

  return (
    <PracticeContext.Provider
      value={{ practices, practice: mergedData, id }}
    >
      <VStack align="flex-start" spacing={0} w="100%">
        <Flex
          pt={2}
          bg={colors.pink.hex}
          w="100%"
          pb={1}
          borderBottom="1px solid #cdcdcd"
        >
          <VStack pr={10} w="100%" align="flex-start" spacing={0}>
            <HStack px={2} align="center" w="100%">
              <Text
                pl={1}
                fontWeight={600}
                textShadow="0 0 6px white"
                color="#222"
                fontFamily="Comfortaa"
                lineHeight={1}
                flex={1}
                fontSize="lg"
              >
                {`${fname} ${lname}`}
              </Text>
              <ProviderAlertPopover midwife={provider} />
              <Button
                onClick={() => setViewLegacy(!viewLegacy)}
                variant="outline"
                bg="gray.50"
                colorScheme={viewLegacy ? 'green' : 'gray'}
                size="xs"
              >
                <HStack>
                  <ViewIcon height={4} />
                  <Text>{viewLegacy ? 'View New' : 'View Legacy'}</Text>
                </HStack>
              </Button>
            </HStack>
          </VStack>
        </Flex>
        <Flex w="100%" bg="whiteAlpha.600" borderRadius={4}>
          {viewLegacy ? <LegacyMidwifeView /> : <PracticeDataView />}
        </Flex>
        {/* <AdminMidwifeInfo /> */}
      </VStack>
    </PracticeContext.Provider>
  )
}

export const PatientMidwifeInfo: React.FC = () => {
  const { processResponse } = useContext(PopUpMessageContext)
  const { user, selectedAssessment } = useContext(UserContext)
  const { midwife, ref } = selectedAssessment || {}
  const update = useUpdateDoc('practice')
  return (
    <Box width="100%">
      {midwife ? (
        <MidwifeInfo id={midwife.id} />
      ) : (
        <VStack p={2} spacing={0} w="100%">
          <Text fontSize="lg" color="gray.600" mb={2}>
            To edit midwife information, assign a midwife to {user?.name}
          </Text>
          <SimpleForm
            field={midwifeId}
            value={{ value: '' }}
            theme="detailed"
            boxProps={{ boxShadow: 'none', p: 0 }}
            onSubmit={async ({ value }) => {
              if (!user || !selectedAssessment) {
                return processResponse({
                  error: 'Select or create an assessment first',
                })
              }
              return update(ref, 'corrections', {
                delivery: { midwifeId: value },
              })
            }}
          />
        </VStack>
      )}
    </Box>
  )
}
