import { useDisclosure } from '@chakra-ui/react'
import React, { useCallback, useContext } from 'react'
import { PopUpMessageContext } from '../../../contexts/PopUpMessage/PopUpMessageContext'
import { UserContext } from '../../../contexts/UserContext'
import { useUpdateDoc } from '../../../hooks/backend/useUpdateDoc'
import { ActionConfirm } from '../../Alerts/ActionConfirm'
import { SolidActionButton } from '../../Buttons/ActionButton'

export const SkipToSignOn = () => {
  const { selectedAssessment, user } = useContext(UserContext)
  const { processResponse } = useContext(PopUpMessageContext)
  const { skippedQuestionnaire, ref } = selectedAssessment || {}
  const { isOpen, onClose, onOpen } = useDisclosure()
  const update = useUpdateDoc('assessment')
  const onUpdate = useCallback(async () => {
    if (!user?.id) return processResponse({ error: 'no user to update' })
    if (!selectedAssessment) { return processResponse({ error: 'no assessment selected' }) }
    return processResponse(
      await update(ref, '', { skippedQuestionnaire: !skippedQuestionnaire }),
    )
  }, [
    user,
    selectedAssessment,
    skippedQuestionnaire,
    processResponse,
    ref,
    update,
  ])
  return selectedAssessment?.submittedOn ? null : (
    <>
      <SolidActionButton ml='auto' size='xs' onClick={onOpen}>
        {skippedQuestionnaire ? 'Require Questionnaire' : 'Skip to Sign On'}
      </SolidActionButton>
      <ActionConfirm
        isOpen={isOpen}
        confirmText={
          skippedQuestionnaire ? 'Require questionnaire' : 'Skip to sign on'
        }
        header='Are you sure?'
        body={
          skippedQuestionnaire
            ? 'This will require the patient to complete the questionnaire'
            : 'This will skip the questionnaire and take the patient straight to the sign on form'
        }
        onClose={onClose}
        onConfirm={onUpdate}
      />
    </>
  )
}
