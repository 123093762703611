import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  AlertDialogProps,
  Button,
} from '@chakra-ui/react'
import { colors } from '@hb/shared'
import React, {
  useCallback, useEffect, useRef, useState,
} from 'react'
import DatePicker from 'react-date-picker'
import { ActionButton } from '../../Buttons'
import './pickers.css'

export const DateTimePickerModal: React.FC<
  {
    onChange: (v?: number) => Promise<any>
    value?: number
    header: string
  } & Omit<AlertDialogProps, 'children' | 'leastDestructiveRef'>
> = ({
  onChange, value, header, onClose, ...props
}) => {
  const cancelRef = useRef<HTMLButtonElement | null>(null)
  const [hasChanged, setHasChanged] = useState(false)
  const [submitting, setSubmitting] = useState(false)
  const [currValue, setCurrValue] = useState<Date | undefined>(
    value ? new Date(value) : undefined,
  )

  useEffect(() => {
    setCurrValue(value ? new Date(value) : undefined)
  }, [value])

  const handleChange = (v: Date) => {
    setCurrValue(v)
    setHasChanged(true)
  }
  const handleConfirm = useCallback(async () => {
    if (hasChanged) {
      setSubmitting(true)
      await onChange(currValue?.getTime())
      setSubmitting(false)
      onClose()
    } else {
      onClose()
    }
  }, [currValue, hasChanged, onChange, onClose])

  return (
    <AlertDialog leastDestructiveRef={cancelRef} onClose={onClose} {...props}>
      <AlertDialogOverlay>
        <AlertDialogContent width='auto'>
          <AlertDialogHeader pb={0} opacity={0.7} fontSize='md'>
            {header}
          </AlertDialogHeader>
          <AlertDialogBody>
            <DatePicker value={currValue} onChange={handleChange} />
          </AlertDialogBody>
          <AlertDialogFooter>
            <Button
              size='sm'
              isLoading={submitting}
              variant='ghost'
              ref={cancelRef}
              onClick={onClose}
            >
              Cancel
            </Button>
            <ActionButton colorScheme='gray'
              ml={2}
              mt={0}
              isLoading={submitting}
              onClick={handleConfirm}
              background={colors.indigo.hex}
              color='white'
            >
              Confirm
            </ActionButton>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  )
}
