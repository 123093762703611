import {
  AppName,
  FieldMapValue,
  UpdateCallback, UserInviteProps,
  USERS,
} from '@hb/shared'
import {
  browserPopupRedirectResolver,
  indexedDBLocalPersistence,
  initializeAuth,
} from 'firebase/auth'
import {
  collection, getDocs, query, where,
} from 'firebase/firestore'
import { httpsCallable } from 'firebase/functions'
import { app } from './app'
import { db } from './db'
import { functions } from './functions'

export const auth = initializeAuth(app, {
  persistence: indexedDBLocalPersistence,
  popupRedirectResolver: browserPopupRedirectResolver,
})
// auth.settings.appVerificationDisabledForTesting = true

export const getPracticeUserIdFromEmail = async (email: string) => {
  if (!email) return null
  const user = await getDocs(
    query(collection(db, USERS), where('email', '==', email)),
  )
  return user.docs[0]?.id
}

const inviteUserFunction = httpsCallable<
  Omit<UserInviteProps, 'id'>,
  UpdateCallback
>(functions, 'inviteUser')

export const inviteUser = async (
  appName: AppName,
  data: FieldMapValue,
  practiceId?: string,
): Promise<UpdateCallback> => {
  const {
    email, fname, lname, role, phone, dob, messageToAdmins,
  } = data
  const submitted = {
    fname,
    lname,
    role,
    createdOn: Date.now(),
  } as Omit<
    UserInviteProps & { practiceId?: string; messageToAdmins?: string },
    'id' | 'appName'
  >

  if (email) submitted.email = email
  if (phone) submitted.phone = phone
  if (dob) submitted.dob = dob
  if (messageToAdmins) submitted.messageToAdmins = messageToAdmins

  if (practiceId) {
    submitted.practiceId = practiceId
  }
  try {
    const res = await inviteUserFunction({
      appName,
      ...submitted,
    })
    return res.data
  } catch (err) {
    console.log(err)
    return { error: 'Error sending invite' }
  }
}
