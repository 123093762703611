import {
  Image,
} from '@chakra-ui/react'
import { ImageViewModalProps } from '@hb/shared'
import React, { useContext, useState } from 'react'
import { TransformComponent, TransformWrapper } from 'react-zoom-pan-pinch'
import { ScreenContext } from '../../contexts/ScreenContext'
import loadingIcon from '../../icons/loading.svg'
import { DefaultModal } from './DefaultModal'

export const ImageViewModal: React.FC<ImageViewModalProps> = ({
  src,
  ...props
}) => {
  const screen = useContext(ScreenContext)
  const [width, setWidth] = useState<number | null>(null)
  const [height, setHeight] = useState<number | null>(null)
  return (
    <DefaultModal
      overlayHeader
      isCentered
      // header={header}
      bodyProps={{
        width: 'auto',
        height: 'auto',
        background: '#00000066',
      }}
      headerTextProps={{ color: 'white', textShadow: '1px 1px 4px black', filter: 'drop-shadow(0 0 4px #00000077)' }}
      contentProps={{
        p: 0,
        width: 'auto',
        background: 'transparent',
        overflow: 'hidden',
        maxH: height || '100%',
        maxW: width || '100%',
      }}
      render={() => (
        <TransformWrapper>
          <TransformComponent>
        <Image
          // h={height}

          maxW={screen.width}
          maxH={screen.height}
          objectFit={'contain'}
          onLoad={(e) => {
            const { naturalHeight, naturalWidth } = e.target as HTMLImageElement
            setWidth(Math.min(naturalWidth || 50, screen.width))
            setHeight(Math.min(naturalHeight || 200, screen.height))
          }}
          fallbackSrc={loadingIcon}
          src={src}
        />
          </TransformComponent>
        </TransformWrapper>
      )}
      {...props}
    />
  )
}
