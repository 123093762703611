import {
  Collection, CollectionItem, DocData,
} from '@hb/shared/collections'
import { useEffect, useMemo, useState } from 'react'
import { subscribeToCollectionItem } from '../../collections/utils/read'

type UseCollectionItem<Data extends DocData> = {
  item: CollectionItem<Data> | null
  loading: boolean
  error: string | null
}

export function useCollectionItem<Data extends DocData = DocData>(
  collection: Collection<Data> | null,
  itemId?: string | null,
): UseCollectionItem<Data> {
  // const collectionState = useCollection(collection)
  // const { loading, items = [] } = collectionState || {}

  // const item = useMemo(
  //   () => (items && itemId ? (items as CollectionItem<Data>[]).find((i) => i.id === itemId) : undefined),
  //   [items, itemId],
  // )
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState<string | null>(null)
  const [item, setItem] = useState<CollectionItem<Data> | null>(null)

  useEffect(() => {
    if (!collection || !itemId) {
      setLoading(false)
      setError(null)
      setItem(null)
      return () => {}
    }
    return subscribeToCollectionItem(collection, itemId, {
      onData: (data) => {
        setItem(data)
      },
      onError: (newError) => {
        setError(newError)
      },
      onLoading: (newLoading) => {
        setLoading(newLoading)
      },
    })
  }, [collection, itemId])

  return useMemo(
    () => ({ item, loading, error }),
    [item, loading, error],
  )
}
