import { Text } from '@chakra-ui/react'
import {
  alternateEmailField,
  colors,
  dobField,
  faxField,
  fnameField,
  getFullName,
  lnameField,
  midwivesCollection,
  phoneField,
  PracticeWithAdmin,
  providerLicenseField,
  providerMedicaidIdField,
  providerNpiField,
  providerSsnField,
  WithId,
} from '@hb/shared'
import React from 'react'
import { useUpdateCollection } from '../../hooks/useAppCollections'
import { DataHeader } from '../DataView/DataHeader'
import { DataViewList } from '../DataView/DataView'
import { Expandable } from '../Expandable'
import { EditableRow } from '../forms/Input'

const MidwifeReferringAdditionalInfo = ({
  midwife,
}: {
  midwife?: WithId<PracticeWithAdmin>
}) => {
  const { updateItem } = useUpdateCollection(midwivesCollection)
  return (
    <>
      <EditableRow
        index={3}
        label='Email'
        field={alternateEmailField}
        value={midwife?.email}
        onSubmit={(v) => updateItem(midwife?.id || '', 'email', v)}
      />
      <EditableRow
        index={4}
        label='Phone'
        field={phoneField}
        value={midwife?.phone}
        onSubmit={(v) => updateItem(midwife?.id || '', 'phone', v)}
      />
      <EditableRow
        label='Fax'
        index={5}
        field={faxField}
        value={midwife?.fax}
        onSubmit={(v) => updateItem(midwife?.id || '', 'fax', v)}
      />
      <Expandable
        header={() => (
          <Text color='gray.500' fontWeight={600} p={1}>
            Other Info
          </Text>
        )}
      >
        <DataViewList
          // index={6}
          itemField={providerLicenseField}
          itemName='License'
          value={midwife?.licenses}
          onSubmit={(v) => updateItem(midwife?.id || '', 'licenses', v)}
        />
        <DataViewList
          itemField={providerMedicaidIdField}
          itemName='Medicaid ID'
          // index={7}
          value={midwife?.medicaidIds}
          onSubmit={(v) => updateItem(midwife?.id || '', 'medicaidIds', v)}
        />
        <EditableRow
          index={8}
          label='SSN'
          field={providerSsnField}
          value={midwife?.ssn}
          onSubmit={(v) => updateItem(midwife?.id || '', 'ssn', v)}
        />
        <EditableRow
          index={9}
          label='DOB'
          field={dobField}
          value={midwife?.dob}
          onSubmit={(v) => updateItem(midwife?.id || '', 'dob', v)}
        />
      </Expandable>
    </>
  )
}

export const ReferringProviderInfo = ({
  midwife,
}: {
  midwife?: WithId<PracticeWithAdmin>
}) => {
  const { updateItem } = useUpdateCollection(midwivesCollection)
  return (
    <Expandable
      nested
      initExpanded
      headerProps={{ bg: `${colors.pink.hex}cc` }}
      header={() => (
        <DataHeader>
          <Text py={1} fontSize='sm'>
            Referring Provider - {getFullName(midwife)}
          </Text>
        </DataHeader>
      )}
    >
      <EditableRow
        index={0}
        label='First Name'
        field={fnameField}
        value={midwife?.fname}
        onSubmit={(v) => updateItem(midwife?.id || '', 'fname', v)}
      />
      <EditableRow
        index={1}
        label='Last Name'
        field={lnameField}
        value={midwife?.lname}
        onSubmit={(v) => updateItem(midwife?.id || '', 'lname', v)}
      />
      <EditableRow
        index={2}
        label='Individual NPI'
        field={providerNpiField}
        value={midwife?.npi}
        onSubmit={(v) => updateItem(midwife?.id || '', 'npi', v)}
      />

      <MidwifeReferringAdditionalInfo midwife={midwife} />
    </Expandable>
  )
}
