import { ChevronDownIcon, ChevronUpIcon } from '@chakra-ui/icons'
import { IconButton } from '@chakra-ui/react'
import React from 'react'

export const ViewButton: React.FC<{
  onClick: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void
  isOpen: boolean
  viewText?: string
  hideText?: string
}> = ({
  viewText = 'View', hideText = 'Hide', onClick, isOpen,
}) => (
  <IconButton
    icon={
      isOpen ? (
        <ChevronUpIcon width={5} height={5} />
      ) : (
        <ChevronDownIcon width={5} height={5} />
      )
    }
    aria-label={isOpen ? hideText : viewText}
    variant='ghost'
    p='2px'
    ml='auto'
    size='xs'
    fontSize='xs'
    color='#777'
    onClick={ (e) => {
      e.stopPropagation()
      onClick(e)
    }}
  >
    {/* {isOpen ? hideText || 'HIDE' : viewText || 'VIEW'} */}
  </IconButton>
)
