import { CalendarIcon, DownloadIcon } from '@chakra-ui/icons'
import {
  Badge,
  BadgeProps,
  Divider,
  Flex,
  IconButton,
  Text,
  Tooltip,
} from '@chakra-ui/react'
import { colors } from '@hb/shared/constants'
import {
  currentPregnancyField,
  deliveryPlanField,
  riskAssessmentField,
  visitHistoryField,
} from '@hb/shared/fields/visit'
import {
  PracticeVisit,
  PracticeVisitDraft,
  UnsavedPracticeVisitDraft,
} from '@hb/shared/types'
import { getDateTimeRangeString, getFullName } from '@hb/shared/utils'
import React, {
  useCallback, useContext, useMemo, useState,
} from 'react'
import { downloadFromStorage } from '../../../../backend'
import { useCachedUser } from '../../../../collections/hooks/cached'
import { PopUpMessageContext } from '../../../../contexts/PopUpMessage/PopUpMessageContext'
import { formatValue } from '../../../../utils'
import { DataCell } from '../../../DataView'
import { DataLabel } from '../../../forms/Input/DataLabel'
import { AuthenticationView } from './AuthenticationView'

const DataBadge = (props: BadgeProps) => (
  <Badge
    bg={colors.green.hex}
    textShadow="1px 1px 3px #00000077"
    color="white"
    boxShadow="1px 1px 4px #00000055"
    {...props}
  />
)

const CurrentPregnancyView = ({
  value,
}: {
  value: PracticeVisit['currentPregnancy']
}) => {
  const formattedValue = useMemo(
    () => formatValue({
      field: currentPregnancyField,
      value,
    }),
    [value],
  )

  return (
    <Flex gap={2} align="center">
      <DataLabel>Current Pregnancy</DataLabel>
      <DataCell>{formattedValue}</DataCell>
    </Flex>
  )
}

export const ParaView = ({ para }: { para: PracticeVisit['para'] }) => (
  <Flex gap={2} flexFlow="row wrap" align="center" py={1}>
    <DataLabel>Para:</DataLabel>
    {para.map((p, i) => (
      <DataBadge fontSize="sm" key={i}>
        {p}
      </DataBadge>
    ))}
  </Flex>
)

const HistoryView = ({
  history,
  historyDetails,
}: {
  history: PracticeVisit['history']
  historyDetails: string | undefined
}) => {
  const options = useMemo(
    () => history.map((v) => visitHistoryField.options.find((o) => o.id === v)),
    [history],
  )

  return (
    <>
      <Flex w="100%" gap={2}>
        <DataLabel>History</DataLabel>
        <Flex flex={1} gap={2} minW="0" flexFlow="row wrap">
          {options.map((o, i) => (
            <DataBadge key={i}>{o?.text}</DataBadge>
          ))}
        </Flex>
      </Flex>
      {historyDetails ? (
        <Flex flexFlow="column" mt={2} gap={0}>
          <DataLabel>History Details</DataLabel>
          <DataCell py={0}>{historyDetails}</DataCell>
        </Flex>
      ) : null}
    </>
  )
}

const DeliveryPlanView = ({
  deliveryPlan,
  deliveryPlanDetails,
}: {
  deliveryPlan: PracticeVisit['deliveryPlan']
  deliveryPlanDetails: string | undefined
}) => {
  const formattedDeliveryPlan = useMemo(
    () => formatValue({
      field: deliveryPlanField,
      value: deliveryPlan,
    }),
    [deliveryPlan],
  )

  return (
    <>
      <Flex gap={2}>
        <DataLabel>Delivery Plan</DataLabel>
        <DataCell p={0}>{formattedDeliveryPlan}</DataCell>
      </Flex>
      {deliveryPlanDetails ? (
        <Flex gap={2} align="center">
          <DataLabel>Delivery Plan Details</DataLabel>
          <DataCell>{deliveryPlanDetails}</DataCell>
        </Flex>
      ) : null}
    </>
  )
}

const HospitalView = ({ hospital }: { hospital: string }) => (
  <Flex gap={2} align="center">
    <DataLabel>Hospital</DataLabel>
    <DataCell py={0}>{hospital}</DataCell>
  </Flex>
)

const KeywordsView = ({ keywords }: { keywords: string | undefined }) => (
  <Flex gap={2} align="center">
    <DataLabel>Keywords</DataLabel>
    {keywords ? (
      keywords.split(',').map((k, i) => <DataBadge key={i}>{k}</DataBadge>)
    ) : (
      <DataCell py={0}>None</DataCell>
    )}
  </Flex>
)

const RiskAssessmentView = ({
  riskAssessment,
}: {
  riskAssessment: PracticeVisit['riskAssessment']
}) => {
  const formattedRiskAssessment = useMemo(
    () => formatValue({
      field: riskAssessmentField,
      value: riskAssessment,
    }),
    [riskAssessment],
  )

  return (
    <Flex gap={2} align="center">
      <DataLabel>Risk Assessment</DataLabel>
      <DataCell py={0}>{formattedRiskAssessment}</DataCell>
    </Flex>
  )
}

const GravidaView = ({ gravida }: { gravida: number }) => (
      <Flex gap={2} align="center" py={1}>
        <DataLabel>Gravida:</DataLabel>
        <DataBadge fontSize="sm">{gravida}</DataBadge>
      </Flex>
)

const AllergyNotesView = ({ allergyNotes }: { allergyNotes: string | undefined }) => (
  <Flex flexFlow='column' gap={0}>
    <DataLabel>Allergy Notes</DataLabel>
    <DataCell py={0}>{allergyNotes || ''}</DataCell>
  </Flex>
)
const NotesView = ({ notes }: { notes: string | undefined }) => (
  <Flex flexFlow='column' gap={0}>
    <DataLabel>Notes</DataLabel>
    <DataCell py={0}>{notes || ''}</DataCell>
  </Flex>
)

export const VisitView = ({ visit }: { visit: PracticeVisit }) => {
  const {
    startTime,
    endTime,
    currentPregnancy,
    patientFirst,
    patientLast,
    history,
    deliveryPlan,
    hospital,
    deliveryPlanDetails,
    historyDetails,
    riskAssessment,
    signedPdfStoragePath,
  } = visit

  const { processResponse } = useContext(PopUpMessageContext)
  const [downloading, setDownloading] = useState(false)
  const onDownload = useCallback(async () => {
    setDownloading(true)
    try {
      await downloadFromStorage(signedPdfStoragePath)
    } catch (err: any) {
      processResponse({ error: err?.message || 'Failed to download PDF' })
      console.error(err)
    }
    setDownloading(false)
  }, [signedPdfStoragePath, processResponse])

  const visitTimeLabel = useMemo(() => {
    const now = Date.now()
    if (startTime > now) return 'Future Visit'
    if (endTime < now) return 'Past Visit'
    return 'Current Visit'
  }, [startTime, endTime])

  return (
    <Flex px={2} flexFlow="column">
      <Flex w="100%" align="center">
        <Text fontSize="lg" fontFamily="Open Sans" color="gray.600">
          Visit with {patientFirst} {patientLast}
        </Text>
        <Badge ml={2} colorScheme="green">
          {visitTimeLabel}
        </Badge>
      </Flex>
      <Flex gap={2} align="center" py={1}>
        <CalendarIcon color="gray.500" />
        <Text
          position="relative"
          top="1px"
          lineHeight={1}
          fontSize="sm"
          fontFamily="Hero-New"
        >
          {getDateTimeRangeString(startTime, endTime)}
        </Text>
      </Flex>
      <GravidaView gravida={visit.gravida} />
      <ParaView para={visit.para} />
      <CurrentPregnancyView value={currentPregnancy} />
      <Divider mb={2} />
      <HistoryView history={history} historyDetails={historyDetails} />
      <Divider my={2} />
      <DeliveryPlanView
        deliveryPlan={deliveryPlan}
        deliveryPlanDetails={deliveryPlanDetails}
      />
      <Divider my={1} />
      <HospitalView hospital={hospital} />
      <RiskAssessmentView riskAssessment={riskAssessment} />
      <Divider my={1} />
      <AllergyNotesView allergyNotes={visit.allergyNotes} />
      <Divider my={1} />
      <NotesView notes={visit.notes} />
      <Divider my={1} />
      <KeywordsView keywords={visit.keywords} />
      <Divider my={2} />
      <Flex align="center" w="100%" gap={2}>
        <Flex minW="0" flex={1}>
          <AuthenticationView authentication={visit.authentication} />
        </Flex>
        <Tooltip
          bg="gray.50"
          color="gray.600"
          placement="top"
          hasArrow
          label="Download PDF"
        >
          <IconButton
            onClick={onDownload}
            isLoading={downloading}
            color="gray.600"
            aria-label="Download"
            icon={<DownloadIcon />}
            borderRadius="full"
            bg="white"
            boxShadow="1px 1px 4px #00000066"
          />
        </Tooltip>
      </Flex>
    </Flex>
  )
}

export const VisitDraftView = ({
  draft,
}: {
  draft: PracticeVisitDraft | UnsavedPracticeVisitDraft
}) => {
  const {
    currentPregnancy,
    date,
    startTime,
    endTime,
    deliveryPlan,
    deliveryPlanDetails,
    gravida,
    history,
    historyDetails,
    hospital,
    patientId,
    para,
    riskAssessment,
  } = draft

  const { data: user } = useCachedUser(patientId)

  const fullName = useMemo(() => getFullName(user), [user])

  const visitTimeLabel = useMemo(() => {
    const visitStart = new Date(`${date}T${startTime}`).getTime()
    const visitEnd = new Date(`${date}T${endTime}`).getTime()
    const now = Date.now()
    if (visitStart > now) return 'Future Visit'
    if (visitEnd < now) return 'Past Visit'
    return 'Current Visit'
  }, [startTime, endTime, date])

  return (
    <Flex w="100%" px={2} flexFlow="column">
      <Flex w="100%" align="center">
        <Text fontSize="lg" fontFamily="Open Sans" color="gray.600">
          Visit Draft for {fullName}
        </Text>
        <Badge ml={2} colorScheme="green">
          {visitTimeLabel}
        </Badge>
      </Flex>
      <Flex gap={2} align="center" py={1}>
        <CalendarIcon color="gray.500" />
        <Text
          position="relative"
          top="1px"
          lineHeight={1}
          fontSize="sm"
          fontFamily="Hero-New"
        >
          {getDateTimeRangeString(
            new Date(`${date}T${startTime}`),
            new Date(`${date}T${endTime}`),
          )}
        </Text>
      </Flex>
      <Flex gap={2} align="center" py={1}>
        <DataLabel>Gravida:</DataLabel>
        <DataBadge fontSize="sm">{gravida}</DataBadge>
      </Flex>
      <ParaView para={para} />
      <CurrentPregnancyView value={currentPregnancy} />
      <Divider mb={2} />
      <HistoryView history={history} historyDetails={historyDetails} />
      <Divider my={2} />
      <DeliveryPlanView
        deliveryPlan={deliveryPlan}
        deliveryPlanDetails={deliveryPlanDetails}
      />
      <Divider my={1} />
      <HospitalView hospital={hospital} />
      <RiskAssessmentView riskAssessment={riskAssessment} />
      <Divider my={1} />
      <AllergyNotesView allergyNotes={draft.allergyNotes} />
      <Divider my={1} />
      <NotesView notes={draft.notes} />
      <Divider my={1} />
      <KeywordsView keywords={draft.keywords} />
    </Flex>
  )
}
