import {
  Flex, FlexProps, forwardRef, Text,
} from '@chakra-ui/react'
import { colors } from '@hb/shared'
import { motion, useSpring, useTransform } from 'framer-motion'
import React, {
  useCallback, useContext, useEffect, useState,
} from 'react'
import { UserContext } from '../../../../contexts/UserContext'
import { useLocalStorage } from '../../../../hooks/useLocalStorage'
import { MBox } from '../../../Motion/MBox'

const DRAG_HANDLE_WIDTH = 8

const MFlex = motion<FlexProps>(Flex)

type ResizableState = {
  percent: number
}
type ResizableProps = Omit<FlexProps, 'width'> & {
  leftChild: (width: number) => React.ReactChild
  rightChild: (width: number) => React.ReactChild
  localStorageId?: string
  width: number
}
export const Resizable = forwardRef(
  (
    {
      leftChild, rightChild, width, localStorageId, ...rest
    }: ResizableProps,
    ref,
  ) => {
    const { assessmentId } = useContext(UserContext)
    const [showRight, setShowRight] = useState(true)
    const [isDragging, setIsDragging] = useState(false)
    const [mounted, setMounted] = useState(false)

    const storedState = useLocalStorage<ResizableState>(localStorageId)

    const x = useSpring(storedState?.percent ? width * storedState.percent : 1080, { duration: isDragging ? 0 : 500 })
    const leftWidth = useTransform(
      x,
      (val) => `${val + 0.5 * DRAG_HANDLE_WIDTH}px`,
    )
    const rightWidth = useTransform(
      x,
      (val) => `${width - (val + 0.5 * DRAG_HANDLE_WIDTH)}px`,
    )

    const [sideWidths, setSideWidths] = useState({
      left: x.get() + 0.5 * DRAG_HANDLE_WIDTH,
      right: width - (x.get() + 0.5 * DRAG_HANDLE_WIDTH),
    })

    const updateSideWidths = useCallback(() => {
      setTimeout(() => {
        const onSet = () => {
          const newX = x.get()
          setSideWidths({
            left: newX + 0.5 * DRAG_HANDLE_WIDTH,
            right: width - (newX + 0.5 * DRAG_HANDLE_WIDTH),
          })
        }
        if (x.getVelocity()) {
          onSet()
          setTimeout(() => updateSideWidths(), 33)
          return
        }
        onSet()
      }, 33)
    }, [x, width])

    const rOpacity = useTransform(x, (val) => Math.min(1, Math.max(0, (width - 150 - val) / 250)))
    useEffect(() => {
      if (width && !mounted) {
        setMounted(true)
        updateSideWidths()
      }
    }, [width, mounted, x, updateSideWidths])

    useEffect(() => {
      if (assessmentId && mounted && showRight) {
        x.set(
          storedState?.percent
            ? storedState.percent * width
            : Math.max(width - 600, width * 0.5),
        )
      } else x.set(width)
      updateSideWidths()
    }, [
      showRight,
      width,
      mounted,
      assessmentId,
      x,
      updateSideWidths,
      storedState,
    ])
    return (
      <Flex bg="gray.300" {...rest} position="relative">
        <MBox
          // @ts-ignore
          style={{ width: leftWidth }}
          h="100%"
          pr="0.25rem"
          ref={ref}
        >
          {leftChild(sideWidths.left)}
        </MBox>
        {assessmentId ? (
          // @ts-ignore
          <MBox
            zIndex={1}
            title="drag to resize"
            onDragStart={() => setIsDragging(true)}
            onDragEnd={() => {
              setIsDragging(false)
              updateSideWidths()
              if (localStorageId) localStorage.setItem(localStorageId, JSON.stringify({ percent: x.get() / width }))
            }}
            position="absolute"
            dragElastic={0}
            dragMomentum={false}
            drag="x"
            dragConstraints={{
              left: 300,
              right: showRight ? width - 325 : width,
            }}
            bg={colors.green.hex}
            style={{ x }}
            cursor="col-resize"
            h="100%"
            w={`${DRAG_HANDLE_WIDTH}px`}
            sx={{
              transition: 'background 0.2s',
            }}
            _hover={{ bg: 'gray.300' }}
            _active={{ bg: 'gray.400' }}
          >
            <Text
              top={0}
              right="100%"
              borderBottom="1px solid #cdcdcd"
              borderLeft="1px solid #cdcdcd"
              borderRadius="0 0 0 4px"
              p={1}
              pl={3}
              pr={3}
              color="#999"
              whiteSpace="nowrap"
              fontSize="xs"
              fontWeight="600"
              position="absolute"
              background="white"
              cursor="pointer"
              onClick={() => setShowRight(!showRight)}
            >
              {showRight ? 'HIDE LOG' : 'SHOW LOG'}
            </Text>
          </MBox>
        ) : null}

        <MFlex
          // @ts-ignore
          style={{ width: rightWidth, opacity: rOpacity }}
          overflow="hidden"
        >
          {rightChild(sideWidths.right)}
        </MFlex>
      </Flex>
    )
  },
)
