import { Box, Text } from '@chakra-ui/react'
import { PopulatedNode } from '@hb/shared'
import React from 'react'
import { DefaultModal } from '../Modals/DefaultModal'

export const TemplateErrorsAlert = ({ errors, onCancel }: {
  errors: PopulatedNode[] | null,
  onCancel: () => void
}) => (
      <DefaultModal
        isOpen={!!errors}
        onClose={onCancel}
        overlayHeader
        render={() => (
          <Box p={4}>
            <Text fontSize='xl'>Assessment errors:</Text>
            {errors?.map((e, i) => (
              <Text key={`${e.path}_${i}`} color='red.600'>
                {e.name}
              </Text>
            ))}
          </Box>
        )}
      />
)
