import { AssessmentInvoiceSnippet } from '@hb/shared/types'
import { useMemo } from 'react'
import { getPaymentInvoiceSnippets } from './utils'

export const usePaymentColors = ({
  paymentComplete,
  amountPaid,
}: {
  paymentComplete: boolean
  amountPaid?: number
}) => {
  const colorScheme = useMemo(() => {
    if (paymentComplete) return 'green'
    if (amountPaid) return 'orange'
    return 'gray'
  }, [paymentComplete, amountPaid])
  const color = useMemo(() => {
    if (paymentComplete) return 'green.600'
    if (amountPaid) return 'orange.600'
    return 'gray.500'
  }, [paymentComplete, amountPaid])
  return { color, colorScheme }
}

export const usePaymentInvoiceSnippets = (
  assessmentInvoiceSnippets: Record<string, AssessmentInvoiceSnippet>,
  paymentId: string,
) => {
  const paymentInvoiceSnippets = useMemo(
    () => getPaymentInvoiceSnippets(assessmentInvoiceSnippets, paymentId),
    [assessmentInvoiceSnippets, paymentId],
  )

  return paymentInvoiceSnippets
}
