import { getValidUrl } from '@hb/shared'
import React, { useMemo } from 'react'
import { SiteLink } from '../SiteLink'

export const ParsedText = ({ children }: { children: string }) => {
  const parsed = useMemo(() => {
    const parts = children.split(' ')
    return parts.reduce(
      (acc, part, i) => {
        const validUrl = getValidUrl(part)
        if (validUrl) {
          const siteLink = <SiteLink key={part} href={validUrl} />
          const added = parts.length > 1 && i === parts.length - 1
            ? [siteLink]
            : [siteLink, ' ']
          return [...acc, ...added]
        }

        return [
          ...acc,
          parts.length > 1 && i === parts.length - 1 ? part : `${part} `,
        ]
      },
      [] as Array<string | React.JSX.Element>,
    )
  }, [children])
  return <span>{parsed}</span>
}
