import { PracticeVisit } from '../types/appointments'
import { toSearchString } from './data'

export const getVisitSearchString = (visit: Omit<PracticeVisit, 'searchString' | 'signedPdfStoragePath'>) => {
  const {
    patientFirst, patientLast, startTime, endTime, keywords, type,
  } = visit
  const keywordsSearchString = keywords ? keywords.split(',').map((k) => k.trim()).join('|') : ''
  return toSearchString(`${type}|${patientFirst}|${patientLast}|${startTime}|${endTime}${keywordsSearchString ? `|${keywordsSearchString}` : ''}`)
}
