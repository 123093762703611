import {
  currentPregnancyField, deliveryPlanDetailsField, deliveryPlanField, existingVisitEndTimeField, existingVisitStartTimeField, gravidaField, historyDetailsField, keywordsField, newVisitDateField, newVisitEndTimeField, newVisitStartTimeField, notesField, paraField, riskAssessmentField, visitHistoryField, visitHospitalField,
} from '@hb/shared/fields'
import { FieldMap } from '@hb/shared/types'

export const newVisitField: FieldMap = {
  name: 'Visit',
  children: {
    date: newVisitDateField,
    startTime: newVisitStartTimeField,
    endTime: newVisitEndTimeField,
    gravida: gravidaField,
    para: paraField,
    currentPregnancy: currentPregnancyField,
    history: visitHistoryField,
    historyDetails: historyDetailsField,
    deliveryPlan: deliveryPlanField,
    deliveryPlanDetails: deliveryPlanDetailsField,
    hospital: visitHospitalField,
    // hospitalDetails: hospitalDetailsField,
    riskAssessment: riskAssessmentField,
    allergyNotes: { ...notesField, placeholder: 'Allergy Notes' },
    notes: notesField,
    keywords: keywordsField,
  },
}

export const existingVisitField: FieldMap = {
  name: 'Visit',
  children: {
    startTime: existingVisitStartTimeField,
    endTime: existingVisitEndTimeField,
    gradiva: gravidaField,
    para: paraField,
    currentPregnancy: currentPregnancyField,
    history: visitHistoryField,
    historyDetails: historyDetailsField,
    deliveryPlan: deliveryPlanField,
    deliveryPlanDetails: deliveryPlanDetailsField,
    hospital: visitHospitalField,
    // hospitalDetails: hospitalDetailsField,
    riskAssessment: riskAssessmentField,
    allergyNotes: { ...notesField, placeholder: 'Allergy Notes' },
    notes: notesField,
    keywords: keywordsField,
  },
} as const
