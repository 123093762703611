import { InfoIcon } from '@chakra-ui/icons'
import { Box, BoxProps, IconButton } from '@chakra-ui/react'
import { WithId } from '@hb/shared'
import React from 'react'
import { CopyTooltip } from './CopyTooltip'

export const CopyId = <T extends WithId>({ item, ...props }: {item: T | null} & BoxProps) => (
  <Box position='absolute' bottom='0.25rem' right='0.25rem' {...props}>
    <CopyTooltip placement='left' bg='gray.600' prefix='ID' label={item ? item.id : 'nothing to copy'} >
      <IconButton
        size='sm'
        variant='ghost'
        aria-label='ID'
        icon={<InfoIcon color='gray.500'/>}
      />
    </CopyTooltip>
  </Box>
)
