import { ArrowBackIcon } from '@chakra-ui/icons'
import { Box, IconButton, Tooltip } from '@chakra-ui/react'
import React, { useContext } from 'react'
import { ThreadViewContext } from './contexts'

export const ThreadBackButton = () => {
  const { onBack } = useContext(ThreadViewContext)
  // const debounced = useDebounce(searchQuery, 500)

  const { addingMembers } = useContext(ThreadViewContext)
  return (
    <Box>
      <Tooltip label="Close Thread" bg='gray.50' color='gray.600' placement='right' hasArrow aria-label="Back">
        <IconButton
          icon={
            <ArrowBackIcon
              position="relative"
              bottom="2px"
              width={4}
              height={4}
            />
          }
          transition="all 300ms"
          bg={'gray.50'}
          color={'gray.500'}
          borderRadius={6}
          alignItems="center"
          justifyContent="center"
          opacity={addingMembers ? 0 : 1}
          filter="drop-shadow(1px 1px 3px #00000077)"
          variant="unstyled"
          onClick={onBack}
          aria-label="Search"
        />
      </Tooltip>
    </Box>
  )
}
