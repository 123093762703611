import {
  adminRoles, getSelectedPracticeId, UserRole,
} from '@hb/shared'
import React, {
  createContext, PropsWithChildren, useContext, useMemo,
} from 'react'
import { useAuth } from '../../store/auth'

export type PracticeAccessContextType = {
  practiceAccess: Record<string, UserRole> | null
  selectedPracticeId: string | null
}

const PracticeAccessContext = createContext<PracticeAccessContextType>({
  practiceAccess: null,
  selectedPracticeId: null,
})

export const PracticeAccessProvider = ({ children }: PropsWithChildren) => {
  const { user, claims } = useAuth()
  const selectedPracticeId = useMemo(
    () => getSelectedPracticeId(claims || {}, user),
    [user, claims],
  )

  const adminPracticeAccess = useMemo(() => {
    const { practiceAccess } = claims || {}
    if (!practiceAccess) return null
    const adminAccess: Record<string, UserRole> = {}
    Object.entries(practiceAccess as Record<string, UserRole>).forEach(([practiceId, access]) => {
      if (adminRoles.includes(access)) {
        adminAccess[practiceId] = access
      }
    })
    return adminAccess
  }, [claims])
  const contextData = useMemo(
    () => ({
      practiceAccess: adminPracticeAccess,
      selectedPracticeId,
    }),
    [adminPracticeAccess, selectedPracticeId],
  )

  return (
    <PracticeAccessContext.Provider
      value={contextData}
    >
      {children}
    </PracticeAccessContext.Provider>
  )
}

export const usePracticeAccess = () => useContext(PracticeAccessContext)
