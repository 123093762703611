import { EditorVersion, Mode } from '@hb/shared'
import React from 'react'
import { RenderElementProps } from 'slate-react'
import { useElementStyle } from '../hooks'

export const DefaultElement: React.FC<
  RenderElementProps & { title?: string; version: EditorVersion, mode: Mode }
> = ({
  children, title, element, attributes, version, mode,
}) => {
  const style = useElementStyle(mode, element, version)
  return (
    <div {...attributes} title={title} style={style}>
      {children}
    </div>
  )
}
