// eslint-disable-next-line no-shadow
export enum FieldTypes {
  ALTERNATE = 'alternate',
  BOOLEAN = 'boolean',
  CHECKBOX = 'checkbox',
  DATE = 'date',
  DATETIME = 'datetime',
  TIME = 'time',
  DOLLAR_AMOUNT = 'dollar',
  DROPDOWN = 'dropdown',
  MULTIPLE_SELECT = 'multiple-select',
  DYNAMIC_DROPDOWN = 'dynamic',
  EMAIL = 'email',
  FILE = 'file',
  ID = 'id',
  NUMBER = 'number',
  PERCENTAGE = 'percentage',
  PHONE = 'phone',
  TEXT = 'text',
  TEXTAREA = 'textarea',
  SIGNATURE = 'signature',
  INITIALS = 'initials',
}
