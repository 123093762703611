import { SettingsIcon } from '@chakra-ui/icons'
import {
  Flex,
  IconButton,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverTrigger,
  Portal,
  Text,
} from '@chakra-ui/react'
import React from 'react'
import { NotificationsVolumeControl } from './NotificationVolumeSettings'
import { MessagingPushNotificationsSettings } from './PushNotificationsSettings'

export const SettingsPopover = () => (
  <Popover strategy="fixed" placement="bottom">
    {({ onClose }) => (
      <>
        <PopoverTrigger>
          <IconButton
            borderRadius="full"
            variant="ghost"
            opacity={0.7}
            _hover={{ opacity: 1, bg: 'blackAlpha.200' }}
            color="white"
            size="xs"
            aria-label="Settings"
            icon={
              <SettingsIcon
                w={4}
                h={4}
                filter="drop-shadow(1px 1px 3px #00000077)"
              />
            }
          />
        </PopoverTrigger>
        <Portal>
          <PopoverContent bg="gray.50">
            <PopoverCloseButton />
            <PopoverBody>
              <Flex gap={2} flexFlow="column">
                <Flex gap={1} align="center">
                  <SettingsIcon color="gray.500" />
                  <Text fontFamily="Hero-New" color="gray.600">
                    Messaging Settings
                  </Text>
                </Flex>
                <NotificationsVolumeControl />
                <MessagingPushNotificationsSettings onClosePopover={onClose} />
              </Flex>
            </PopoverBody>
            <PopoverArrow bg="gray.50" />
          </PopoverContent>
        </Portal>
      </>
    )}
  </Popover>
)
