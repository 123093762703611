import {
  Badge,
  Box,
  Button,
  HStack,
  Popover,
  PopoverArrow,
  PopoverContent,
  PopoverTrigger,
  Text,
  useDisclosure,
  VStack,
} from '@chakra-ui/react'
import { MessageThread, ThreadType } from '@hb/shared/types'
import React, { useEffect, useMemo, useState } from 'react'
import { addUsersToThread, removeUsersFromThread } from '../../../backend'
import { useApp } from '../../../contexts'
import { useMe } from '../../../hooks/backend/useMe'
import { getBadgeColor } from '../../Users/UserBadge'
import { DeleteThreadButton } from './DeleteThreadButton'
import { ThreadMembersPreview } from './ThreadMembersPreview'
import { ThreadMemberView } from './ThreadMemberView'

export const ThreadMembersPopover = ({
  thread,
  threadId,
  threadType,
  onAddClick,
  isAdding,
}: {
  thread: MessageThread | null
  threadId?: string,
  threadType: ThreadType
  onAddClick: (() => void) | (() => Promise<void>)
  isAdding: boolean
}) => {
  const [addLoading, setAddLoading] = useState(false)
  const { subscribers = [] } = thread || {}
  const { appName } = useApp()
  const me = useMe()
  const { isOpen, onClose, onOpen } = useDisclosure()
  const canEdit = useMemo(
    () => threadType !== ThreadType.DIRECT,
    [threadType],
  )
  const memberTypeName = useMemo(() => (threadType === ThreadType.ASSESSMENT ? 'subscriber' : 'member'), [threadType])
  const addButtonText = useMemo(() => {
    if (threadType === ThreadType.ASSESSMENT) {
      if (me && subscribers.includes(me.uid)) return 'UNSUBSCRIBE'
      return 'SUBSCRIBE TO THREAD'
    }
    return 'EDIT MEMBERS'
  }, [threadType, me, subscribers])
  useEffect(() => {
    if (!isAdding || !canEdit) onClose()
  }, [isAdding, canEdit, onClose])
  return (
    <Popover
      isOpen={!isAdding && isOpen && thread?.type !== ThreadType.DIRECT}
      onClose={onClose}
      onOpen={canEdit ? onOpen : () => {}}
      placement="bottom-end"
      trigger="hover"
    >
      <PopoverTrigger>
        <Box cursor='pointer'>
          <ThreadMembersPreview noTooltips={!isAdding} thread={thread} />
        </Box>
      </PopoverTrigger>
      <PopoverContent minW="250px" maxH='500px' overflowY='auto' bg="gray.100" w="auto">
        <PopoverArrow bg="gray.100" />
        <VStack
          w="100%"
          align="flex-start"
          borderRadius={6}
          px={2}
          py={2}
          spacing={2}
        >
          <HStack w="100%">
            <Text
              fontFamily="Open Sans"
              fontSize="sm"
              color="gray.600"
              fontWeight={600}
            >
              {memberTypeName.toUpperCase()}S
            </Text>
            <Badge
              ml="auto"
              bg={
                thread?.type === ThreadType.DIRECT
                  ? '#ababab'
                  : getBadgeColor(appName, thread?.accessLevel)
              }
              color="white"
              textShadow="1px 1px 3px #00000077"
            >
              {threadType === ThreadType.DIRECT || threadType === ThreadType.ASSESSMENT
                ? threadType.toUpperCase()
                : thread?.accessLevel?.toUpperCase()}{' '}
              CHAT
            </Badge>
          </HStack>
          <VStack w="100%" spacing={1} align="flex-start">
            {subscribers.length ? subscribers.map((sub) => (
              <ThreadMemberView key={sub} userId={sub} />
            )) : (
              <Text fontFamily="Open Sans" fontSize="sm" color="gray.500">
                No {memberTypeName}s
              </Text>
            )}
          </VStack>
          {!isAdding ? (
            <Button
              isLoading={addLoading}
              w="100%"
              size="xs"
              _hover={{
                bg: 'green.300',
              }}
              colorScheme="green"
              fontFamily="Open Sans"
              onClick={async () => {
                if (!me || !threadId) return
                setAddLoading(true)
                try {
                  if (threadType === ThreadType.ASSESSMENT) {
                    if (subscribers.includes(me.uid)) {
                      await removeUsersFromThread({ threadId, type: threadType, userIds: [me.uid] })
                    } else {
                      await addUsersToThread({
                        threadId, type: threadType, userIds: [me.uid], title: '',
                      })
                    }
                  } else {
                    await onAddClick()
                  }
                } catch (e) {
                  console.error(e)
                }
                setAddLoading(false)
                onClose()
              }}
            >
              {addButtonText}
            </Button>
          ) : null}
          <DeleteThreadButton/>
        </VStack>
      </PopoverContent>
    </Popover>
  )
}
