import { Collection } from '@hb/shared/collections'
import { Template, TemplateKey } from '@hb/shared/types'
import { createContext, Ref } from 'react'
import { ReactEditor } from 'slate-react'

export type SelectedTemplate = {collection: Collection<Template>, templateType: TemplateKey, templateId: string}
export const TemplateContext = createContext<
  Partial<{
    selected: SelectedTemplate | null
    templateId?: string
    ref: Ref<ReactEditor>,
    select:(selected: SelectedTemplate) => void
    deselect: () => void
      }>
      >({})
