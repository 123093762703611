import { httpsCallable } from 'firebase/functions'
import { functions } from '../../backend'
import { showError } from '../../toast'

const getCalendlyLinkFunction = httpsCallable<{adminId: string}, string | null>(functions, 'getCalendlyLink')
export const fetchCalendlyLink = async (adminId: string) => {
  const { data } = await getCalendlyLinkFunction({ adminId })
  return data
}

const getCalendlyLinksFunction = httpsCallable<{}, { calendlyLink: string, calendlyDescription: string }[]>(functions, 'getCalendlyLinks')
export const fetchCalendlyLinks = async () => {
  try {
    const { data } = await getCalendlyLinksFunction()
    return data
  } catch (err) {
    showError('Error getting links')
  }
  return null
}
