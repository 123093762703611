import { BadgeProps } from '@chakra-ui/react'
import {
  AssessmentStatus, colors, Status, StatusType,
} from '@hb/shared'
import { useMemo } from 'react'

interface StatusButton extends BadgeProps {
  buttonText: string
  buttonColor: string
  buttonBackground?: string
}

// assessment status buttons
const awaitingQuestionnaireButton: StatusButton = {
  buttonText: 'AWAITING QUESTIONNAIRE',
  buttonColor: '#777',
  colorScheme: 'gray',
}

const submittedButton: StatusButton = {
  buttonText: 'ASSESSMENT REQUIRED',
  buttonColor: colors.orange.hex,
  colorScheme: 'orange',
}

const sentButton: StatusButton = {
  buttonText: 'ASSESSMENT SENT',
  buttonColor: colors.indigo.hex,
  colorScheme: 'blue',
}

const viewedButton: StatusButton = {
  buttonText: 'ASSESSMENT VIEWED',
  buttonColor: colors.green.hex,
  colorScheme: 'green',
}

const draftSavedButton: StatusButton = {
  buttonText: 'DRAFT SAVED',
  buttonColor: colors.orange.hex,
  colorScheme: 'orange',
}

const questionnaireIncompleteButton: StatusButton = {
  buttonText: 'INCOMPLETE QUESTIONNAIRE',
  buttonColor: colors.orange.hex,
  colorScheme: 'orange',
}

const signedOnButton: StatusButton = {
  buttonText: 'SIGNED ON',
  buttonColor: colors.green.hex,
  colorScheme: 'green',
}

// stage status buttons
const completedButton: StatusButton = {
  buttonText: 'EDIT',
  buttonColor: colors.indigo.hex,
  colorScheme: 'blue',
}
const completeButton: StatusButton = {
  buttonText: 'CONTINUE',
  buttonColor: colors.indigo.hex,
  colorScheme: 'blue',
}
const incompleteButton: StatusButton = {
  buttonText: 'BEGIN',
  buttonColor: colors.indigo.hex,
  colorScheme: 'blue',
}
const infoButton: StatusButton = {
  buttonText: 'VIEW',
  buttonColor: '#808080',
  colorScheme: 'gray',
}

export const getStatusButton = (type: StatusType, status: Status) => {
  switch (type) {
    case 'assessment':
      switch (status as AssessmentStatus) {
        case 'incomplete':
          return questionnaireIncompleteButton
        case 'viewed':
          return viewedButton
        case 'sent':
          return sentButton
        case 'draft-saved':
          return draftSavedButton
        case 'signed-on':
          return signedOnButton
        case 'submitted':
          return submittedButton
        default:
          return awaitingQuestionnaireButton
      }
    default:
      switch (status) {
        case 'complete':
          return completedButton
        case 'in progress':
          return completeButton
        case 'incomplete':
          return incompleteButton
        default:
          return infoButton
      }
  }
}

export const useStatusButton = (
  type: StatusType,
  status: Status,
): StatusButton => useMemo<StatusButton>(() => getStatusButton(type, status), [type, status])
