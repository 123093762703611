import { USER_ROLES } from '@hb/shared/collections'
import { UserRoleItem } from '@hb/shared/types'
import { useAuth } from '../../../store'
import { useDocument } from '../useDocument'

export const useMyRoleItem = () => {
  const authUser = useAuth((state) => state.authUser)
  const uid = authUser?.uid
  return useDocument<UserRoleItem>(USER_ROLES, uid)
}
