import { forwardRef } from '@chakra-ui/react'
import React, { useContext } from 'react'
import TextAreaAutosize, { TextareaAutosizeProps } from 'react-textarea-autosize'
import { ThemeContext } from '../../../../../contexts'

export const TextAreaElement = forwardRef<TextareaAutosizeProps, 'textarea'>(({ style, ...props }, ref) => {
  const { theme } = useContext(ThemeContext)
  return (
  <TextAreaAutosize
    ref={ref}
    style={{
      // @ts-ignore
      height: 'auto',
      resize: 'none',
      width: '100%',
      padding: theme === 'basic' ? '0.1rem' : '0.33rem',
      fontFamily: 'hero-new',
      boxSizing: 'border-box',
      borderRadius: '4px',
      fontSize: theme === 'basic' ? '0.8rem' : '0.9rem',
      color: props.disabled ? '#777' : '#111',
      background: 'none',
      border: 'none',
      outline: 'none',
      ...style,
    }}
    {...props}
  />
  )
})
