import { CheckIcon } from '@chakra-ui/icons'
import { Box, Flex, Text } from '@chakra-ui/react'
import {
  colors, getDateTimeString, getFieldsFromText, getFullName, isField,
} from '@hb/shared'
import {
  AssessmentDocument,
  ConsentForm,
  FieldMap,
  isConsentForm,
  TemplateNodeFieldMap,
  UploadProgress,
} from '@hb/shared/types'
import { FormApi, FORM_ERROR, ValidationErrors } from 'final-form'
import arrayMutators from 'final-form-arrays'
import React, {
  CSSProperties,
  PropsWithChildren,
  useContext,
  useMemo,
  useState,
} from 'react'
import { Form, useFormState } from 'react-final-form'
import { useSlate } from 'slate-react'
import { useCachedUser } from '../../collections/hooks/cached'
import { ThemeContext, ThemeContextData } from '../../contexts'
import { SolidActionButton } from '../Buttons'
import { CollapseError } from '../CollapseError'
import { useFormSubmit } from '../forms/FinalForm/hooks/useFormSubmit'
import { useFormValidate } from '../forms/FinalForm/hooks/useFormValidate'
import { UploadProgressView } from '../forms/FinalForm/UploadProgressView'
import { TextEditorFormContext } from './context'

const defaultOnSubmit = async () => ({
  [FORM_ERROR]: 'No onSubmit function provided',
})

const TextEditorFormFooter = ({
  uploads,
  field,
  document,
  handleSubmit,
}: {
  uploads: Record<string, UploadProgress> | null
  field: FieldMap
  document?: AssessmentDocument | ConsentForm
  handleSubmit: () => void
}) => {
  const { submitting, errors } = useFormState({
    subscription: { submitting: true, errors: true },
  })
  const { readOnly } = useContext(TextEditorFormContext)

  const firstErrorText = useMemo(() => {
    const [firstError] = Object.keys(errors || {})
    if (!firstError) return ''
    const firstErrorField = field.children[firstError]
    return firstErrorField && isField(firstErrorField)
      ? `${firstErrorField.placeholder}: ${errors?.[firstError]}`
      : `Error: ${errors?.[firstError]}`
  }, [errors, field])

  const { data: signedByUser } = useCachedUser(
    (document as ConsentForm)?.signedBy || null,
  )
  const { data: awaitingSignatureByUser } = useCachedUser(
    (document as ConsentForm)?.toBeSignedByUser || null,
  )

  const statusText = useMemo(() => {
    if (!document || !isConsentForm(document)) return ''
    if (document.signedOn) return `Signed at ${getDateTimeString(document.signedOn)} by ${getFullName(signedByUser)}`
    if (document.toBeSignedByUser) return `Awaiting signature from ${getFullName(awaitingSignatureByUser)}`
    return 'Unsigned'
  }, [document, signedByUser, awaitingSignatureByUser])

  return (
    <Flex
      bg="gray.50"
      borderTop="1px solid #cdcdcd"
      w="100%"
      direction="column"
    >
      <UploadProgressView uploads={uploads} />
      <Flex align="center" gap={2} w="100%" p={2}>
        <Box flex={1}>
          <CollapseError
            style={{
              background: 'transparent',
              color: '#cc0000',
              justifyContent: 'flex-start',
            }}
            error={firstErrorText}
          />
        </Box>
        {readOnly ? (
          <Flex align='center' gap={1}>
            {document && isConsentForm(document) && document.signedOn ? (
              <CheckIcon color={colors.green.hex} />
            ) : null}
            <Text
              color={colors.green.hex}
              fontFamily="Open Sans"
              fontWeight={600}
              fontSize="sm"
            >
              {statusText}
            </Text>
          </Flex>
        ) : (
          <SolidActionButton
            type="submit"
            pointerEvents={firstErrorText ? 'none' : 'auto'}
            opacity={firstErrorText ? 0.5 : 1}
            isLoading={submitting}
            onClick={handleSubmit}
          >
            Submit
          </SolidActionButton>
        )}
      </Flex>
    </Flex>
  )
}

const textEditorTheme: ThemeContextData = {
  theme: 'detailed',
  placeholderAbove: false,
  tooltipError: true,
}

export const TextEditorFormProvider = ({
  children,
  document,
  onSubmit,
  height,
  style,
  readOnly,
}: PropsWithChildren<{
  readOnly?: boolean
  document?: AssessmentDocument | ConsentForm
  style?: CSSProperties
  height: number | 'auto'
  onSubmit?: (
    submitted: any,
    onUploadProgress: (progress: Record<string, UploadProgress>) => void,
    form: FormApi
  ) => Promise<ValidationErrors>
}>) => {
  const { children: text } = useSlate()
  // if readonly, collect all fields from templateText
  const field = useMemo<TemplateNodeFieldMap>(
    () => (readOnly
      ? {
        name: '',
        children: getFieldsFromText(text),
      }
      : {
        name: '',
        children: {},
      }),
    [text, readOnly],
  )

  const initData = useMemo(
    () => (document && isConsentForm(document) ? document.formData || {} : {}),
    [document],
  )

  const hasFields = Object.keys(field.children).length > 0

  const [uploads, setUploads] = useState<Record<string, UploadProgress> | null>(
    null,
  )
  const validate = useFormValidate(field)
  const usedValidate = useMemo(() => {
    if (!document || !isConsentForm(document)) return undefined
    return (document.signedOn ? undefined : validate)
  }, [validate, document])
  const submit = useFormSubmit(field, onSubmit || defaultOnSubmit, setUploads)
  const contextData = useMemo(
    () => ({ field, readOnly: !onSubmit }),
    [field, onSubmit],
  )
  const usedHeight = typeof height === 'number' ? `${height}px` : height
  return (
    <TextEditorFormContext.Provider value={contextData}>
      <ThemeContext.Provider value={textEditorTheme}>
        <Form
          mutators={{ ...arrayMutators }}
          initialValues={initData}
          validate={usedValidate}
          onSubmit={submit}
        >
          {({ handleSubmit }) => (
            <Flex h={usedHeight} w="auto" direction="column" style={style}>
              <Box minH='0' overflowY="auto" flex={height === 'auto' ? 'unset' : 1} w="auto">
                {children}
              </Box>
              {readOnly && hasFields ? (
                <TextEditorFormFooter
                  document={document}
                  field={field}
                  handleSubmit={handleSubmit}
                  uploads={uploads}
                />
              ) : null}
            </Flex>
          )}
        </Form>
      </ThemeContext.Provider>
    </TextEditorFormContext.Provider>
  )
}
