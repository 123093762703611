import { Collapse, StackProps, VStack } from '@chakra-ui/react'
import React, { PropsWithChildren, useEffect, useState } from 'react'

export const ExpandOnMount = ({
  children,
  style,
  ...props
}: PropsWithChildren<StackProps>) => {
  const [isOpen, setIsOpen] = useState(false)
  useEffect(() => {
    setTimeout(() => setIsOpen(true), 100)
  }, [])
  return (
    <Collapse style={{ width: '100%', ...style }} in={isOpen} animateOpacity>
      <VStack w='100%' align='flex-start' spacing={0} {...props}>
        {children}
      </VStack>
    </Collapse>
  )
}
