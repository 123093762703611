import {
  Center,
  CircularProgress,
  HStack,
  StackProps,
  Text,
} from '@chakra-ui/react'
import { colors } from '@hb/shared/constants'
import React, { PropsWithChildren } from 'react'

export const DocumentsHeader = ({
  children,
  numDocs,
  loading,
  stackProps,
}: PropsWithChildren<{
  numDocs: number
  loading: boolean
  stackProps?: StackProps
}>) => (
  <HStack borderTopRadius={6} bg="#fafafa" px={2} w="100%" {...stackProps}>
    <Text fontFamily="Hero-New" fontWeight={500} color="gray.600" py={2}>
      {children}
    </Text>
    <Center
      width="20px"
      height="20px"
      position="relative"
      bottom="1px"
      borderRadius="full"
      bg={colors.green.hex}
    >
      {loading ? (
        <CircularProgress color="white" size={5} isIndeterminate />
      ) : (
        <Text
          fontSize="xs"
          fontFamily="Hero-New"
          fontWeight={600}
          color="white"
        >
          {numDocs}
        </Text>
      )}
    </Center>
  </HStack>
)
