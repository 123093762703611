import infoIcon from '@hb/shared-frontend/icons/info.svg'
import React, { CSSProperties, PropsWithChildren } from 'react'

const InfoSpan = ({ children, style }: PropsWithChildren<{style: CSSProperties}>) => (
    <span
      style={{
        fontSize: '14px',
        padding: '0.5rem',
        width: '100%',
        textAlign: 'left',
        boxSizing: 'border-box',
        color: '#555',
        ...style,
      }}
    >
      <img src={infoIcon} alt='info' style={{ height: 14, marginRight: 5 }} />
      {children}
    </span>
)

export default InfoSpan
