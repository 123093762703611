import React from 'react'
import { ImageViewModal } from './ImageViewModal'
import { PdfViewModal } from './PdfView/PdfViewModal'

interface FileViewModalProps {
  type: string
  url: string
  header: string
  onClose: () => void
  isOpen: boolean
}

export const FileViewModal: React.FC<FileViewModalProps> = ({
  type,
  url,
  ...props
}) => (type === 'application/pdf' ? (
    <PdfViewModal url={url} {...props} />
) : (
    <ImageViewModal src={url} {...props} />
))
