import { BoxProps } from '@chakra-ui/react'
import { FieldTypes, Note, UpdateCallback } from '@hb/shared'
import React, { useCallback } from 'react'
import { useMe } from '../../hooks/backend/useMe'
import { SimpleForm } from './FinalForm/SimpleForm'

export const NoteForm: React.FC<{
  note?: Note
  placeholder?: string
  onSubmit: (data: Note) => Promise<UpdateCallback>
  boxProps?: BoxProps
}> = ({
  note,
  boxProps,
  onSubmit,
  placeholder = 'Note...',
}) => {
  const id = useMe()?.uid

  const submit = useCallback(
    async (data: string): Promise<UpdateCallback> => {
      if (id) {
        return onSubmit({
          text: data || '',
          updatedOn: Date.now(),
          updatedBy: id,
        })
      }
      return { error: 'Error - not authenticated' }
    },
    [id, onSubmit],
  )
  return (
    <SimpleForm
      boxProps={{ width: '100%', ...boxProps }}
      field={{ type: FieldTypes.TEXTAREA, placeholder, optional: true }}
      onSubmit={submit}
      theme='detailed'
      value={{ value: note?.text || '', updatedOn: note?.updatedOn, updatedBy: note?.updatedBy }}
    />
  )
}
