import { HStack, Text, Tooltip } from '@chakra-ui/react'
import { colors, PracticeInvoiceLineItem, UpdateCallback } from '@hb/shared'
import React from 'react'
import { DeleteButton } from '../../Buttons/DeleteButton'
import { FeeBadge } from '../FeeBadge'
import { LineItemPopover } from './LineItemPopover'

export const EditableLineItemView = ({
  value,
  id,
  onSubmit,
  onDelete,
}: {
  value: PracticeInvoiceLineItem
  id: string
  onSubmit: (
    updated: PracticeInvoiceLineItem & { lineItemId: string | null }
  ) => Promise<UpdateCallback>
  onDelete: () => Promise<UpdateCallback>
}) => (
  <HStack
    spacing={2}
    py={1}
    px={3}
    sx={{
      ':nth-of-type(odd)': { background: 'rgb(245,245,245)' },
      ':nth-of-type(even)': { background: 'white' },
    }}
    w='100%'
  >
    <HStack flex={1}>
      <Text
        minW='0'
        fontFamily='Open Sans'
        isTruncated
        fontWeight={600}
        color='gray.500'
        fontSize='sm'
      >
        Additional line item
      </Text>
      {value?.description ? (
        <Tooltip
          placement='top'
          hasArrow
          bg={colors.green.hex}
          color='gray.50'
          label={value?.description}
        >
          <Text maxW='300px' isTruncated fontSize='sm'>
            {value?.description || ''}
          </Text>
        </Tooltip>
      ) : null}
      <LineItemPopover onSubmit={onSubmit} value={value} lineItemId={id} />
      <DeleteButton itemName='Line Item' onDelete={onDelete} />
    </HStack>
    <HStack width='120px' justify='center' position='relative' spacing={1}>
      {value.receivedAmount ? <FeeBadge color='gray.500' amount={value.receivedAmount} /> : null}
    </HStack>
    <HStack width='110px' justify='center' position='relative' spacing={1}>
      <FeeBadge amount={value?.amount} />
    </HStack>
    <span style={{ width: '4px' }} />
  </HStack>
)
