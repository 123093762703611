import {
  Collection, CollectionId, CollectionItem, CollectionState, CollectionSubscriber, CombinedCollection, DerivedCollection, DocData, DocumentSubscriber, FirestoreCollection, getBaseCollection, getCollectionId,
} from '@hb/shared/collections'
import { UserGroup } from '@hb/shared/types'
import { arrayToObject, sortByRank } from '@hb/shared/utils/data'
import {
  collection as firestoreCollection, CollectionReference, doc, getDoc, onSnapshot, orderBy, query as firestoreQuery, Query, Unsubscribe, where,
} from 'firebase/firestore'
import { db } from '../../backend'
import { useCollections } from '../../store/collections'

export const getCollectionQuery = <Data extends DocData>(
  collection: Collection<Data>,
): Query<CollectionItem<Data>> => {
  const baseCollection = getBaseCollection(
    collection,
  )
  if (!baseCollection) throw new Error('No collection')
  const { refPath, filters } = baseCollection
  let q = firestoreQuery(firestoreCollection(db, refPath) as Query<CollectionItem<Data>>)
  if (filters) {
    filters.forEach(([field, op, value]) => {
      q = firestoreQuery(q, where(field, op, value))
    })
  }
  return q
}

const onCollectionData = <Data extends DocData = DocData>(
  collectionId: string,
  data: Array<CollectionItem<Data>>,
) => {
  const { [collectionId]: curr } = useCollections.getState()
  if (!curr) return
  Object.values(curr?.subscribers || {}).forEach((subscriber) => {
    subscriber.onData(data)
    subscriber.onLoading(false)
  })
  useCollections.setState({
    [collectionId]: {
      ...curr,
      items: data,
      loading: false,
      error: null,
    },
  })
}

const onCollectionError = (collectionId: string, error: string) => {
  const { [collectionId]: curr } = useCollections.getState()
  if (!curr) return
  Object.values(curr?.subscribers || {}).forEach((subscriber) => {
    subscriber.onError(error)
  })
  useCollections.setState({
    [collectionId]: {
      ...curr,
      loading: false,
      error,
    },
  })
}

const onCollectionLoading = (collectionId: string, loading: boolean) => {
  const { [collectionId]: curr } = useCollections.getState()
  if (!curr) return
  Object.values(curr?.subscribers || {}).forEach((subscriber) => {
    subscriber.onLoading(loading)
  })
  useCollections.setState({
    [collectionId]: {
      ...curr,
      loading,
    },
  })
}

const subscribeToExistingCollection = <
  Data extends DocData,
>(
    baseCollection: Collection<Data>,
    baseCollectionState: CollectionState<Data>,
    subscriber: CollectionSubscriber<Data>,
  ) => {
  const baseCollectionId = getCollectionId(baseCollection)
  const subscriberId = getUniqueSubscriberId(
    Object.keys(baseCollectionState.subscribers),
  )
  useCollections.setState({
    [baseCollectionId]: {
      ...baseCollectionState,
      subscribers: {
        ...baseCollectionState.subscribers,
        [subscriberId]: subscriber,
      },
    },
  })
  return () => {
    const { [baseCollectionId]: currOnUnsub } = useCollections.getState()
    if (currOnUnsub) {
      const { [subscriberId]: _removed, ...subscribers } = currOnUnsub.subscribers
      if (Object.keys(subscribers).length === 0) {
        // unsubscribe from firestore
        currOnUnsub.unsubscribe()
        useCollections.setState({
          [baseCollectionId]: undefined,
        })
      } else {
        useCollections.setState({
          [baseCollectionId]: { ...currOnUnsub, subscribers },
        })
      }
    }
  }
}

const createFirestoreSubscriber = <Data extends DocData = DocData>(
  collection: FirestoreCollection<Data>,
  isOtherCollection?: boolean,
) => {
  const { noRanks } = collection
  const collectionId = getCollectionId(collection)

  try {
    const baseQuery: Query<CollectionItem<Data>> = getCollectionQuery(collection)
    const q = noRanks || isOtherCollection
      ? baseQuery
      : firestoreQuery(baseQuery, orderBy('rank', 'asc'))
    const unsubscribe = onSnapshot(
      q,
      (snapshot) => {
        const items: Array<CollectionItem<Data>> = snapshot.docs.map(
          (snapshotDoc) => ({
            ...snapshotDoc.data(),
            id: snapshotDoc.id,
          }),
        )
        onCollectionData(collectionId, items)
      },
      (error: any) => {
        console.error(error)
        console.log(`Error getting collection ${collectionId}, ${collection.refPath}`)
        const { [collectionId]: curr } = useCollections.getState()
        if (!curr) return

        useCollections.setState({
          [collectionId]: {
            ...curr,
            loading: false,
            error: error?.message || 'Error getting collection',
          },
        })
      },
    )

    const initState: CollectionState<Data> = {
      items: [],
      subscribers: {},
      unsubscribe,
      loading: true,
      error: null,
    }

    return initState
  } catch (error: any) {
    const { [collectionId]: curr } = useCollections.getState()
    console.error(error)
    const initState: CollectionState<Data> = {
      ...curr,
      items: [],
      subscribers: {},
      unsubscribe: () => {},
      loading: false,
      error: error?.message || 'Error getting collection',
    }

    useCollections.setState({
      [collectionId]: initState,
    })
    return initState
  }
}

const createDerivedSubscriber = <Data extends DocData = DocData>(
  collection: DerivedCollection<Data>,
  accessLevel: UserGroup,
): CollectionState<Data> => {
  const { _type } = collection
  if (_type !== 'derivedCollection') throw new Error('Collection is not derived')
  const { baseCollection, transform } = collection

  const collectionId = getCollectionId(collection)
  const baseCollectionId = getCollectionId(baseCollection)

  const baseCollectionState = useCollections.getState()[baseCollectionId as CollectionId]
    || createSubscriber(
      baseCollection,
      accessLevel,
    )

  const { items: baseItems = [] } = baseCollectionState || {}
  const items = transform(baseItems as CollectionItem<Data>[])

  const initState: CollectionState<Data> = {
    items,
    loading: false,
    error: null,
    subscribers: {},
    unsubscribe: subscribeToExistingCollection(
      baseCollection,
      baseCollectionState as CollectionState<Data>,
      {
        onData: (updatedItems) => onCollectionData(
          collectionId,
          transform(updatedItems as CollectionItem<Data>[]),
        ),
        onError: (error) => onCollectionError(
          collectionId,
          `Error from base collection: ${error}`,
        ),
        onLoading: (loading) => onCollectionLoading(collectionId, loading),
      },
    ),
  }
  useCollections.setState({
    [collectionId]: initState,
  })
  return initState
}

const createCombinedSubscriber = <Data extends DocData = DocData>(
  collection: CombinedCollection<Data>,
  accessLevel: UserGroup,
): CollectionState<Data> => {
  const { _type } = collection
  const collectionId = getCollectionId(collection)
  const indexCollectionId = getCollectionId(collection.index)
  const otherCollectionIds = Object.keys(collection.otherCollections)
  if (_type !== 'combinedCollection') throw new Error('Collection is not combined')
  const { index, otherCollections } = collection

  const onData = (id: string, data: Array<CollectionItem<Partial<Data>>>) => {
    const collectionsState = useCollections.getState()
    const otherCollectionData = [indexCollectionId, ...otherCollectionIds].reduce(
      (acc, curr) => {
        const { items } = collectionsState[curr] || {}
        if (curr === id) return acc
        return { ...acc, [curr]: arrayToObject(items) || {} }
      },
      {} as Record<string, Record<string, CollectionItem<Partial<Data>>>>,
    )
    const dataIds = data.map((item) => item.id)
    const otherCollectionItemIds = Object.values(otherCollectionData).map((items) => Object.keys(items)).flat()
    const allIds = Array.from(new Set([...dataIds, ...otherCollectionItemIds]))
    const items: Array<CollectionItem<Data>> = allIds.map((itemId) => {
      const fetchedItem = data.find((item) => item.id === itemId)
      const otherItems = Object.entries(otherCollectionData).reduce(
        (acc, [, otherCollectionItems]) => {
          const item = otherCollectionItems[itemId]
          if (!item) return acc
          return item ? { ...acc, ...item } : acc
        },
        {} as CollectionItem<Data>,
      )
      return id === indexCollectionId ? { ...fetchedItem, ...otherItems } : { ...otherItems, ...fetchedItem } as CollectionItem<Data>
    })
    onCollectionData(collectionId, collection.index.noRanks ? items : sortByRank(items))
  }

  const allCollectionIds = [
    `${collectionId}-index`,
    ...Object.keys(otherCollections),
  ]
  const onLoading = () => {
    const collectionsState = useCollections.getState()
    const anyLoading = allCollectionIds.some(
      (id) => !!collectionsState[id]?.loading,
    )
    onCollectionLoading(collectionId, anyLoading)
  }

  const onError = (error: string) => {
    onCollectionError(collectionId, error)
  }

  const subscribeToCollections = () => {
    const unsubscribeIndex = subscribeToCollection<Data>(
      index as Collection<Data>,
      accessLevel,
      { onData: (v) => onData(indexCollectionId, v), onLoading, onError },
    )
    const subscribeOtherCollections = Object.entries(otherCollections).filter(
      ([, otherCollection]) => otherCollection.access.includes(accessLevel),
    ).map(
      ([id, otherCollection]) => subscribeToCollection<Partial<Data>>(
          otherCollection as Collection<Partial<Data>>,
          accessLevel,
          {
            onData: (v) => onData(id, v),
            onLoading,
            onError,
          },
          true,
      ),
    )
    return () => {
      unsubscribeIndex()
      subscribeOtherCollections.forEach((unsubscribe) => unsubscribe())
    }
  }

  const getInitItems = () => {
    const collectionsState = useCollections.getState()
    const indexItems = collectionsState[indexCollectionId]?.items || []
    const otherItems = Object.entries(otherCollections).map(
      ([id]) => collectionsState[id]?.items || [],
    )
    const allIds = new Set([...indexItems.map((item) => item.id), ...otherItems.flat().map((item) => item.id)])
    return Array.from(allIds).map((id) => {
      const indexItem = indexItems.find((item) => item.id === id)
      const others = otherItems.map((o) => o.find((item) => item.id === id))
      return { ...indexItem, ...Object.assign({}, ...others) } as CollectionItem<Data>
    })
  }

  const getInitLoading = () => {
    const collectionsState = useCollections.getState()
    return allCollectionIds.some((id) => !!collectionsState[id]?.loading)
  }

  const getInitError = () => {
    const collectionsState = useCollections.getState()
    return allCollectionIds.map((id) => collectionsState[id]?.error).find((error) => !!error) || null
  }

  const initState: CollectionState<Data> = {
    items: getInitItems(),
    loading: getInitLoading(),
    error: getInitError(),
    subscribers: {},
    unsubscribe: subscribeToCollections(),
  }
  useCollections.setState({
    [collectionId]: initState,
  })
  return initState
}

const createSubscriber = <Data extends DocData = DocData>(
  collection: Collection<Data>,
  accessLevel: UserGroup,
  isOtherCollection?: boolean,
): CollectionState<Data> | null => {
  const { _type, access } = collection
  if (access && !access.includes(accessLevel)) return null
  switch (_type) {
    case 'derivedCollection':
      return createDerivedSubscriber(collection, accessLevel)
    case 'firestoreCollection':
      return createFirestoreSubscriber<Data>(collection, isOtherCollection)
    case 'combinedCollection':
      return createCombinedSubscriber(collection, accessLevel)
    default:
      console.trace(collection)
      throw new Error('Collection is not derived or collection')
  }
}

const getUniqueSubscriberId = (existingIds: string[]) => {
  let id = Math.random().toString(36).substring(7)
  while (existingIds.includes(id)) {
    id = Math.random().toString(36).substring(7)
  }
  return id
}

const addSubscriber = <Data extends DocData = DocData>(
  collection: Collection<Data>,
  state: CollectionState<Data>,
  subscriber: CollectionSubscriber<Data>,
) => {
  const collectionId = getCollectionId(collection)
  const subscriberId = getUniqueSubscriberId(
    Object.keys(state.subscribers),
  )
  subscriber.onData(state.items)
  subscriber.onLoading(state.loading)
  if (state.error) subscriber.onError(state.error)
  useCollections.setState({
    [collectionId]: {
      ...state,
      subscribers: {
        ...state.subscribers,
        [subscriberId]: subscriber,
      },
    },
  })
}

export const subscribeToCollection = <Data extends DocData = DocData>(
  collection: Collection<Data>,
  accessLevel: UserGroup,
  subscriber: CollectionSubscriber<Data>,
  isOtherCollection?: boolean,
) => {
  const collectionId = getCollectionId(collection)
  const { [collectionId]: curr } = useCollections.getState()
  const subscriberId = getUniqueSubscriberId(
    Object.keys(curr?.subscribers || {}),
  )
  if (curr) {
    addSubscriber(collection, curr, subscriber)
  } else {
    const created = createSubscriber(collection, accessLevel, isOtherCollection)
    if (created) addSubscriber(collection, created, subscriber)
  }

  return () => {
    const { [collectionId]: currOnUnsub } = useCollections.getState()
    if (currOnUnsub) {
      const { [subscriberId]: _removed, ...subscribers } = currOnUnsub.subscribers
      if (Object.keys(subscribers).length === 0) {
        // unsubscribe from firestore
        currOnUnsub.unsubscribe()
        useCollections.setState({
          [collectionId]: undefined,
        })
      } else {
        useCollections.setState({
          [collectionId]: { ...currOnUnsub, subscribers },
        })
      }
    }
  }
}

const getFirestoreCollectionItem = async <Data extends DocData>(
  collection: FirestoreCollection<Data>,
  itemId: string,
): Promise<CollectionItem<Data> | undefined> => {
  const { refPath } = collection
  const fetched = await getDoc(doc(db, refPath, itemId))
  if (!fetched.exists) return undefined
  return { ...fetched.data(), id: fetched.id } as CollectionItem<Data>
}

const getDerivedCollectionItem = async <Data extends DocData>(
  collection: DerivedCollection<Data>,
  itemId: string,
): Promise<CollectionItem<Data> | undefined> => {
  const { baseCollection, transform } = collection
  const baseItem = await getCollectionItem(baseCollection, itemId)
  if (!baseItem) return undefined
  return transform([baseItem])[0]
}

const getCombinedCollectionItem = async <Data extends DocData>(
  collection: CombinedCollection<Data>,
  itemId: string,
): Promise<CollectionItem<Data> | undefined> => {
  const { index, otherCollections } = collection
  const indexItem = await getCollectionItem(index, itemId)
  if (!indexItem) return undefined
  const otherItems = await Promise.all(
    Object.entries(otherCollections).map(
      async ([id, otherCollection]) => {
        const item = await getCollectionItem(otherCollection, itemId)
        return item ? { [id]: item } : {}
      },
    ),
  )
  return { ...indexItem, ...Object.assign({}, ...otherItems) }
}

export const getCollectionItem = async <Data extends DocData>(
  collection: Collection<Data>,
  itemId: string,
): Promise<CollectionItem<Data> | undefined> => {
  const { _type } = collection
  switch (_type) {
    case 'firestoreCollection':
      return getFirestoreCollectionItem(collection as FirestoreCollection<Data>, itemId)
    case 'derivedCollection':
      return getDerivedCollectionItem(collection as DerivedCollection<Data>, itemId)
    case 'combinedCollection':
      return getCombinedCollectionItem(collection as CombinedCollection<Data>, itemId)
    default:
      console.trace(collection)
      throw new Error(`Invalid collection type ${_type}`)
  }
}

const subscribeToFirestoreCollectionItem = <Data extends DocData>(
  collection: FirestoreCollection<Data>,
  id: string,
  subscriber: DocumentSubscriber<Data>,
) => {
  const coll = firestoreCollection(db, collection.refPath) as CollectionReference<Data>
  return onSnapshot(doc(coll, id), {
    next: (snapshot) => {
      const data = snapshot.data()
      if (!data) {
        subscriber.onError('Item not found')
        return
      }
      subscriber.onData({ ...data, id })
    },
    error: (error) => {
      subscriber.onError(error.message)
    },
  })
}

const subscribeToDerivedCollectionItem = <Data extends DocData>(
  collection: DerivedCollection<Data>,
  id: string,
  subscriber: DocumentSubscriber<Data>,
) => {
  const { baseCollection, transform } = collection
  const baseSubscriber: DocumentSubscriber<Data> = {
    onData: (data) => {
      const transformed = transform([data])
      subscriber.onData(transformed[0])
    },
    onError: subscriber.onError,
    onLoading: subscriber.onLoading,
  }
  return subscribeToCollectionItem(baseCollection, 'admin', baseSubscriber)
}

const subscribeToCombinedCollectionItem = <Data extends DocData>(
  collection: CombinedCollection<Data>,
  id: string,
  subscriber: DocumentSubscriber<Data>,
) => {
  const { index, otherCollections } = collection
  const indexSubscriber: DocumentSubscriber<Data> = {
    onData: (data) => {
      const otherItems = Object.entries(otherCollections).map(
        ([, otherCollection]) => getCollectionItem(otherCollection, id),
      )
      Promise.all(otherItems).then((otherItemsData) => {
        const otherItemsDataObj = otherItemsData.reduce((acc, curr) => ({ ...acc, ...curr }), {} as Partial<Data>)
        const indexData = data as CollectionItem<Data>
        const combinedData = { ...indexData, ...otherItemsDataObj }
        subscriber.onData(combinedData)
      })
    },
    onError: subscriber.onError,
    onLoading: subscriber.onLoading,
  }
  // console.log('subscribing to collection item...')
  return subscribeToCollectionItem(index, id, indexSubscriber)
}

export const subscribeToCollectionItem = <Data extends DocData>(
  collection: Collection<Data>,
  id: string,
  subscriber: DocumentSubscriber<Data>,
): Unsubscribe => {
  const { _type } = collection
  switch (_type) {
    case 'firestoreCollection':
      return subscribeToFirestoreCollectionItem(collection as FirestoreCollection<Data>, id, subscriber)
    case 'derivedCollection':
      return subscribeToDerivedCollectionItem(collection as DerivedCollection<Data>, id, subscriber)
    case 'combinedCollection':
      return subscribeToCombinedCollectionItem(collection as CombinedCollection<Data>, id, subscriber)
    default:
      console.trace(collection)
      throw new Error(`Invalid collection type ${_type}`)
  }
}
