import {
  Button, Center, Divider, Text, VStack,
} from '@chakra-ui/react'
import {
  Alert,
  arrayToObject,
  colors,
  getSortedKeys,
  objectToArray,
} from '@hb/shared'
import React, { useContext, useMemo, useState } from 'react'
import { UserContext } from '../../../contexts/UserContext'

export const UrgentAlertsPopUp = () => {
  const { user, selectedAssessment } = useContext(UserContext)
  const {
    adminAlerts: adminAssessmentAlerts,
    miscarried,
    practiceAlerts: practiceAssessmentAlerts,
  } = selectedAssessment || {}
  const {
    adminAlerts: adminPatientAlerts,
    practiceAlerts: practicePatientAlerts,
  } = user || {}

  const patientAlerts = useMemo<
    Record<string, Alert & { practice?: boolean; admin?: boolean }>
  >(
    () => ({
      ...arrayToObject(
        objectToArray(adminPatientAlerts || {}).map((a) => ({
          ...(typeof a === 'string' ? { text: a } : a),
          admin: true,
        })),
      ),
      ...arrayToObject(
        objectToArray(practicePatientAlerts || {}).map((a) => ({
          ...(typeof a === 'string' ? { text: a } : a),
          practice: true,
        })),
      ),
    }),
    [adminPatientAlerts, practicePatientAlerts],
  )
  const assessmentAlerts = useMemo<
    Record<string, Alert & { practice?: boolean; admin?: boolean }>
  >(
    () => ({
      ...arrayToObject(
        objectToArray(adminAssessmentAlerts || {}).map((a) => ({
          ...(typeof a === 'string' ? { text: a } : a),
          admin: true,
        })),
      ),
      ...arrayToObject(
        objectToArray(practiceAssessmentAlerts || {}).map((a) => ({
          ...(typeof a === 'string' ? { text: a } : a),
          practice: true,
        })),
      ),
    }),
    [adminAssessmentAlerts, practiceAssessmentAlerts],
  )
  const patientAlertKeys = useMemo(
    () => getSortedKeys(patientAlerts).map((k) => parseInt(k, 10)),
    [patientAlerts],
  )

  const assessmentAlertKeys = useMemo(
    () => getSortedKeys(assessmentAlerts).map((k) => parseInt(k, 10)),
    [assessmentAlerts],
  )
  const urgentAlerts = useMemo<
    Array<{ text: string; practice?: boolean; admin?: boolean }>
  >(() => {
    const alerts = []
    if (miscarried) {
      alerts.push({ text: 'Assessment: Miscarried' })
    }
    if (patientAlertKeys.length) {
      patientAlertKeys.forEach((k) => {
        const alert = patientAlerts?.[k]
        if (typeof alert !== 'string' && alert?.urgent) {
          alerts.push({
            text: `Patient: ${alert.text}`,
            practice: alert.practice,
            admin: alert.admin,
          })
        }
      })
    }
    if (assessmentAlertKeys.length) {
      assessmentAlertKeys.forEach((k) => {
        const alert = assessmentAlerts?.[k]
        if (typeof alert !== 'string' && alert?.urgent) {
          alerts.push({
            text: `Assessment: ${alert.text}`,
            practice: alert.practice,
            admin: alert.admin,
          })
        }
      })
    }
    return alerts
  }, [
    assessmentAlertKeys,
    miscarried,
    patientAlertKeys,
    patientAlerts,
    assessmentAlerts,
  ])
  const [closed, setClosed] = useState(urgentAlerts.length === 0)

  return (
    <Center
      opacity={closed ? 0 : 1}
      pointerEvents={closed ? 'none' : 'auto'}
      transition="opacity 300ms"
      position="absolute"
      left={0}
      top={0}
      zIndex={2}
      bg="gray.100"
      height="100%"
      width="100%"
    >
      <VStack
        divider={<Divider />}
        bg="white"
        shadow="md"
        borderRadius={6}
        spacing={2}
        py={2}
        px={4}
        maxW="800px"
      >
        <Text fontWeight={600} fontSize="lg">
          Review urgent alerts
        </Text>
        {urgentAlerts.map((alert) => (
          <Center
            key={alert.text}
            p={1}
            borderRadius="md"
            width="100%"
            flexFlow="column"
          >
            {alert.practice ? (
              <Text fontSize="sm" fontWeight={600} color="gray.500">
                PRACTICE ALERT
              </Text>
            ) : null}
            {alert.admin ? (
              <Text fontSize="sm" fontWeight={600} color="gray.500">
                ADMIN ALERT
              </Text>
            ) : null}
            <Text fontWeight={600} fontSize="lg" color="red.500">
              {alert.text}
            </Text>
          </Center>
        ))}
        <Button
          onClick={() => setClosed(true)}
          color="white"
          bg={colors.green.hex}
        >
          Continue to profile
        </Button>
      </VStack>
    </Center>
  )
}
