import {
  Button,
  Center,
  Divider,
  HStack,
  Popover,
  PopoverArrow,
  PopoverContent,
  PopoverTrigger,
  Portal,
  StackProps,
  Text,
  VStack,
} from '@chakra-ui/react'
import { colors, getDateString, getTimeString } from '@hb/shared'
import { MultiFactorInfo, PhoneMultiFactorGenerator } from 'firebase/auth'
import React from 'react'
import keyIcon from '../../icons/key.svg'
import smsIcon from '../../icons/sms.svg'
import { SvgIcon } from '../../icons/SvgIcon'
import { useAppModals } from '../../store/modals'

const MFAHelpPopover = () => {
  const { open } = useAppModals((s) => s.contactForm)
  return (
    <Popover isLazy placement="top-end" trigger="hover" strategy="fixed">
      <PopoverTrigger>
        <Center
          onClick={(e) => e.stopPropagation()}
          ml="auto"
          aria-label="help"
          cursor="pointer"
          w="20px"
          h="20px"
          borderRadius="full"
          bg="blackAlpha.500"
          color="gray.50"
          _hover={{ bg: 'blackAlpha.300' }}
        >
          <Text fontSize="1rem">?</Text>
        </Center>
      </PopoverTrigger>
      <Portal>
        <PopoverContent>
          <VStack p={2} align="flex-start">
            <Text fontSize="sm" color="gray.600">
              For the time being, please{' '}
              <Button
                onClick={() => open('root', {
                  autofill: {
                    note: 'Request to remove or rename a verification method. ',
                  },
                  noteHint:
                      'Please specify which authentication method you would like to remove/rename.',
                })
                }
                variant="link"
                size="sm"
                colorScheme="green"
              >
                Contact Us
              </Button>{' '}
              to rename or remove a verification method.
            </Text>
          </VStack>
          <PopoverArrow />
        </PopoverContent>
      </Portal>
    </Popover>
  )
}

const factorIdToName = (factorId: string) => {
  if (factorId === PhoneMultiFactorGenerator.FACTOR_ID) return 'Phone (SMS)'
  return 'Authenticator App'
}

export const MultiFactorInfoView = ({
  factorInfo,
}: {
  factorInfo: MultiFactorInfo
}) => (
  <VStack spacing={0} p={2} w="100%" align="flex-start">
    <HStack w="100%">
      <HStack px={2} borderRadius="full" bg={colors.green.hex} spacing={1}>
        {factorInfo.factorId === PhoneMultiFactorGenerator.FACTOR_ID ? (
          <SvgIcon
            filter="invert(100%) brightness(2) drop-shadow(1px 1px 2px #00000077)"
            src={smsIcon}
            w={4}
          />
        ) : (
          <SvgIcon filter="invert(100%) brightness(2)" src={keyIcon} w={5} />
        )}
        <Text
          fontSize="sm"
          textShadow="1px 1px 2px #00000077"
          fontFamily="Comfortaa"
          fontWeight={600}
          color="white"
        >
          {factorIdToName(factorInfo.factorId)}
        </Text>
      </HStack>
      {/* {isSoleFactor || readOnly ? null : (
          <DeleteButton
            ml="auto"
            onDelete={() => removeSecondaryFactor({ factorId: factorInfo.uid })}
            size="xs"
            itemName="secondary factor"
            alertBody="This will remove the secondary factor from your account. You must have at least one secondary factor enrolled."
            actionName="Remove"
          />
        )} */}
      <MFAHelpPopover />
    </HStack>
    {/* {factorInfo.displayName ? (
        <Text
          fontSize="sm"
          pt={1}
          fontFamily="Hero-New"
          color="gray.600"
          px={2}
        >
          {factorInfo.displayName}
        </Text>
      ) : null} */}
    <Text pt={1} fontFamily="Hero-New" color="gray.600" px={2}>
      {factorInfo.displayName}
    </Text>
    <Text lineHeight={1} px={2} fontFamily="Open Sans" fontSize="sm">
      Enrolled on {getDateString(factorInfo.enrollmentTime, 'short')} at{' '}
      {getTimeString(factorInfo.enrollmentTime)}
    </Text>
  </VStack>
)

export const EnrolledFactorsView = ({
  factors,
  stackProps,
}: {
  factors: MultiFactorInfo[] | null
  stackProps?: StackProps
}) => (
  <VStack
    divider={<Divider />}
    w="100%"
    spacing={0}
    align="flex-start"
    {...stackProps}
  >
    {factors?.length ? (
      factors.map((factor) => (
        <MultiFactorInfoView key={factor.uid} factorInfo={factor} />
      ))
    ) : (
      <Text
        px={2}
        py={1}
        fontSize="sm"
        w="100%"
        bg="gray.100"
        color="gray.600"
        fontWeight="medium"
        fontFamily="hero-new"
      >
        No secondary factors enrolled
      </Text>
    )}
  </VStack>
)
