import {
  arrayToObject, DocData, objectToArray, WithId,
} from '@hb/shared'
import { useMemo } from 'react'

export const useRecordFromArray = <T extends WithId<DocData>>(
  arr?: Array<T> | null,
) => useMemo(() => arrayToObject(arr || []), [arr])

export const useArrayFromRecord = <T extends DocData>(
  record?: Record<string, T> | null,
) => useMemo(() => objectToArray(record || {}), [record])
