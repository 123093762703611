import {
  AdminAssessmentData, Assessment,
  ASSESSMENTS,
  AssessmentSnippet,
  ASSESSMENTS_ADMIN,
  ASSESSMENT_SNIPPETS,
  Claim, Fax,
  FAX_RECEIVED_COLLECTION,
  FAX_SENT_COLLECTION, getPracticeAssessmentDocPath,
  midwivesCollection,
  populateAssessment,
  PopulatedAssessment,
  PracticeAssessmentData,
  providersCollection,
  WithId,
} from '@hb/shared'
import 'firebase/firestore'
import { useContext, useMemo } from 'react'
import { useCollection } from '../../collections/hooks/useCollection'
import {
  AppContext, usePracticeAccess,
} from '../../contexts'
import { useRecordFromArray } from '../useRecordFromArray'
import { useDocument } from './useDocument'

export const usePopulatedItem = (
  item: WithId<any> | null,
  collectionId: string,
) => {
  const practices = useCollection(midwivesCollection)
  const providersRecord = useRecordFromArray(practices?.items || [])

  const insurers = useCollection(providersCollection)
  const insurersRecord = useRecordFromArray(insurers?.items || [])

  const shouldFetchAssessment = useMemo(
    () => [ASSESSMENT_SNIPPETS, ASSESSMENTS].includes(collectionId),
    [collectionId],
  )

  const { data: snippet } = useDocument<AssessmentSnippet>(
    ASSESSMENT_SNIPPETS,
    item?.id,
    shouldFetchAssessment,
  )

  const { data: assessment } = useDocument<Assessment>(
    ASSESSMENTS,
    item?.id,
    shouldFetchAssessment,
  )
  const { selectedPracticeId } = usePracticeAccess()
  const { appName } = useContext(AppContext)

  const { data: practiceAssessmentData } = useDocument<PracticeAssessmentData>(
    getPracticeAssessmentDocPath(selectedPracticeId || '', item?.id),
    shouldFetchAssessment && appName === 'providers-app'
      ? item?.id || null
      : null,
  )

  const { data: assessmentAdminData } = useDocument<AdminAssessmentData>(
    ASSESSMENTS_ADMIN,
    item?.id,
    shouldFetchAssessment,
  )

  return useMemo(() => {
    if (!item) return null
    switch (collectionId) {
      case 'assessment-snippets':
        return assessment
          ? (populateAssessment(
              assessment as Assessment,
              providersRecord || {},
              insurersRecord || {},
              snippet || undefined,
              assessmentAdminData || undefined,
              practiceAssessmentData || undefined,
          ) as WithId<PopulatedAssessment>)
          : null
      case FAX_SENT_COLLECTION:
      case FAX_RECEIVED_COLLECTION:
        return item as WithId<Fax>
      default:
        return item as WithId<Claim>
    }
  }, [
    item,
    collectionId,
    snippet,
    providersRecord,
    practiceAssessmentData,
    insurersRecord,
    assessment,
    assessmentAdminData,
  ])
}
