import { UnuploadedFileDBValue } from '@hb/shared/types'
import { ChangeEvent, useCallback, useContext } from 'react'
import { PopUpMessageContext } from '../../../../contexts'

export type FileUploadingState = 'uploading' | 'deleting' | null

const dataUrlFromFile = (file: File) => new Promise<string>((resolve, reject) => {
  const reader = new FileReader()
  reader.readAsDataURL(file)
  reader.onload = () => resolve(reader.result as string)
  reader.onerror = () => reject(new Error('Error reading image'))
})

export type ChangedFile = {file: UnuploadedFileDBValue, blob: File}
export type OnChangeFiles = (files: Array<ChangedFile>) => void
export const useUploadDbFileValue = (
  onChange: undefined | OnChangeFiles,
  onStateChange: (state: FileUploadingState) => void,
  onFocus?: () => void,
) => {
  const { showError } = useContext(PopUpMessageContext)
  return useCallback(
    async (e: ChangeEvent<HTMLInputElement>) => {
      if (onFocus) onFocus()
      if (!onChange) {
        showError('No onChange function provided')
        return
      }
      const files = Array.from(e.target.files || [])
      if (!files.length) return
      onStateChange('uploading')

      const uploaded = await Promise.all(
        files.map(async (file) => {
          const dataUrl = await dataUrlFromFile(file)
          const fileDBValue: UnuploadedFileDBValue = {
            dataUrl,
            name: file.name,
            type: file.type,
          }
          return { file: fileDBValue, blob: file }
        }),
      )
      onStateChange(null)
      onChange(uploaded)
    },
    [onChange, onFocus, showError, onStateChange],
  )
}
