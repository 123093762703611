import cloneDeep from 'lodash.clonedeep'
import merge from 'lodash.merge'
import { set as nestedSet } from 'nested-property'
import {
  APPOINTMENTS, ASSESSMENTS, ASSESSMENTS_ADMIN, CONSENT_FORMS, getPracticeAssessmentCollectionPath, MIDWIVES, VISITS, VISIT_DRAFTS,
} from '../collections'
import { colors } from '../constants'
import { planDesignField } from '../fields/callIn/planDesignAndStateMandates'
import { stateOptions } from '../fields/shared'
import { assessmentFields, getNestedUserFields } from '../fields/userFields'
import {
  AdminAssessmentData,
  Assessment,
  AssessmentSnippet,
  AssessmentStage,
  AssessmentStatus,
  Claim,
  FieldMap,
  FieldMapValue,
  Form,
  InsuranceProvider,
  Log,
  PopulatedAssessment,
  PopulatedAssessmentInterface,
  PracticeAssessmentData,
  PracticeAssessmentStage,
  PracticeWithAdmin,
  RedFlagRecord,
  UserFieldKey,
  UserForm,
  UserGroup,
} from '../types'
import { objectToArray, toSearchString } from './data'
import { getDateString } from './dates'
import {
  defaultStageValidate,
  formatField,
  getFieldIsRedFlagged,
  isFieldMap,
  isInfoStage,
  isListField,
} from './fields'
import { getFormStatus } from './forms'
import { getCorrectedValue } from './populated'

export const getUrgentString = (log: Log | undefined) => {
  if (log) {
    const logKeys = Object.keys(log)
    for (let i = 0; i < logKeys.length; i += 1) {
      const logEntry = log[parseInt(logKeys[i], 10)]
      if (logEntry.urgent) return `From log - ${logEntry.text}`
    }
  }
  return undefined
}

export const getUrgentSort = (adminData?: AdminAssessmentData | Claim) => {
  const { urgentColor, urgentReason, log } = adminData || {}
  const logUrgent = getUrgentString(log)
  return urgentColor || (urgentReason || logUrgent ? colors.red.hex : 'zzz')
}

export const getLogInfo = (log?: Log) => {
  const logKeys = log
    ? Object.keys(log).map((key) => parseInt(key, 10))
    : undefined
  let lastUpdatedKey
  let lastCreatedKey
  let lastUpdated
  let lastCreated
  if (log) {
    lastUpdatedKey = logKeys?.sort(
      (a: number, b: number) => (log![b].updatedOn || b) - (log![a].updatedOn || a),
    )[0]
    lastCreatedKey = logKeys?.sort((a, b) => b - a)[0]
    if (lastUpdatedKey) lastUpdated = log[lastUpdatedKey]
    if (lastCreatedKey) lastCreated = log[lastCreatedKey]
  }

  return {
    lastCreated,
    lastUpdated,
    lastCreatedKey,
    lastUpdatedKey,
  }
}

export const getAvailableForms = (
  admin: boolean,
  assessment?: PopulatedAssessment,
) => {
  const forms: UserForm[] = []
  let currentFormId: UserFieldKey | undefined = 'introduction'
  while (currentFormId) {
    const form: Form | string | undefined = assessmentFields[currentFormId]
    if (form) {
      if (typeof form !== 'string') {
        const status = getFormStatus(form, assessment?.data)
        forms.push({ id: currentFormId, status })
        if (status !== 'complete') {
          currentFormId = undefined
        } else if (form.getNextFormId) {
          currentFormId = form.getNextFormId(admin, assessment)
        } else {
          currentFormId = undefined
        }
      } else {
        forms.push({ id: currentFormId, status: 'info' })
        currentFormId = undefined
      }
    } else if (currentFormId) {
      forms.push({ id: currentFormId, status: 'info' })
      currentFormId = undefined
    } else {
      console.error(`Can't find form with id: ${currentFormId}`)
      currentFormId = undefined
    }
  }

  return forms
}

export const getCurrentlyOnMedicaidPlan = (data?: FieldMapValue) => data?.['insurance-info']?.option === 'medicaid'
  || (data?.['insurance-info']?.option === 'two-plans'
    && !!data?.['insurance-info']?.medicaidCoverage?.currentlyOnMedicaidPlan)

export const getPrimaryCoverage = (data?: FieldMapValue) => data?.['insurance-info']?.primaryCoverage

export const getMedicaidCoverage = (data?: FieldMapValue) => data?.['insurance-info']?.medicaidCoverage

export const getSecondaryCoverage = (data?: FieldMapValue) => data?.['insurance-info']?.secondaryCoverage

export const getMidwifeId = (data?: FieldMapValue) => data?.delivery?.midwifeId

export const mergeAssessmentData = (
  assessment?: Assessment | PopulatedAssessmentInterface | PopulatedAssessment,
) => merge({}, assessment?.data, assessment?.corrections)

export const mergeAssessmentSignOnData = (
  assessment?: Assessment,
) => merge({}, assessment?.signOnData, assessment?.signOnCorrections)

export const getInsuranceCoverageText = (assessment?: PopulatedAssessment) => {
  if (!assessment) return 'zzz'
  const { insuranceProvider, selectedCoverage } = assessment
  const option = getCorrectedValue(assessment, 'insurance-info.option')
  if (option === 'no-coverage') return 'No coverage'
  const insuranceProviderName: string = insuranceProvider?.name || '(No Insurer)'
  const isMedicaid = getCurrentlyOnMedicaidPlan(assessment.mergedData)
  const planName = selectedCoverage?.planName === "Other / I don't know" ? 'Other' : selectedCoverage?.planName
  if (isMedicaid) {
    if (
      planName
      && insuranceProvider?.plans?.find((p) => p.name === planName)
    ) {
      return `${insuranceProviderName} Medicaid - ${planName}`
    }
    return `${insuranceProviderName} Medicaid`
  }
  if (planName && insuranceProvider?.plans?.find((p) => p.name === planName)) {
    return `${insuranceProviderName} - ${planName}`
  }
  return insuranceProviderName
}

export const getAssessmentStatus = (
  assessment: PopulatedAssessment,
  adminData?: AdminAssessmentData | PopulatedAssessment,
): AssessmentStatus => {
  if (assessment) {
    const { data } = assessment || {}
    if (assessment?.resultsViewedOn) return 'viewed'
    if (assessment?.sentOn) return 'sent'
    if (adminData?.draftSavedOn) return 'draft-saved'
    if (assessment?.submittedOn) return 'submitted'
    if (data) {
      const formStatus = defaultStageValidate(
        getNestedUserFields(true, assessment),
        true,
      )(data)
      const answersComplete = !(formStatus && Object.keys(formStatus).length)
      return answersComplete ? 'questions-complete' : 'incomplete'
    }
  }
  return 'awaiting-questionnaire'
}

export const getLogSnippet = (log?: Log): Log | undefined => {
  const { lastCreated, lastCreatedKey } = getLogInfo(log)
  return lastCreated && lastCreatedKey
    ? { [lastCreatedKey]: lastCreated }
    : undefined
}

// 0 awaiting questionnaire
// 1 questions complete, awaiting assessment
// 2 assessment sent, awaiting sign on by patient
// 3 awaiting sign on by midwife
// 4 signed on
export const getInquiryRank = ({
  assessment,
  signedOnByPractice,
}: {
  assessment?: Assessment
  signedOnByPractice: boolean
}) => {
  const { sentOn, submittedOn, signedOnDate } = assessment || {}
  let inquiryRank = 4
  if (signedOnByPractice) inquiryRank = 3
  else if (submittedOn) {
    inquiryRank = 1
    if (sentOn) {
      inquiryRank = 4
      if (signedOnDate) {
        inquiryRank = 2
      }
    }
  }

  return inquiryRank
}

const populateAssessmentData = (
  data: FieldMapValue | undefined,
  providers: Record<string, PracticeWithAdmin>,
  insurers: Record<string, InsuranceProvider>,
) => {
  if (!data) return undefined
  const populated = cloneDeep(data)
  const midwifeId = data?.delivery?.midwifeId
  if (populated?.delivery?.midwife) delete populated.delivery.midwife
  if (populated?.['insurance-info']?.primaryCoverage?.insuranceProvider) {
    delete populated['insurance-info'].primaryCoverage.insuranceProvider
  }
  if (populated?.['insurance-info']?.secondaryCoverage?.insuranceProvider) {
    delete populated['insurance-info'].secondaryCoverage.insuranceProvider
  }
  if (populated?.['insurance-info']?.medicaidCoverage?.insuranceProvider) {
    delete populated['insurance-info'].medicaidCoverage.insuranceProvider
  }
  const primaryInsurerId = data?.['insurance-info']?.primaryCoverage?.insuranceProviderId
  const secondaryInsurerId = data?.['insurance-info']?.secondaryCoverage?.insuranceProviderId
  const medicaidInsurerId = data?.['insurance-info']?.medicaidCoverage?.insuranceProviderId

  if (midwifeId && providers[midwifeId]) {
    nestedSet(populated, 'delivery.midwife', providers[midwifeId])
  }
  if (primaryInsurerId && insurers[primaryInsurerId]) {
    nestedSet(
      populated,
      'insurance-info.primaryCoverage.insuranceProvider',
      insurers[primaryInsurerId],
    )
  }
  if (secondaryInsurerId && insurers[secondaryInsurerId]) {
    nestedSet(
      populated,
      'insurance-info.secondaryCoverage.insuranceProvider',
      insurers[secondaryInsurerId],
    )
  }
  if (medicaidInsurerId && insurers[medicaidInsurerId]) {
    nestedSet(
      populated,
      'insurance-info.medicaidCoverage.insuranceProvider',
      insurers[medicaidInsurerId],
    )
  }

  return populated
}

export const getRedFlaggedFields = (
  fieldMap: FieldMap,
  values?: FieldMapValue,
): RedFlagRecord | undefined => {
  if (!values) return undefined
  const { children } = fieldMap
  let redFlagged: RedFlagRecord = {}
  const childKeys = Object.keys(children)
  for (let i = 0; i < childKeys.length; i += 1) {
    const key = childKeys[i]
    const child = children[key]
    if (!isInfoStage(child) && !isListField(child)) {
      if (isFieldMap(child)) {
        const childRedFlagged = getRedFlaggedFields(child, values[key])
        if (childRedFlagged) redFlagged = { ...redFlagged, ...childRedFlagged }
      } else {
        const childRedFlaggedReason = getFieldIsRedFlagged(child, values[key])
        if (childRedFlaggedReason) {
          redFlagged[key] = {
            reason: childRedFlaggedReason,
            header: child.placeholder,
          }
        }
      }
    }
  }
  return Object.keys(redFlagged).length !== 0 ? redFlagged : undefined
}

export const getAssessmentRedFlags = (
  assessment: Partial<PopulatedAssessment>,
) => {
  let redFlagged: RedFlagRecord = {}
  // if no unsent assessments, no red flags
  if (assessment.sentOn) return undefined
  if (assessment.insuranceProvider?.redFlagged) {
    redFlagged.insuranceProvider = {
      header: assessment.insuranceProvider.name,
      reason:
        assessment.insuranceProvider.redFlaggedReason
        || 'Insurance provider red-flagged',
    }
  }

  if (assessment.midwife?.redFlagged) {
    redFlagged.midwife = {
      header: assessment?.midwife?.name || 'Error getting midwife name',
      reason: assessment?.midwife?.redFlaggedReason || 'Midwife red-flagged',
    }
  }

  redFlagged = {
    ...redFlagged,
    ...getRedFlaggedFields(
      getNestedUserFields(true, assessment as PopulatedAssessment),
      assessment.mergedData,
    ),
  }

  return Object.keys(redFlagged).length ? redFlagged : undefined
}
export const getSelectedCoverage = (
  mergedData: FieldMapValue,
  insurers: Record<string, InsuranceProvider>,
) => {
  const isMedicaid = getCurrentlyOnMedicaidPlan(mergedData)
  const coverage = isMedicaid
    ? getMedicaidCoverage(mergedData)
    : getPrimaryCoverage(mergedData)
  if (coverage) {
    coverage.insuranceProvider = insurers?.[coverage?.insuranceProviderId]
  }

  return coverage
}
export const populateAssessment = (
  assessment: Assessment,
  providers: Record<string, PracticeWithAdmin>,
  insurers: Record<string, InsuranceProvider>,
  assessmentSnippet?: AssessmentSnippet,
  adminAssessmentData?: AdminAssessmentData,
  practiceAssessmentData?: PracticeAssessmentData,
): PopulatedAssessment => {
  const populatedData = populateAssessmentData(
    assessment.data,
    providers,
    insurers,
  )
  const populatedCorrections = populateAssessmentData(
    assessment.corrections,
    providers,
    insurers,
  )

  const mergedData = mergeAssessmentData(assessment)
  const mergedSignOnData = mergeAssessmentSignOnData(assessment)
  let stages: Array<AssessmentStage | PracticeAssessmentStage> = []
  if (practiceAssessmentData) stages = practiceAssessmentData.stages
  else if (adminAssessmentData) stages = adminAssessmentData.stages || []
  const midwifeId = getMidwifeId(mergedData)
  const payments = adminAssessmentData?.payments || practiceAssessmentData?.payments
  const populated: Omit<PopulatedAssessment, 'availableForms'> = {
    ...assessment,
    ...practiceAssessmentData,
    delivery: assessmentSnippet?.delivery || practiceAssessmentData?.delivery || undefined,
    archivedOn: assessment.archivedOn || null,
    stages,
    midwifeId: practiceAssessmentData?.midwifeId || midwifeId,
    data: populatedData,
    corrections: populatedCorrections,
    payments,
  }

  const selectedCoverage = getSelectedCoverage(mergedData, insurers)
  const insuranceProvider = selectedCoverage?.insuranceProvider
  const midwife = midwifeId ? providers[midwifeId] : undefined
  if (midwife) mergedData.delivery.midwife = midwife
  const urgent = getUrgentString(adminAssessmentData?.log)
  const withoutRedFlags: Omit<PopulatedAssessment, 'redFlags'> = {
    ...populated,
    ...assessmentSnippet,
    ...adminAssessmentData,
    status: getAssessmentStatus(populated, adminAssessmentData),
    adminAlerts: adminAssessmentData?.alerts,
    practiceAlerts: practiceAssessmentData?.alerts,
    mergedData,
    mergedSignOnData,
    signedOnDate: assessment.signedOnDate || null,
    selectedCoverage,
    insuranceProvider,
    urgent,
    midwife,
    midwifeId: midwifeId || undefined,
    receivedPayments: objectToArray(payments?.received || {}),
    duePayments: objectToArray(payments?.due || {}),
    files: assessment.files || {},
    adminFiles: adminAssessmentData?.files || {},
    practiceFiles: practiceAssessmentData?.files || {},
  }
  return {
    ...withoutRedFlags,
    redFlags: getAssessmentRedFlags(withoutRedFlags),
  } as PopulatedAssessment
}

export const getRedFlagText = (
  redFlags: RedFlagRecord | undefined,
) => (redFlags ? Object.values(redFlags)[0].reason : 'zzz')

export const stringifySnippet = (snippet: Partial<AssessmentSnippet>) => {
  const {
    phone = 'zzz',
    email = 'zzz',
    fname = 'zzz',
    lname = 'zzz',
    nextActionText = 'zzz',
    midwifeName = 'zzz',
    insuranceCoverage = 'zzz',
    partnerEmail,
    assessmentName = 'zzz',
    stages = [],
    status = 'incomplete',
    nickname,
    partnerName,
    deliveredOn,
    authRefNumbers,
    additionalAuthRefNumbers,
    additionalClaimsRefNumbers,
    claims,
    dob,
    hasOverduePayment,
  } = snippet || {}
  const searchable = [
    fname,
    lname,
    midwifeName,
    insuranceCoverage,
    partnerName,
    partnerEmail,
    assessmentName,
    nickname,
    Object.keys(claims || {}).join('|'),
    authRefNumbers?.join('|') || '',
    deliveredOn ? getDateString(deliveredOn, 'short') : '',
    additionalAuthRefNumbers?.map((r) => r.referenceNumber).join('|') || '',
    additionalClaimsRefNumbers?.map((r) => r.referenceNumber).join('|') || '',
    email,
    phone,
    dob ? getDateString(dob, 'short') : '',
    hasOverduePayment ? 'overdue payment' : '',
    nextActionText,
    ...stages.join('|'),
    status,
  ]

  return toSearchString(searchable.filter((s) => !!s && s !== 'zzz').join('|'))
}

export const getPracticeAppointmentsCollectionPath = (midwifeId: string) => `${MIDWIVES}/${midwifeId}/${APPOINTMENTS}`
export const getPracticeAssessmentVisitsCollectionPath = (midwifeId: string) => `${MIDWIVES}/${midwifeId}/${VISITS}`
export const getPracticeVisitDraftsCollectionPath = (midwifeId: string) => `${MIDWIVES}/${midwifeId}/${VISIT_DRAFTS}`
export const getPracticeConsentFormsCollectionPath = (midwifeId: string) => `${MIDWIVES}/${midwifeId}/${CONSENT_FORMS}`

export const getAssessmentName = (
  assessment: Assessment | PracticeAssessmentData | PopulatedAssessment | null,
  assessmentSnippet?: AssessmentSnippet,
) => {
  const edd = assessmentSnippet?.edd
    || getCorrectedValue(assessment as Assessment, 'delivery.edd')
  const assessmentName = (assessment as Assessment)?.name
    || (assessment as PracticeAssessmentData)?.assessmentName
    || assessmentSnippet?.assessmentName
  const enteredName = assessmentName && assessmentName !== 'zzz' ? assessmentName : ''
  return (
    enteredName
    || `${enteredName ? `${enteredName} | ` : ''} EDD: ${
      edd ? getDateString(edd, 'short') : 'Awaiting'
    }`
  )
}

const getStateAcronym = (state: string) => stateOptions.find((s) => state === s.text)?.id
export const getSnippetPlanState = <T extends PopulatedAssessment = PopulatedAssessment>(assessment: T) => {
  const mergedData = mergeAssessmentData(assessment)

  const isMedicaidPlan = getCurrentlyOnMedicaidPlan(mergedData)

  // account for legacy value which is note which may contain the state or abbreviation

  const planStateValue = getCorrectedValue(
    assessment,
    `insurance-info.${isMedicaidPlan ? 'medicaidCoverage' : 'primaryCoverage'}.plan-design-and-state-mandates.followsStateMandatesNote`,
  ) as string | undefined

  const completeAcronymMatch = stateOptions.map((s) => s.id).find((s) => planStateValue === s)
  if (completeAcronymMatch) return completeAcronymMatch

  const fullStateMatch = stateOptions.map((s) => s.text.toLowerCase()).find((s) => planStateValue?.toLowerCase().includes(s))
  const fullStateMatchAcronym = fullStateMatch ? getStateAcronym(fullStateMatch) : null
  if (fullStateMatchAcronym) return fullStateMatchAcronym

  // get value without punctuation
  const stateValueNoPunctuation = planStateValue?.replace(/[^a-zA-Z]/g, '')
  const stateAcronymMatch = stateOptions.map((s) => s.id).find((s) => stateValueNoPunctuation?.toLowerCase().split(' ').includes(s.toLowerCase()))

  return stateAcronymMatch || null
}

export const getSnippetPlanCoverageType = <T extends PopulatedAssessment = PopulatedAssessment>(assessment: T) => {
  const mergedData = mergeAssessmentData(assessment)

  const isMedicaidPlan = getCurrentlyOnMedicaidPlan(mergedData)
  const planCoverageTypeValue = getCorrectedValue(
    assessment,
    `insurance-info.${isMedicaidPlan ? 'medicaidCoverage' : 'primaryCoverage'}.plan-design-and-state-mandates.planDesign`,
  )

  return formatField(planDesignField, planCoverageTypeValue) || null
}

export const getSnippetIsMarketplacePlan = <T extends PopulatedAssessment = PopulatedAssessment>(assessment: T) => {
  const isMedicaidPlan = getCurrentlyOnMedicaidPlan(assessment.mergedData)
  const planOption = getCorrectedValue(assessment, 'insurance-info.option')
  return !isMedicaidPlan && planOption !== undefined
}

export const getSnippetPartnerEmail = <T extends PopulatedAssessment = PopulatedAssessment>(assessment: T) => (assessment.mergedSignOnData?.hasPartner
  ? assessment.mergedSignOnData?.partnerContact?.email
  : undefined)

export const getSnippetPartnerName = <T extends PopulatedAssessment = PopulatedAssessment>(assessment: T) => (assessment.mergedSignOnData?.hasPartner
  ? assessment.mergedSignOnData?.partnerContact?.name
  : undefined)

export const getBlankAssessment = (createdBy: string, createdByGroup: UserGroup, patientId: string, midwifeId?: string): Assessment => ({
  archivedOn: null,
  createdOn: Date.now(),
  patientId,
  midwifeId: midwifeId || null,
  data: midwifeId ? { delivery: { midwifeId } } : {},
  createdBy,
  createdByGroup,
})
export const getAssessmentFilesCollection = (firstArg: string, practiceId: string | undefined | null) => {
  switch (firstArg) {
    case 'files':
      return ASSESSMENTS
    case 'adminFiles':
      return ASSESSMENTS_ADMIN
    case 'practiceFiles':
      if (!practiceId) throw new Error('No practice ID provided when getting practice file')
      return getPracticeAssessmentCollectionPath(practiceId)
    default:
      throw new Error(`Invalid file id: ${firstArg} - should start with insurance-info or files`)
  }
}
