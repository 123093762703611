import {
  Divider, Flex, IconButton, Text, VStack,
} from '@chakra-ui/react'
import { colors, View } from '@hb/shared'
import * as React from 'react'
import { useContext, useState } from 'react'
import { Link } from 'react-router-dom'
import { ScreenContext } from '../../contexts'
import hamburgerIcon from '../../icons/hamburger-menu.svg'
import { UserPanel } from '../Auth/UserPanel'
import { useNestedViews } from './hooks'

const MenuContext = React.createContext<{ hideMenu:() => void }>({
  hideMenu: () => {},
})

const MenuItem: React.FC<View> = ({ name, path }) => {
  const { hideMenu } = React.useContext(MenuContext)
  return (
    <Link
      onClick={hideMenu}
      style={{ textDecoration: 'none', color: '#333' }}
      to={typeof path === 'string' ? path : path[0]}
    >
      <Flex fontFamily="Open Sans" px={2}>
        {name}
      </Flex>
    </Link>
  )
}

const MenuFolder: React.FC<{
  views: View[]
  name?: string
}> = ({ views, name }) => (views.length ? (
    <VStack
      py={2}
      w="100%"
      spacing={1}
      style={{
        alignItems: 'flex-start',
      }}
    >
      {name ? (
        <Text
          color={colors.indigo.hex}
          whiteSpace="nowrap"
          px={2}
          lineHeight={1}
          fontFamily="Comfortaa"
          fontWeight={600}
          fontSize="md"
        >
          {name}
        </Text>
      ) : null}
      {views.map((v) => (
        <MenuItem
          key={typeof v.path === 'string' ? v.path : v.path[0]}
          {...v}
        />
      ))}
    </VStack>
) : null)

export const Menu: React.FC<{ views: View[] }> = ({ views }) => {
  const [active, setActive] = useState(false)
  const { isMobile, width: screenWidth } = useContext(ScreenContext)
  const width = React.useMemo(
    () => Math.min(screenWidth * 0.75, 300),
    [screenWidth],
  )
  const {
    publicViews, userViews, adminViews, superAdminViews,
  } = useNestedViews(views)
  return (
    <MenuContext.Provider value={{ hideMenu: () => setActive(false) }}>
      <Flex
        display={isMobile ? 'flex' : 'none'}
        direction="row"
        position="fixed"
        w="100%"
        h="100%"
        zIndex={3}
        onClick={() => setActive(false)}
        style={{
          pointerEvents: active ? 'auto' : 'none',
          flexFlow: 'row',
          background: `rgba(0,0,0,${active ? 0.3 : 0})`,
          transition: 'background 500ms',
          alignItems: 'flex-start',
          justifyContent: 'space-between',
        }}
      >
        <VStack
          pos="absolute"
          right={`${active ? 0 : -(width + 5)}px`}
          spacing={0}
          transition="right 300ms"
          boxShadow="0 0 4px #555"
          borderLeftRadius={6}
          bg="white"
          width={`${width}px`}
          height="100%"
        >
          <VStack
            opacity={active ? 1 : 0}
            transition="all 300ms"
            spacing={0}
            w="100%"
            height="100%"
            overflowY="auto"
          >
            <VStack
              divider={<Divider />}
              px={1}
              py={2}
              spacing={0}
              w="100%"
              flex={1}
            >
              <MenuFolder name="Hamilton Billing" views={publicViews} />
              <MenuFolder name="My Account" views={userViews} />
              <MenuFolder name="Admin Access" views={adminViews} />
              <MenuFolder name="Super Admin Access" views={superAdminViews} />
            </VStack>
            <UserPanel />
          </VStack>
          <IconButton
            aria-label="Open menu"
            onClick={(e) => {
              setActive(!active)
              e.stopPropagation()
            }}
            variant="unstyled"
            position="absolute"
            left="-46px"
            transition="left 300ms"
            pointerEvents="auto"
            alignItems="center"
            bg="transparent"
            top={2}
            icon={
              <img
                style={{ width: 30, filter: 'drop-shadow(0 0 1px #111)' }}
                src={hamburgerIcon}
              />
            }
          />
        </VStack>
      </Flex>
    </MenuContext.Provider>
  )
}

export default Menu
