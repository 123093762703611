import { Text, VStack } from '@chakra-ui/react'
import {
  ALPHABET,
  FieldTypes,
  getNewServiceDiagnosisCode,
  MultipleSelectField,
} from '@hb/shared'
import React, { useMemo } from 'react'
import { useField } from 'react-final-form'
import { FormElement } from '../../../../../forms/Input'

export const DiagnosisPointersInput = ({ name }: { name: string }) => {
  const {
    input: { value: diagnosisCodes },
  } = useField<Array<string>>('DiagnosisCodes')
  const options = useMemo(
    () => (diagnosisCodes || []).map((code, idx) => ({
      id: `${idx + 1}`,
      text: `${(ALPHABET[idx].toUpperCase())} | ${getNewServiceDiagnosisCode(code as any)}`,
    })),
    [diagnosisCodes],
  )
  // const field = useMemo(
  //   () => ({
  //     name: 'Diagnosis Pointers',
  //     itemName: 'Diagnosis Pointer',
  //     itemFields: {
  //       type: FieldTypes.DROPDOWN,
  //       options,
  //       placeholder: options?.length
  //         ? 'Diagnosis pointer'
  //         : 'No diagnosis codes to point to',
  //     } as DropdownField,
  //   } as ListField),
  //   [options],
  // )

  const field = useMemo<MultipleSelectField>(
    () => ({
      type: FieldTypes.MULTIPLE_SELECT,
      options,
      placeholder: 'Diagnosis pointers',
    }),
    [options],
  )

  return (
    <VStack px={2} align='flex-start' w='100%' spacing={0}>
      {options.length ? (
        <FormElement field={field} name={name} />
      ) : (
        <>
          <Text fontWeight={600} color='gray.500' fontSize='sm'>
            Diagnosis Pointer
          </Text>
          <Text>No diagnosis codes to point to</Text>
        </>
      )}
    </VStack>
  )
}
