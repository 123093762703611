import { ViewIcon, ViewOffIcon } from '@chakra-ui/icons'
import { Flex, IconButton, Tooltip } from '@chakra-ui/react'
import { InputElement, InputProps, TextField } from '@hb/shared'
import React, { forwardRef, useState } from 'react'
import InputContainer from '../Shared/InputContainer'
import { TextInputElement } from './styles'

const TextInput: InputElement<TextField> = forwardRef<
  HTMLInputElement,
  InputProps<TextField>
>((props, ref) => {
  const [showPassword, setShowPassword] = useState(false)

  const { field, input, disabled } = props

  const { placeholder, optional } = field || {}
  return (
    <InputContainer {...props}>
      <TextInputElement
        ref={ref}
        disabled={disabled}
        placeholder={`${placeholder}${optional ? ' (optional)' : ''}`}
        type={field.protected && !showPassword ? 'password' : 'text'}
        autoComplete={field.autocomplete}
        {...input}
      />
      {field.protected ? (
        <Flex zIndex={2} pos='absolute' right='0'>
          <Tooltip
            placement='top'
            bg='gray.400'
            gutter={0}
            label={showPassword ? 'Hide' : 'Show'}
          >
            <IconButton
              onClick={() => setShowPassword(!showPassword)}
              size='sm'
              right={2}
              transform='translate(0,-2px)'
              color='gray.400'
              variant='unstyled'
              icon={showPassword ? <ViewOffIcon /> : <ViewIcon />}
              aria-label='show password'
              _focus={{ border: 'none' }}
            />
          </Tooltip>
        </Flex>
      ) : null}
    </InputContainer>
  )
})

export default TextInput
