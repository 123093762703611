import {
  Button,
  Flex,
  Popover,
  PopoverArrow,
  PopoverCloseButton,
  PopoverContent,
  PopoverTrigger,
  Text,
} from '@chakra-ui/react'
import {
  AssessmentSnippet,
  AssessmentSortKey,
  ASSESSMENTS_ADMIN,
  DataColumn,
  getPracticeAssessmentCollectionPath,
  NextAction,
  WithId,
} from '@hb/shared'
import React, { PropsWithChildren, useContext } from 'react'
import { DataGridContext } from '../../../contexts'
import { SortButton } from '../../DataList/SortButton'
import { GridItemPreviewContent } from '../../DataView/GridItemPreviewContent'
import { EllipsisText } from '../../EllipsisText'
import {
  NextActionContent,
} from '../../Users/Profile/NextAction'

export const NextActionPopover = ({
  children,
  isOpen,
  sortKey = 'nextActionDate',
}: PropsWithChildren<{
  isOpen: boolean
  sortKey?: AssessmentSortKey
}>) => {
  const { reverseName, closePreview } = useContext(DataGridContext)

  return (
    <Popover
      isOpen={isOpen}
      placement="bottom-end"
      closeOnBlur={false}
      onClose={closePreview}
      trigger="click"
    >
      <PopoverTrigger>
        <SortButton sortKey={sortKey}>NEXT ACTION</SortButton>
      </PopoverTrigger>
      <GridItemPreviewContent
        width="400px"
        placement="left"
        previewId="nextAction"
      >
        <PopoverArrow />
        <PopoverCloseButton />
        <Flex direction="column" maxW="100vw" width="400px">
          <Text py={1} px={2}>
            {reverseName}
          </Text>
          {children}
        </Flex>
      </GridItemPreviewContent>
    </Popover>
  )
}

// export const getListNextActionPopover = (collection: string) => () => {
//   const { item, preview } = useContext(DataGridContext)
//   return (
//     <NextActionPopover isOpen={preview?.item === 'nextAction' && !!item}>
//       {/* {item ? <NextActionView id={preview?.id} collection={collection}
//       item={item} /> : null} */}
//       {item && 'patientId' in item ? (
//         <NextActionView
//           w="400px"
//           bg="gray.50"
//           borderTop="1px solid #cdcdcd"
//           id={preview?.id}
//           collection={collection}
//           item={item}
//         />
//       ) : null}
//     </NextActionPopover>
//   )
// }

export const getRenderNextAction = (
  collection: string,
): DataColumn<
    WithId<{ nextActionText: string; id: string; nextAction?: NextAction }>,
    string
  >['Render'] => ({ data }) => {
  const { nextActionText, nextAction, id } = data
  const displayedText = nextAction?.text || nextActionText
  return (
      <Popover strategy="fixed" isLazy>
        {({ isOpen }) => (
          <>
            <PopoverTrigger>
              <Button
                w="100%"
                justifyContent="flex-start"
                size="sm"
                bg={isOpen ? 'blue.600' : 'blackAlpha.50'}
                _hover={{
                  bg: isOpen ? 'blue.400' : 'blue.200',
                  color: 'white',
                }}
              >
                <Text
                  // width={itemWidth}
                  textAlign="left"
                  color={isOpen ? 'white' : 'blue.600'}
                  overflow="hidden"
                  textOverflow="ellipsis"
                  fontWeight={400}
                  fontSize="xs"
                >
                  {!displayedText || displayedText === 'zzz'
                    ? 'None'
                    : displayedText}
                </Text>
              </Button>
            </PopoverTrigger>
            <PopoverContent px={2} py={1} bg='gray.50' w="auto">
              <PopoverArrow />
              <Text
                fontWeight={500}
              >
                Next Action
              </Text>
              <Flex w="350px">
                <NextActionContent
                  id={id}
                  collection={collection}
                  item={data as any}
                />
              </Flex>
              <PopoverCloseButton />
            </PopoverContent>
          </>
        )}
      </Popover>
  )
}

export const renderAssessmentNextAction = getRenderNextAction(ASSESSMENTS_ADMIN)
export const getRenderPracticeAssessmentNextAction = (practiceId: string) => getRenderNextAction(getPracticeAssessmentCollectionPath(practiceId))

export const insurerColumn: DataColumn<WithId<AssessmentSnippet>, string> = {
  title: 'INSURER',
  Header: () => <SortButton sortKey="insuranceCoverage">INSURER</SortButton>,
  sortKey: 'insuranceCoverage',
  Render: ({ data }) => (
    <EllipsisText
      // maxWidth={itemWidth}
      text={
        'insuranceCoverage' in data
        && data?.insuranceCoverage
        && data.insuranceCoverage !== 'zzz'
          ? `${data?.insuranceCoverage}${data.planState ? ` | ${data.planState}` : ''}`
          : 'No coverage'
      }
    />
  ),
}
