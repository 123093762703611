import { Button, ButtonProps } from '@chakra-ui/react'
import { colors } from '@hb/shared'
import React from 'react'

export const AddItemButton = (props: ButtonProps) => (
  <Button
    border={`1px solid ${colors.green.hex}`}
    color={colors.green.hex}
    bg='white'
    transition='all 330ms'
    size='xs'
    {...props}
  />
)
