import { useMemo } from 'react'

export const getSelectedItem = <T extends { id: string }>(
  items?: T[] | null,
  id?: string,
) => items?.find((item) => item.id === id)

export function useSelectedItem<T extends { id: string }>(
  items?: T[] | null,
  id?: string,
): T | undefined {
  return useMemo(() => getSelectedItem<T>(items, id), [id, items])
}
