import {
  CircularProgress,
  HStack,
  StackDivider,
  Text,
  VStack,
} from '@chakra-ui/react'
import {
  addressToString,
  DropdownField,
  DropdownOptionItem,
  FieldTypes,
  groupNpiField,
  midwivesCollection,
  PracticeWithAdmin,
  providerNpiField,
  resolveProviderClaimsAddress,
  taxClassField,
  taxIdField,
  tinField,
  WithId,
} from '@hb/shared'
import { deleteField } from 'firebase/firestore'
import React, { useMemo } from 'react'
import { useUpdateCollection } from '../../hooks/useAppCollections'
import { getRowBackground } from '../DataView'
import { Editable } from '../forms/Input'
import { DataLabel } from '../forms/Input/DataLabel'

const BillingNPIField = ({ midwife }: { midwife?: WithId<PracticeWithAdmin> }) => {
  const { updateItem } = useUpdateCollection(midwivesCollection)
  const { group, npi, isGroup } = midwife || {}
  const { npi: groupNpi } = group || {}

  return isGroup ? (
    <Editable
      onSubmit={(val) => updateItem(midwife?.id || '', 'group.npi', val)}
      value={groupNpi}
      field={groupNpiField}
    />
  ) : (
    <Editable
      onSubmit={(val) => updateItem(midwife?.id || '', 'npi', val)}
      value={npi}
      field={providerNpiField}
    />
  )
}

const TaxIdType = ({ midwife }: { midwife?: WithId<PracticeWithAdmin> }) => {
  const { updateItem } = useUpdateCollection(midwivesCollection)
  return (
    <HStack justify='flex-start' flex={1}>
      <Editable
        onSubmit={(v) => updateItem(midwife?.id || '', 'taxIdIsSSN', v === 'ssn')}
        field={tinField}
        value={midwife?.taxIdIsSSN ? 'ssn' : 'ein'}
      />
    </HStack>
  )
}

const BillingTaxId = ({ midwife }: { midwife?: WithId<PracticeWithAdmin> }) => {
  const { updateItem } = useUpdateCollection(midwivesCollection)
  const { taxId } = midwife || {}
  return (
    <HStack
      borderTop='1px solid #dedede'
      borderBottom='1px solid #dedede'
      bg='rgb(245,245,245)'
      divider={<StackDivider />}
      px={2}
      w='100%'
      justify='space-between'
    >
      <HStack flex={1}>
        <DataLabel>Billing Tax ID</DataLabel>
        <Editable

          onSubmit={(val) => updateItem(midwife?.id || '', 'taxId', val)}
          value={taxId}
          field={taxIdField}
        />
        <HStack width='100px' px={2} ml='auto'>
          <TaxIdType midwife={midwife} />
        </HStack>
      </HStack>
      <TaxClass midwife={midwife} />
    </HStack>
  )
}

const TaxClass = ({ midwife }: { midwife?: WithId<PracticeWithAdmin> }) => {
  const { feinTaxClass } = midwife || {}
  const { updateItem, loading } = useUpdateCollection(midwivesCollection)
  return (
    <HStack flex={1}>
      {loading ? <CircularProgress size={3} isIndeterminate /> : null}
      <DataLabel>Tax Class</DataLabel>
      <Editable
        onSubmit={(v) => updateItem(midwife?.id || '', 'feinTaxClass', v)}
        value={feinTaxClass}
        field={taxClassField}
      />
    </HStack>
  )
}

const ClaimsAddressSelect = ({ midwife }: { midwife?: WithId<PracticeWithAdmin> }) => {
  const { updateItem } = useUpdateCollection(midwivesCollection)
  const {
    servicingAddresses,
    corporateAddress,
    paymentsAddress,
    claimsAddress,
  } = midwife || {}

  const field = useMemo<DropdownField>(() => {
    const options: DropdownOptionItem[] = []
    if (corporateAddress) {
      options.push({
        id: 'corporateAddress',
        text: 'Corporate Address',
      })
    }
    if (paymentsAddress) {
      options.push({
        id: 'paymentsAddress',
        text: 'Payments Address',
      })
    }
    if (servicingAddresses?.length) {
      servicingAddresses.forEach((a, idx) => {
        options.push({
          id: `servicingAddresses.${idx}`,
          text: `Servicing Address ${idx + 1}`,
        })
      })
    }
    return {
      options,
      placeholder: 'Select address',
      optional: true,
      type: FieldTypes.DROPDOWN,
    }
  }, [servicingAddresses, corporateAddress, paymentsAddress])

  const resolvedAddress = useMemo(
    () => resolveProviderClaimsAddress(midwife),
    [midwife],
  )

  return (
    <HStack bg={getRowBackground(3)} px={2} justify='space-between' w='100%'>
      <HStack spacing={1}>
        <DataLabel>Claims Address</DataLabel>
        <Editable
          value={claimsAddress}
          onSubmit={(v) => updateItem(midwife?.id || '', 'claimsAddress', v || deleteField())
          }
          field={field}
        />
      </HStack>
      {resolvedAddress ? (
        <Text fontSize='sm' color='gray.600'>
          {addressToString(resolvedAddress)}
        </Text>
      ) : (
        <Text color='gray.600' px={2} fontStyle='italic'>
          No address selected
        </Text>
      )}
    </HStack>
  )
}

export const ProviderBillingInfo = ({
  midwife,
}: {
  midwife?: PracticeWithAdmin
}) => (
  <VStack
    bg='white'
    align='flex-start'
    w='100%'
    spacing={0}
    overflow='hidden'
    borderRadius={4}
    border='1px solid #dedede'
    shadow='md'
  >
    <HStack bg={getRowBackground(1)} px={2} w='100%'>
      <DataLabel>Billing NPI</DataLabel>
      <BillingNPIField midwife={midwife} />
    </HStack>
    <BillingTaxId midwife={midwife} />
    <ClaimsAddressSelect midwife={midwife} />
  </VStack>
)
