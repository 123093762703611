import { FileDBValue, UnuploadedFileDBValue } from '@hb/shared'
import {
  FullMetadata,
  getDownloadURL,
  getMetadata,
  ref,
  StorageError,
} from 'firebase/storage'
import { useEffect, useMemo, useState } from 'react'
import { storage } from '../../../backend/storage'

export const getFileUrl = async (
  path: string,
): Promise<{
  error?: string
  url?: string
  metadata?: FullMetadata
}> => {
  let url: string | undefined
  let metadata: FullMetadata | undefined
  try {
    const fileRef = ref(storage, path)
    metadata = await getMetadata(fileRef)
    url = await getDownloadURL(fileRef)
  } catch (err) {
    const { code } = err as StorageError
    switch (code) {
      case 'storage/object-not-found':
        return {}
      case 'storage/unauthorized':
        return { error: 'User unauthorized to read file' }
      case 'storage/unauthenticated':
        return { error: 'Unauthenticated' }
      default:
        return { error: 'Error reading file' }
    }
  }
  if (!url) return { error: 'Error reading file' }
  return { url, metadata }
}
export interface UseFileData {
  path?: string | null
  pathLoading?: boolean
  noFetch?: boolean
  value?: FileDBValue | UnuploadedFileDBValue
}

export const useFile = ({
  path, pathLoading, noFetch, value,
}: UseFileData) => {
  const { uploadedOn } = value || {}
  const [url, setUrl] = useState<string | undefined>()
  const [metadata, setMetadata] = useState<FullMetadata | undefined>()
  const [error, setError] = useState<string | undefined>()
  const [blob, setBlob] = useState<Blob | undefined>()
  const [blobErr, setBlobErr] = useState<string | undefined>()
  const [blobLoading, setBlobLoading] = useState(false)
  const [loading, setLoading] = useState(false)

  const shouldRefetch = useMemo(
    () => !!(
      value?.uploadedOn
        && new Date(value.uploadedOn) < new Date(Date.now() + 1000 * 15)
    ),
    [value],
  )
  const { dataUrl } = value || {}
  useEffect(() => {
    let mounted = true
    setUrl(undefined)
    setBlob(undefined)
    if (path && !pathLoading) {
      let numRetries = 0
      const refetch = () => {
        setLoading(true)
        getFileUrl(path)
          .then(
            ({ error: err, url: fetchedUrl, metadata: fetchedMetadata }) => {
              if (err) console.log({ err, path })
              if (mounted) {
                if (err || (!fetchedUrl && !fetchedMetadata)) {
                  if (!shouldRefetch || numRetries > 3) {
                    setLoading(false)
                    setError(err)
                  } else {
                    setTimeout(() => {
                      numRetries += 1
                      refetch()
                    }, 1500)
                  }
                } else if (fetchedUrl) {
                  setLoading(false)
                  if (!noFetch) {
                    setBlobLoading(true)
                    fetch(fetchedUrl)
                      .then((res) => {
                        res
                          .blob()
                          .then((resBlob) => {
                            setBlobLoading(false)
                            setBlob(resBlob)
                          })
                          .catch((bErr) => {
                            setBlobLoading(false)
                            console.error(bErr)
                            setBlobErr('Error fetching file')
                          })
                      })
                      .catch((fetchErr) => {
                        if (!shouldRefetch || numRetries > 3) {
                          console.error(fetchErr)
                        } else {
                          setTimeout(() => {
                            numRetries += 1
                            refetch()
                          }, 1500)
                        }
                      })
                  }

                  if (fetchedMetadata) setMetadata(fetchedMetadata)
                  setUrl(fetchedUrl)
                }
              }
            },
          )
          .catch((err) => {
            if (mounted) {
              if (!shouldRefetch || numRetries > 3) {
                setBlobLoading(false)
                setLoading(false)
                console.log(err)
                setError('error fetching')
                setUrl(undefined)
                setMetadata(undefined)
              } else {
                setTimeout(() => {
                  numRetries += 1
                  refetch()
                }, 1500)
              }
            }
          })
      }
      refetch()
    } else if (dataUrl) {
      setUrl(dataUrl)
      setBlobLoading(true)
      fetch(dataUrl).then((res) => {
        res.blob().then((resBlob) => {
          setBlobLoading(false)
          setBlob(resBlob)
        })
      })
    }
    return () => {
      mounted = false
    }
  }, [path, pathLoading, noFetch, uploadedOn, shouldRefetch, dataUrl])

  return useMemo(
    () => ({
      url,
      metadata,
      error,
      loading,
      blob,
      blobErr,
      blobLoading,
    }),
    [url, metadata, error, loading, blob, blobErr, blobLoading],
  )
}
