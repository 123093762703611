import {
  AspectRatio, Flex, Image, ImageProps,
} from '@chakra-ui/react'
import React from 'react'

export const ViewHeaderImage = (props: ImageProps) => (
  <AspectRatio my={6} mb={8} w={props.width || [200, 250, 300]} ratio={1}>
    <Flex position='relative'>
      <Image objectFit='contain' maxW='100%' maxH='100%' {...props} />
    </Flex>
  </AspectRatio>
)
