import {
  CircularProgress, HStack, StackProps, Text,
} from '@chakra-ui/react'
import React from 'react'

export const Loading = ({
  text = 'Loading...',
  stackProps,
}: {
  text?: string
  stackProps?: StackProps
}) => (
  <HStack p={3} {...stackProps}>
    <CircularProgress size={5} isIndeterminate color="green.500" />
    <Text color='gray.600' fontSize='sm' fontFamily='Open Sans'>{text}</Text>
  </HStack>
)
