import { WarningTwoIcon } from '@chakra-ui/icons'
import {
  HStack,
  IconButton,
  Popover,
  PopoverArrow,
  PopoverContent,
  PopoverTrigger,
  Text,
} from '@chakra-ui/react'
import {
  midwivesCollection, PracticeWithAdmin, providerRedFlaggedField, WithId,
} from '@hb/shared'
import React from 'react'
import { useUpdateCollection } from '../../hooks/useAppCollections'
import { SimpleForm } from '../forms/FinalForm/SimpleForm'

export const ProviderAlertPopover = ({
  midwife,
}: {
  midwife?: WithId<PracticeWithAdmin>
}) => {
  const { updateItem } = useUpdateCollection(midwivesCollection)
  return (
    <HStack spacing={1}>
      <Text
        isTruncated
        maxW='250px'
        fontSize='sm'
        color={midwife?.redFlagged ? 'yellow.600' : 'gray.400'}
      >
        {midwife?.redFlagged ? midwife.redFlaggedReason || 'Red Flagged' : ''}
      </Text>
      <Popover isLazy placement='bottom'>
        {/* @ts-ignore */}
        <PopoverTrigger>
          <IconButton
            variant='ghost'
            bg='gray.50'
            borderRadius='full'
            size='sm'
            // borderRadius='full'
            aria-label='Red flaggs'
            icon={
              <WarningTwoIcon
                width={5}
                height={5}
                color={midwife?.redFlagged ? 'yellow.600' : 'gray.400'}
              />
            }
          />
        </PopoverTrigger>
        <PopoverContent p={0}>
          <SimpleForm<{ redFlagged?: boolean; redFlaggedReason?: string }>
            value={{
              redFlagged: midwife?.redFlagged,
              redFlaggedReason: midwife?.redFlaggedReason,
            }}
            field={providerRedFlaggedField}
            boxProps={{ px: 0, py: 0 }}
            onSubmit={({ redFlagged, redFlaggedReason }) => updateItem(midwife?.id || '', '', {
              redFlagged,
              redFlaggedReason: redFlagged ? redFlaggedReason : '',
            })
            }
          />
          <PopoverArrow />
        </PopoverContent>
      </Popover>
    </HStack>
  )
}
