import { Claim } from '@hb/shared'
import { query, where } from 'firebase/firestore'
import { useMemo } from 'react'
import { CLAIMS_REF } from '../../../collections/collections'
import { useApp } from '../../../contexts/AppContext'
import { usePracticeAccess } from '../../../contexts/PracticeAccess'
import { useAuth } from '../../../store'
import { useQuery } from '../useQuery'

const usePatientClaimRef = (id?: string) => {
  const admin = useAuth((s) => s.admin)
  return useMemo(
    () => (id && admin ? query(CLAIMS_REF, where('patientId', '==', id)) : null),
    [id, admin],
  )
}

export const usePatientClaims = (patientId?: string) => {
  const claimsRef = usePatientClaimRef(patientId)
  return useQuery<Claim>(claimsRef)
}

const useAssessmentClaimRef = (id?: string, practiceId?: string | null) => useMemo(() => {
  if (!id) return null
  if (practiceId === null) return null
  let q = query(CLAIMS_REF, where('assessmentId', '==', id))
  if (practiceId) q = query(q, where('midwifeId', '==', practiceId))
  return q
}, [id, practiceId])

export const useAssessmentClaims = (assessmentId?: string) => {
  const { appName } = useApp()
  const { selectedPracticeId } = usePracticeAccess()
  let practiceId: string | null | undefined
  if (appName === 'providers-app') {
    practiceId = selectedPracticeId
  }
  const claimsRef = useAssessmentClaimRef(
    assessmentId,
    practiceId,
  )
  return useQuery<Claim>(claimsRef)
}
