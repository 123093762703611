import {
  ArrowForwardIcon,
  AttachmentIcon,
  ChatIcon,
  CheckIcon,
  CloseIcon,
} from '@chakra-ui/icons'
import {
  Badge,
  Box,
  HStack,
  IconButton,
  Image,
  Text,
  Tooltip,
  VStack,
} from '@chakra-ui/react'
import { BUG_REPORTS } from '@hb/shared/collections'
import { colors } from '@hb/shared/constants'
import { BugReport, WithId } from '@hb/shared/types'
import { getFullName } from '@hb/shared/utils'
import { DocumentReference } from 'firebase/firestore'
import React, {
  useCallback, useContext, useMemo, useState,
} from 'react'
import { Link, useHistory } from 'react-router-dom'
import { saveDoc } from '../../../backend'
import { useCachedUser } from '../../../collections/hooks/cached'
import { PopUpMessageContext } from '../../../contexts'
import archiveIcon from '../../../icons/archive.svg'
import bugIcon from '../../../icons/bug_report.svg'
import { SvgIcon } from '../../../icons/SvgIcon'
import { useAuth } from '../../../store'
import { ActionLog } from '../../ActionLog'
import { CommentsView } from '../../Comments/CommentsView'
import { DataViewList } from '../../DataView'
import { Editable } from '../../forms'
import {
  bugReportDescriptionField,
  bugReportField,
  bugReportFileField,
} from './constants'

const priorityColors: Record<BugReport['priority'], string> = {
  low: 'yellow',
  medium: 'orange',
  high: 'red',
}

const BugReportClosedStatusButton = ({
  bugReport,
}: {
  bugReport: WithId<BugReport>
}) => {
  const { closedOn, id } = bugReport
  const [updatingClosedStatus, setUpdatingClosedStatus] = useState(false)

  const { processResponse } = useContext(PopUpMessageContext)
  const toggleClosedStatus = useCallback(async () => {
    setUpdatingClosedStatus(true)
    try {
      await saveDoc(
        BUG_REPORTS,
        id,
        null,
        closedOn
          ? { closedOn: null, status: 'open' }
          : { closedOn: Date.now(), status: 'closed' },
        () => {},
      )
    } catch (error: any) {
      processResponse({
        error: error?.message || 'An error occurred updating the bug report',
      })
    }
    setUpdatingClosedStatus(false)
  }, [closedOn, id, processResponse])
  return (
    <Tooltip
      placement="top"
      bg="gray.50"
      color="gray.600"
      label={`Mark as ${closedOn ? 'open' : 'closed'}`}
    >
      <IconButton
        icon={<CheckIcon />}
        filter="drop-shadow(1px 1px 3px #00000055)"
        aria-label="Set Closed Status"
        onClick={(e) => {
          toggleClosedStatus()
          e.stopPropagation()
        }}
        isLoading={updatingClosedStatus}
        size="sm"
        color={closedOn ? 'white' : 'gray.400'}
        bg={closedOn ? colors.green.hex : 'white'}
      />
    </Tooltip>
  )
}

const BugReportArchivedButton = ({
  bugReport,
}: {
  bugReport: WithId<BugReport>
}) => {
  const { archivedBy, archivedOn, id } = bugReport
  const [updatingArchived, setUpdatingArchived] = useState(false)
  const authUser = useAuth((s) => s.authUser)
  const { data: archivedByUser } = useCachedUser(archivedBy)

  const labelText = useMemo(() => {
    if (!archivedOn) return 'Click to archive'
    if (archivedByUser) {
      return `Archived by ${getFullName(archivedByUser)} - Click to unarchive`
    }
    return 'Archived - click to unarchive'
  }, [archivedByUser, archivedOn])
  const { processResponse } = useContext(PopUpMessageContext)
  const toggleArchived = useCallback(async () => {
    setUpdatingArchived(true)
    try {
      if (!authUser) {
        processResponse({
          error: 'You must be logged in to archive a bug report',
        })
        return
      }
      await saveDoc(
        BUG_REPORTS,
        id,
        null,
        archivedOn
          ? { archivedOn: null, archivedBy: null, archived: false }
          : {
            archivedBy: authUser.uid,
            archivedOn: Date.now(),
            archived: true,
          },
        () => {},
      )
    } catch (error: any) {
      processResponse({
        error: error?.message || 'An error occurred updating the bug report',
      })
    }
    setUpdatingArchived(false)
  }, [archivedOn, id, processResponse, authUser])
  return (
    <Tooltip placement="top" bg="gray.50" color="gray.600" label={labelText}>
      <IconButton
        icon={
          <SvgIcon
            width="18px"
            height="18px"
            filter={
              archivedOn
                ? 'brightness(200%) grayscale(100%)'
                : 'brightness(100%)'
            }
            src={archiveIcon}
          />
        }
        filter="drop-shadow(1px 1px 3px #00000055)"
        aria-label="View Bug Report"
        onClick={(e) => {
          e.stopPropagation()
          toggleArchived()
        }}
        isLoading={updatingArchived}
        size="xs"
        color={archivedOn ? 'white' : 'gray.400'}
        bg={archivedOn ? colors.green.hex : 'white'}
      />
    </Tooltip>
  )
}

export const BugReportPreview = ({
  bugReport,
  selected,
}: {
  bugReport: WithId<BugReport>
  selected?: boolean
}) => {
  const {
    title, description, priority, files, comments,
  } = bugReport

  const commentsArray = Object.values(comments)
  const history = useHistory()
  return (
    <VStack
      borderRadius={4}
      spacing={0}
      cursor="pointer"
      onClick={() => history.push(`/admin/bug-reports/${bugReport.id}`)}
      bg={selected !== false ? 'gray.50' : 'white'}
      _hover={{
        bg: selected !== false ? 'gray.100' : 'gray.50',
      }}
      w="100%"
      boxShadow={`0px 0px 8px ${selected ? '#00000088' : '#00000022'}`}
    >
      <HStack
        spacing={1}
        borderTopRadius={4}
        py={1}
        bg="red.600"
        px={2}
        w="100%"
        borderBottom="1px solid #cdcdcd"
      >
        <Image
          filter="invert(100%) drop-shadow(1px 1px 3px #00000077)"
          src={bugIcon}
          width={5}
          alt="Bug Report"
        />
        <Text
          flex={1}
          minW="0"
          isTruncated
          filter="drop-shadow(1px 1px 3px #00000077)"
          fontWeight={500}
          fontFamily="Open Sans"
          color="white"
        >
          {title}
        </Text>
        <Badge
          boxShadow="1px 1px 3px #00000077"
          textShadow="1px 1px 3px #00000077"
          colorScheme={priorityColors[priority]}
          variant="solid"
          fontSize="xs"
          px={2}
          py={1}
        >
          {priority.toUpperCase()} PRIORITY
        </Badge>
      </HStack>
      <VStack spacing={1} w="100%" py={2} px={3}>
        <HStack w="100%">
          <Text
            borderRadius={4}
            border="1px solid #cdcdcd"
            py={1}
            px={2}
            flex={1}
            bg="white"
          >
            {description}
          </Text>
          <VStack h="100%" justify="flex-start">
            <BugReportClosedStatusButton bugReport={bugReport} />
            <Tooltip
              placement="top"
              bg="gray.50"
              color="gray.600"
              label={selected ? 'Viewing Bug Report' : 'View Bug Report'}
            >
              <Box>
                <Link to={`/admin/bug-reports/${bugReport.id}`}>
                  <IconButton
                    icon={<ArrowForwardIcon width={5} height={5} />}
                    filter="drop-shadow(1px 1px 3px #00000055)"
                    size="sm"
                    // onClick={(e) => {
                    //   // e.stopPropagation()
                    // }}
                    aria-label={
                      selected ? 'Viewing Bug Report' : 'View Bug Report'
                    }
                    bg={selected ? colors.green.hex : 'white'}
                    color={selected ? 'white' : 'gray.400'}
                  />
                </Link>
              </Box>
            </Tooltip>
          </VStack>
        </HStack>
        <HStack py={1} w="100%">
          <HStack spacing={1}>
            <AttachmentIcon w={4} color="gray.500" />
            <Text fontSize="sm" fontFamily="Open Sans" color="gray.500">
              {files.length || 'No'} file{files.length !== 1 ? 's' : ''}
            </Text>
          </HStack>
          <HStack ml="auto" spacing={1}>
            <Text fontSize="sm" fontFamily="Open Sans" color="gray.500">
              {commentsArray.length || 'No'} comment
              {commentsArray.length !== 1 ? 's' : ''}
            </Text>
            <ChatIcon w={4} color="gray.400" />
          </HStack>
        </HStack>
        <HStack w="100%">
          <VStack spacing={0}>
            <ActionLog
              action="Created"
              by={bugReport.createdBy}
              on={bugReport.createdOn}
            />
            {bugReport.createdOn !== bugReport.updatedOn && (
              <ActionLog
                action="Updated"
                by={bugReport.updatedBy}
                on={bugReport.updatedOn}
              />
            )}
          </VStack>
          <Box ml="auto">
            <BugReportArchivedButton bugReport={bugReport} />
          </Box>
        </HStack>
      </VStack>
    </VStack>
  )
}

export const BugReportView = ({
  bugReport,
  docRef,
  onClose,
}: {
  bugReport: WithId<BugReport>
  docRef: DocumentReference<BugReport>
  onClose?: () => void
}) => {
  const {
    title, description, priority, files, comments,
  } = bugReport
  return (
    <VStack
      borderRadius={4}
      spacing={0}
      w="100%"
      bg="white"
      boxShadow="1px 1px 3px #00000077"
    >
      <HStack
        spacing={2}
        borderTopRadius={4}
        py={1}
        bg="red.600"
        px={2}
        w="100%"
        borderBottom="1px solid #cdcdcd"
      >
        <Image
          filter="invert(100%) drop-shadow(1px 1px 3px #00000077)"
          src={bugIcon}
          width={5}
          alt="Bug Report"
        />
        <Text
          flex={1}
          minW="0"
          isTruncated
          filter="drop-shadow(1px 1px 3px #00000077)"
          fontWeight={500}
          fontSize="lg"
          fontFamily="Open Sans"
          color="white"
        >
          {title}
        </Text>
        <Badge
          boxShadow="1px 1px 3px #00000077"
          textShadow="1px 1px 3px #00000077"
          colorScheme={priorityColors[priority]}
          variant="solid"
          fontSize="xs"
          px={2}
          py={1}
        >
          {priority.toUpperCase()} PRIORITY
        </Badge>
        <BugReportClosedStatusButton bugReport={bugReport} />
        {onClose ? (
          <IconButton
            icon={<CloseIcon w={3} />}
            filter="drop-shadow(1px 1px 3px #00000055)"
            aria-label="Close"
            onClick={onClose}
            size="sm"
            color={'gray.400'}
            bg={'white'}
          />
        ) : null}
      </HStack>
      <VStack align="flex-start" spacing={1} w="100%" py={2} px={3}>
        <ActionLog
          action="Created"
          by={bugReport.createdBy}
          on={bugReport.createdOn}
        />
        {bugReport.createdOn !== bugReport.updatedOn && (
          <ActionLog
            action="Updated"
            by={bugReport.updatedBy}
            on={bugReport.updatedOn}
          />
        )}
        <VStack spacing={0} w="100%">
          <Text
            fontSize="sm"
            w="100%"
            fontWeight={600}
            fontFamily="Open Sans"
            color="gray.500"
          >
            Description
          </Text>
          <Editable
            field={bugReportDescriptionField}
            value={description}
            theme="detailed"
            style={{
              border: '1px solid #cdcdcd',
              borderRadius: '6px',
              padding: '0.2rem 0.3rem',
              minHeight: '80px',
            }}
            onSubmit={(value) => saveDoc(BUG_REPORTS, docRef.id, null, { description: value })
              .then(() => ({ success: 'Bug report updated' }))
              .catch((err: any) => ({
                error:
                    err?.message || 'An error occurred updating the bug report',
              }))
            }
          />
        </VStack>
        <VStack align="flex-start" spacing={0} w="100%">
          <Text
            fontSize="sm"
            fontWeight={600}
            fontFamily="Open Sans"
            color="gray.500"
          >
            Files
          </Text>
          <HStack w="100%">
            {/* {files.length ? (
              files.map((file, index) => (
                <FileView
                  key={file.id || `${index}`}
                  data={file}
                  field={bugReportFileField}
                />
              ))
            ) : (
              <Text
                fontFamily="Open Sans"
                fontStyle="italic"
                fontSize="sm"
                color="gray.600"
              >
                No files
              </Text>
            )} */}
            <DataViewList
              itemField={bugReportFileField}
              value={files}
              itemName="File"
              baseStoragePath={`${BUG_REPORTS}/${docRef.id}/files`}
              initExpanded
              onSubmit={(value) => saveDoc(BUG_REPORTS, docRef.id, bugReportField, {
                files: value,
              })
                .then(() => ({ success: 'Files updated' }))
                .catch((err: any) => ({
                  error:
                      err?.message
                      || 'An error occurred updating the bug report',
                }))
              }
            />
          </HStack>
        </VStack>
        <CommentsView
          comments={comments}
          docRef={docRef}
          fieldPath="comments"
        />
      </VStack>
    </VStack>
  )
}
