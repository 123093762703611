import React, { CSSProperties, PropsWithChildren, useState } from 'react'

export const StatusButtonBase = ({
  color, background, children, style, onClick,
}: PropsWithChildren<{
  color: string
  background?: string
  style?: CSSProperties
  onClick?: () => void
}>) => {
  const [hovered, setHovered] = useState(false)
  return (
    <span
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
      onClick={onClick}
      style={{
        cursor: 'pointer',
        padding: '2px 6px',
        borderRadius: 6,
        opacity: hovered || !onClick ? 1 : 0.8,
        letterSpacing: '0.05rem',
        fontWeight: 600,
        color,
        border: `1px solid ${color}`,
        background,
        fontSize: '10px',
        whiteSpace: 'nowrap',
        ...style,
      }}
    >
      {children}
    </span>
  )
}
