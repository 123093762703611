import { Portal, Text } from '@chakra-ui/react'
import { getBaseCollection } from '@hb/shared/collections'
import { FieldTypes } from '@hb/shared/constants'
import { NestedFieldItem } from '@hb/shared/editor'
import {
  DynamicDropdownField, Field as IField, FieldMap, FieldMapValue, isShortcutMap, ListField, Shortcut, ShortcutMap, TemplateField, TemplateFieldMap,
} from '@hb/shared/types'
import {
  isField, isFieldItem, isInfoStage, isListField, isShortcut,
} from '@hb/shared/utils'
import React, {
  useCallback, useEffect, useMemo, useRef, useState,
} from 'react'
import { useResizeObserver } from '../../../hooks/useResizeObserver'
import { filteredEvents } from '../../../utils'
import { Container } from '../../Container'

// FOR USE WITH TEMPLATES

const useDisplayedChildren = (
  field: FieldMap | ShortcutMap | IField | TemplateFieldMap,
) => {
  const { children } = field
  return useMemo(() => {
    if (!children) return {}
    const keys = Object.keys(children).filter((k) => {
      const child = children[k]
      if (isInfoStage(child)) return false
      if (isListField(child)) return true
      if (isShortcutMap(child)) return true
      if (child.type === FieldTypes.FILE) return false
      return true
    })
    const filtered: Record<string, FieldMap | IField | Shortcut | ShortcutMap> = {}
    for (let i = 0; i < keys.length; i += 1) {
      const key = keys[i]
      filtered[key] = children[key] as
        | FieldMap
        | IField
        | Shortcut
        | ShortcutMap
    }
    return filtered
  }, [children])
}

type Offset = {
  x: number
  y: number
}

const useHover = () => {
  const timerRef = useRef<ReturnType<typeof setTimeout> | null>(null)
  const [hovered, setHovered] = useState(false)
  const onHover = () => {
    setHovered(true)
    if (timerRef.current) {
      clearTimeout(timerRef.current)
    }
  }

  const onBlur = () => {
    timerRef.current = setTimeout(() => {
      if (setHovered) setHovered(false)
    }, 133)
  }
  return { hovered, onHover, onBlur }
}

const FieldDropdownItem: React.FC<{
  rootPath: string
  field: IField | Shortcut | TemplateField
  id: string
  onClick: (v: NestedFieldItem) => void
  index?: number
  parent: FieldMap | ShortcutMap | ListField
  parentName: string
  value?: any
}> = ({
  field, rootPath, onClick, id, index, parentName,
}) => {
  const { name, optional, condition } = useMemo(
    () => ({
      name: isShortcut(field) ? field.name : field.placeholder,
      optional: isShortcut(field) ? undefined : field.optional,
      condition: isShortcut(field) ? undefined : field.condition,
    }),
    [field],
  )

  const [hovered, setHovered] = useState(false)

  const handleClick = useCallback(() => {
    onClick({
      id,
      path: `${rootPath}.${id}`,
      name: `${parentName} -> ${name}${
        index !== undefined ? ` [${index + 1}]` : ''
      }`,
      ...field,
    })
  }, [onClick, rootPath, id, name, parentName, index, field])

  return (
    <Container
      {...filteredEvents(handleClick)}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
      style={{
        opacity: hovered ? 1 : 0.6,
        cursor: 'pointer',
        height: 'auto',
        alignItems: 'flex-start',
      }}
    >
      <Container
        style={{
          height: 'auto',
          width: 'auto',
          flexFlow: 'row',
          alignItems: 'center',
          lineHeight: '25px',
          whiteSpace: 'nowrap',
          padding: '0 10px',
          fontSize: '14px',
        }}
      >
        {name} {optional || condition ? '(?)' : null}
        {/* {shortcut ? (
          <img src={calcIcon} style={{ height: 18, marginLeft: 10 }} />
        ) : null} */}
      </Container>
    </Container>
  )
}

// const ListFieldDropdownItem: React.FC<{
//   rootPath: string
//   onItemClick: (i: NestedFieldItem) => void,
//   field: ListField,
//   id: string,
//   depth: number,
//   parent: FieldMap | ShortcutMap | ListField,
//   offset: Offset
// }> = ({}) => {

// }

const FieldDropdownIdItem: React.FC<{
  rootPath: string
  onItemClick: (i: NestedFieldItem) => void
  field: IField | Shortcut
  id: string
  value?: any
  index?: number
  parentName: string
  depth: number
  parent: FieldMap | ShortcutMap | ListField
  offset: Offset
}> = ({
  field,
  id,
  offset,
  rootPath,
  onItemClick,
  depth,
  value,
  index,
  parentName,
}) => {
  const { collection } = field as DynamicDropdownField
  const collectionField = useMemo(() => {
    const baseCollection = getBaseCollection(collection)
    return baseCollection.field ? { ...baseCollection.field, rootPath } : null
  }, [collection, rootPath])

  const itemId = useMemo(() => {
    const idIndex = id.lastIndexOf('Id')
    if (idIndex !== -1) {
      return id.substring(0, idIndex)
    }
    return id
  }, [id])

  return collectionField ? (
    <NestedFieldDropdown
      onItemClick={onItemClick}
      depth={depth}
      id={itemId}
      index={index}
      value={value}
      offset={offset}
      parentName={parentName}
      rootPath={rootPath}
      field={collectionField}
    />
  ) : null
}

const FieldDropdownName: React.FC<{ name: string; nested?: boolean }> = ({
  name,
  nested,
}) => (
  <Container
    style={{
      height: 25,
      alignItems: 'flex-start',
      boxSizing: 'border-box',
      paddingRight: '30px',
    }}
  >
    <span
      style={{
        padding: '0px 10px',
        whiteSpace: 'nowrap',
        fontSize: '14px',
      }}
    >
      {name}
    </span>
    <span
      style={{
        cursor: 'pointer',
        position: 'absolute',
        right: 10,
        opacity: 0.8,
        fontSize: '13px',
        transform: `rotate(${nested ? -90 : 0}deg) scale(${nested ? 0.8 : 1})`,
      }}
    >
      ▼
    </span>
  </Container>
)

const ListFieldDropdownItem: React.FC<{
  field: ListField
  rootPath: string
  id: string
  onItemClick: (i: NestedFieldItem) => void
  depth: number
  offset: Offset
  value?: Array<FieldMapValue>
  parentName: string
}> = ({
  field,
  value,
  rootPath,
  id,
  onItemClick,
  depth,
  offset,
  parentName,
}) => {
  const { name, itemFields, itemName } = field
  const displayedChildren = useDisplayedChildren(itemFields)

  const { onHover, onBlur, hovered } = useHover()
  const displayedValue = useMemo(() => value || [{}], [value])
  return (
    <Container
      onMouseEnter={onHover}
      onMouseLeave={onBlur}
      style={{
        opacity: hovered ? 1 : 0.6,
        cursor: 'pointer',
        height: 'auto',
        alignItems: 'flex-start',
      }}
    >
      <FieldDropdownName nested name={name} />
      {displayedValue.map((v, i) => (isField(itemFields) ? (
          <FieldDropdownItem
            parentName={`${parentName} -> ${name}`}
            rootPath={rootPath}
            id={`${id}.${i}`}
            index={i}
            onClick={onItemClick}
            parent={field}
            key={`${id}_${i}`}
            field={itemFields}
            value={v}
          />
      ) : (
          <FieldDropdownContent
            key={`${id}_${i}`}
            header={v.name || `${itemName} ${i + 1}`}
            expanded={hovered}
            children={displayedChildren}
            value={v}
            name={`${parentName} -> ${name}`}
            id={`${id}.${i}`}
            index={i}
            depth={depth}
            offset={offset}
            onItemClick={onItemClick}
            rootPath={rootPath}
            field={itemFields}
          />
      )))}
    </Container>
  )
}

const NestedFieldDropdown: React.FC<{
  rootPath: string
  id: string
  field: FieldMap | ShortcutMap
  onItemClick: (i: NestedFieldItem) => void
  depth: number
  value?: FieldMapValue
  parentName?: string
  index?: number
  offset: Offset
}> = ({
  id,
  field,
  onItemClick,
  rootPath,
  offset,
  depth,
  value,
  index,
  parentName,
}) => {
  const { name } = field
  const displayedChildren = useDisplayedChildren(field)
  const { onHover, onBlur, hovered } = useHover()

  if (!Object.keys(displayedChildren).length) return null

  return (
    <Container
      onMouseEnter={onHover}
      onMouseLeave={onBlur}
      style={{
        opacity: hovered ? 1 : 0.6,
        cursor: 'pointer',
        height: 'auto',
        alignItems: 'flex-start',
      }}
    >
      <FieldDropdownName nested name={name} />
      <FieldDropdownContent
        value={value}
        nested
        id={id}
        index={index}
        rootPath={rootPath}
        field={field}
        name={parentName ? `${parentName} -> ${name}` : name}
        depth={depth}
        children={displayedChildren}
        offset={offset}
        onItemClick={onItemClick}
        expanded={hovered}
      />
    </Container>
  )
}

const FieldDropdownChild: React.FC<{
  child: FieldMap | ShortcutMap | IField | Shortcut | ListField
  childId: string
  onItemClick: (item: NestedFieldItem) => void
  childRootPath: string
  value?: FieldMapValue
  parentName: string
  width: number
  depth: number
  field: FieldMap | ShortcutMap | TemplateFieldMap
  index?: number
}> = ({
  child,
  childId,
  onItemClick,
  childRootPath,
  parentName,
  value,
  width,
  depth,
  index,
  field,
}) => {
  // if (isTemplateFieldMap(field)) return <TemplateFieldView field={child as IField} id={childId} />
  if (isListField(child)) {
    return (
      <ListFieldDropdownItem
        parentName={parentName}
        value={value?.[childId]}
        id={childId}
        key={childId}
        offset={{ x: width, y: -1 }}
        field={child}
        onItemClick={onItemClick}
        depth={depth + 1}
        rootPath={childRootPath}
      />
    )
  }
  if (isFieldItem(child)) {
    switch (child.type) {
      case FieldTypes.ID:
        return (
          <FieldDropdownIdItem
            parentName={parentName}
            value={value?.[childId.substring(0, childId.length - 2)]}
            depth={depth + 1}
            rootPath={childRootPath}
            key={childId}
            offset={{ x: width, y: -1 }}
            onItemClick={onItemClick}
            field={child}
            index={index}
            id={childId}
            parent={field}
          />
        )
      default:
        return (
          <FieldDropdownItem
            parentName={parentName}
            value={value?.[childId]}
            rootPath={childRootPath}
            index={index}
            key={childId}
            onClick={onItemClick}
            field={child}
            id={childId}
            parent={field}
          />
        )
    }
  } else {
    return (
      <NestedFieldDropdown
        id={childId}
        value={value?.[childId]}
        depth={depth + 1}
        onItemClick={onItemClick}
        offset={{ x: width + 1, y: -1 }}
        index={index}
        key={childId}
        rootPath={childRootPath}
        parentName={parentName}
        field={child}
      />
    )
  }
}

const FieldDropdownContent: React.FC<{
  rootPath: string
  id: string
  header?: string
  value?: FieldMapValue
  name: string
  index?: number
  children: Record<
    string,
    FieldMap | ShortcutMap | Shortcut | IField | ListField
  >
  field: FieldMap | ShortcutMap
  onItemClick: (item: NestedFieldItem) => void
  nested?: boolean
  offset: Offset
  depth: number
  expanded: boolean
}> = ({
  rootPath,
  children,
  header,
  field,
  onItemClick,
  index,
  name,
  offset,
  expanded,
  id,
  depth,
  value,
}) => {
  const ref = useRef<HTMLDivElement>(null)
  const { x, y } = offset || {}
  const { height = 0, width = 0 } = useResizeObserver(ref)
  const oddDepth = useMemo(() => depth % 2 === 1, [depth])
  return (
    <Container
      style={{
        position: 'absolute',
        width: 'auto',
        top: !depth ? y : undefined,
        left: !depth || oddDepth ? x : undefined,
        right: !depth || oddDepth ? undefined : x,
        // boxSizing: 'border-box',
        height,
        zIndex: 3,
        pointerEvents: expanded ? 'auto' : 'none',
        justifyContent: 'flex-start',
        opacity: expanded ? 1 : 0,
        // overflow: 'hidden',
        transition: 'height 333ms, opacity 333ms',
      }}
    >
      <Container
        ref={ref}
        style={{
          height: 'auto',
          width: 'auto',
          display: 'block',
          background: 'white',
          border: '1px solid #777',
        }}
      >
        {header ? (
          <Text minW="250px" fontWeight={500} fontSize="sm" mx={2} my={1}>
            {header}
          </Text>
        ) : null}
        {Object.keys(children).map((childId) => {
          const childRootPath = `${rootPath ? `${rootPath}.` : ''}${id}`
          const child = children[childId]
          return (
            <FieldDropdownChild
              child={child}
              parentName={`${name}${
                index !== undefined ? ` [${index + 1}]` : ''
              }`}
              childId={childId}
              childRootPath={childRootPath}
              depth={depth}
              field={field}
              onItemClick={onItemClick}
              width={width}
              key={childId}
              value={value}
            />
          )
        })}
      </Container>
    </Container>
  )
}

export const NestedOptionSelect: React.FC<{
  rootPath?: string
  id: string
  field: FieldMap | ShortcutMap | TemplateFieldMap
  values?: FieldMapValue
  onItemClick: (item: NestedFieldItem) => void
  zIndex?: number
}> = ({
  id, field, onItemClick, rootPath = '', zIndex = 2, values,
}) => {
  const { name } = field || {}
  const [hovered, setHovered] = useState(false)
  const ref = useRef<HTMLDivElement>(null)

  const [{ x, y }, setOffset] = useState({ x: 0, y: 0 })
  useEffect(() => {
    const box = ref.current?.getBoundingClientRect()
    if (box) {
      setOffset({ x: box.x, y: box.y + box.height })
    }
  }, [hovered])

  const displayedChildren = useDisplayedChildren(field)

  return (
    <Container
      ref={ref}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
      style={{
        width: 'auto',
        minWidth: 100,
        cursor: 'pointer',
        margin: '5px',
        zIndex: (zIndex || 0) + 1000,
        height: 25,
        boxSizing: 'border-box',
        border: '1px solid #cdcdcd',
        justifyContent: 'flex-start',
      }}
    >
      <FieldDropdownName name={name} />
      <Portal>
        <FieldDropdownContent
          name={name}
          depth={0}
          children={displayedChildren}
          id={id}
          rootPath={rootPath}
          field={field}
          value={values}
          onItemClick={onItemClick}
          offset={{ x, y }}
          expanded={hovered}
        />
      </Portal>
    </Container>
  )
}
