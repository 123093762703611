import {
  assessmentFields,
  defaultStageValidate,
  FieldMap,
  FieldMapValue,
  InfoStage,
  isField,
  isInfoStage,
  StageStatus,
  UserFieldKey,
} from '@hb/shared'
import { ValidationErrors } from 'final-form'
import { get as nestedGet } from 'nested-property'
import { useMemo } from 'react'

export const getStageStatus = (
  stage?: InfoStage | FieldMap,
  value?: FieldMapValue,
  es?: ValidationErrors,
): StageStatus => {
  if (stage && isInfoStage(stage)) return 'info'
  const validate = stage?.validate || defaultStageValidate(stage, true)
  let errors
  if (!es) errors = validate(value)
  else errors = es
  if (!stage || !value) return 'incomplete'
  if (errors) {
    const numErrors = Object.keys(errors).length
    const numRequired = Object.keys(stage.children).filter((childName) => {
      const child = stage.children[childName]
      if (isInfoStage(child)) return false
      if (isField(child)) {
        return !child.optional
      }
      return true
    }).length
    if (numErrors) {
      if (numErrors === numRequired) {
        return 'incomplete'
      }
      return 'in progress'
    }
  }
  return 'complete'
}

export function useStage(
  formId?: UserFieldKey,
  path?: string,
  formData?: FieldMapValue,
) {
  const form = useMemo(() => formId && assessmentFields[formId], [formId])
  const stage = useMemo(
    () => (!(form && path) || typeof form === 'string'
      ? undefined
      : form.stages[path]),
    [path, form],
  ) as FieldMap | undefined
  const value = useMemo(
    () => (!(form && path) || typeof form === 'string'
      ? undefined
      : nestedGet(formData, form.path ? `${form.path}.${path}` : path)),
    [formData, form, path],
  )
  const errors = useMemo(() => {
    if (!stage) return undefined
    const validate = stage.validate || defaultStageValidate(stage, true)
    return validate(value)
  }, [value, stage])

  const status = useMemo<StageStatus>(
    () => getStageStatus(stage, value, errors),
    [stage, errors, value],
  )

  return {
    value,
    errors,
    status,
  }
}
