import { getLocalDateString } from '@hb/shared/utils'
import { useEffect, useMemo, useState } from 'react'

// const toDateString = (date: Date) => {
//   const month = date.getMonth() + 1
//   const day = date.getDate()
//   const year = date.getFullYear()
//   return `${padZeros(month, 2)}/${padZeros(day, 2)}/${year}`
// }

// formatted as MM/DD/YYYY updates at midnight

const midnightSeconds = 24 * 60 * 60

const localDateSeconds = () => {
  const date = new Date()
  const hour = date.getHours()
  const minute = date.getMinutes()
  const second = date.getSeconds()
  return hour * 3600 + minute * 60 + second
}

export const useTodayDate = () => {
  const [date, setDate] = useState(new Date())
  const todayString = useMemo(() => getLocalDateString(date), [date])
  useEffect(() => {
    const timeUntilMidnight = midnightSeconds - localDateSeconds()
    const timeout = setTimeout(() => {
      setDate(new Date())
    }, timeUntilMidnight * 1000)
    return () => clearTimeout(timeout)
  }, [todayString])

  return { date, todayString }
}
