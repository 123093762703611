import { InputElement, InputProps, PhoneField } from '@hb/shared'
import * as React from 'react'
import { forwardRef } from 'react'
import InputContainer from '../../Shared/InputContainer'
import { TextInputElement } from '../styles'

const PhoneInput: InputElement<PhoneField> = forwardRef<
  HTMLInputElement,
  InputProps<PhoneField>
>((props, ref) => {
  const { input, field, disabled } = props
  const { placeholder = 'Phone Number' } = field

  return (
    <InputContainer {...props}>
      <TextInputElement disabled={disabled} ref={ref} placeholder={placeholder} {...input} />
    </InputContainer>
  )
})

export default PhoneInput
