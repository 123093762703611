import { InvoiceStatus } from '../types/invoice'

export const nyTimezoneOffset = 4 * 60 * 60 * 1000

export const UNSENT_INVOICE_STATUSES: Readonly<Array<InvoiceStatus>> = ['scheduled', 'pending-approval'] as const

export const invoiceStatuses: Array<InvoiceStatus> = [
  'draft',
  'scheduled',
  'pending-approval',
  'sent',
  'paid',
]
