import { Button, ButtonProps, forwardRef } from '@chakra-ui/react'
import { colors } from '@hb/shared/constants'
import React from 'react'

export const ActionButton = forwardRef((props: ButtonProps, ref) => (
  <Button
    ref={ref}
    fontFamily='Open Sans'
    fontWeight={700}
    // size='sm'
    py={2}
    lineHeight={1}
    _hover={{
      bg: 'gray.100',
    }}
    px={4}
    colorScheme='green'
    variant='outline'
    {...props}
  />
))

export const SolidActionButton = forwardRef((props: ButtonProps, ref) => (
  <Button
    ref={ref}
    fontFamily='Open Sans'
    fontWeight={700}
    // size='sm'
    bg={props.colorScheme ? undefined : colors.green.hex}
    color='white'
    // boxShadow='1px 1px 3px rgba(0,0,0,0.5)'
    py={2}
    lineHeight={1}
    textShadow='1px 1px 3px rgba(0,0,0,0.5)'
    _hover={{
      bg: 'green.400',
      boxShadow: 'inset 1px 1px 3px rgba(0,0,0,0.3)',
      // eslint-disable-next-line no-underscore-dangle
      ...props._hover,
    }}
    px={4}
    variant='solid'
    {...props}
  />
))
