import { FieldTypes } from '@hb/shared/constants'
import {
  DropdownField, DropdownOptionItem, TextField, ThreadType,
} from '@hb/shared/types'

export const newThreadTypeField: DropdownField = {
  placeholder: 'Thread Type',
  type: FieldTypes.DROPDOWN,
  options: [
    { text: 'Group Chat', id: ThreadType.GROUP },
    { text: 'Direct Message', id: ThreadType.DIRECT },
  ],
}

export const groupThreadTitleField: TextField = {
  placeholder: 'Thread Title',
  type: FieldTypes.TEXT,
}

export const practiceAccessLevelOptions: Array<DropdownOptionItem> = [
  { text: 'HB Admins & Provider', id: 'practice' },
  // { text: 'Patient', id: 'patient' },
]

export const adminAccessLevelOptions: Array<DropdownOptionItem> = [
  { text: 'Hamilton Billing Admins', id: 'admin' },
  ...practiceAccessLevelOptions,
]

export const practiceAccessLevelField: DropdownField = {
  placeholder: 'User Group',
  options: practiceAccessLevelOptions,
  type: FieldTypes.DROPDOWN,
}

export const adminAccessLevelField: DropdownField = {
  placeholder: 'User Group',
  options: adminAccessLevelOptions,
  type: FieldTypes.DROPDOWN,
}
