import { AttachmentIcon, CloseIcon, CopyIcon } from '@chakra-ui/icons'
import {
  Box,
  Divider,
  Flex,
  HStack,
  IconButton,
  Popover,
  PopoverArrow,
  PopoverContent,
  PopoverTrigger,
  Portal,
  Text,
  Tooltip,
  VStack,
} from '@chakra-ui/react'
import {
  FieldTypes,
  FileDBValue,
  FileField,
  PopulatedThreadMessage,
  ThreadType,
  UnuploadedFileDBValue,
  UploadProgress,
} from '@hb/shared'
import React, { useContext, useMemo, useState } from 'react'
import { CopyChatFileToAssessmentModal } from '../../Assessments/CopyFileToAssessmentModal'
import { ExpandOnMount } from '../../ExpandOnMount'
import { FileView } from '../../forms'
import { UploadProgressView } from '../../forms/FinalForm/UploadProgressView'
import { FileViewButton } from '../../forms/Input/File/BaseFileView'
import { ThreadViewContext } from './contexts'

const getAttachedFileField = (
  index: number,
  value: FileDBValue | UnuploadedFileDBValue,
): FileField => ({
  type: FieldTypes.FILE,
  placeholder: value?.name || `Attached File ${index + 1}`,
  name: `attached-file-${index}`,
})
const CopyToAssessmentView = ({
  threadId,
  size,
  file,
  threadType,
}: {
  size?: 'xs' | 'sm' | 'md' | 'lg'
  threadId: string
  file: FileDBValue
  threadType: ThreadType
}) => {
  const [isOpen, setIsOpen] = useState(false)
  // const { processResponse } = useContext(PopUpMessageContext)
  return (
    <Box>
      <Tooltip
        placement="top"
        hasArrow
        bg="gray.50"
        color="gray.600"
        label="Copy to Assessment"
      >
        <FileViewButton
          aria-label="Copy to Assessment"
          size={size}
          onClick={(e) => {
            e.stopPropagation()
            setIsOpen(true)
          }}
        >
          <CopyIcon />
        </FileViewButton>
      </Tooltip>
      {isOpen ? (
        <CopyChatFileToAssessmentModal
          onClose={() => setIsOpen(false)}
          file={file}
          threadId={threadId}
          threadType={threadType}
        />
      ) : null}
    </Box>
  )
}

export const AttachedFile = ({
  index,
  file,
  onRemove,
}: {
  index: number
  file: FileDBValue | UnuploadedFileDBValue
  onRemove?: () => void
}) => {
  const { threadId, threadType } = useContext(ThreadViewContext)
  const isImageType = file.type.startsWith('image/')
  return (
    <Flex
      p={!isImageType ? 1 : 0}
      bg={!isImageType ? 'gray.50' : 'transparent'}
      border={!isImageType ? '1px solid #cdcdcd' : 'none'}
      borderRadius={6}
      flexFlow="column"
      maxW="160px"
      alignItems="center"
      key={`attached-file-${index}`}
      position="relative"
    >
      <HStack zIndex={2} pos={isImageType ? 'absolute' : 'relative'} w="100%">
        {!isImageType ? (
          <Tooltip
            label={file.name || `Attached File ${index + 1}`}
            placement="top"
          >
            <Text w="100%" flex={1} px={2} minW="0" isTruncated fontSize="sm">
              {file.name || `Attached File ${index + 1}`}
            </Text>
          </Tooltip>
        ) : null}
        {onRemove ? (
          <IconButton
            size="xs"
            ml="auto"
            color="white"
            bg="red.400"
            borderRadius="full"
            aria-label="Remove"
            icon={<CloseIcon />}
            onClick={() => onRemove()}
          />
        ) : null}
      </HStack>
      <FileView small field={getAttachedFileField(index, file)} data={file}>
        {onRemove ? null : (
          <CopyToAssessmentView
            threadType={threadType}
            file={file as FileDBValue}
            threadId={threadId}
            size={isImageType ? 'xs' : 'sm'}
          />
        )}
      </FileView>
    </Flex>
  )
}

export const UploadingAttachedFiles = ({
  attachedFiles,
  onRemove,
  uploads,
}: {
  attachedFiles: UnuploadedFileDBValue[]
  onRemove: (index: number) => void
  uploads: Record<string, UploadProgress>
}) => (
  <Flex
    height={
      attachedFiles.length
        ? `${120} + ${Object.keys(uploads).length * 20}`
        : '0px'
    }
    overflowY="hidden"
    opacity={attachedFiles.length ? 1 : 0}
    transition="all 300ms"
    overflowX="auto"
    flexFlow="column"
    w="100%"
    bg="gray.100"
  >
    <VStack
      pos="relative"
      borderTop="1px solid #cdcdcd"
      py={1}
      px={2}
      height="120px"
      align="flex-start"
      w="100%"
      spacing={0}
    >
      <Flex gap={1} flexFlow="row wrap" align="center">
        {attachedFiles.map((file, index) => (
          <ExpandOnMount
            style={{ width: 'auto' }}
            key={`attached-file-${index}`}
          >
            <AttachedFile
              index={index}
              file={file}
              onRemove={() => onRemove(index)}
            />
          </ExpandOnMount>
        ))}
      </Flex>
    </VStack>
    <UploadProgressView uploads={uploads} />
  </Flex>
)

const AttachedFilesBody = ({
  attachedFiles,
}: {
  attachedFiles: Record<string, FileDBValue>
}) => {
  const sortedKeys = useMemo(
    () => Object.keys(attachedFiles || {}).sort(),
    [attachedFiles],
  )
  return (
    <Flex gap={1} height="110px" flexFlow="row" overflowX="auto" align="center">
      {sortedKeys.map((fileKey, index) => (
        <AttachedFile
          key={`attached-file-${index}`}
          index={index}
          file={attachedFiles?.[fileKey]!}
        />
      ))}
    </Flex>
  )
}

const ThreadMessageAttachmentsPopover = ({
  attachedFiles,
}: {
  attachedFiles: PopulatedThreadMessage['attachedFiles']
}) => {
  const sortedKeys = useMemo(
    () => Object.keys(attachedFiles || {}).sort(),
    [attachedFiles],
  )
  return (
    <Popover trigger="hover" placement="top" isLazy>
      <PopoverTrigger>
        <Flex
          bg="gray.100"
          fontWeight={500}
          align="center"
          fontFamily="Hero-New"
          fontSize="sm"
          border="1px solid #cdcdcd"
          color="gray.400"
          px="0.45rem"
          borderRadius="full"
          gap="0.15rem"
        >
          <AttachmentIcon color="gray.400" />
          <span>{sortedKeys.length}</span>
        </Flex>
      </PopoverTrigger>
      <Portal>
        <PopoverContent
          bg="gray.100"
          borderRadius={6}
          h="auto"
          p={1}
          maxHeight="300px"
          w="auto"
        >
          <AttachedFilesBody attachedFiles={attachedFiles || {}} />
          <PopoverArrow bg="gray.100" />
        </PopoverContent>
      </Portal>
    </Popover>
  )
}

export const AttachedFiles = ({
  attachedFiles,
  isPreview,
}: {
  attachedFiles?: Record<string, FileDBValue>
  isPreview?: boolean
}) => {
  const sortedKeys = useMemo(
    () => Object.keys(attachedFiles || {}).sort(),
    [attachedFiles],
  )
  if (!sortedKeys.length) return null
  return isPreview ? (
    <ThreadMessageAttachmentsPopover attachedFiles={attachedFiles} />
  ) : (
    <>
      <AttachedFilesBody attachedFiles={attachedFiles || {}} />
      <Divider mb={1} mt={2} />
    </>
  )
}

export const AttachedFilesPreview = ({
  attachedFiles,
}: {
  attachedFiles: Record<string, FileDBValue> | undefined
}) => (
    <Flex>
      <Text color='gray.600' fontSize='sm'>
        {Object.keys(attachedFiles || {}).length} Attached Files
      </Text>
    </Flex>
)
