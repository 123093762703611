import { Stack, StackProps } from '@chakra-ui/react'
import React from 'react'
import { colors } from '@hb/shared'

export const InfoBox: React.FC<StackProps> = (props) => (
    <Stack
      py={4}
      px={[3, 6]}
      borderRadius={28}
      border={`2px solid ${colors.green.hex}`}
      fontFamily='hero-new'
      background='white'
      fontWeight={300}
      width='auto'
      spacing={4}
      {...props}
    />
)
