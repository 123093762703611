import {
  Center, CircularProgress, HStack, Text, VStack,
} from '@chakra-ui/react'
import { colors } from '@hb/shared'

import React, { useEffect, useRef } from 'react'
import { useHistory } from 'react-router-dom'
import { FloatingContainer } from '../Containers/FloatingContainer'
import { LoadingOverlay } from '../LoadingOverlay'

export const RedirectView: React.FC<{ loading?: boolean }> = ({ loading }) => {
  const timer = useRef<any | undefined>()
  const history = useHistory()

  useEffect(() => {
    if (!loading) {
      timer.current = setTimeout(() => {
        history.push('/')
      }, 1500)
    }
    return () => {
      if (timer.current) clearTimeout(timer.current)
    }
  }, [loading, history])

  return !loading ? (
    <Center w="100%">
      <FloatingContainer maxW="500px">
        <HStack spacing={3}>
          <CircularProgress isIndeterminate size={6} color={colors.green.hex} />
          <VStack spacing={0} align="flex-start">
            <Text fontFamily="Open Sans">404</Text>
            <Text fontFamily="Open Sans" fontSize="sm" color="gray.600">
              Page not found - redirecting to Home...
            </Text>
          </VStack>
        </HStack>
      </FloatingContainer>
    </Center>
  ) : (
    <LoadingOverlay loading={true} />
  )
}
