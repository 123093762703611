import { Button, ButtonProps, forwardRef } from '@chakra-ui/react'
import React from 'react'

export const CancelButton = forwardRef(
  (props: ButtonProps, ref) => (
  <Button
    ref={ref}
    fontFamily='Comfortaa'
    variant='ghost'
    size='sm'
    mr={3}
    children='Cancel'
    {...props}
  />
  ),
)
