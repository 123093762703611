import { FieldTypes } from '../../constants/FieldTypes'
import {
  BooleanField, DollarAmountField, PercentageField, Stage,
} from '../../types/forms'
import { maximumReimbursableChargesField } from './maximumReimbursableCharges'
import reimbursementOption from './reimbursementOption'
import { oonCondition } from './utils'

export const outOfNetworkDeductibleField: DollarAmountField = {
  type: FieldTypes.DOLLAR_AMOUNT,
  labels: [
    { text: 'What is my individual annual ' },
    { text: 'out of network', style: { textDecoration: 'underline' } },
    { text: ' deductible?' },
  ],
  placeholder: 'Out of Network Deductible',
  condition: oonCondition,
}

export const outOfNetworkCoinsuranceField: PercentageField = {
  type: FieldTypes.PERCENTAGE,
  labels: [
    {
      text: 'What is the percentage coinsurance my plan will cover after any deductible applies?',
    },
  ],
  hints: [{ text: 'Coinsurance is normally a percentage (%) for the year' }],
  validate: (v) => {
    if (parseInt(v, 10) < 50) {
      return 'Should be higher than 50%'
    }
    return undefined
  },
  placeholder: 'Out of Network Coinsurance',
  condition: oonCondition,
}

export const outOfNetworkOutOfPocketMaxField: DollarAmountField = {
  type: FieldTypes.DOLLAR_AMOUNT,
  labels: [
    { text: 'What is my individual annual ' },
    { text: 'out of network', style: { textDecoration: 'underline' } },
    { text: ' out of pocket max?' },
  ],
  placeholder: 'Out of Network Out of Pocket Max',
  condition: oonCondition,
}

export const outOfNetworkDeductibleCountsTowardOOPMField: BooleanField = {
  type: FieldTypes.BOOLEAN,
  labels: [
    { text: 'Does my deductible count towards the out of pocket max?' },
  ],
  placeholder: 'Deductible counts toward OOPM?',
  condition: oonCondition,
}

const outOfNetwork: Stage = {
  storagePath: 'insurance-info.primaryCoverage.out-of-network',
  name: 'Out of Network Coverage',
  children: {
    noBenefits: {
      type: FieldTypes.CHECKBOX,
      placeholder: 'I do not have out of network benefits',
      optional: true,
    },
    deductible: outOfNetworkDeductibleField,
    coinsurance: outOfNetworkCoinsuranceField,
    outOfPocketMax: outOfNetworkOutOfPocketMaxField,
    deductibleCountsTowardOOPM: outOfNetworkDeductibleCountsTowardOOPMField,
    reimbursementOption,
    maximumReimbursableCharges: maximumReimbursableChargesField,
  },
}

export default outOfNetwork
