import { AddIcon } from '@chakra-ui/icons'
import {
  Box,
  HStack,
  IconButton,
  Image, Tooltip, VStack,
} from '@chakra-ui/react'
import { PracticeInvoice, UpdateCallback, WithId } from '@hb/shared'
import React, { useCallback, useContext, useState } from 'react'
import {
  archiveInvoice, deleteInvoice, restoreDeletedInvoice, unarchiveInvoice,
} from '../../../backend/invoices'
import { PopUpMessageContext } from '../../../contexts/PopUpMessage/PopUpMessageContext'
import archiveIcon from '../../../icons/archive.svg'
import unarchiveIcon from '../../../icons/unarchive.svg'
import { DeleteButton } from '../../Buttons'
import { NoteForm } from '../../forms/NoteForm'
import { updateInvoiceAdminNote } from './utils'

const actions: Record<
  'archive' | 'unarchive' | 'delete' | 'recover',
  (invoiceId: string) => Promise<UpdateCallback>
> = {
  archive: (invoiceId) => archiveInvoice({ invoiceId }).then(() => ({ success: 'Archived invoice' })),
  unarchive: (invoiceId) => unarchiveInvoice({ invoiceId }).then(() => ({
    success: 'Un-archived invoice',
  })),
  delete: (invoiceId) => deleteInvoice({ invoiceId }).then(() => ({ success: 'Deleted invoice' })),
  recover: (invoiceId) => restoreDeletedInvoice({ invoiceId }).then(() => ({ success: 'Restored deleted invoice' })),
}
export const InvoiceAdminNotes = ({
  invoice,
  onClose,
}: {
  invoice: WithId<PracticeInvoice>
  onClose: () => void
}) => {
  const [loadingAction, setLoadingAction] = useState<
    'archive' | 'unarchive' | 'delete' | 'recover' | null
  >(null)
  const { processResponse } = useContext(PopUpMessageContext)
  const handleAction = useCallback(
    (action: 'archive' | 'unarchive' | 'delete' | 'recover') => {
      setLoadingAction(action)

      actions[action](invoice.id)
        .then((res) => {
          setLoadingAction(null)
          processResponse(res)
          if (action === 'delete')onClose()
        })
        .catch((err) => {
          setLoadingAction(null)
          processResponse({ error: err.message })
        })
    },
    [invoice, processResponse, onClose],
  )

  return (
    <VStack w="100%">
      <Box
        bg="gray.50"
        borderTop="1px solid #cdcdcd"
        borderBottomRadius={4}
        w="100%"
      >
        <NoteForm
          placeholder="Admin note"
          onSubmit={(v) => updateInvoiceAdminNote(invoice.id, v || '')}
          note={invoice?.adminNote}
        />
      </Box>
      <HStack justify='flex-end' width='100%' pb={1} px={2}>
        <Tooltip placement='top' hasArrow label={invoice.archivedOn ? 'Unarchive Invoice' : 'Archive Invoice'}>
        <IconButton
          aria-label={invoice.archivedOn ? 'Unarchive invoice' : 'Archive invoice'}
          size='sm'
          variant='ghost'
          isLoading={
            loadingAction === 'archive' || loadingAction === 'unarchive'
          }
          onClick={() => handleAction(invoice.archivedOn ? 'unarchive' : 'archive')
          }
          icon={<Image src={invoice.archivedOn ? unarchiveIcon : archiveIcon} height="24px" />}
        />
        </Tooltip>
        {
          invoice.deletedOn ? (
            <Tooltip placement='top' hasArrow label={'Recover deleted invoice'}>
            <IconButton
              aria-label={'Recover deleted invoice'}
              size='sm'
              variant='ghost'
              color='green.500'
              isLoading={
                loadingAction === 'recover'
              }
              onClick={() => handleAction('recover')
              }
              icon={<AddIcon/>}
            />
            </Tooltip>
          ) : <DeleteButton
          size='sm'
          itemName="invoice"
          onDelete={() => handleAction('delete')}
        />
        }

      </HStack>
    </VStack>
  )
}
