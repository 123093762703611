import { EditIcon } from '@chakra-ui/icons'
import {
  Badge,
  Box,
  CloseButton,
  Collapse,
  Flex,
  HStack,
  IconButton,
  Text,
  VStack,
} from '@chakra-ui/react'
import {
  colors,
  DateField,
  DropdownField,
  FieldMap,
  FieldMapValue,
  FieldTypes,
  fnameField,
  getDateString,
  MultipleSelectField,
  notesField,
  TextField,
} from '@hb/shared'

import React, {
  useCallback, useContext, useMemo, useState,
} from 'react'
import { updateAssessmentDeliveryStatus } from '../../../../backend'
import { PopUpMessageContext, useApp, UserContext } from '../../../../contexts'
import { formatValue } from '../../../../utils'
import { ActionLog } from '../../../ActionLog'
import { DeleteButton } from '../../../Buttons/DeleteButton'
import { SimpleForm } from '../../../forms'
import { CollapseHorizontal } from '../../../shared'

const dateField: DateField = {
  type: FieldTypes.DATE,
  placeholder: 'Date of Delivery',
  maxDate: 'now',
}

const sexField: DropdownField = {
  type: FieldTypes.DROPDOWN,
  placeholder: 'Sex of Newborn',
  options: [
    {
      id: 'M',
      text: 'Male',
    },
    {
      id: 'F',
      text: 'Female',
    },
  ],
}

const locationField: DropdownField = {
  type: FieldTypes.DROPDOWN,
  placeholder: 'Delivery Location',
  options: [
    {
      id: 'home',
      text: 'Home',
    },
    {
      id: 'hospital',
      text: 'Hospital',
    },
    {
      id: 'birthing-center',
      text: 'Birthing Center',
    },
    {
      id: 'other',
      text: 'Other',
    },
  ],
}
// 'nsvd' | 'cSection' | 'breech' | 'vacuum' | 'forceps'
const locationOtherField: TextField = {
  type: FieldTypes.TEXT,
  placeholder: 'Other Delivery Location',
  condition: (v) => v?.deliveryLocation === 'other',
}
const deliveryTypesField: MultipleSelectField = {
  type: FieldTypes.MULTIPLE_SELECT,
  placeholder: 'Delivery Types',
  options: [
    { id: 'nsvd', text: 'NSVD' },
    { id: 'cSection', text: 'C-Section' },
    { id: 'breech', text: 'Breech' },
    { id: 'vacuum', text: 'Vacuum' },
    { id: 'forceps', text: 'Forceps' },
  ],
}

const reportDeliveryField: FieldMap = {
  name: 'Report Delivery',
  children: {
    date: dateField,
    newbornSex: sexField,
    newbornFname: {
      ...fnameField,
      placeholder: 'Newborn First Name',
      optional: true,
    },
    newbornLname: {
      ...fnameField,
      placeholder: 'Newborn Last Name',
      optional: true,
    },
    newbornWeight: {
      type: FieldTypes.NUMBER,
      placeholder: 'Newborn Weight (pounds)',
      precision: 2,
      optional: true,
      min: 0,
    },
    deliveryLocation: locationField,
    deliveryLocationOther: locationOtherField,
    deliveryTypes: deliveryTypesField,
    summary: { ...notesField, placeholder: 'Summary' },
  },
}

export const ReportDeliveryContent = ({
  onClose,
}: {
  onClose?: () => void
}) => {
  const { processResponse } = useContext(PopUpMessageContext)
  const { assessmentId, selectedAssessment } = useContext(UserContext)
  const { deliveredOn, delivery } = selectedAssessment || {}
  const {
    updatedBy,
    updatedOn,
    updatedByGroup,
    newbornFname,
    newbornLname,
    newbornWeight,
  } = delivery || {}
  const [isEditing, setIsEditing] = useState(!deliveredOn)
  // const fieldKeys = useMemo(() => Object.keys(fields || {}), [fields])
  const { appName } = useApp()
  const handleSubmit = useCallback(
    async (d: FieldMapValue | null) => {
      if (!assessmentId) {
        return { error: 'Internal error' }
      }

      return updateAssessmentDeliveryStatus({
        appName,
        assessmentId,
        deliveredOn: d?.date || null,
        newbornSex: d?.newbornSex || null,
        newbornWeight: d?.newbornWeight || null,
        newbornFname: d?.newbornFname || null,
        newbornLname: d?.newbornLname || null,
        summary: d?.summary || null,
        deliveryTypes: d?.deliveryTypes || null,
        deliveryLocation: d?.deliveryLocation || null,
        deliveryLocationOther: d?.deliveryLocationOther || null,
      })
        .then(() => {
          // if (onClose) onClose()
          if (d?.date) {
            setIsEditing(false)
          } else {
            setIsEditing(true)
          }
          return processResponse({ success: 'Report delivery updated' })
        })
        .catch((err: any) => {
          console.error(err)
          return processResponse({
            error: err?.message || 'Error updating report delivery',
          })
        })
    },
    [processResponse, assessmentId, appName],
  )

  const value = useMemo(
    () => (deliveredOn
      ? {
        date: deliveredOn,
        newbornSex: delivery?.newbornSex,
        deliveryLocation: delivery?.location,
        deliveryLocationOther: delivery?.locationOther,
        newbornFname: delivery?.newbornFname,
        newbornLname: delivery?.newbornLname,
        newbornWeight: delivery?.newbornWeight,
        deliveryTypes: delivery?.deliveryTypes,
        summary: delivery?.summary,
      }
      : {}),
    [deliveredOn, delivery],
  )

  const formattedDate = useMemo(
    () => (deliveredOn ? getDateString(deliveredOn) : ''),
    [deliveredOn],
  )
  const formattedSex = useMemo(
    () => formatValue({ field: sexField, value: delivery?.newbornSex }),
    [delivery?.newbornSex],
  )
  const formattedLocation = useMemo(
    () => (delivery?.location === 'other'
      ? `Other - ${delivery?.locationOther || ''}`
      : formatValue({ field: locationField, value: delivery?.location })),
    [delivery],
  )

  return (
    <Flex h="100%" borderRadius={6} flexDir="column">
      <HStack
        borderBottom="1px solid #cdcdcd"
        px={3}
        py={1}
        bg={colors.green.hex}
        borderTopRadius={6}
      >
        <Text
          fontSize="md"
          fontFamily="Comfortaa"
          fontWeight={600}
          textShadow="1px 1px 3px #00000077"
          color="white"
        >
          {deliveredOn ? 'Delivery Info' : 'Report Delivery'}
        </Text>
        <CollapseHorizontal width={40} in={!isEditing && !!deliveredOn}>
          <HStack spacing={1}>
            <IconButton
              aria-label="Edit"
              icon={<EditIcon />}
              _hover={{
                bg: 'blackAlpha.200',
              }}
              borderRadius="full"
              onClick={() => setIsEditing(true)}
              size="sm"
              variant="ghost"
              color="white"
            />
          </HStack>
        </CollapseHorizontal>
        {onClose ? (
          <CloseButton
            ml="auto"
            color="white"
            borderRadius="full"
            bg="blackAlpha.200"
            size="sm"
            onClick={onClose}
          />
        ) : null}
      </HStack>
      <Box overflowY='auto' flex={1} minH='0' w="100%">
        <Collapse style={{ width: '100%' }} in={!isEditing}>
          <VStack
            px={3}
            spacing={0}
            align="flex-start"
            py={2}
            fontFamily="Hero-New"
            fontSize="0.92rem"
            borderBottomRadius={6}
            onClick={() => setIsEditing(true)}
          >
            <HStack>
              <Text color="gray.500" fontWeight={500} fontSize="sm">
                Date of Delivery
              </Text>
              <Text>{formattedDate || 'None'}</Text>
            </HStack>
            <HStack>
              <Text color="gray.500" fontWeight={500} fontSize="sm">
                Sex of Newborn
              </Text>
              <Text>{formattedSex || 'None'}</Text>
            </HStack>
            <HStack>
              <Text color="gray.500" fontWeight={500} fontSize="sm">
                Newborn First Name
              </Text>
              <Text>{newbornFname || 'None'}</Text>
            </HStack>
            <HStack>
              <Text color="gray.500" fontWeight={500} fontSize="sm">
                Newborn Last Name
              </Text>
              <Text>{newbornLname || 'None'}</Text>
            </HStack>
            <HStack>
              <Text color="gray.500" fontWeight={500} fontSize="sm">
                Newborn Weight (pounds)
              </Text>
              <Text>{newbornWeight || 'None'}</Text>
            </HStack>
            <HStack w="100%">
              <Text color="gray.500" fontWeight={500} fontSize="sm">
                Delivery Location
              </Text>
              <Text>{formattedLocation || 'None'}</Text>
            </HStack>
            <HStack w="100%">
              <Text color="gray.500" fontWeight={500} fontSize="sm">
                Delivery Types
              </Text>
              <HStack>
                {delivery?.deliveryTypes?.length ? (
                  delivery?.deliveryTypes.map((v) => (
                    <Badge
                      fontWeight={500}
                      borderRadius="full"
                      bg={colors.green.hex}
                      color="green.50"
                      textShadow="1px 1px 3px #00000077"
                      key={v}
                      px={2}
                      fontSize="xs"
                      fontFamily="Hero-New"
                    >
                      {v}
                    </Badge>
                  ))
                ) : (
                  <Text color="gray.500">None</Text>
                )}
              </HStack>
            </HStack>
            <HStack w="100%">
              <Text color="gray.500" fontWeight={500} fontSize="sm">
                Summary
              </Text>
              <Text>{delivery?.summary || 'None'}</Text>
            </HStack>
            <HStack w="100%">
              <ActionLog
                action="Updated"
                by={updatedBy}
                group={updatedByGroup}
                on={updatedOn}
              />
              <DeleteButton
                size="xs"
                ml="auto"
                variant="ghost"
                color={colors.red.hex}
                itemName="delivery report"
                onDelete={() => handleSubmit(null)}
              />
            </HStack>
          </VStack>
        </Collapse>
        <Collapse style={{ width: '100%' }} in={isEditing}>
          <SimpleForm
            onCancel={deliveredOn ? () => setIsEditing(false) : onClose} //
            boxProps={{ bg: 'transparent' }}
            theme="detailed"
            field={reportDeliveryField}
            value={value}
            onSubmit={handleSubmit}
          />
        </Collapse>
      </Box>
    </Flex>
  )
}
