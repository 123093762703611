import {
  AdminUserData, getFullName, getReverseName, PopulatedAdmin, PopulatedAdminData, User, UserRoleItem, USERS, USERS_ADMIN, USER_ROLES,
} from '@hb/shared'
import { useMemo } from 'react'
import { useDocument } from '../../hooks/backend/useDocument'
import { useAuth } from '../../store/auth'

export default function usePopulatedAdmin(
  id?: string,
  disabled?: boolean,
): PopulatedAdminData {
  const admin = useAuth((s) => s.admin)
  const {
    data: usersItem,
    loading: uLoading,
    ref: usersItemRef,
  } = useDocument<User>(USERS, id)
  const {
    data: adminItem,
    loading: aLoading,
    ref: adminUserRef,
  } = useDocument<AdminUserData>(USERS_ADMIN, id, !!admin)

  const {
    data: roleItem,
    ref: userRoleRef,
    loading: rLoading,
  } = useDocument<UserRoleItem>(USER_ROLES, id, !!admin)

  const loading = useMemo(() => {
    if (aLoading || uLoading || rLoading) return true
    return false
  }, [aLoading, uLoading, rLoading])
  const {
    item,
    ref: userRef,
    isInvite,
  } = useMemo(() => {
    if (uLoading || disabled) return { item: null, ref: null, isInvite: false }
    return { item: usersItem, ref: usersItemRef, isInvite: false }
  }, [usersItem, disabled, uLoading, usersItemRef])

  const user = useMemo<PopulatedAdmin | null>(() => {
    if (!id) return null
    return {
      ...roleItem,
      ...item,
      ...adminItem,
      isInvite,
      name: getFullName(item),
      reverseName: getReverseName(item),
      id,
    }
  }, [
    item,
    adminItem,
    id,
    roleItem,
    isInvite,
  ])
  return useMemo(() => ({
    user,
    loading,
    userRoleRef,
    userRef,
    adminUserRef,
  }), [user, loading, userRef, adminUserRef, userRoleRef])
}
